import React, { useState, useEffect, Fragment } from "react";

import { Button, Card, Modal, Table } from "react-bootstrap";
import { Trans } from "react-i18next";
import i18next from "i18next";
import { format, toZonedTime } from "date-fns-tz";
import BlockUI from "util/block-ui/block-ui";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Lottie from "lottie-react";
import loadingAnimation from "assets/img/animated-icons/loading.json";
import { startOfDay } from "date-fns";
import { ReactComponent as PaymentIcon } from "assets/img/icons/payment.svg";
import ZymranAvatar from "component/common/ZymranAvatar";

import { getChainedAppointments } from "../../service/appointment/AppointmentService";
import NewCheckout from "./checkout/NewCheckout";
import useLocale from "hooks/useLocale";

const ViewAppointment = ({
  chainId,
  calendarStartTime,
  timezone,
  selectedLocationId,
  updateCheckoutInfo,
  handleShowEditAppointmentModalFromView,
}) => {

  const locale = useLocale();

  const APPOINTMENT_STATUS_FINISHED = "finished";
  const [appointments, setAppointments] = useState([]);
  const [appointmentDivs, setAppointmentDivs] = useState(null);
  const [firstAppointment, setFirstAppointment] = useState(null);
  const [blocking, setBlocking] = useState(false);
  const [showAppointmentCheckoutModal, setShowAppointmentCheckoutModal] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    phoneNumberPrimary: "",
    phoneNumberSecondary: "",
    dob: "",
    createdAt: "",
    notes: "",
  });

  const zonedCurrentTime = toZonedTime(calendarStartTime, timezone);
  const currentDateWithoutTime = startOfDay(zonedCurrentTime);
  const formattedDayOfWeek = format(currentDateWithoutTime, "EEEE", { locale });
  const formattedDate = format(currentDateWithoutTime, "d MMM, yyyy", {
    locale,
  });
  const totalSum = appointments.reduce((acc, curr) => acc + curr.price, 0);

  useEffect(() => {
    if (appointments.length === 0) {
      loadChainedAppointments(chainId);
    }
    // eslint-disable-next-line
  }, [chainId, appointments.length]);

  useEffect(() => {
    if (appointments.length > 0) {
      const firstAppointment = appointments[0];
      setCustomer(firstAppointment.customer);
      setFirstAppointment(firstAppointment);
      setLoading(false);
      prepareAppointmentDivs();
    }
    // eslint-disable-next-line
  }, [appointments.length]);

  const handleCloseAppointmentCheckoutModal = () => {
    setShowAppointmentCheckoutModal(false);
  };

  const handleCloseAppointmentCheckout = () => {
    loadChainedAppointments(chainId);
    setShowAppointmentCheckoutModal(false);
  };

  const getDurationInHoursAndMinutes = (startTime, endTime) => {
    const duration = moment.duration(moment(endTime).diff(moment(startTime)));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());

    return i18next.t("service_duration_in_hours_and_minutes", {
      hours,
      minutes,
    });
  };

  const prepareAppointmentDivs = () => {
    const appointmentDivs = appointments.map((appointment, index) => {
      return (
        <tr key={index} className="appointment-view-table-body-row">
          <td className="p1-bold appointment-view-number">{index + 1}</td>
          <td className="p2-bold primary-text-color">
            {moment(appointment.startTime).format("HH:mm")}
          </td>
          <td>
            <div className="d-flex flex-column">
              <div className="p2-bold primary-text-color">
                {appointment.selectedService.label}
              </div>
              <div className="p2 secondary-text-color">
                {getDurationInHoursAndMinutes(
                  appointment.startTime,
                  appointment.endTime
                )}
              </div>
            </div>
          </td>
          <td>
            <div className="d-flex flex-row align-items-center">
              <ZymranAvatar
                avatarUrl={appointment.staff.avatarUrl}
                size="md"
                shape="circle"
                className={"me-2"}
              />
              <div className="d-flex flex-column">
                <div className="p2-bold primary-text-color">
                  {`${appointment.staff.firstName} ${appointment.staff.lastName}`}
                </div>
                <div className="p2 secondary-text-color">
                  {appointment.staff.position}
                </div>
              </div>
            </div>
          </td>
          <td className="p2-bold primary-text-color">{appointment.price}</td>
        </tr>
      );
    });
    setAppointmentDivs(appointmentDivs);
  };

  const loadChainedAppointments = async (chainId) => {
    try {
      setBlocking(true);
      const response = await getChainedAppointments(chainId);
      const appointmentList = response.data;
      const appts = [];
      appointmentList.forEach((appointment) => {
        const appt = {
          id: appointment.id,
          chainId: appointment.chainId,
          startTime: toZonedTime(new Date(appointment.startTime), timezone),
          endTime: toZonedTime(new Date(appointment.endTime), timezone),
          duration: calculateDuration(
            appointment.startTime,
            appointment.endTime
          ),
          resourceId: appointment.staffLocation.id,
          selectedStaffLocation: {
            value: appointment.staffLocation.id,
            label:
              appointment.staffLocation.staff.firstName +
              " " +
              appointment.staffLocation.staff.lastName,
          },
          selectedService: {
            value: appointment.service.id,
            label: appointment.service.name,
          },
          staff: appointment.staffLocation.staff,
          customer: appointment.customer,
          price: appointment.service.price,
          checkoutInfo: appointment.checkoutInfo,
          status: appointment.status,
          fromDB: true,
          toSave: false,
          toDelete: false,
          comment: appointment.comment,
        };
        appts.push(appt);
      });
      setAppointments(appts);
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const duration = (end - start) / 60000; // Calculate the duration in minutes
    return duration;
  };

  ViewAppointment.propTypes = {
    calendarStartTime: PropTypes.instanceOf(Date).isRequired,
    timezone: PropTypes.string.isRequired,
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-centerf">
          <Lottie
            animationData={loadingAnimation}
            loop={true}
            style={{ height: "220px", width: "220px" }}
          />
        </div>
      ) : (
        <>
          <Card className="appointment-service-card">
            <Card.Header className="appointment-info-header">
              <h3 className="mb-0">
                {formattedDayOfWeek}, {formattedDate}
              </h3>
            </Card.Header>
            <Card.Body className="appointment-service-card-body">
              <Table borderless>
                <thead className="appointment-view-table-header">
                  <tr className="primary-text-color">
                    <th></th>
                    <th scope="col" className="w-25 align-middle p1-bold">
                      <Trans>time</Trans>
                    </th>
                    <th scope="col" className="w-25 align-middle p1-bold">
                      <Trans>service</Trans>
                    </th>
                    <th scope="col" className="align-middle p1-bold">
                      <Trans>staff_member</Trans>
                    </th>
                    <th className="align-middle p1-bold" scope="col">
                      <Trans>cost</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>{appointmentDivs}</tbody>
                <tfoot className="appointment-view-table-footer mt-2">
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="p1-bold primary-text-color">
                      <Trans>total</Trans>
                    </td>
                    <td className="primary-text-color">
                      <div className="p1-bold">{totalSum}</div>
                      <div className="p1">
                        <Trans>tenge</Trans>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </Table>
              <BlockUI tag="div" blocking={blocking} />
            </Card.Body>
          </Card>
          <div className="customer-pane-column">
            <Card className="customer-pane">
              <Card.Header className="customer-pane__header">
                <h3 className="mb-0">
                  <Trans>customer</Trans>
                </h3>
              </Card.Header>
              <Card.Body className="overflow-auto customer-pane__body">
                <div className="customer-pane__avatar-info">
                  {customer ? (
                    <>
                      <ZymranAvatar
                        avatarUrl={customer.avatarUrl}
                        fullName={`${customer.firstName} ${customer.lastName}`}
                        size="xl"
                        shape="circle"
                      />
                      <div className="customer-pane__info">
                        <div className="p1-bold">
                          {customer.firstName} {customer.lastName}
                        </div>
                        <div className="p2">
                          <a href={`mailto:`}>{customer.email}</a>
                        </div>
                        <p className="p2 secondary-text-color mb-0">
                          {customer.phoneNumberPrimary}
                        </p>
                        <p className="p2 secondary-text-color mb-0">
                          <Trans>birth_date</Trans>:{" "}
                          {customer.dob ? moment(customer.dob).format("DD.MM.YYYY") : ""}
                        </p>
                      </div>
                    </>
                  ) : (
                    <div className="d-flex flex-grow-1 justify-content-center">
                      <Trans>no_customer_data_available</Trans>
                    </div>
                  )}
                </div>
                <hr className="customer-pane__divider" />
                {appointments.length > 0 &&
                  appointments[0].status &&
                  appointments[0].status.name === "finished" &&
                  appointments[0].checkoutInfo ? (
                  <>
                    <div className="customer-pane__payment-date-block">
                      <span className="primary-text-color p1-bold">
                        <Trans>appointment_paid</Trans>
                      </span>
                      <span className="secondary-text-color p2">
                        {format(
                          toZonedTime(
                            appointments[0].checkoutInfo.checkoutDate,
                            timezone
                          ),
                          "dd MMM yyyy HH:mm",
                          { locale }
                        )}
                      </span>
                    </div>
                    <hr className="customer-pane__divider" />
                    <div className="customer-pane__payment-block">
                      {appointments[0].checkoutInfo.cashRegisterSums.map(
                        (cashRegisterSum, index) => {
                          return (
                            <Fragment key={index}>
                              <div className="customer-pane__payment-details primary-text-color">
                                <span className="p2">
                                  <Trans>payment_method</Trans>
                                </span>
                                <span className="p2-bold">
                                  <Trans>{`cash_register_type_${cashRegisterSum.cashRegisterName}`}</Trans>
                                </span>
                              </div>
                              <div className="customer-pane__payment-details primary-text-color">
                                <span className="p2">
                                  <Trans>payment_amount</Trans>
                                </span>
                                <span className="p2-bold">
                                  {cashRegisterSum.sum}
                                </span>
                              </div>
                            </Fragment>
                          );
                        }
                      )}
                    </div>
                    <div className="customer-pane__payment-block">
                      {appointments[0].checkoutInfo.cashRegisterChange.map(
                        (cashRegisterChange, index) => {
                          return (
                            <Fragment key={index}>
                              <div className="customer-pane__payment-details primary-text-color">
                                <span className="p2">
                                  <Trans>change_return_method</Trans>
                                </span>
                                <span className="p2-bold">
                                  <Trans>{`cash_register_type_${cashRegisterChange.name}`}</Trans>
                                </span>
                              </div>
                              <div className="customer-pane__payment-details primary-text-color">
                                <span className="p2">
                                  <Trans>payment_amount</Trans>
                                </span>
                                <span className="p2-bold">
                                  {cashRegisterChange.sum}
                                </span>
                              </div>
                            </Fragment>
                          );
                        }
                      )}
                    </div>
                    <div className="customer-pane__accepted-by-block primary-text-color">
                      <span className="p2">
                        <Trans>payment_accepted_by</Trans>
                      </span>
                      <div className="customer-pane__accepted-by-name-block ">
                        <span className="p2-bold">
                          {
                            appointments[0].checkoutInfo.acceptedByUser
                              .firstName
                          }{" "}
                          {appointments[0].checkoutInfo.acceptedByUser.lastName}
                        </span>
                        <span className="secondary-text-color p3">
                          {appointments[0].checkoutInfo.acceptedByUser.position}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="customer-pane__unpaid-box">
                    <PaymentIcon
                      className="mb-3"
                      style={{ height: 96, width: 96 }}
                    />
                    <span className="p2 secondary-text-color">
                      <Trans>appointment_not_paid</Trans>
                    </span>
                  </div>
                )}
                {/* <CustomerInfoForCalendar customerData={customer} updateCustomer={updateCustomer} /> */}
              </Card.Body>
            </Card>
            <div className="d-flex align-items-end align-self-stretch gap-3">
              {appointments[0]?.status?.name !==
                APPOINTMENT_STATUS_FINISHED && (
                  <Button
                    name="editRecord"
                    variant="grey"
                    onClick={handleShowEditAppointmentModalFromView}
                    className="flex-1 text-nowrap"
                  >
                    <Trans>edit_record</Trans>
                  </Button>
                )}
              {appointments.length > 0 &&
                appointments[0].status &&
                appointments[0].status.name === "new" && (
                  <Button
                    name="checkout"
                    variant="blue"
                    onClick={() => setShowAppointmentCheckoutModal(true)}
                    className="flex-1"
                  >
                    <Trans>checkout_button</Trans>
                  </Button>
                )}
            </div>
          </div>
        </>
      )}
      <Modal
        id="appointmentCheckout"
        name="appointmentCheckout"
        show={showAppointmentCheckoutModal}
        onHide={() => setShowAppointmentCheckoutModal(false)}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="bg-primary px-card border-bottom-0"
        >
          <Modal.Title as="h5" className="text-white light">
            <Trans>appointment_checkout</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <NewCheckout
            appointment={firstAppointment}
            locationId={selectedLocationId}
            handleClose={handleCloseAppointmentCheckout}
            updateCheckoutInfo={updateCheckoutInfo}
            currentDateFormatted={formattedDate}
            timezone={timezone}
          />
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Button
            name="close"
            variant="secondary"
            onClick={handleCloseAppointmentCheckoutModal}
          >
            <Trans>close</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewAppointment;
