import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

const EnhancedCreatableSelect = ({
  id,
  name,
  label,
  placeholder,
  options,
  onChange,
  onCreateOption,
  value,
  errors,
  formatCreateLabel,
  groupClassName = '',
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Form.Group className={`position-relative ${groupClassName}`}>
      {value && (
        <Form.Label
          className={`${errors ? 'is-invalid' : ''} ${isFocused ? 'is-focused' : ''}`}
        >
          {label}:
        </Form.Label>
      )}
      <CreatableSelect
        id={id}
        name={name}
        isClearable
        options={options}
        onChange={onChange}
        onCreateOption={onCreateOption}
        value={value}
        placeholder={placeholder}
        className={`custom-createable-select ${errors ? 'is-invalid-react-select' : ''}`}
        formatCreateLabel={formatCreateLabel}
        onMenuOpen={() => setIsFocused(true)}
        onMenuClose={() => setIsFocused(false)}
      />
      <Form.Control.Feedback type='invalid'>{errors}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default EnhancedCreatableSelect;