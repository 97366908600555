import EnhancedFormControl from "component/common/EnhancedFormControl";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { updateCashRegisters } from "service/cash-register/CashRegisterService";
import { getLocations } from "service/location/LocationService";
import { notifySuccess } from "util/notify";

const EditCashRegister = ({
  selectedCashRegisterGroup,
  selectedCashRegisterGroupId,
  onUpdate,
  onDelete,
  handleClose,
}) => {
  const [showWarningModal, setShowWarningModal] = useState(true);
  const [locations, setLocations] = useState([]);
  const [locationAddresses, setLocationAddresses] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState({});
  const [blocking, setBlocking] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(6);
  const [errors, setErrors] = useState({});
  const allLocationsCheckboxRef = useRef();
  const isSelectedLocationsInitialized = useRef(false);
  const { t } = useTranslation();

  useEffect(() => {
    loadLocations();
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      initSelectedLocations();
      isSelectedLocationsInitialized.current = true;
    }
    // eslint-disable-next-line
  }, [locations.length]);

  useEffect(() => {
    if (locations.length > 0) {
      prepareLocationsDivs();
    }
    // eslint-disable-next-line
  }, [
    isSelectedLocationsInitialized,
    selectedLocations,
    errors.selectedLocations,
    itemsToShow,
  ]);

  useEffect(() => {
    if (allLocationsCheckboxRef.current) {
      if (checkAllLocationsSelected()) {
        allLocationsCheckboxRef.current.indeterminate = false;
        allLocationsCheckboxRef.current.checked = true;
      } else if (checkAtLeastOneLocationSelected()) {
        allLocationsCheckboxRef.current.indeterminate = true;
        allLocationsCheckboxRef.current.checked = false;
      } else {
        allLocationsCheckboxRef.current.indeterminate = false;
        allLocationsCheckboxRef.current.checked = false;
      }
    }
    // eslint-disable-next-line
  }, [selectedLocations]);

  const checkAllLocationsSelected = () => {
    for (let i = 0; i < locations.length; i++) {
      if (!selectedLocations[locations[i].id]) {
        return false;
      }
    }
    return true;
  };

  const checkAtLeastOneLocationSelected = () => {
    for (let i = 0; i < locations.length; i++) {
      if (selectedLocations[locations[i].id]) {
        return true;
      }
    }
    return false;
  };

  const initSelectedLocations = () => {
    let selectedLocs = {};
    locations.forEach((item) => {
      if (
        selectedCashRegisterGroup.locations.some((loc) => loc.id === item.id)
      ) {
        selectedLocs[item.id] = true;
      } else {
        selectedLocs[item.id] = false;
      }
    });
    setSelectedLocations(selectedLocs);
  };

  const loadLocations = async () => {
    let response = [];
    try {
      response = await getLocations();
      setLocations(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeLocation = (locId, checked) => {
    setSelectedLocations((prevSelectedLocations) => ({
      ...prevSelectedLocations,
      [locId]: checked,
    }));

    if (!!errors.selectedLocations) {
      setErrors({
        ...errors,
        selectedLocations: null,
      });
    }
  };

  const onChangeAllLocations = (e) => {
    let selectedLocs = {};
    if (e.target.checked) {
      locations.forEach((item) => {
        selectedLocs[item.id] = true;
      });
    } else {
      locations.forEach((item) => {
        selectedLocs[item.id] = false;
      });
    }
    if (!!errors.selectedLocations) {
      setErrors({
        ...errors,
        selectedLocations: null,
      });
    }
    setSelectedLocations(selectedLocs);
  };

  const prepareLocationsDivs = () => {
    let locationAddresses = [];

    locationAddresses = locations.slice(0, itemsToShow).map((loc, index) => {
      let la = loc.address;

      return (
        <Form.Check
          key={loc.id}
          label={la.city + " " + la.street + " " + la.houseNumber}
          type="checkbox"
          id={`la-checkbox-${loc.id}`}
          name={`la-checkbox-${loc.id}`}
          value={loc.id}
          isInvalid={!!errors.selectedLocations}
          className="location-checkbox"
        >
          <Form.Check.Input
            type="checkbox"
            checked={selectedLocations[loc.id]}
            onChange={(e) => onChangeLocation(loc.id, e.target.checked)}
          />
          <Form.Check.Label className="d-flex flex-column align-items-start mb-0">
            <p className="mb-0 p2">{loc.name}</p>
            <p className="mb-0 p3 secondary-text-color">
              {la.city + " " + la.street + " " + la.houseNumber}
            </p>
          </Form.Check.Label>
        </Form.Check>
      );
    });
    setLocationAddresses(locationAddresses);
  };

  const findFormErrors = () => {
    const newErrors = {};

    let atLeastOneLocationSelected = false;
    for (let loc in selectedLocations) {
      if (selectedLocations[loc]) {
        atLeastOneLocationSelected = true;
      }
    }

    if (!atLeastOneLocationSelected)
      newErrors.selectedLocations = t("field_required");

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      try {
        setBlocking(true);
        const payload = {};
        payload.cashRegisterTypeId = selectedCashRegisterGroupId;
        console.log("selectedLocations: ", selectedLocations);
        payload.locations = Object.keys(selectedLocations)
          .filter((key) => selectedLocations[key])
          .map(String);
        console.log("EditCashRegister. payload: ", payload);
        const response = await updateCashRegisters(payload);
        notifySuccess(t("cash_register_created"));
        onUpdate(response.data);
        handleClose();
      } catch (error) {
      } finally {
        setBlocking(false);
      }
    }
  };

  // const handleDelete = async () => {
  //   try {
  //     setBlocking(true);
  //     const payload = {};
  //     payload.cashRegisterTypeId = selectedCashRegisterGroupId;
  //     payload.locations = [];
  //     const response = await updateCashRegisters(payload);
  //     notifySuccess(t("cash_register_deleted"));
  //     onDelete(response.data);
  //     handleClose();
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setBlocking(false);
  //   }
  // };

  return (
    <>
      <div className="container central-content-container">
        <Card className="mb-3">
          <Card.Header className="plain-card-header">
            <h3 className="mb-0">
              <Trans>basic_information</Trans>
            </h3>
          </Card.Header>
          <Card.Body>
            <EnhancedFormControl
              type="text"
              name="eventTitle"
              label={t("name_of_thing")}
              value={t("cash_register_type_" + selectedCashRegisterGroup.name)}
              disabled
            />
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <Card.Header className="plain-card-header">
            <h3 className="mb-0">
              <Trans>locations</Trans>
            </h3>
          </Card.Header>
          <Card.Body className="d-flex flex-column gap-3">
            <div className="form-check location-checkbox">
              <input
                ref={allLocationsCheckboxRef}
                className="form-check-input"
                type="checkbox"
                value=""
                id="la-all-checkbox"
                name="la-all-checkbox"
                onChange={onChangeAllLocations}
              />
              <label className="form-check-label" htmlFor="la-all-checkbox">
                <Trans>select_all</Trans>
              </label>
            </div>
            <div className="cash-register-check-grid">{locationAddresses}</div>
            <div className="text-danger">{errors.selectedLocations}</div>
            {locations.length > itemsToShow && (
              <div className="d-flex justify-content-center">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setItemsToShow(locations.length);
                  }}
                  className="btn btn-link"
                >
                  <Trans>show_more</Trans>
                </button>
              </div>
            )}
          </Card.Body>
        </Card>

        <div className="d-flex justify-content-end align-items-start gap-3">
          <Button variant="grey" name="cancelButton" onClick={handleClose}>
            <Trans>cancel</Trans>
          </Button>
          <Button
            variant="blue"
            type="submit"
            name="saveButton"
            onClick={handleSubmit}
          >
            <Trans>save</Trans>
          </Button>
        </div>
      </div>

      <Modal
        show={showWarningModal}
        onHide={() => setShowWarningModal(false)}
        fullscreen={false}
        centered
        style={{ backdropFilter: "blur(8px)", zIndex: "9999" }}
      >
        <Modal.Header className="plain-modal-header">
          <Modal.Title>
            <Trans>important</Trans>
          </Modal.Title>
        </Modal.Header>
        {/* TODO: Need to translate this */}
        <Modal.Body>
          <div className="d-flex flex-column">
            <div className="mb-3 primary-text-color text-center">
              <p>
                Дорогой пользователь любое изменение данных в Способе Оплаты
                (Кассе) так-же затронет все предыдущие расходно приходные
                документы. Например, изменение название кассы так-же поменяет
                отображение расходно приходной части на новое название.
              </p>
              Удаление кассы не позволит Вам пользоваться удаленной кассой в
              будущем, расходно приходная часть будет сохранена.
            </div>
            <Button
              variant="blue"
              name="confirmWarningButton"
              onClick={() => setShowWarningModal(false)}
            >
              <Trans>confirm</Trans>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditCashRegister;
