import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Trans } from "react-i18next";
import { REGISTRATION_STEPS } from "util/constants";
import CodeInput from "./CodeInput";

const Step2 = ({
  currentStep,
  resendOtp,
  errors,
  setField,
}) => {
  const [code, setCode] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [clicksRemaining, setClicksRemaining] = useState(3);
  const timeoutDuration = 5 * 1000;

  useEffect(() => {
    let timer;
    if (disabled) {
      timer = setInterval(() => {
        setRemainingTime((prevRemainingTime) => {
          if (prevRemainingTime <= 1000) {
            clearInterval(timer);
            setDisabled(false);
            return 0;
          }
          return prevRemainingTime - 1000;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [disabled]);

  if (currentStep !== REGISTRATION_STEPS.STEP2) {
    return null;
  }

  const handleClick = () => {
    setClicksRemaining((prevClicksRemaining) => prevClicksRemaining - 1);
    setDisabled(true);
    setRemainingTime(timeoutDuration);
    resendOtp();
  };

  const handleCodeChange = (value) => {
    setCode(value);
    setField("userInformation", "code", value);
  };

  return (
    <>
      <h2 className="text-center mb-4">
        <Trans>enter_email_or_phone_code</Trans>
      </h2>
      <Form.Group className="text-center mb-4">
        <CodeInput
          numDigits={4}
          code={code}
          setCode={setCode}
          setField={setField}
          onChange={handleCodeChange}
          errors={errors}
        />
        <Form.Control.Feedback type="invalid">
          {errors.code}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="d-flex justify-content-center mb-4">
        <Button
          name="resendCodeButton"
          className="mx-auto"
          variant="blue-light"
          onClick={handleClick}
          disabled={disabled || clicksRemaining <= 0}
        >
          {disabled ? (
            <>
              <Trans>time_remaining</Trans>: {Math.floor(remainingTime / 1000)}{" "}
            </>
          ) : (
            <>
              <Trans>resend_code</Trans>
            </>
          )}
        </Button>
      </div>
      <div className="text-center">
        {clicksRemaining === 0 && remainingTime === 0 && (
          <>
            <span>
              <Trans>send_email_to_support_code_issue</Trans>:{" "}
            </span>
            <a href="mailto:info@themewagon.com">igor@zymran.com</a>
          </>
        )}
      </div>
    </>
  );
};

Step2.propTypes = {
  currentStep: PropTypes.number.isRequired,
  errors: PropTypes.object.isRequired,
  setField: PropTypes.func.isRequired,
};

export default Step2;
