import { useEffect, useState } from "react";
import SalaryForCategory from "./SalaryForCategory";
import { Button } from "react-bootstrap";
import ZymranIcon from "component/common/ZymranIcon";
import { Trans } from "react-i18next";
import { getServiceCategories } from "service/service-category/ServiceCategoryService";
import { isNumericInput } from "util/validation";

const SalaryForCategoriesSection = ({
  staffData,
  setStaffData,
  errors,
  setErrors,
  toggleOptions,
}) => {
  const [selectedServiceCategoryIds, setSelectedServiceCategoryIds] = useState(
    []
  );
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);

  const filteredCategoryOptions = serviceCategories.filter(
    (option) => !selectedOptions.includes(option)
  );

  useEffect(() => {
    loadServiceCategories();
  }, []);

  const loadServiceCategories = async () => {
    try {
      const scList = [];
      const response = await getServiceCategories();
      response.data.forEach((sc) => {
        scList.push({ value: sc.id, label: sc.name });
      });
      setServiceCategories(scList);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCategoryChange = (index, option) => {
    const updatedSelectedOptions = [...selectedOptions];
    const previouslySelectedCategoryId =
      staffData.salaryForCategories[index].serviceCategoryId;

    if (previouslySelectedCategoryId) {
      setSelectedOptions(
        selectedOptions.filter(
          (option) => option.value !== previouslySelectedCategoryId
        )
      );
    }
    // Add the newly selected service category ID if it's not already in updatedSelectedOptions
    if (option.value && !updatedSelectedOptions.includes(option.value)) {
      setSelectedOptions((prevOptions) => [...prevOptions, option]);
    }

    const updatedStaffData = { ...staffData };
    updatedStaffData.salaryForCategories[index].serviceCategoryId =
      option.value;
    setStaffData(updatedStaffData);
  };

  const handleSalaryChange = (index, newValue) => {
    if (isNumericInput(newValue)) {
      const updatedStaffData = { ...staffData };
      updatedStaffData.salaryForCategories[index].salary = newValue;
      setStaffData(updatedStaffData);
    }
  };

  const addCategory = () => {
    setSelectedServiceCategoryIds([...selectedServiceCategoryIds, null]);

    setStaffData((prevData) => ({
      ...prevData,
      salaryForCategories: [
        ...prevData.salaryForCategories,
        { salary: 0, isPercentage: false, serviceCategoryId: null },
      ],
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      salaryForCategories: [...prevErrors.salaryForCategories, {}],
    }));
  };

  const removeCategory = (index) => {
    const updatedIds = [...selectedServiceCategoryIds];
    updatedIds.splice(index, 1);
    setSelectedServiceCategoryIds(updatedIds);

    const removedCategoryId =
      staffData.salaryForCategories[index].serviceCategoryId;
    const updatedSelectedOptions = selectedOptions.filter(
      (option) => option.value !== removedCategoryId
    );
    setSelectedOptions(updatedSelectedOptions);

    setStaffData((prevData) => {
      const updatedCategories = [...prevData.salaryForCategories];
      updatedCategories.splice(index, 1);
      return { ...prevData, salaryForCategories: updatedCategories };
    });
  };

  const handleSalaryToggleChange = (index, optionValue) => {
    const isPercentage = optionValue === "percentage";
    const updatedStaffData = { ...staffData };
    updatedStaffData.salaryForCategories[index].isPercentage = isPercentage;
    setStaffData(updatedStaffData);
  };

  return (
    <div className="salary-block">
      {serviceCategories && serviceCategories.length > 0 ? (
        <>
          <p className="p1-bold mb-0">
            <Trans>payment_by_service_categories</Trans>
          </p>
          {staffData.salaryForCategories.map((category, index) => (
            <SalaryForCategory
              key={index}
              uniqueId={index}
              serviceCategories={filteredCategoryOptions}
              // selectedServiceCategory={category.selectedServiceCategory}
              selectedServiceCategory={serviceCategories.find(
                (option) => option.value === category.serviceCategoryId
              )}
              salaryForCategoriesItem={category}
              salary={category.salary}
              isPercentage={category.isPercentage}
              onCategoryChange={(option) => handleCategoryChange(index, option)}
              onSalaryChange={(newValue) => handleSalaryChange(index, newValue)}
              onRemove={() => removeCategory(index)}
              errors={errors}
              toggleOptions={toggleOptions}
              onToggleChange={(optionValue) =>
                handleSalaryToggleChange(index, optionValue)
              }
            />
          ))}
          <Button
            id="addMoreSelects"
            variant="blue-light"
            size="sm"
            type="button"
            onClick={addCategory}
            disabled={
              serviceCategories.length === selectedServiceCategoryIds.length
            }
          >
            <ZymranIcon name="plus" />
            <Trans>add_service_category</Trans>
          </Button>
        </>
      ) : (
        <p className="p2 secondary-text-color">
          <Trans>add_service_categories_in_service_menu</Trans>
        </p>
      )}
    </div>
  );
};

export default SalaryForCategoriesSection;
