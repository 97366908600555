import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { deleteStaffSchedule } from "../../service/staff-schedule/StaffScheduleService";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { notifyWarning } from "../../util/notify";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
  addDays,
} from "date-fns";
import AppContext from "context/Context";
import { toZonedTime } from "date-fns-tz";
import moment from "moment";
import useLocale from "hooks/useLocale";

const EditStaffSchedule = ({
  timezone,
  parentSlwh,
  selectedStaff,
  handleEditSubmit,
  handleCreateSubmit,
  updateStaffSchedules,
  handleCloseEditStaffScheduleModal,
  isCreateOperation,
  ...props
}) => {
  const {
    config: { isDark },
  } = useContext(AppContext);

  const locale = useLocale();
  const scheduleStartTime = toZonedTime(
    moment().startOf("day").toDate(),
    timezone
  );
  const scheduleEndTime = toZonedTime(moment().endOf("day").toDate(), timezone);
  const step = 15;
  const [slwh, setSlwh] = useState({
    id: -1,
    dayOfWeek: "",
    staffLocationId: -1,

    primaryShiftStart: toZonedTime(
      setHours(setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0), 9),
      timezone
    ),

    primaryShiftEnd: toZonedTime(
      setHours(
        setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
        13
      ),
      timezone
    ),
    secondaryShiftStart: toZonedTime(
      setHours(
        setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
        14
      ),
      timezone
    ),
    secondaryShiftEnd: toZonedTime(
      setHours(
        setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
        18
      ),
      timezone
    ),
    repeat: false,
    repeatEndDate: null,
    minRepeatEndDate: toZonedTime(addDays(new Date(), 7), timezone),
    dayOff: false,
  });
  const { t } = useTranslation();
  const repeatOptions = [
    { value: "always", label: t("always") },
    { value: "tillSelectedDate", label: t("till_selected_date") },
  ];
  const [selectedRepeatOption, setSelectedRepeatOption] = useState(
    repeatOptions[0]
  );

  const [errors, setErrors] = useState({});
  const [blocking, setBlocking] = useState(false);

  useEffect(() => {
    try {
      if (
        parentSlwh.primaryShiftStart !== undefined &&
        parentSlwh.secondaryShiftStart !== undefined &&
        parentSlwh.primaryShiftStart !== null &&
        parentSlwh.secondaryShiftStart !== null
      ) {
        const workingHours = {
          id: parentSlwh.id,
          dayOfWeek: parentSlwh.dayOfWeek,
          staffLocationId: parentSlwh.staffLocationId,

          primaryShiftStart: toZonedTime(
            parentSlwh.primaryShiftStart,
            timezone
          ),
          primaryShiftEnd: toZonedTime(parentSlwh.primaryShiftEnd, timezone),
          secondaryShiftStart: toZonedTime(
            parentSlwh.secondaryShiftStart,
            timezone
          ),
          secondaryShiftEnd: toZonedTime(
            parentSlwh.secondaryShiftEnd,
            timezone
          ),
          repeat: parentSlwh.repeat,
          repeatEndDate:
            parentSlwh.endRepeat !== null && parentSlwh.endRepeat !== undefined
              ? toZonedTime(parentSlwh.endRepeat, timezone)
              : null,
          minRepeatEndDate: toZonedTime(addDays(new Date(), 7), timezone),
          dayOff: parentSlwh.dayOff,
        };
        setSlwh(workingHours);
        setSelectedRepeatOption(workingHours.repeatEndDate ? repeatOptions[1] : repeatOptions[0]);
      } else if (
        parentSlwh.primaryShiftStart !== undefined &&
        parentSlwh.secondaryShiftStart !== undefined &&
        parentSlwh.primaryShiftStart !== null &&
        parentSlwh.secondaryShiftStart === null
      ) {
        const workingHours = {
          id: parentSlwh.id,
          dayOfWeek: parentSlwh.dayOfWeek,
          staffLocationId: parentSlwh.staffLocationId,
          primaryShiftStart: toZonedTime(
            parentSlwh.primaryShiftStart,
            timezone
          ),
          primaryShiftEnd: toZonedTime(parentSlwh.primaryShiftEnd, timezone),
          secondaryShiftStart: null,
          secondaryShiftEnd: null,
          repeat: parentSlwh.repeat,
          repeatEndDate: null,
          minRepeatEndDate: toZonedTime(addDays(new Date(), 7), timezone),
          dayOff: parentSlwh.dayOff,
        };
        setSlwh(workingHours);
      } else {
        const workingHours = {
          id: parentSlwh.id,
          dayOfWeek: parentSlwh.dayOfWeek,
          staffLocationId: parentSlwh.staffLocationId,

          primaryShiftStart: toZonedTime(
            setHours(
              setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
              9
            ),
            timezone
          ),
          primaryShiftEnd: toZonedTime(
            setHours(
              setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
              13
            ),
            timezone
          ),
          secondaryShiftStart: toZonedTime(
            setHours(
              setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
              14
            ),
            timezone
          ),
          secondaryShiftEnd: toZonedTime(
            setHours(
              setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
              18
            ),
            timezone
          ),
          repeat: parentSlwh.repeat === undefined ? false : parentSlwh.repeat,
          repeatEndDate: null,
          minRepeatEndDate: toZonedTime(addDays(new Date(), 7), timezone),
          dayOff: parentSlwh.dayOff === undefined ? false : parentSlwh.dayOff,
        };
        setSlwh(workingHours);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [parentSlwh]);

  const setField = (field, value) => {
    setSlwh({
      ...slwh,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const findFormErrors = () => {
    console.log("findFormErrors called");
    const newErrors = {};

    if (moment(slwh.primaryShiftStart).isSameOrAfter(slwh.primaryShiftEnd)) {
      newErrors.primaryShiftEnd = t("shift_end_after_start");
    }
    if (
      moment(slwh.secondaryShiftStart).isSameOrAfter(slwh.secondaryShiftEnd)
    ) {
      newErrors.secondaryShiftEnd = t("shift_end_after_start");
    }
    if (moment(slwh.primaryShiftEnd).isAfter(slwh.secondaryShiftStart)) {
      newErrors.primaryShiftStart = t("shifts_overlap");
    }
    if (selectedRepeatOption["value"] == "tillSelectedDate" && slwh["repeat"] && !slwh["repeatEndDate"]) {
      newErrors.repeatEndDate = t("value_cannot_be_empty");
    }
    return newErrors;
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      const updatedSlwh = { ...slwh };
      handleEditSubmit(updatedSlwh);
      handleClose();
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      const updatedSlwh = { ...slwh };
      handleCreateSubmit(updatedSlwh);
      handleClose();
    }
  };

  const handleDelete = async (e) => {
    try {
      setBlocking(true);
      await deleteStaffSchedule(slwh.id);
      notifyWarning(t("staff_schedule_deleted"));
      const updatedSlwh = { ...slwh, dayOff: true };
      updateStaffSchedules(updatedSlwh);
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const handleClose = () => {
    handleCloseEditStaffScheduleModal();
  };

  const onChangeRepeat = (e) => {
    setSlwh({
      ...slwh,
      repeat: e.target.checked,
    });
  };

  return (
    <Modal
      id="editStaffScheduleModal"
      name="editStaffScheduleModal"
      show={props.show}
      onHide={handleClose}
      centered
      contentClassName="border"
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? "white" : undefined}
        className="plain-modal-header"
      >
        <Modal.Title>
          <h3 className="mb-0">
            <Trans>edit_staff_schedule</Trans>
          </h3>
          <span className="p1">
            {selectedStaff.firstName} {selectedStaff.lastName}
          </span>{" "}
          <span className="p1 secondary-text-color">
            {selectedStaff.position}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-card plain-modal-body">
        <Form className="d-flex flex-column align-items-start gap-3 align-self-stretch">
          <Form.Group className="primary-text-color">
            <span className="p1-bold">
              <Trans>{slwh.dayOfWeek}</Trans>{" "}
            </span>
          </Form.Group>
          <Form.Check
            type="checkbox"
            id="day-off-checkbox"
            label={t("day_off")}
            checked={slwh.dayOff}
            onChange={(e) => setField("dayOff", e.target.checked)}
          />
          <div className="d-flex flex-column justify-content-center align-items-start gap-2 align-self-stretch primary-text-color">
            <p className="p-1 mb-0">
              <Trans>primary_shift</Trans>
            </p>
            <div className="d-flex align-items-center align-self-stretch gap-1">
              <div className="flex-grow-1">
                <DatePicker
                  selected={slwh.primaryShiftStart}
                  onChange={(date) => setField("primaryShiftStart", date)}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={scheduleStartTime}
                  maxTime={scheduleEndTime}
                  timeIntervals={step}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  className={`form-control ${errors.primaryShiftStart ? "datepicker-error" : ""
                    }`}
                  wrapperClassName="w-100"
                  disabled={slwh.dayOff}
                />
                {errors.primaryShiftStart && (
                  <Form.Text className="text-danger">
                    {errors.primaryShiftStart}
                  </Form.Text>
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.primaryShiftStart}
                </Form.Control.Feedback>
              </div>
              <span>-</span>
              <div className="flex-grow-1">
                <DatePicker
                  selected={slwh.primaryShiftEnd}
                  onChange={(date) => setField("primaryShiftEnd", date)}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={scheduleStartTime}
                  maxTime={scheduleEndTime}
                  timeIntervals={step}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  className={`form-control ${errors.primaryShiftEnd ? "datepicker-error" : ""
                    }`}
                  wrapperClassName="w-100"
                  disabled={slwh.dayOff}
                />
                {errors.primaryShiftEnd && (
                  <Form.Text className="text-danger">
                    {errors.primaryShiftEnd}
                  </Form.Text>
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.primaryShiftEnd}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-start gap-2 align-self-stretch primary-text-color">
            <p className="p-1 mb-0">
              <Trans>secondary_shift</Trans>
            </p>
            <div className="d-flex align-items-center align-self-stretch gap-1">
              <div className="flex-grow-1">
                <DatePicker
                  selected={slwh.secondaryShiftStart}
                  onChange={(date) => setField("secondaryShiftStart", date)}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={scheduleStartTime}
                  maxTime={scheduleEndTime}
                  timeIntervals={step}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  className={`form-control ${errors.secondaryShiftStart ? "datepicker-error" : ""
                    }`}
                  wrapperClassName="w-100"
                  disabled={slwh.dayOff}
                />
                {errors.secondaryShiftStart && (
                  <Form.Text className="text-danger">
                    {errors.secondaryShiftStart}
                  </Form.Text>
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.secondaryShiftStart}
                </Form.Control.Feedback>
              </div>
              <span>-</span>
              <div className="flex-grow-1">
                <DatePicker
                  selected={slwh.secondaryShiftEnd}
                  onChange={(date) => setField("secondaryShiftEnd", date)}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={scheduleStartTime}
                  maxTime={scheduleEndTime}
                  timeIntervals={step}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  className={`form-control ${errors.secondaryShiftEnd ? "datepicker-error" : ""
                    }`}
                  wrapperClassName="w-100"
                  disabled={slwh.dayOff}
                />
                {errors.secondaryShiftEnd && (
                  <Form.Text className="text-danger">
                    {errors.secondaryShiftEnd}
                  </Form.Text>
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.secondaryShiftEnd}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-start align-self-stretch gap-2">
            <Form.Check
              type="checkbox"
              id="repeat-checkbox"
              label={t("repeat")}
              checked={slwh.repeat}
              onChange={(e) => onChangeRepeat(e)}
            />
            <div className="d-flex align-items-center align-self-stretch gap-075">
              <Select
                value={selectedRepeatOption}
                onChange={setSelectedRepeatOption}
                options={repeatOptions}
                classNamePrefix="react-select"
                className="w-100"
                isDisabled={!slwh.repeat}
              />
              <DatePicker
                id="repeat-end-date"
                name="repeat-end-date"
                placeholderText="dd.mm.yyyy"
                minDate={slwh.minRepeatEndDate}
                dateFormat="dd.MM.yyyy"
                selected={slwh.repeatEndDate}
                isClearable
                onChange={(date) => setField("repeatEndDate", date)}
                className={`form-control ${errors.repeatEndDate ? "datepicker-error" : ""}`}
                locale={locale}
                wrapperClassName="w-100"

                disabled={
                  !slwh.repeat ||
                  (selectedRepeatOption &&
                    selectedRepeatOption.value === "always")
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.repeatEndDate}
              </Form.Control.Feedback>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" size="sm" onClick={handleClose}>
          <Trans>cancel</Trans>
        </Button>
        <Button variant="orange" size="sm" onClick={handleDelete}>
          <Trans>delete</Trans>
        </Button>
        {!isCreateOperation ? (
          <Button variant="blue" size="sm" onClick={handleUpdate}>
            <Trans>save_schedule</Trans>
          </Button>
        ) : (
          <Button variant="blue" size="sm" onClick={handleCreate}>
            <Trans>save_schedule</Trans>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

EditStaffSchedule.propTypes = {
  timezone: PropTypes.string.isRequired,
  parentSlwh: PropTypes.object.isRequired,
  handleEditSubmit: PropTypes.func.isRequired,
  handleCloseEditStaffScheduleModal: PropTypes.func.isRequired,
};

export default EditStaffSchedule;
