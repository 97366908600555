import Cookies from "js-cookie";

const authHeader = () => {
  const user = Cookies.get("zymran_user") ? JSON.parse(Cookies.get("zymran_user")) : null;

  if (user && user.token) {
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    };
  } else {
    return {};
  }
};

export { authHeader };
