import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import "bootstrap-icons/font/bootstrap-icons.css";
import Main from "./Main";
import "leaflet/dist/leaflet.css";

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Main>
      <App />
    </Main>
  </I18nextProvider>,
  document.getElementById("main")
);

// If you want your app to work offline and load faster, you can chađinge
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
