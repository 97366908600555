import { Fragment, useState, useEffect } from "react";
import { Button, Collapse, Table } from "react-bootstrap";
import { Trans } from "react-i18next";
import { getStaff } from "service/staff/StaffService";
import ZymranIcon from "component/common/ZymranIcon";
import { toZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import moment from "moment-timezone";
import ZymranAvatar from "component/common/ZymranAvatar";

const ViewStaff = ({ staffId }) => {
  const [openSalary, setOpenSalary] = useState(false);
  const timezone = moment.tz.guess();

  const [staffData, setStaffData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    middleName: "",
    position: "",
    commission: null,
    salary: null,
    phoneNumber: "",
    email: "",
    locations: [],
  });

  useEffect(() => {
    loadStaffData();
    // eslint-disable-next-line
  }, []);

  const loadStaffData = async () => {
    try {
      const response = await getStaff(staffId);
      const staff = response.data;
      const staffLocs = staff.staffLocations;
      const locations = [];
      staffLocs.forEach((item) => {
        locations.push(item.location);
      });
      staff.locations = locations;
      setStaffData(staff);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const daysOfWeek =
    staffData && staffData.staffSchedule
      ? Object.keys(staffData.staffSchedule)
      : [];

  return (
    <>
      <div className="d-flex align-items-center align-self-stretch">
        <div className="staff-view-info-block">
          <ZymranAvatar
            avatarUrl={staffData.avatarUrl}
            size="xl"
            shape="circle"
            fullName={`${staffData.firstName} ${staffData.lastName}`}
          />
          <div className="staff-view-info-details">
            <div>
              <p className="p1-bold mb-0 primary-text-color">
                {staffData.firstName} {staffData.lastName}
              </p>
              <p className="p2 mb-0 secondary-text-color">
                {staffData.position}
              </p>
            </div>
            <div>
              <a href={`mailto:` + staffData.email} className="p2">
                {staffData.email}
              </a>
              <p className="p2 mb-0 secondary-text-color">
                {staffData.phoneNumber}
              </p>
            </div>
          </div>
        </div>
        <div className="p1 primary-text-color staff-view-locations-block">
          {staffData.locations.map((item, index) => {
            return (
              <p key={index} className="mb-0">
                {item.name}
              </p>
            );
          })}
        </div>
      </div>
      <div className="staff-view-open-hours-block">
        <h3 className="mb-0 primary-text-color">
          <Trans>working_hours</Trans>
        </h3>
        <div className="table-responsive table-rounded-wrapper align-self-stretch">
          <Table bordered className="staff-view-table mb-0">
            <thead>
              <tr>
                <th>
                  <Trans>day_of_week</Trans>
                </th>
                <th>
                  <Trans>location</Trans>
                </th>
                <th>
                  <Trans>primary_shift_times</Trans>
                </th>
                <th>
                  <Trans>secondary_shift_times</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {daysOfWeek.map((day) => {
                const locationsForDay = staffData.staffSchedule[day] || [];
                const rowSpan = locationsForDay.length;

                return locationsForDay.map((entry, index) => (
                  <Fragment key={`${day}_${index}`}>
                    {!entry.dayOff && (
                      <tr
                        className={index === rowSpan - 1 ? "week-day-row" : ""}
                      >
                        {index === 0 && (
                          <td
                            rowSpan={rowSpan}
                            style={{ verticalAlign: "middle" }}
                            className="week-day-cell"
                          >
                            <Trans>{day}</Trans>
                          </td>
                        )}
                        <td>{entry.locationName}</td>
                        <td>
                          {entry.primaryShiftStart && entry.primaryShiftEnd
                            ? `${format(
                                toZonedTime(entry.primaryShiftStart, timezone),
                                "HH:mm"
                              )} - ${format(
                                toZonedTime(entry.primaryShiftEnd, timezone),
                                "HH:mm"
                              )}`
                            : "N/A"}
                        </td>
                        <td>
                          {entry.secondaryShiftStart && entry.secondaryShiftEnd
                            ? `${format(
                                toZonedTime(
                                  entry.secondaryShiftStart,
                                  timezone
                                ),
                                "HH:mm"
                              )} - ${format(
                                toZonedTime(entry.secondaryShiftEnd, timezone),
                                "HH:mm"
                              )}`
                            : "N/A"}
                        </td>
                      </tr>
                    )}
                    {entry.dayOff && (
                      <tr className="day-off-row">
                        <td>
                          <Trans>{day}</Trans>
                        </td>
                        <td colSpan={3} className="text-center border-left-0">
                          <Trans>day_off</Trans>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ));
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="staff-view-salary-block">
        <div className="d-flex justify-content-between align-items-center align-self-stretch">
          <h3 className="mb-0 primary-text-color">
            <Trans>salary</Trans>
          </h3>
          <Button
            name="salaryCollapseButton"
            variant="grey"
            size="sm"
            onClick={() => setOpenSalary(!openSalary)}
            aria-controls="collapse-salary"
            aria-expanded={openSalary}
          >
            {openSalary ? (
              <>
                <ZymranIcon name="chevron-up" />
                <Trans>hide</Trans>
              </>
            ) : (
              <>
                <ZymranIcon name="chevron-down" />
                <Trans>show</Trans>
              </>
            )}
          </Button>
        </div>
        <Collapse in={openSalary}>
          <div
            id="collapse-salary"
            className="d-flex flex-column align-self-stretch gap-3"
          >
            <div className="d-flex align-items-center gap-2 align-selft-stretch">
              <p className="mb-0 p1-bold">
                <Trans>wage_for_month</Trans>
              </p>
              {staffData.salary && staffData.salary.salary}{" "}
              {staffData.salary && staffData.salary.isPercentage ? (
                <span>%</span>
              ) : (
                <span>
                  <Trans>tenge</Trans>
                </span>
              )}
            </div>
            <div className="table-responsive table-rounded-wrapper">
              <Table
                bordered
                className="staff-view-table staff-salary-table mb-0"
              >
                <thead>
                  <tr>
                    <th>
                      <Trans>pay_by_service_category</Trans>
                    </th>
                    <th>
                      <Trans>sum</Trans>
                    </th>
                    <th>
                      <Trans>percentage_from_turnover</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {staffData.salaryForCategories &&
                    staffData.salaryForCategories.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.serviceCategoryName}</td>
                          <td>
                            {item.isPercentage ? (
                              <span>-</span>
                            ) : (
                              <>
                                {item.salary} <Trans>tenge</Trans>
                              </>
                            )}
                          </td>
                          <td>
                            {item.isPercentage ? (
                              <>{item.salary} %</>
                            ) : (
                              <span>-</span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div className="table-responsive table-rounded-wrapper">
              <Table
                bordered
                className="staff-view-table staff-salary-table mb-0"
              >
                <thead>
                  <tr>
                    <th>
                      <Trans>specification_by_specific_services</Trans>
                    </th>
                    <th>
                      <Trans>sum</Trans>
                    </th>
                    <th>
                      <Trans>percentage_from_turnover</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {staffData.salaryForServices &&
                    staffData.salaryForServices.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.serviceName}</td>
                          <td>
                            {item.isPercentage ? (
                              <span>-</span>
                            ) : (
                              <>
                                {item.salary} <Trans>tenge</Trans>
                              </>
                            )}
                          </td>
                          <td>
                            {item.isPercentage ? (
                              <>{item.salary} %</>
                            ) : (
                              <span>-</span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default ViewStaff;
