import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getStaffLocationsByLocation = (location_id) => {
  return http.get(`staff-location/location/${location_id}`, {
    headers: authHeader(),
  });
};

const getStaffLocations = () => {
  return http.get(`staff-location/`, { headers: authHeader() });
};

export { getStaffLocationsByLocation, getStaffLocations };
