import { Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { toZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import ZymranAvatar from "component/common/ZymranAvatar";
import useLocale from "hooks/useLocale";
import { DateTime } from "luxon";

const ServiceAppointmentTypeData = ({
  incomeDetail,
  setShowViewAppointmentModal,
}) => {
  const timezone = DateTime.local().zoneName;
  const locale = useLocale();

  console.log();

  return (
    <>
      <div className="d-flex justify-content-between align-items-start align-self-stretch mb-4">
        <Table borderless size="sm" className="p1 primary-text-color w-auto">
          <tbody>
            <tr>
              <th className="p1-bold secondary-text-color">
                <Trans>document_number</Trans>
              </th>
              <td>{incomeDetail.code}</td>
            </tr>
            <tr>
              <th className="p1-bold secondary-text-color">
                <Trans>date</Trans>
              </th>
              <td>
                {format(
                  toZonedTime(incomeDetail.date, timezone),
                  "dd MMM yyyy HH:mm",
                  { locale }
                )}
              </td>
            </tr>
            <tr>
              <th className="p1-bold secondary-text-color">
                <Trans>location</Trans>
              </th>
              <td>
                {incomeDetail.appointments.length > 0
                  ? incomeDetail.appointments[0].staffLocation.location.name
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <th className="p1-bold secondary-text-color">
                <Trans>income_order_type</Trans>
              </th>
              <td>
                <span className="me-2">
                  <Trans>service</Trans>
                </span>
                {incomeDetail.checkoutInfo && (
                  <Link onClick={() => setShowViewAppointmentModal(true)}>
                    <Trans>more_info</Trans>...
                  </Link>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="d-flex flex-column align-items-start align-self-stretch gap-3">
          <div className="finances-issued-by-block">
            <p className="p1-bold mb-0">
              <Trans>customer</Trans>
            </p>
            <div className="d-flex flex-row align-items-center">
              <div className="d-flex">
                <ZymranAvatar
                  avatarUrl={incomeDetail.appointments[0].customer.avatarUrl}
                  size="lg"
                  shape="circle"
                  className={"me-2"}
                />
              </div>
              {incomeDetail.appointments &&
                incomeDetail.appointments.length >= 1 && (
                  <div className="p2-bold mb-0 text-blue">
                    {incomeDetail.appointments[0].customer.firstName}{" "}
                    {incomeDetail.appointments[0].customer.lastName}
                  </div>
                )}
            </div>
          </div>
          {incomeDetail.checkoutInfo && (
            <div className="finances-issued-by-block">
              <p className="p1-bold mb-0">
                <Trans>payment_accepted_by</Trans>
              </p>
              <div className="d-flex flex-row align-items-center">
                <div className="d-flex">
                  <ZymranAvatar
                    avatarUrl={
                      incomeDetail.checkoutInfo.acceptedByUser.avatarUrl
                    }
                    size="lg"
                    shape="circle"
                    className={"me-2"}
                  />
                </div>
                <div className="d-flex flex-column align-items-start text-start">
                  <p className="p2-bold mb-0 text-blue">
                    {incomeDetail.checkoutInfo.acceptedByUser.firstName}{" "}
                    {incomeDetail.checkoutInfo.acceptedByUser.lastName}
                  </p>
                  <p className="p3 mb-0 secondary-text-color">
                    {incomeDetail.checkoutInfo.acceptedByUser.position}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {incomeDetail.appointments && (
        <div className="table-rounded-wrapper mb-4">
          <Table responsive className="cash-register-table mb-0">
            <thead className="p2-bold secondary-text-color">
              <tr>
                <th>№</th>
                <th scope="col">
                  <Trans>service_name</Trans>
                </th>
                <th scope="col">
                  <Trans>staff_member</Trans>
                </th>
                <th scope="col">
                  <Trans>price</Trans>
                </th>
              </tr>
            </thead>
            <tbody className="p2 primary-text-color">
              {incomeDetail.appointments.map((appointment, index) => (
                <tr key={appointment.id} className="align-middle">
                  <td>{index + 1}</td>
                  <td>{appointment.service.name}</td>
                  <td>
                    <div className="d-flex flex-row align-items-center">
                      <div className="d-flex">
                        <ZymranAvatar
                          avatarUrl={
                            incomeDetail.appointments[0].staffLocation.staff
                              .avatarUrl
                          }
                          size="md"
                          shape="circle"
                          className={"me-2"}
                        />
                      </div>
                      <div className="text-blue">
                        {appointment.staffLocation.staff.firstName}{" "}
                        {appointment.staffLocation.staff.lastName}
                      </div>
                    </div>
                  </td>
                  <td>
                    {appointment.service.price} <Trans>tenge</Trans>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      <div className="p1 primary-text-color income-cost-block d-flex flex-column gap-3">
        <Row>
          <Col xs={6} className="p1-bold secondary-text-color">
            <Trans>total</Trans>
          </Col>
          <Col xs={6} className="p1-bold">
            {incomeDetail.checkoutInfo.totalSum} <Trans>tenge</Trans>
          </Col>
        </Row>
        {incomeDetail.checkoutInfo.cashRegisterSums.map((crs, index) => (
          <div key={index} className="d-flex flex-column gap-2">
            <Row>
              <Col xs={6} className="p1-bold secondary-text-color">
                <Trans>payment_method</Trans>
              </Col>
              <Col xs={6}>
                <Trans>{`cash_register_type_${crs.cashRegisterName}`}</Trans>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="p1-bold secondary-text-color">
                <Trans>accepted_for_payment</Trans>
              </Col>
              <Col xs={6}>{crs.sum}</Col>
            </Row>
          </div>
        ))}
      </div>
    </>
  );
};

export default ServiceAppointmentTypeData;
