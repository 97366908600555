const APPOINTMENT_STATUS = {
  NEW: "new",
  FINISHED: "finished",
  CANCELLED: "cancelled",
};

const HTTP_STATUS = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  UNPROCESSABLE_CONTENT: 422,
  INTERNAL_SERVER_ERROR: 500,
};

const REGISTRATION_STEPS = {
  STEP1: 1,
  STEP2: 2,
  STEP3: 3,
  STEP4: 4,
};

const RESTORE_PASSWORD_STEPS = {
  STEP1: 1,
  STEP2: 2,
  STEP3: 3,
  STEP4: 4,
};

const INCOME_STATUS = {
  DONE: "done",
  DELETED: "deleted",
};

const WEEKDAYS = {
  MONDAY: "monday",
  TUESDAY: "tuesday",
  WEDNESDAY: "wednesday",
  THURSDAY: "thursday",
  FRIDAY: "friday",
  SATURDAY: "saturday",
  SUNDAY: "sunday",
};

const CALENDAR_START_HOUR = 0;
const CALENDAR_END_HOUR = 23;
const CALENDAR_STEP = 15;

const ROLES = {
  SUPERADMIN: "SUPERADMIN",
  ADMIN: "ADMIN",
  PARTNER: "PARTNER",
  STAFF: "STAFF",
}

export {
  REGISTRATION_STEPS,
  RESTORE_PASSWORD_STEPS,
  APPOINTMENT_STATUS,
  HTTP_STATUS,
  INCOME_STATUS,
  WEEKDAYS,
  CALENDAR_START_HOUR,
  CALENDAR_END_HOUR,
  CALENDAR_STEP,
  ROLES,
};
