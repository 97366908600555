import loadingAnimation from "assets/img/animated-icons/loading.json";
import ViewAppointment from "component/calendar/ViewAppointment";
import { toZonedTime } from "date-fns-tz";
import Lottie from "lottie-react";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Card, Dropdown, Modal } from "react-bootstrap";
import { Trans } from "react-i18next";
import { getExpenseByCode } from "service/expense/ExpenseService";
import ExpenseDetailChange from "./ExpenseDetailChange";
import ExpenseDetailCredit from "./ExpenseDetailCredit";
import ExpenseDetailEncashment from "./ExpenseDetailEncashment";
import ExpenseDetailSalary from "./ExpenseDetailSalary";

const ExpenseDetail = ({ selectedExpense }) => {
  const [expenseDetail, setExpenseDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [showViewAppointmentModal, setShowViewAppointmentModal] =
    useState(false);
  const [calendarStartTime, setCalendarStartTime] = useState({});
  const timezone = DateTime.local().zoneName;

  useEffect(() => {
    loadExpenseDetail();
    // eslint-disable-next-line
  }, []);

  const loadExpenseDetail = async () => {
    try {
      const response = await getExpenseByCode(selectedExpense.code);
      console.log("getExpenseByCode response: ", response.data);
      const expenseDetail = response.data;
      setExpenseDetail(expenseDetail);
      const dateObject = toZonedTime(response.data.date, timezone);
      setCalendarStartTime(dateObject);
      console.log("dateObject: ", dateObject);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container central-content-container">
        <Card className="mb-3">
          <Card.Header className="plain-modal-header">
            <div className="d-flex flex-between-center">
              <h3 className="mb-0 finance-code-color">
                {expenseDetail && expenseDetail.code}
              </h3>
              <h3>
                <Trans>expense_info</Trans>
              </h3>
              <Dropdown align="end">
                <Dropdown.Toggle
                  variant="blue"
                  size="sm"
                  id="createDropdown"
                  className="d-md-block d-none"
                >
                  <Trans>action</Trans>
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-2">
                  <Dropdown.Item>
                    <Trans>print</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Trans>download_pdf</Trans>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Card.Header>
          <Card.Body>
            {loading ? (
              <div className="d-flex justify-content-center">
                <Lottie
                  animationData={loadingAnimation}
                  style={{ width: 100, height: 100 }}
                />
              </div>
            ) : expenseDetail.type === "salary" ? (
              <ExpenseDetailSalary
                expenseDetail={expenseDetail}
                location={selectedExpense.location}
              />
            ) : expenseDetail.type === "manual" &&
              expenseDetail.reason === "change" ? (
              <ExpenseDetailChange
                expenseDetail={expenseDetail}
                location={selectedExpense.location}
                setShowViewAppointmentModal={setShowViewAppointmentModal}
              />
            ) : expenseDetail.type === "manual" ? (
              <ExpenseDetailCredit
                expenseDetail={expenseDetail}
                location={selectedExpense.location}
              />
            ) : expenseDetail.type === "encashment" ? (
              <ExpenseDetailEncashment
                expenseDetail={expenseDetail}
                location={selectedExpense.location}
              />
            ) : (
              <ExpenseDetailCredit
                expenseDetail={expenseDetail}
                location={selectedExpense.location}
              />
            )}
          </Card.Body>
        </Card>
      </div>
      <Modal
        id="viewAppointmentModal"
        name="viewAppointmentModal"
        show={showViewAppointmentModal}
        onShow={() => setShowViewAppointmentModal(true)}
        onHide={() => setShowViewAppointmentModal(false)}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="appointment-header px-card border-bottom-0"
        >
          {" "}
          <Modal.Title as="h5" className="text-white light">
            <Trans>view_appointment</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="appointment-body">
          <ViewAppointment
            chainId={
              expenseDetail.appointments &&
                expenseDetail.appointments.length > 0
                ? expenseDetail.appointments[0].chainId
                : undefined
            }
            calendarStartTime={calendarStartTime}
            timezone={timezone}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExpenseDetail;

