import CustomerSearchPane from "component/calendar/CustomerSearchPane";
import IconButton from "component/common/IconButton";
import ZymranIcon from "component/common/ZymranIcon";
import CreateCustomer from "component/customer/CreateCustomer";
import { addMinutes, format } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { notifyError, notifySuccess } from "util/notify";
import {
  getChainedAppointments,
  updateAppointments,
} from "../../../service/appointment/AppointmentService";
import BlockUI from "../../../util/block-ui/block-ui";
import Appointment from "../Appointment";
import useLocale from "hooks/useLocale";

const EditAppointment = ({
  appointment,
  appointmentsFromDB,
  setAppointmentsFromDB,
  calendarStartTime,
  calendarEndTime,
  timezone,
  deletedAppointments,
  setDeletedAppointments,
  handleCloseEditAppointmentModal,
  staffLocations,
  setShowEditAppointmentModal,
  setShowViewAppointmentModal,
}) => {
  const [appointmentsLocal, setAppointmentsLocal] = useState([]);
  const [appointmentDivs, setAppointmentDivs] = useState(null);

  const step = 15;
  const [blocking, setBlocking] = useState(false);

  const { t } = useTranslation();
  const [showCustomerCreateModal, setCustomerCreateModalShow] = useState(false);
  const handleShowCustomerCreateModal = () => setCustomerCreateModalShow(true);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const locale = useLocale();

  useEffect(() => {
    if (appointmentsLocal.length === 0) {
      loadChainedAppointments(appointment.chainId);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (appointmentsLocal.length > 0) {
      prepareAppointmentDivs();
    }
    // eslint-disable-next-line
  }, [appointmentsLocal.length, appointmentsLocal]);

  const loadChainedAppointments = async (chainId) => {
    try {
      setBlocking(true);
      const response = await getChainedAppointments(chainId);
      const appointmentList = response.data;
      const appts = [];
      appointmentList.forEach((appointment) => {
        let appt = {
          id: appointment.id,
          chainId: appointment.chainId,
          startTime: toZonedTime(new Date(appointment.startTime), timezone),
          endTime: toZonedTime(new Date(appointment.endTime), timezone),
          duration: calculateDuration(
            appointment.startTime,
            appointment.endTime
          ),
          resourceId: appointment.staffLocation.id,
          selectedStaffLocation: {
            value: appointment.staffLocation.id,
            label:
              appointment.staffLocation.staff.firstName +
              " " +
              appointment.staffLocation.staff.lastName,
          },
          selectedService: {
            value: appointment.service.id,
            label: appointment.service.name,
          },
          customer: appointment.customer,
          price: appointment.service.price,
          checkoutInfo: appointment.checkoutInfo,
          status: appointment.status,
          fromDB: true,
          toSave: false,
          toDelete: false,
          comment: appointment.comment ? appointment.comment : "",
        };
        appts.push(appt);
      });

      if (appointment.customer) {
        setSelectedCustomer(appointment.customer);
      } else {
        setSelectedCustomer({});
      }
      setAppointmentsLocal(appts);
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const handleCustomerCreateClose = () => {
    setCustomerCreateModalShow(false);
  };

  const handleAddAppointment = () => {
    const lastAppointment =
      appointmentsLocal.length > 0
        ? appointmentsLocal[appointmentsLocal.length - 1]
        : null;

    let calStartTime = lastAppointment
      ? lastAppointment.endTime
      : calendarStartTime;

    let appt = {
      id: (appointmentsLocal.length + 1) * -1,
      startTime: calStartTime,
      endTime: fromZonedTime(addMinutes(calStartTime, step), timezone),
      resourceId: appointment.resourceId,
      selectedStaffLocation: {
        value: 0,
        label: "",
      },
      selectedService: {
        value: 0,
        label: "",
      },
      toSave: true,
      fromDB: false,
      chainId: appointment.chainId,
      customer: appointment.customer,
    };
    setAppointmentsLocal((oldAppointments) => [...oldAppointments, appt]);
  };

  const setAppointmentField = (appointmentId, field, value) => {
    const index = appointmentsLocal.findIndex(
      (appt) => appt.id === appointmentId
    );
    const appts = [...appointmentsLocal];

    appts[index][field] = value;
    if (field === "duration") {
      const startTime = appts[index]["startTime"];
      appts[index]["endTime"] = addMinutes(startTime, value);

      // Update the startTime of the following appointment
      const nextAppointmentIndex = index + 1;
      if (nextAppointmentIndex < appts.length) {
        appts[nextAppointmentIndex].startTime = appts[index].endTime;
      }
    }
    appts[index]["toSave"] = true;

    setAppointmentsLocal(appts);
  };

  const handleRemoveAppointment = (appointment) => {
    if (appointment.fromDB) {
      appointment.toDelete = true;
      setDeletedAppointments(() => [...deletedAppointments, appointment]);
    }
    let newAppointments = appointmentsLocal.filter((appt) => {
      return appt.id !== appointment.id;
    });
    setAppointmentsLocal(newAppointments);
  };

  const handleCustomerSearchSelection = (item) => {
    const newAppointments = [];

    appointmentsLocal.forEach((appt) => {
      newAppointments.push({
        ...appt,
        customer: item,
      });
    });
    setAppointmentsLocal(newAppointments);
  };

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const duration = (end - start) / 60000; // Calculate the duration in minutes
    return duration;
  };

  const processAppointmentsMissingDuration = () => {
    return appointmentsLocal.map((appointment) => {
      const duration = calculateDuration(
        appointment.startTime,
        appointment.endTime
      );
      return { ...appointment, duration };
    });
  };

  const prepareAppointmentDivs = () => {
    console.log("prepareAppointmentDivs called");
    const processedAppointments = processAppointmentsMissingDuration();
    const appointmentDivs = processedAppointments.map((appointment, index) => {
      return (
        <Card.Body key={index} className="appointment-service-card-body">
          <Appointment
            appointmentIndex={index}
            appointment={appointment}
            calendarStartTime={calendarStartTime}
            calendarEndTime={calendarEndTime}
            step={step}
            setAppointmentField={setAppointmentField}
            staffLocations={staffLocations}
            handleRemoveAppointment={handleRemoveAppointment}
          />
        </Card.Body>
      );
    });
    setAppointmentDivs(appointmentDivs);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const newErrors = findFormErrors();

    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      if (newErrors.location !== undefined && newErrors.location.length > 0) {
        notifyError(t("select_location"));
      }
      if (newErrors.duration !== undefined && newErrors.duration.length > 0) {
        notifyError(t("field_required"));
      }
      if (
        newErrors.customerId !== undefined &&
        newErrors.customerId.length > 0
      ) {
        notifyError(t("customer_required"));
      }
    } else {
      sendUpdateAppointments();
    }
  };

  const findFormErrors = () => {
    const newErrors = {};

    let appointmentsToSave = appointmentsLocal.filter((appt) => {
      return appt.toSave === true;
    });

    appointmentsToSave.forEach((appt) => {
      // TODO: check for empty date, not string
      if (!appt.startTime || appt.startTime === "")
        newErrors.startTime = t("field_required");
      if (!appt.duration || appt.duration === "")
        newErrors.duration = t("field_required");
      if (!appt.resourceId || appt.resourceId === 0)
        newErrors.password = t("field_required");
      if (!appt.customer || !appt.customer.id || appt.customer.id === 0)
        newErrors.customerId = t("field_required");
      return newErrors;
    });
    return newErrors;
  };

  const sendUpdateAppointments = async () => {
    setBlocking(true);

    const temp = appointmentsLocal.filter((appt) => {
      return appt.toSave === true;
    });
    temp.push(...deletedAppointments);
    const apptsToSend = [];

    temp.forEach((appt) => {
      apptsToSend.push({
        ...appt,
        staffLocationId: appt.selectedStaffLocation.value,
        serviceId: appt.selectedService.value,
        customerId: selectedCustomer.id,
        customer: selectedCustomer,
        startTime: fromZonedTime(appt.startTime, timezone),
        endTime: fromZonedTime(
          addMinutes(appt.startTime, appt.duration),
          timezone
        ),
      });
    });

    try {
      // Send request for update to the backend api
      const response = await updateAppointments(apptsToSend);

      // Update current appointmentsFromDB in the state of the component
      if (response.status === 200) {
        updateLocalAppointments(response.data);
        setBlocking(false);
        setShowEditAppointmentModal(false);
        setShowViewAppointmentModal(false);
        notifySuccess(t("appointments_updated"));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const updateLocalAppointments = (appointments) => {
    const newAppointmentsFromDB = [];
    const processedAppointmentsMap = new Map();

    for (const appointment of appointmentsFromDB) {
      let found = false;
      for (const appt of deletedAppointments) {
        if (appointment.id === appt.id) {
          found = true;
          break;
        }
      }
      if (!found) {
        if (!processedAppointmentsMap.get(appointment.id)) {
          newAppointmentsFromDB.push(appointment);
          processedAppointmentsMap.set(appointment.id, appointment);
        }
      } else {
        processedAppointmentsMap.set(appointment.id, appointment);
      }
    }

    for (const appointment of appointments) {
      for (const appt of appointmentsFromDB) {
        if (!processedAppointmentsMap.get(appointment.id)) {
          if (appointment.id === appt.id) {
            const newAppt = {
              ...appt,
              startTime: appointment.startTime,
              endTime: appointment.endTime,
              duration: calculateDuration(
                appointment.startTime,
                appointment.endTime
              ),
              resourceId: appointment.staffLocation.id,
              customer: appointment.customer,
              selectedStaffLocation: {
                value: appointment.staffLocation.id,
                label:
                  appointment.staffLocation.staff.firstName +
                  " " +
                  appointment.staffLocation.staff.lastName,
              },
              selectedService: {
                value: appointment.service.id,
                label: appointment.service.name,
              },
            };
            newAppointmentsFromDB.push(newAppt);
            processedAppointmentsMap.set(appointment.id, newAppt);
            break;
          } else {
            if (!processedAppointmentsMap.get(appointment.id)) {
              const newAppt = {
                id: appointment.id,
                startTime: appointment.startTime,
                endTime: appointment.endTime,
                duration: calculateDuration(
                  appointment.startTime,
                  appointment.endTime
                ),
                resourceId: appointment.staffLocation.id,
                customer: appointment.customer,
                selectedStaffLocation: {
                  value: appointment.staffLocation.id,
                  label:
                    appointment.staffLocation.staff.firstName +
                    " " +
                    appointment.staffLocation.staff.lastName,
                },
                selectedService: {
                  value: appointment.service.id,
                  label: appointment.service.name,
                },
                chainId: appointment.chainId,
              };
              newAppointmentsFromDB.push(newAppt);
              processedAppointmentsMap.set(appointment.id, newAppt);
            }
          }
        } else {
          if (appointment.id === appt.id) {
            appt.startTime = appointment.startTime;
            appt.endTime = appointment.endTime;
            appt.duration = calculateDuration(
              appointment.startTime,
              appointment.endTime
            );
            appt.resourceId = appointment.resourceId;
            appt.customer = appointment.customer;
            appt.selectedStaffLocation = {
              value: appointment.staffLocation.id,
              label:
                appointment.staffLocation.staff.firstName +
                " " +
                appointment.staffLocation.staff.lastName,
            };
            appt.selectedService = {
              value: appointment.service.id,
              label: appointment.service.name,
            };
            appt.chainId = appointment.chainId;
          }
        }
      }
    }

    try {
      const oldAppointments = [];

      for (const appointment of appointmentsFromDB) {
        let found = false;
        for (const appt of newAppointmentsFromDB) {
          if (appointment.id === appt.id) {
            found = true;
            break;
          }
        }
        if (!found) {
          if (!processedAppointmentsMap.get(appointment.id)) {
            oldAppointments.push(appointment);
            processedAppointmentsMap.set(appointment.id, appointment);
          }
        }
      }

      newAppointmentsFromDB.push(...oldAppointments);
      setAppointmentsFromDB(newAppointmentsFromDB);
      setDeletedAppointments([]);
      setAppointmentsLocal([]);
    } catch (error) {
      console.error(error);
    }
  };

  EditAppointment.propTypes = {
    calendarStartTime: PropTypes.instanceOf(Date).isRequired,
    calendarEndTime: PropTypes.instanceOf(Date).isRequired,
    timezone: PropTypes.string.isRequired,
    deletedAppointments: PropTypes.array.isRequired,
    setDeletedAppointments: PropTypes.func.isRequired,
  };

  return (
    <>
      <Card className="appointment-service-card">
        <Card.Header className="appointment-info-header">
          <h3 className="mb-0">
            {format(
              toZonedTime(appointment.startTime, timezone),
              "dd MMM yyyy HH:mm", { locale }
            )}
          </h3>
        </Card.Header>
        {appointmentDivs}

        <div className="appointment-service-card-footer">
          <button
            name="addServiceButton"
            className="btn btn-blue-light"
            onClick={handleAddAppointment}
          >
            <ZymranIcon name="pen" />
            <span>
              <Trans>add_service</Trans>
            </span>
          </button>
        </div>
        <BlockUI tag="div" blocking={blocking} />
      </Card>
      <div className="customer-pane-column">
        <Card className="customer-pane">
          <Card.Header className="customer-pane__header">
            <h3 className="mb-0">
              <Trans>customer</Trans>
            </h3>
            <IconButton
              iconClassName="me-2"
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-2"
              onClick={handleShowCustomerCreateModal}
            >
              <Trans>create_new_customer</Trans>
            </IconButton>
          </Card.Header>
          <Card.Body className="overflow-auto customer-pane__body">
            <CustomerSearchPane
              autoCompleteItem={customerList}
              handleCustomerSearchSelection={handleCustomerSearchSelection}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
            />
          </Card.Body>
        </Card>
        <div className="d-flex align-items-end align-self-stretch gap-3">
          <Button
            name="closeButton"
            variant="grey"
            onClick={handleCloseEditAppointmentModal}
            className="w-100"
          >
            <Trans>close</Trans>
          </Button>
          <Button
            name="saveAppointmentButton"
            type="submit"
            variant="blue"
            onClick={handleUpdate}
            className="w-100"
          >
            <Trans>save</Trans>
          </Button>
        </div>
      </div>
      <Modal
        show={showCustomerCreateModal}
        fullscreen={true}
        onHide={() => setCustomerCreateModalShow(false)}
        aria-labelledby="customer-create-modal-title"
      >
        <Modal.Header closeButton closeVariant="white" className="bg-primary">
          <Modal.Title
            id="customer-create-modal-title"
            className="text-white light"
          >
            <Trans>create_customer</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <CreateCustomer
            handleCustomerCreateClose={handleCustomerCreateClose}
            setCustomers={setCustomerList}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditAppointment;
