export const appRoutes = {
  label: "App",
  labelDisable: true,
  children: [
    {
      name: "calendar",
      icon: "calendar",
      to: "/calendar",
      active: true,
    },
    {
      name: "settings",
      icon: "settings",
      active: true,
      children: [
        {
          name: "customers",
          to: "/settings/customer",
          icon: "clients",
          exact: true,
          active: true,
        },
        {
          name: "locations",
          icon: "map-point",
          to: "/settings/location",
          active: true,
        },
        {
          name: "staff",
          icon: "employee",
          to: "/settings/staff",
          active: true,
        },
        {
          name: "staff_schedule",
          icon: "calendar-2",
          to: "/settings/staff-schedule",
          active: true,
        },
        {
          name: "services",
          icon: "service",
          to: "/settings/service",
          active: true,
        },
      ],
    },
    {
      name: "dashboard",
      icon: "pie-chart",
      to: "/dashboard",
      active: true,
    },
    {
      name: "finances",
      icon: "finance",
      exact: false,
      active: true,
      children: [
        {
          name: "payment_types",
          to: "/finance/cash-register",
          icon: "cashier",
          exact: true,
          active: true,
          category: "cash_register",
        },
        {
          name: "incoming_order",
          to: "/finance/income-view",
          icon: "income",
          exact: true,
          active: true,
          category: "cash_register_operations",
        },
        {
          name: "expense_order",
          to: "/finance/expense",
          icon: "outcome",
          exact: true,
          active: true,
          category: "cash_register_operations",
        },
        {
          name: "salary",
          to: "/finance/salary",
          icon: "salary",
          exact: true,
          active: true,
          category: "cash_register_operations",
        },
        {
          name: "encashment",
          to: "/finance/encashment",
          icon: "encashment",
          exact: true,
          active: true,
          category: "cash_register_operations",
        },
      ],
    },
  ],
};

export const financeRoutes = {
  label: "finance",
  children: [
    {
      name: "finance",
      icon: "chart-pie",
      to: "/finance",
      exact: false,
      active: true,
      children: [
        {
          name: "test1",
          to: "/test1",
          icon: "users",
          exact: true,
          active: true,
        },
        {
          name: "test2",
          to: "/test2",
          icon: "users",
          exact: true,
          active: true,
        },
      ],
    },
  ],
};

// eslint-disable-next-line
export default [appRoutes];
