import ZymranIcon from "component/common/ZymranIcon";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import useLocale from "hooks/useLocale";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal, Table } from "react-bootstrap";
import { Trans } from "react-i18next";
import { getEncashmentList } from "service/encashment/EncashmentService";
import CashRegisterEncashmentProcessing from "./CashRegisterEncashmentProcessing";
import EncashmentProcessing from "./EncashmentProcessing";
import LocationEncashmentProcessing from "./LocationEncashmentProcessing";

const EncashmentView = () => {
  const locale = useLocale();
  const timezone = DateTime.local().zoneName;
  const [encashmentDataList, setEncashmentDataList] = useState([]);
  const selectedEncashmentData = null;
  const selectedLocationId = "";
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedCashRegisterData, setSelectedCashRegisterData] =
    useState(null);
  const [showEncashmentProcessingModal, setShowEncashmentProcessingModal] =
    useState(false);
  const [
    showLocationEncashmentProcessingModal,
    setShowLocationEncashmentProcessingModal,
  ] = useState(false);
  const [
    showCashRegisterEncashmentProcessingModal,
    setShowCashRegisterEncashmentProcessingModal,
  ] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  // const columns = [
  //   {
  //     accessor: "location",
  //     Header: t("location"),
  //     Cell: (rowData) => {
  //       const { location } = rowData.row.original;
  //       return <>{location.name}</>;
  //     },
  //   },
  //   {
  //     Header: t("last_encashment_date"),
  //     accessor: "dateOfLastEncashment",
  //     Cell: (rowData) => {
  //       const { dateOfEncashment } = rowData.row.original;
  //       return (
  //         <span>
  //           {dateOfEncashment &&
  //             format(toZonedTime(dateOfEncashment, timezone), "dd MMM yyyy", {
  //               locale,
  //             })}
  //         </span>
  //       );
  //     },
  //   },
  //   {
  //     Header: t("encashment_balance"),
  //     accessor: "balance",
  //     Cell: (rowData) => {
  //       const { totalBalance } = rowData.row.original;
  //       return <>{totalBalance}</>;
  //     },
  //   },
  //   {
  //     accessor: "status",
  //     Cell: (rowData) => {
  //       const { status } = rowData.row.original;
  //       const { location } = rowData.row.original;
  //       return (
  //         <>
  //           {status !== STATUS_DONE && (
  //             <Button
  //               variant="blue-light"
  //               name="encashButton"
  //               size="sm"
  //               onClick={() => handleEncashmentClick(location.id)}
  //             >
  //               <Trans>encash</Trans>
  //             </Button>
  //           )}
  //         </>
  //       );
  //     },
  //   },
  // ];

  useEffect(() => {
    loadEncashmentData();
  }, []);

  // const handleEncashmentClick = (locationId) => {
  //   setSelectedLocationId(locationId);
  //   setShowEncashmentProcessingModal(true);
  // };

  const handleEncashmentProcessingModalClose = () => {
    setShowEncashmentProcessingModal(false);
  };

  const handleSuccessfulEncashment = () => {
    setShowEncashmentProcessingModal(false);
    loadEncashmentData();
  };

  const loadEncashmentData = async () => {
    try {
      const response = await getEncashmentList();
      setEncashmentDataList(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      // setStaffLoading(false);
    }
  };

  const toggleRowExpansion = (id) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const handleLocationEncashmentClick = (location) => {
    setSelectedLocation(location);
    setShowLocationEncashmentProcessingModal(true);
  };

  const handleCashRegisterEncashmentClick = (encashmentDetail, location) => {
    setSelectedCashRegisterData(encashmentDetail);
    setSelectedLocation(location);
    setShowCashRegisterEncashmentProcessingModal(true);
  };

  const updateEncashmentData = (updatedData) => {
    setEncashmentDataList((currentDataList) =>
      currentDataList.map((data) => {
        if (data.location.id === updatedData.location.id) {
          return {
            ...data,
            dateOfLastEncashment: updatedData.dateOfLastEncashment,
            balance: updatedData.balance,
            dataByCashRegisters: data.dataByCashRegisters.map((register) => {
              const updatedRegister = updatedData.dataByCashRegisters.find(
                (updatedReg) =>
                  updatedReg.cashRegisterId === register.cashRegisterId
              );
              if (updatedRegister) {
                return {
                  ...register,
                  dateOfLastEncashment: updatedRegister.dateOfLastEncashment,
                  balance: updatedRegister.balance,
                };
              }
              return register;
            }),
          };
        }
        return data;
      })
    );
  };

  const updateCashRegisterBalance = (updatedData) => {
    setEncashmentDataList((currentDataList) =>
      currentDataList.map((encashmentData) => {
        if (encashmentData.location.id === updatedData.location.id) {
          return {
            ...encashmentData,
            dataByCashRegisters: encashmentData.dataByCashRegisters.map(
              (register) => {
                if (register.cashRegisterId === updatedData.cashRegisterId) {
                  return {
                    ...register,
                    balance: updatedData.balance,
                    dateOfLastEncashment: updatedData.dateOfLastEncashment,
                  };
                }
                return register;
              }
            ),
          };
        }
        return encashmentData;
      })
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="mb-0">
          <Trans>encashment</Trans>
        </h1>
      </div>

      {encashmentDataList && encashmentDataList.length > 0 && (
        <Card>
          <div className="table-rounded-wrapper border-0">
            <Table
              borderless
              size="sm"
              className="jazu-list-table p2 primary-text-color w-100 mb-0"
            >
              <thead>
                <tr>
                  <th className="fw-medium text">
                    <Trans>location</Trans>
                  </th>
                  <th className="fw-medium text">
                    <Trans>cash_register</Trans>
                  </th>
                  <th className="fw-medium text">
                    <Trans>last_encashment_date</Trans>
                  </th>
                  <th className="fw-medium text">
                    <Trans>balance</Trans>, ₸
                  </th>
                  <th className="fw-medium text"></th>
                </tr>
              </thead>
              <tbody>
                {encashmentDataList.map((encashmentData, index) => {
                  const isExpanded = expandedRows.includes(index);
                  const iconStyle = {
                    transform: isExpanded ? "rotate(180deg)" : "none",
                  };
                  return (
                    <React.Fragment key={index}>
                      <tr
                        onClick={() => toggleRowExpansion(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <td className="align-middle p2-bold">
                          <ZymranIcon
                            name="chevron-down"
                            className="me-2"
                            style={iconStyle}
                          />
                          {encashmentData.location.name}
                        </td>
                        <td></td>
                        <td className="align-middle">
                          {encashmentData.dateOfLastEncashment &&
                            format(
                              toZonedTime(
                                encashmentData.dateOfLastEncashment,
                                timezone
                              ),
                              "dd MMM yyyy",
                              { locale }
                            )}
                        </td>
                        <td className="align-middle">
                          {encashmentData.balance}
                        </td>
                        <td className="align-middle">
                          <Button
                            variant="blue-light"
                            name="encashButton"
                            size="sm"
                            onClick={() =>
                              handleLocationEncashmentClick(
                                encashmentData.location
                              )
                            }
                          >
                            <Trans>encash</Trans>
                          </Button>
                        </td>
                      </tr>
                      {expandedRows.includes(index) &&
                        encashmentData.dataByCashRegisters.map(
                          (encashmentDetail, detailIndex) => {
                            return (
                              <tr key={detailIndex}>
                                <td></td>
                                <td className="align-middle">
                                  <Trans>{`cash_register_type_${encashmentDetail.cashRegisterName}`}</Trans>
                                </td>
                                <td className="align-middle">
                                  {encashmentDetail.dateOfLastEncashment &&
                                    format(
                                      toZonedTime(
                                        encashmentDetail.dateOfLastEncashment,
                                        timezone
                                      ),
                                      "dd MMM yyyy",
                                      { locale }
                                    )}
                                </td>
                                <td className="align-middle">
                                  {encashmentDetail.balance}
                                </td>
                                <td className="align-middle">
                                  <Button
                                    variant="blue-light"
                                    name="encashButton"
                                    size="sm"
                                    onClick={() =>
                                      handleCashRegisterEncashmentClick(
                                        encashmentDetail,
                                        encashmentData.location
                                      )
                                    }
                                  >
                                    <Trans>encash</Trans>
                                  </Button>
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Card>
      )}

      <Modal
        show={showEncashmentProcessingModal}
        onHide={handleEncashmentProcessingModalClose}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title>
            <Trans>encashment</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EncashmentProcessing
            handleClose={handleEncashmentProcessingModalClose}
            handleSuccessfulEncashment={handleSuccessfulEncashment}
            selectedLocationId={selectedLocationId}
            selectedEncashmentData={selectedEncashmentData}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showLocationEncashmentProcessingModal}
        onHide={() => setShowLocationEncashmentProcessingModal(false)}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title>
            <Trans>encashment</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LocationEncashmentProcessing
            selectedLocation={selectedLocation}
            handleClose={() => setShowLocationEncashmentProcessingModal(false)}
            onSuccessfulEncashment={updateEncashmentData}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showCashRegisterEncashmentProcessingModal}
        onHide={() => setShowCashRegisterEncashmentProcessingModal(false)}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title>
            <Trans>encashment</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CashRegisterEncashmentProcessing
            selectedLocation={selectedLocation}
            selectedCashRegisterData={selectedCashRegisterData}
            handleClose={() =>
              setShowCashRegisterEncashmentProcessingModal(false)
            }
            onSuccessfulEncashment={updateCashRegisterBalance}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EncashmentView;
