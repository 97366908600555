import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getServices = () => {
  return http.get(`service/`, {
    headers: authHeader(),
  });
};

const getServicesByStaffLocation = (staffLocationId) => {
  return http.get(`service/staff-location/${staffLocationId}`, {
    headers: authHeader(),
  });
};

const createService = (payload) => {
  return http.post(`service/`, payload, { headers: authHeader() });
};

const deleteService = (id) => {
  return http.delete(`service/${id}`, { headers: authHeader() });
};

const getService = (id) => {
  return http.get(`service/${id}`, { headers: authHeader() });
};

const updateService = (payload) => {
  return http.put(`service/`, payload, { headers: authHeader() });
};

export {
  getServices,
  getServicesByStaffLocation,
  createService,
  updateService,
  getService,
  deleteService,
};
