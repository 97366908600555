import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import notFoundAnimation from "assets/img/animated-icons/not-found.json";
import typingAnimation from "assets/img/animated-icons/typing-in-chat.json";
import { ReactComponent as UserAvatarIcon } from "assets/img/icons/user-avatar.svg";
import Flex from "component/common/Flex";
import ZymranAvatar from "component/common/ZymranAvatar";
import ZymranIcon from "component/common/ZymranIcon";
import { isIterableArray } from "helpers/utils";
import _ from "lodash";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { Trans } from "react-i18next";
import { searchCustomer } from "service/customer/CustomerService";
import CustomerInfoForCalendar from "./CustomerInfoForCalendar";

const CustomerSearchPane = ({
  handleCustomerSearchSelection,
  selectedCustomer,
  setSelectedCustomer,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showCustomerInfo, setShowCustomerInfo] = useState(false);

  useEffect(() => {
    if (selectedCustomer.id) {
      setShowCustomerInfo(true);
    }
    // eslint-disable-next-line
  }, [selectedCustomer]);

  const debouncedSearch = _.debounce(
    (searchTerm, pageNumber = 1, pageSize = 5) => {
      searchCustomer({ searchKey: searchTerm, pageNumber, pageSize })
        .then((response) => {
          setSearchResults(response.data.customers);
        })
        .catch((error) => console.error(error));
    },
    300
  );

  useEffect(() => {
    if (searchInputValue) {
      debouncedSearch(searchInputValue);
    } else {
      setSearchResults([]);
    }
    // eslint-disable-next-line
  }, [searchInputValue]);

  const handleCustomerSelection = (item) => {
    setSelectedCustomer(item);
    handleCustomerSearchSelection(item);
    setShowCustomerInfo(true);
  };

  const hideCustomerInfo = () => {
    setSelectedCustomer({});
    handleCustomerSearchSelection({});
    setShowCustomerInfo(false);
  };

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const CustomerSearchContent = ({ item }) => {
    return (
      <div
        className="customer-pane__dropdown-cell"
        onClick={() => handleCustomerSelection(item)}
      >
        <ZymranAvatar
          avatarUrl={item.avatarUrl}
          fullName={`${item.firstName} ${item.lastName}`}
          size="sm"
          shape="circle"
        />
        <div className="customer-pane__dropdown-customer-info">
          <div className="d-flex flex-column">
            <div className="p2 primary-text-color">
              {item.firstName} {item.lastName}
            </div>
            <div className="customer-pane__dropdown-customer-contacts p2">
              {item.phoneNumberPrimary && (
                <span>{item.phoneNumberPrimary}</span>
              )}
              {item.phoneNumberPrimary && item.email && <span>, </span>}
              {item.email && <span>{item.email}</span>}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!showCustomerInfo && (
        <>
          <Dropdown onToggle={toggle} className="search-box w-100">
            <Dropdown.Toggle
              as="div"
              data-toggle="dropdown"
              aria-expanded={dropdownOpen}
              bsPrefix="toggle"
            >
              <Form>
                <Form.Control
                  type="search"
                  name="search-input"
                  placeholder="Search..."
                  aria-label="Search"
                  className="search-input customer-pane__search-input"
                  value={searchInputValue}
                  onChange={({ target }) => setSearchInputValue(target.value)}
                  onClick={() => setDropdownOpen(false)}
                />
                <FontAwesomeIcon
                  icon="search"
                  className="position-absolute text-400 search-box-icon"
                />
                {searchInputValue && (
                  <button
                    name="closeSearch"
                    className="search-input customer-pane__search-close-btn"
                    onClick={() => setSearchInputValue("")}
                  >
                    <ZymranIcon name="close" />
                  </button>
                )}
              </Form>
            </Dropdown.Toggle>
            <Dropdown.Menu className="customer-pane__dropdown">
              {/* <div className="customer-pane__dropdown" style={{ maxHeight: '24rem' }}> */}
              {isIterableArray(searchResults) ? (
                <>
                  {searchResults.map((item) => (
                    <CustomerSearchContent item={item} key={item.id} />
                  ))}
                </>
              ) : searchInputValue !== "" ? (
                <Flex
                  direction="column"
                  className="py-3 align-self-stretch"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Lottie
                    animationData={notFoundAnimation}
                    loop={true}
                    style={{ height: "150px", width: "150px" }}
                  />
                  <Trans>search_not_found_try_another</Trans>
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  className="py-3 align-self-stretch"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Lottie
                    animationData={typingAnimation}
                    loop={true}
                    style={{ height: "100px", width: "100px" }}
                  />
                  <div className="secondary-text-color">
                    <Trans>type_for_customers</Trans>...
                  </div>
                </Flex>
              )}
              {/* </div> */}
            </Dropdown.Menu>
          </Dropdown>
          <div className="scrollbar py-3">
            {searchInputValue === "" && (
              <div className="customer-pane__empty-client-info">
                <UserAvatarIcon className="customer-pane__default-avatar" />
                <div className="text-center">
                  <Trans>please_select_or_create_customer</Trans>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {showCustomerInfo && (
        <>
          <CustomerInfoForCalendar customerData={selectedCustomer} />
          <Button
            name="chooseAnotherCustomer"
            variant="grey"
            size="sm"
            onClick={hideCustomerInfo}
          >
            <Trans>choose_another_customer</Trans>
          </Button>
        </>
      )}
    </>
  );
};

export default CustomerSearchPane;
