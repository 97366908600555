import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import notFoundAnimation from "assets/img/animated-icons/not-found.json";
import typingAnimation from "assets/img/animated-icons/typing-in-chat.json";
import Flex from "component/common/Flex";
import ZymranAvatar from "component/common/ZymranAvatar";
import ZymranIcon from "component/common/ZymranIcon";
import { isIterableArray } from "helpers/utils";
import _ from "lodash";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { searchCustomer } from "service/customer/CustomerService";

const CustomerSearchContent = ({ item, handleCustomerSelection }) => {
  return (
    <div
      className="customer-pane__dropdown-cell"
      onClick={() => handleCustomerSelection(item)}
    >
      <ZymranAvatar
        avatarUrl={item.avatarUrl}
        fullName={`${item.firstName} ${item.lastName}`}
        size="sm"
        shape="circle"
      />
      <div className="customer-pane__dropdown-customer-info">
        <div className="customer-pane__dropdown-customer-name p2 primary-text-color">
          {item.firstName} {item.lastName}
        </div>
        <div className="customer-pane__dropdown-customer-contacts p2">
          {item.phoneNumberPrimary && <span>{item.phoneNumberPrimary}</span>}
          {item.phoneNumberPrimary && item.email && <span>, </span>}
          {item.email && <span>{item.email}</span>}
        </div>
      </div>
    </div>
  );
};

const CustomerSearchBox = ({ handleCustomerSelection }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [resultItem, setResultItem] = useState([]);

  const { t } = useTranslation();

  // const fuseJsOptions = {
  //   includeScore: true,
  //   keys: ["firstName", "lastName", "middleName", "phoneNumberPrimary"],
  //   threshold: 0.4,
  // };

  const debouncedSearch = _.debounce(
    (searchTerm, pageNumber = 1, pageSize = 10) => {
      searchCustomer({ searchKey: searchTerm, pageNumber, pageSize })
        .then((response) => {
          setResultItem(response.data.customers);
        })
        .catch((error) => console.error(error));
    },
    300
  );

  useEffect(() => {
    if (searchInputValue) {
      debouncedSearch(searchInputValue);
    } else {
      setResultItem([]);
    }
    // eslint-disable-next-line
  }, [searchInputValue]);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <>
      <Dropdown onToggle={toggle} className="search-box">
        <Dropdown.Toggle
          as="div"
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
          bsPrefix="toggle"
        >
          <Form>
            <Form.Control
              type="search"
              name="search-input"
              placeholder={t("search")}
              aria-label="Search"
              className="search-input customer-pane__search-input search-input--sm"
              value={searchInputValue}
              onChange={({ target }) => setSearchInputValue(target.value)}
              onClick={() => setDropdownOpen(false)}
            />
            <FontAwesomeIcon
              icon="search"
              className="position-absolute text-400 search-box-icon"
            />
            {searchInputValue && (
              <button
                name="closeSearch"
                className="search-input customer-pane__search-close-btn"
                onClick={() => setSearchInputValue("")}
              >
                <ZymranIcon name="close" />
              </button>
            )}
          </Form>
        </Dropdown.Toggle>
        <Dropdown.Menu className="customer-pane__dropdown">
          {isIterableArray(resultItem) ? (
            <>
              {resultItem.map((item) => (
                <CustomerSearchContent
                  item={item}
                  key={item.id}
                  handleCustomerSelection={handleCustomerSelection}
                />
              ))}
            </>
          ) : searchInputValue !== "" ? (
            <Flex
              direction="column"
              className="py-3 px-2 text-center"
              alignItems="center"
              justifyContent="center"
            >
              <Lottie
                animationData={notFoundAnimation}
                loop={true}
                style={{ height: "150px", width: "150px" }}
              />
              <Trans>search_not_found_try_another</Trans>
            </Flex>
          ) : (
            <Flex
              direction="column"
              className="py-3 align-self-stretch"
              alignItems="center"
              justifyContent="center"
            >
              <Lottie
                animationData={typingAnimation}
                loop={true}
                style={{ height: "100px", width: "100px" }}
              />
              <div className="secondary-text-color">
                <Trans>type_for_customers</Trans>...
              </div>
            </Flex>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default CustomerSearchBox;
