import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import EnhancedSelect from "component/common/EnhancedSelect";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import { Form, Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import IconButton from "component/common/IconButton";
import ServiceStaffSearchBox from "./ServiceStaffSearchBox";
import { getStaffLocationsByLocation } from "service/staff-location/StaffLocationService";
import ServiceLocationStaffSection from "./ServiceLocationStaffSection";
import ZymranIcon from "component/common/ZymranIcon";
import { isIterableArray } from "helpers/utils";
import Fuse from "fuse.js";
import Lottie from "lottie-react";
import notFoundAnimation from "assets/img/animated-icons/not-found.json";
import Flex from "component/common/Flex";
import ZymranAvatar from "component/common/ZymranAvatar";

const ServiceLocationSection = ({
  locationOptions,
  durationOptions,
  priceTypeOptions,
  locationPriceData,
  totalSelectedStaffLocations,
  setTotalSelectedStaffLocations,
  locationStaffPriceData,
  setLocationPriceData,
  setLocationStaffPriceData,
  errors,
  setErrors,
  locationSections,
  sectionValue,
  removeLocationSection,
  setSelectedLocations,
  preselectedLocationId,
  handleSelectedLocationAddition,
  setLocationField,
}) => {
  const { t } = useTranslation();
  const [selectedLocationOption, setSelectedLocationOption] = useState(
    locationOptions.find((opt) => opt.value === preselectedLocationId) || null
  );
  const [previousSelectedLocation, setPreviousSelectedLocation] = useState(
    preselectedLocationId || null
  );
  const [showStaffModal, setShowStaffModal] = useState(false);
  const [
    selectedStaffLocationsForThisSection,
    setSelectedStaffLocationsForThisSection,
  ] = useState(() => {
    return preselectedLocationId
      ? totalSelectedStaffLocations.filter(
          (sl) => sl.location.id === preselectedLocationId
        )
      : [];
  });
  const [searchInput, setSearchInput] = useState("");
  const [staffLocationsWithFullData, setStaffLocationsWithFullData] = useState(
    []
  );
  const [resultItem, setResultItem] = useState(staffLocationsWithFullData);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const fuseJsOptions = {
    includeScore: true,
    keys: [
      "staff.firstName",
      "staff.lastName",
      "staff.middleNmae",
      "staff.email",
      "staff.phoneNumber",
    ],
    threshold: 0.4,
  };

  let searchResult = new Fuse(staffLocationsWithFullData, fuseJsOptions)
    .search(searchInput)
    .map((item) => item.item);

  useEffect(() => {
    if (searchInput) {
      setResultItem(searchResult);
      isIterableArray(searchResult)
        ? setDropdownOpen(true)
        : setDropdownOpen(false);
    } else {
      setResultItem(null);
    }
    // eslint-disable-next-line
  }, [searchInput]);

  useEffect(() => {
    if (selectedLocationOption && selectedLocationOption.value !== "") {
      loadStaffLocations();
    }
    // eslint-disable-next-line
  }, [selectedLocationOption]);

  useEffect(() => {
    if (selectedLocationOption) {
      handleSelectedLocationAddition(selectedLocationOption.value);
    }
    // eslint-disable-next-line
  }, [selectedLocationOption]);

  const loadStaffLocations = async () => {
    try {
      const response = await getStaffLocationsByLocation(
        selectedLocationOption.value
      );
      setStaffLocationsWithFullData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const applyLocationPriceToStaff = () => {
    if (locationStaffPriceData.size > 0) {
      const currentLocationPriceData = locationPriceData.get(
        selectedLocationOption.value
      );
      const staffPrice = locationStaffPriceData.get(
        selectedLocationOption.value
      );

      if (staffPrice) {
        const currentSelectedStaffLocations =
          totalSelectedStaffLocations.filter(
            (sl) => sl.location.id === selectedLocationOption.value
          );

        currentSelectedStaffLocations.forEach((sl) => {
          const priceData = staffPrice.get(sl.id);
          console.log("priceData: ", priceData);
          priceData["toSave"] = true;
          priceData["duration"] = currentLocationPriceData.duration;
          priceData["priceType"] = currentLocationPriceData.priceType;
          priceData["price"] = currentLocationPriceData.price;
          staffPrice.set(sl.id, priceData);
        });
        setLocationStaffPriceData(
          new Map(
            locationStaffPriceData.set(selectedLocationOption.value, staffPrice)
          )
        );
      }
    }
  };

  const handleLocationSelection = (selectedOption) => {
    //set toSave flag to true for the selected location
    let priceData = locationPriceData.get(selectedOption.value);
    priceData["toSave"] = true;
    setLocationPriceData(
      new Map(locationPriceData.set(selectedOption.value, priceData))
    );

    // setSelectedLocations(prevLocations => [...prevLocations, selectedOption.value]);
    if (previousSelectedLocation) {
      let prevPriceData = locationPriceData.get(previousSelectedLocation);
      prevPriceData["toSave"] = false;
      setLocationPriceData(
        new Map(locationPriceData.set(previousSelectedLocation, prevPriceData))
      );

      setSelectedLocations((prevLocations) =>
        prevLocations.map((loc) =>
          loc === previousSelectedLocation ? selectedOption.value : loc
        )
      );
    } else {
      setSelectedLocations((prevLocations) => [
        ...prevLocations,
        selectedOption.value,
      ]);
    }
    setPreviousSelectedLocation(selectedOption.value);
    setSelectedLocationOption(selectedOption);
    setErrors((prevErrors) => ({
      ...prevErrors,
      atLeastOneSelectedLocation: null,
    }));
  };

  const handlePriceTypeChange = (selectedOption) => {
    setLocationField(
      selectedLocationOption?.value,
      "priceType",
      selectedOption
    );

    if (selectedOption.value === "free") {
      setLocationField(selectedLocationOption?.value, "price", 0);
    }
  };

  const handleStaffSelection = (selectedRows) => {
    const existingStaffIds = new Set(
      selectedStaffLocationsForThisSection.map((s) => s.id)
    );
    const newStaffLocations = selectedRows.filter(
      (row) => !existingStaffIds.has(row.id)
    );
    const mergedSelectedStaffLocations = [
      ...selectedStaffLocationsForThisSection,
      ...newStaffLocations,
    ];
    setSelectedStaffLocationsForThisSection(mergedSelectedStaffLocations);
    setTotalSelectedStaffLocations((prevStaffLocations) => {
      const filteredPrevStaff = prevStaffLocations.filter(
        (sl) => sl.location.id !== selectedLocationOption.value
      );
      return [...filteredPrevStaff, ...mergedSelectedStaffLocations];
    });
    setShowStaffModal(false);
  };

  const addSingleStaffSelection = (staffLocationData) => {
    const existsInThisSection = selectedStaffLocationsForThisSection.some(
      (sl) =>
        sl.location.id === staffLocationData.location.id &&
        sl.staff.id === staffLocationData.staff.id
    );

    if (!existsInThisSection) {
      setSelectedStaffLocationsForThisSection((prevRows) => [
        ...prevRows,
        staffLocationData,
      ]);
    }

    setTotalSelectedStaffLocations((prevStaffLocations) => {
      const exists = prevStaffLocations.some(
        (sl) =>
          sl.location.id === staffLocationData.location.id &&
          sl.staff.id === staffLocationData.staff.id
      );

      if (!exists) {
        return [...prevStaffLocations, staffLocationData];
      } else {
        return prevStaffLocations;
      }
    });

    setDropdownOpen(false);
  };

  return (
    <div className="service-location-and-staff-section">
      {locationSections.length > 1 && (
        <Button
          id={`removeLocationSectionButton-${sectionValue}`}
          name={`removeLocationSectionButton-${sectionValue}`}
          variant="orange-light"
          size="sm"
          className="service-location-section-remove-button"
          onClick={() =>
            removeLocationSection(sectionValue, selectedLocationOption.value)
          }
        >
          <ZymranIcon name="delete" size={16} className="me-2" />
          <Trans>delete_location</Trans>
        </Button>
      )}
      <EnhancedSelect
        id={`location-${sectionValue}`}
        name={`location-${sectionValue}`}
        label={t("location")}
        placeholder={t("select_location")}
        selectedValue={selectedLocationOption}
        options={locationOptions}
        onChange={(option) => handleLocationSelection(option)}
        errors={errors.atLeastOneSelectedLocation}
        isInvalid={!!errors.atLeastOneSelectedLocation}
      />
      <Row className="gx-3">
        <Col>
          <Row className="gx-3">
            <Col sm={4}>
              <EnhancedSelect
                id={`duration-${sectionValue}`}
                name={`duration-${sectionValue}`}
                label={t("duration")}
                placeholder={t("select_duration")}
                selectedValue={
                  locationPriceData.get(selectedLocationOption?.value)?.duration
                }
                options={durationOptions}
                onChange={(e) =>
                  setLocationField(selectedLocationOption?.value, "duration", e)
                }
                errors={
                  errors.locationPrice[selectedLocationOption?.value]?.duration
                }
                groupClassName="flex-grow-1"
                disabled={!selectedLocationOption}
              />
            </Col>
            <Col sm={4}>
              <EnhancedSelect
                id={`priceType-${sectionValue}`}
                name={`priceType-${sectionValue}`}
                label={t("price_type")}
                placeholder={t("select_price_type")}
                selectedValue={
                  locationPriceData.get(selectedLocationOption?.value)
                    ?.priceType
                }
                options={priceTypeOptions}
                onChange={(e) => handlePriceTypeChange(e)}
                errors={
                  errors.locationPrice[selectedLocationOption?.value]?.priceType
                }
                groupClassName="flex-grow-1"
                disabled={!selectedLocationOption}
              />
            </Col>
            <Col sm={4}>
              <EnhancedFormControl
                id={`price-${sectionValue}`}
                name={`price-${sectionValue}`}
                type="text"
                label={t("price")}
                placeholder={t("price")}
                value={
                  locationPriceData.get(selectedLocationOption?.value)?.price ||
                  ""
                }
                onChange={(e) =>
                  setLocationField(
                    selectedLocationOption?.value,
                    "price",
                    e.target.value
                  )
                }
                isInvalid={
                  !!errors.locationPrice[selectedLocationOption?.value]?.price
                }
                errors={
                  errors.locationPrice[selectedLocationOption?.value]?.price
                }
                groupClassName="flex-grow-1"
                disabled={
                  locationPriceData.get(selectedLocationOption?.value)
                    ?.priceType?.value === "free" || !selectedLocationOption
                }
              />
            </Col>
          </Row>
        </Col>
        <Col sm="auto" className="d-flex align-items-center">
          <Button
            id={`applyToAllStaffButton-${sectionValue}`}
            name={`applyToAllStaffButton-${sectionValue}`}
            className="flex-grow-0"
            size="sm"
            variant="blue-light"
            onClick={applyLocationPriceToStaff}
          >
            <Trans>apply_to_all_staff</Trans>
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Dropdown onToggle={toggle} className="search-box w-100 mb-4">
            <Dropdown.Toggle
              // id="staffSearchBoxToggle"
              as="div"
              data-toggle="dropdown"
              aria-expanded={dropdownOpen}
              bsPrefix="toggle"
            >
              <Form.Control
                id={`staffSearchInput-${sectionValue}`}
                name={`staffSearchInput-${sectionValue}`}
                type="text"
                placeholder={t("search_staff_by_name_phone_email")}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onClick={() => setDropdownOpen(false)}
                className="search-input search-input--sm"
                disabled={!selectedLocationOption}
              />
              <ZymranIcon
                name="search"
                className="position-absolute text-400 search-box-icon"
              />
              {searchInput && (
                <button
                  id={`staffSearchInputClear-${sectionValue}`}
                  name={`staffSearchInputClear-${sectionValue}`}
                  className="search-input customer-pane__search-close-btn"
                  onClick={() => setSearchInput("")}
                >
                  <ZymranIcon name="close" />
                </button>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu className="staff-search-dropdown">
              {isIterableArray(resultItem) ? (
                <>
                  {resultItem.map((item) => (
                    <div
                      key={item.id}
                      className="staff-search-dropdown-cell"
                      onClick={() => addSingleStaffSelection(item)}
                    >
                      <ZymranAvatar
                        avatarUrl={item.staff.avatarUrl}
                        fullName={`${item.staff.firstName} ${item.staff.lastName}`}
                        size="sm"
                        shape="circle"
                      />
                      <div className="d-block flex-grow-1">
                        <span className="p2 primary-text-color">
                          {item.staff.firstName} {item.staff.lastName}
                        </span>
                        <span className="p2 secondary-text-color">
                          {" "}
                          - {item.staff.position}
                        </span>
                      </div>
                    </div>
                  ))}
                </>
              ) : searchInput !== "" ? (
                <Flex
                  direction="column"
                  className="py-3 align-self-stretch"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Lottie
                    animationData={notFoundAnimation}
                    loop={true}
                    style={{ height: "150px", width: "150px" }}
                  />
                  <Trans>search_not_found_try_another</Trans>
                </Flex>
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col xs="auto">
          <IconButton
            id={`addStaffButton-${sectionValue}`}
            name={`addStaffButton-${sectionValue}`}
            variant="primary"
            icon="plus"
            size="sm"
            transform="shrink-3"
            iconAlign="left"
            onClick={() => setShowStaffModal(true)}
            disabled={!selectedLocationOption}
          >
            <span className="p2-bold">
              <Trans>add</Trans>
            </span>
          </IconButton>
        </Col>
      </Row>
      <ServiceLocationStaffSection
        selectedStaffLocationsForThisSection={
          selectedStaffLocationsForThisSection
        }
        setSelectedStaffLocationsForThisSection={
          setSelectedStaffLocationsForThisSection
        }
        setTotalSelectedStaffLocations={setTotalSelectedStaffLocations}
        locationStaffPriceData={locationStaffPriceData}
        setLocationStaffPriceData={setLocationStaffPriceData}
        selectedLocationOption={selectedLocationOption}
        durationOptions={durationOptions}
        priceTypeOptions={priceTypeOptions}
        errors={errors}
        setErrors={setErrors}
      />

      <Modal
        show={showStaffModal}
        onHide={() => setShowStaffModal(false)}
        centered
        scrollable
        fullscreen={false}
        className="blur-backdrop"
        dialogClassName="services-staff-modal"
      >
        <Modal.Header closeButton closeVariant="white" className="bg-primary">
          <Modal.Title className="text-white light">
            <Trans>add_employees</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="plain-modal-body">
          <ServiceStaffSearchBox
            autoCompleteItem={staffLocationsWithFullData}
            handleStaffSelection={handleStaffSelection}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ServiceLocationSection;
