import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import Cookies from 'js-cookie';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [languages] = useState([
    { code: 'kz', title: 'Қаз' },
    { code: 'ru', title: 'Рус' },
  ]);
  const [toggleContents, setToggleContents] = useState('');

  const setSelectedLanguage = (code) => {
    i18n.changeLanguage(code);
    console.log("Language code set: ", code);
    Cookies.set("i18next", code, {
      expires: 7,
      sameSite: "Lax",

    });
  };

  useEffect(() => {
    const currentLang = i18n.language;
    const currentLangTitle = languages.find(lang => lang.code === currentLang)?.title || 'Қаз';
    setToggleContents(currentLangTitle);
  }, [i18n.language, languages]);

  return (
    <Dropdown
      align="end"
      bsPrefix="language-switcher-dropdown nav-item dropdown"
      onSelect={(eventKey) => {
        const { title } = languages.find(({ code }) => eventKey === code);
        setSelectedLanguage(eventKey);
        setToggleContents(<>{title}</>);
      }}
    >
      <Dropdown.Toggle
        as="a"
        href="#"
        variant="secondary"
        id="dropdown-flags"
        className="language-toggle dropdown-toggle caret-off nav-link"
      >
        <div className="lang-toggle nav-link p-0">{toggleContents}</div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="bg-white dark__bg-1000 rounded-2">
          {languages.map(({ code, title }) => (
            <Dropdown.Item key={code} eventKey={code}>
              {title}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSwitcher;
