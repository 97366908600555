import React, { useEffect, useState } from 'react';
import { Form, InputGroup, ToggleButton } from 'react-bootstrap';
import classNames from 'classnames';
import ZymranIcon from 'component/common/ZymranIcon';

const EnhancedFormControlWithToggle = ({
  id,
  uniqueId,
  name,
  type,
  value,
  toggleOptions,
  onChange,
  isInvalid,
  readOnly,
  placeholder,
  label,
  errors,
  groupClassName,
  initiallySelectedOption,
  onToggleChange,
  required }) => {

  const [focusedInput, setFocusedInput] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    const isValidInitiallySelectedOption = toggleOptions.some(option => option.value === initiallySelectedOption);
    const defaultSelectedOption = isValidInitiallySelectedOption ? initiallySelectedOption : toggleOptions[0].value;
    setSelectedOption(defaultSelectedOption);
  }, [initiallySelectedOption, toggleOptions]);
  
  const handleInputFocus = (e) => {
    const { name } = e.target;
    setFocusedInput(name);
  };

  const handleInputBlur = () => {
    setFocusedInput('');
  };

  const handleToggleChange = (optionName) => {
    setSelectedOption(optionName);
    onToggleChange(optionName);
  };

  const renderPlaceholder = () => {
    return required ? `${placeholder}*` : placeholder;
  };

  const inputType = type === 'number' ? 'text' : type;
  const additionalProps = type === 'number' ? { inputMode: 'numeric', pattern: '[0-9]*' } : {};

  return (
    <Form.Group className={`position-relative ${groupClassName}`}>
      {(value !== undefined && value !== null && value !== '') && (
        <Form.Label
          className={`${errors ? 'is-invalid' : ''} ${focusedInput === name ? 'is-focused' : ''
            }`}
        >
          {label}:
        </Form.Label>
      )}
      <InputGroup>
        <Form.Control
          className="form-control input-with-toggle"
          id={id}
          name={name}
          type={inputType}
          value={value}
          onChange={onChange}
          isInvalid={isInvalid}
          placeholder={renderPlaceholder()}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          readOnly={readOnly}
          {...additionalProps}
        />
        {toggleOptions.map((option, index) => (
          <div className={classNames('input-toggle-wrapper', {
            'last-toggle-wrapper': index === toggleOptions.length - 1,
            'is-invalid': isInvalid,
            'is-focused': focusedInput === name,
          })} key={option.name}>
            <ToggleButton
              key={option.name}
              type="radio"
              id={`radio-${id}-${option.name}-${uniqueId}`}
              name={`radio-${id}-${option.name}-${uniqueId}`}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={(e) => handleToggleChange(e.currentTarget.value)}
              className='mb-0 input-toggle-button'
            >
              <ZymranIcon name={option.name} />
            </ToggleButton>
          </div>
        ))}
      </InputGroup>
      <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default EnhancedFormControlWithToggle;
