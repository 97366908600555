import axios from "axios";

import { HTTP_STATUS } from "util/constants";
import { notifyError } from "util/notify";
import history from "util/history";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  headers: {
    "Content-type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error("axios error: ", error.response);
      // The request was made and the server responded with a status code
      const status = error.response.status;
      const message = error.response.data.detail;
      if (status === HTTP_STATUS.UNAUTHORIZED) {
        history.replace("/signin/partner");
        notifyError(message);
      } else {
        notifyError(message);
      }
      // Return the error to the calling code
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
