import { ProgressBar } from "react-bootstrap";
import { Trans } from "react-i18next";

const ProfileStatsProgressBar = ({ locationStats, dataKey, colors }) => {
  let data = locationStats[dataKey] || {
    expected: 0,
    received: 0,
    canceled: 0,
  };
  let expectedFromBackend = data.expected || 0;
  let receivedFromBackend = data.received || 0;
  let canceled = data.canceled || 0;

  let expectedSegment = expectedFromBackend;
  let receivedSegment = receivedFromBackend;
  let extraSegment = 0;
  let totalSum;

  // Calculate progress bar segments based on the comparison of received and expected values
  if (receivedFromBackend < expectedFromBackend) {
    expectedSegment = expectedFromBackend - receivedFromBackend;
    totalSum = expectedSegment + receivedFromBackend + canceled;
  } else {
    extraSegment = receivedFromBackend - expectedFromBackend;
    totalSum = extraSegment + expectedFromBackend + canceled;
    receivedSegment = expectedFromBackend;
    expectedSegment = 0;
  }

  return (
    <div className="profile-stats-card__stat-block">
      <div>
        <span className="p3">{locationStats.locationName} </span>
        <span className="p3-bold">{receivedFromBackend}</span>
        <span className="p3">
          {" "}
          <Trans>out_of</Trans> {expectedFromBackend}
        </span>
      </div>
      <div className="w-100">
        <ProgressBar style={{ height: 10 }} className="shadow-none rounded-3">
          {receivedSegment > 0 && (
            <ProgressBar
              variant={colors["received"]}
              now={(receivedSegment * 100) / totalSum}
              key="received"
              className="border-end border-2 border-white"
            />
          )}
          {expectedSegment > 0 && (
            <ProgressBar
              variant={colors["expected"]}
              now={(expectedSegment * 100) / totalSum}
              key="expected"
              className="border-end border-2 border-white"
            />
          )}
          {extraSegment > 0 && (
            <ProgressBar
              variant={colors["extra"]}
              now={(extraSegment * 100) / totalSum}
              key="extra"
              className="border-end border-2 border-white"
            />
          )}
          {canceled > 0 && (
            <ProgressBar
              variant={colors["canceled"]}
              now={(canceled * 100) / totalSum}
              key="canceled"
              className="border-end border-2 border-white"
            />
          )}
        </ProgressBar>
      </div>
    </div>
  );
};

export default ProfileStatsProgressBar;
