import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import languageKZ from "./locale/kz/translate.json";
import languageRU from "./locale/ru/translate.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      kz: languageKZ,
      ru: languageRU,
    },
    order: ['cookie', 'querystring', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    lookupCookie: 'i18next',
    /* DON'T DELETE lng. Default language */
    lng: "ru",
    /* When react i18next not finding any language to as default in browser */
    fallbackLng: "kz",
    /* debugger For Development environment */
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      // wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
      useSuspense: true,
    },
  });

export default i18n;
