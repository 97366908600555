import React from "react";
import { Route, Routes } from "react-router-dom";
import ListCustomers from "./ListCustomers";

const Customer = () => {
  return (
    <>
      <Routes>
        <Route path="/*" element={<ListCustomers />} />
      </Routes>
    </>
  );
};

export default Customer;
