import http from "../../http-common";
import { authHeader } from "service/auth/auth-header";

const getBusinessTypes = () => {
  return http.get(`business-type/`, {
    headers: authHeader(),
  });
};

export { getBusinessTypes };
