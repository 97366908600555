import React from "react";
import { Trans } from "react-i18next";

const RegistrationComplete = () => {
  return (
    <div className="container">
      <p className="lh-1 py-3">
        <Trans>email_confirmation</Trans>
      </p>
      <a href="/" className="btn btn-primary">
        <Trans>to_main_page</Trans>
      </a>
    </div>
  );
};

export default RegistrationComplete;
