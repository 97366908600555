import CustomerSearchPane from "component/calendar/CustomerSearchPane";
import CreateCustomerQuick from "component/calendar/create/CreateCustomerQuick";
import IconButton from "component/common/IconButton";
import ZymranIcon from "component/common/ZymranIcon";
import CreateCustomer from "component/customer/CreateCustomer";
import { addMinutes, format } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import useLocale from "hooks/useLocale";
import capitalize from "lodash/capitalize";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { createAppointments } from "../../../service/appointment/AppointmentService";
import BlockUI from "../../../util/block-ui/block-ui";
import { notifyError, notifySuccess } from "../../../util/notify";
import Appointment from "../Appointment";

const CreateAppointment = ({
  appointment,
  calendarStartTime,
  calendarEndTime,
  timezone,
  setAppointmentsFromDB,
  handleCloseCreateAppointmentModal,
  calculateDuration,
  setAppointment,
  selectedLocationId,
  updateCheckoutInfo,
  handleAppointmentCreated,
  ...props
}) => {
  const [appointments, setAppointments] = useState([]);
  const [appointmentDivs, setAppointmentDivs] = useState([]);

  const step = 15;
  const [blocking, setBlocking] = useState(false);
  const [showCustomerCreateModal, setCustomerCreateModalShow] = useState(false);
  const [showQuickCustomerCreateModal, setShowQuickCustomerCreateModal] =
    useState(false);
  const handleShowCustomerCreateModal = () => setCustomerCreateModalShow(true);
  const { t } = useTranslation();

  const locale = useLocale();
  const [selectedCustomer, setSelectedCustomer] = useState({});

  useEffect(() => {
    if (appointments.length === 0) {
      const defaultAppointment = {
        ...appointment,
        chainId: null,
        customer: null,
        customerId: null,
        selectedService: {
          value: 0,
          label: "",
        },
        comment: "",
      };
      setAppointments((oldAppointments) => [
        ...oldAppointments,
        defaultAppointment,
      ]);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (appointments.length > 0) {
      prepareAppointmentDivs();
    }
    // eslint-disable-next-line
  }, [appointments.length, appointments]);

  const handleCustomerCreateClose = () => {
    setCustomerCreateModalShow(false);
  };

  const handleCustomerSearchSelection = (item) => {
    setSelectedCustomer(item);
    let newAppointments = [];

    appointments.forEach((appt) => {
      newAppointments.push({
        ...appt,
        customer: item,
        customerId: item.id,
      });
    });
    setAppointments(newAppointments);
  };

  const handleAddAppointment = () => {
    const lastAppointment =
      appointments.length > 0 ? appointments[appointments.length - 1] : null;

    const calStartTime = lastAppointment
      ? lastAppointment.endTime
      : calendarStartTime;

    let lastCustomer = lastAppointment
      ? lastAppointment.customer
      : selectedCustomer;

    let appt = {
      id: appointments.length + 1,
      startTime: calStartTime,
      endTime: fromZonedTime(addMinutes(calStartTime, step), timezone),
      resourceId: appointment.resourceId,
      customer: lastCustomer,
      customerId: lastCustomer ? lastCustomer.id : null,
      selectedStaffLocation: {
        value: 0,
        label: "",
      },
      selectedService: {
        value: 0,
        label: "",
      },
      staffLocation: {},
    };
    setAppointments((oldAppointments) => [...oldAppointments, appt]);
  };

  const setAppointmentField = (appointmentId, field, value) => {
    const index = appointments.findIndex((appt) => appt.id === appointmentId);
    const appts = [...appointments];
    appts[index][field] = value;
    if (field === "duration") {
      const startTime = appts[index]["startTime"];
      appts[index]["endTime"] = addMinutes(startTime, value);

      // Update the startTime of the following appointment
      const nextAppointmentIndex = index + 1;
      if (nextAppointmentIndex < appts.length) {
        appts[nextAppointmentIndex].startTime = appts[index].endTime;
      }
    }
    setAppointments(appts);
  };

  const handleRemoveAppointment = (appointment) => {
    let newAppointments = appointments.filter((appt) => {
      return appt.id !== appointment.id;
    });
    setAppointments(newAppointments);
  };

  const prepareAppointmentDivs = () => {
    const appointmentDivs = appointments.map((appointment, index) => {
      if (!appointment.selectedStaffLocation) {
        appointment.selectedStaffLocation = {
          value: 0,
          label: "",
        };
      }
      return (
        <Card.Body key={index} className="appointment-service-card-body">
          <Appointment
            appointmentIndex={index}
            appointment={appointment}
            calendarStartTime={calendarStartTime}
            calendarEndTime={calendarEndTime}
            step={step}
            setAppointmentField={setAppointmentField}
            staffLocations={props.staffLocations}
            handleRemoveAppointment={handleRemoveAppointment}
          />
        </Card.Body>
      );
    });
    setAppointmentDivs(appointmentDivs);
  };

  const handleCreate = async (e) => {
    try {
      e.preventDefault();
      setBlocking(true);
      for (const appt of appointments) {
        appt.staffLocationId = appt.selectedStaffLocation.value;
        appt.serviceId = appt.selectedService.value;
        if (!appt.selectedService.value) {
          notifyError(t("service_required"));
          setBlocking(false);
          return;
        }
        if (
          appt.customer === undefined ||
          appt.customer === null ||
          !appt.customerId ||
          appt.customerId === null
        ) {
          notifyError(t("customer_not_selected"));
          setBlocking(false);
          setShowQuickCustomerCreateModal(true);
          return;
        }
      }
      sendCreateAppointments();
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const hanldeCreateWithoutCustomer = async () => {
    try {
      setBlocking(true);
      for (const appt of appointments) {
        appt.staffLocationId = appt.selectedStaffLocation.value;
        appt.serviceId = appt.selectedService.value;
        appt.customer = null;
        if (!appt.selectedService.value) {
          notifyError(t("service_required"));
          setBlocking(false);
          return;
        }
      }
      sendCreateAppointments();
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const sendCreateAppointments = async () => {
    console.log("sendCreateAppointments is called");
    try {
      setBlocking(true);
      let apptsToSend = [];

      appointments.forEach((appt) => {
        const apptToSend = {
          ...appt,
          startTime: fromZonedTime(appt.startTime, timezone),
          endTime: fromZonedTime(
            addMinutes(appt.startTime, appt.duration),
            timezone
          ),
          customerId: appt.customerId,
        };
        apptsToSend.push(apptToSend);
      });

      console.log("APPTS TO SEND: ", apptsToSend);
      const response = await createAppointments(apptsToSend);

      if (response.status === 201) {
        const appts = response.data;
        const newAppts = [];

        appts.forEach((appt) => {
          const newAppt = {
            id: appt.id,
            startTime: appt.startTime,
            endTime: appt.endTime,
            duration: calculateDuration(appt.startTime, appt.endTime),
            resourceId: appt.staffLocation.id,
            selectedStaffLocation: {
              value: appt.staffLocation.id,
              label:
                appt.staffLocation.staff.firstName +
                " " +
                appt.staffLocation.staff.lastName,
            },
            selectedService: {
              value: appt.service.id,
              label: appt.service.name,
            },
            service: appt.service,
            chainId: appt.chainId,
            status: appt.status,
            customer: appt.customer,
          };
          newAppts.push(newAppt);
        });
        setAppointmentsFromDB((oldAppts) => [...oldAppts, ...newAppts]);
        setAppointment(newAppts[0]);
        setBlocking(false);
        notifySuccess(t("appointment_created"));
        handleAppointmentCreated(newAppts[0]);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setBlocking(false);
    }
  };

  const handleCloseQuickCustomerCreateModal = () => {
    setShowQuickCustomerCreateModal(false);
  };

  CreateAppointment.propTypes = {
    appointment: PropTypes.object.isRequired,
    calendarStartTime: PropTypes.instanceOf(Date).isRequired,
    calendarEndTime: PropTypes.instanceOf(Date).isRequired,
    timezone: PropTypes.string.isRequired,
  };

  return (
    <>
      <Card className="appointment-service-card">
        <Card.Header className="appointment-info-header">
          <h3 className="mb-0">
            {capitalize(
              format(
                toZonedTime(appointment.startTime, timezone),
                "EEEE dd MMM yyyy HH:mm",
                { locale }
              )
            )}
          </h3>
        </Card.Header>
        <div className="appointment-service-card-scrollable-area">
          {appointmentDivs}
          <div className="appointment-service-card-footer">
            <button
              name="addServiceButton"
              className="btn btn-blue-light"
              onClick={handleAddAppointment}
            >
              <ZymranIcon name="pen" />
              <span>
                <Trans>add_service</Trans>
              </span>
            </button>
          </div>
        </div>
      </Card>
      <BlockUI tag="div" blocking={blocking} />

      <div className="customer-pane-column">
        <Card className="customer-pane">
          <Card.Header className="customer-pane__header">
            <h3 className="mb-0">
              <Trans>customer</Trans>
            </h3>
            <IconButton
              // iconClassName="me-0"
              variant="blue-light"
              size="sm"
              icon="plus"
              transform="shrink-2"
              onClick={handleShowCustomerCreateModal}
            >
              <Trans>create_new_customer</Trans>
            </IconButton>
          </Card.Header>
          <Card.Body className="overflow-auto customer-pane__body">
            <CustomerSearchPane
              handleCustomerSearchSelection={handleCustomerSearchSelection}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
            />
          </Card.Body>
        </Card>
        <div className="d-flex align-items-end align-self-stretch gap-3">
          <Button
            name="closeButton"
            variant="grey"
            onClick={handleCloseCreateAppointmentModal}
            className="w-100"
          >
            <Trans>close</Trans>
          </Button>
          <Button
            name="createAppointmentButton"
            type="submit"
            variant="blue"
            onClick={handleCreate}
            className="w-100"
          >
            <Trans>create</Trans>
          </Button>
        </div>
      </div>

      <Modal
        show={showCustomerCreateModal}
        fullscreen={true}
        onHide={() => setCustomerCreateModalShow(false)}
        // dialogClassName="client-modal"
        // className="modal-right"
        aria-labelledby="customer-create-modal-title"
      >
        <Modal.Header closeButton closeVariant="white" className="bg-primary">
          <Modal.Title
            id="customer-create-modal-title"
            className="text-white light"
          >
            <Trans>customer_addition</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <CreateCustomer
            handleCustomerCreateClose={handleCustomerCreateClose}
            setBlocking={setBlocking}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showQuickCustomerCreateModal}
        centered
        fullscreen={false}
        className="confirmation-modal"
      >
        <Modal.Body>
          <CreateCustomerQuick
            onCustomerCreated={(customer) => {
              handleCustomerSearchSelection(customer);
              setShowQuickCustomerCreateModal(false);
            }}
            hanldeCreateWithoutCustomer={hanldeCreateWithoutCustomer}
            onClose={handleCloseQuickCustomerCreateModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateAppointment;
