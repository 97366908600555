import React, { Fragment, useEffect, useState } from "react";
import { Form, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fuse from "fuse.js";
import { Link } from "react-router-dom";
import { isIterableArray } from "helpers/utils";
import Flex from "component/common/Flex";
import SoftBadge from "component/common/SoftBadge";
import ZymranIcon from "component/common/ZymranIcon";
import ZymranAvatar from "component/common/ZymranAvatar";

const StaffSearchContent = ({ item, handleShowViewStaffModal }) => {
  return (
    <Dropdown.Item as={Link} onClick={() => handleShowViewStaffModal(item.id)}>
      <Flex alignItems="center" className="dropdown-item-inner-flex">
        <ZymranAvatar
          avatarUrl={item.avatarUrl}
          fullName={`${item.firstName} ${item.lastName}`}
          size="sm"
          shape="circle"
        />

        <div className="d-flex flex-column align-items-start flex-grow-0">
          <p className="p2 mb-0 primary-text-color">
            {item.firstName} {item.lastName}
          </p>
          <p className="p2 mb-0 secondary-text-color">
            {item.phoneNumber}, {item.email}
          </p>
          <p className="p2 mb-0 secondary-text-color">{item.position}</p>
        </div>
      </Flex>
    </Dropdown.Item>
  );
};

const StaffSearchBox = ({ autoCompleteItem, handleShowViewStaffModal }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [resultItem, setResultItem] = useState(autoCompleteItem);

  const fuseJsOptions = {
    includeScore: true,
    keys: ["firstName", "lastName", "middleName", "email", "phoneNumber"],
    threshold: 0.4,
  };

  let searchResult = new Fuse(autoCompleteItem, fuseJsOptions)
    .search(searchInputValue)
    .map((item) => item.item);

  const recentlyBrowsedItems = resultItem.filter(
    (item) => item.catagories === "recentlyBrowsedItems"
  );

  const suggestedFilters = resultItem.filter(
    (item) => item.catagories === "suggestedFilters"
  );

  const suggestionFiles = resultItem.filter(
    (item) => item.catagories === "suggestionFiles"
  );

  const suggestionMembers = resultItem.filter(
    (item) => item.catagories === "suggestionMembers"
  );

  const toggle = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    if (searchInputValue) {
      setResultItem(searchResult);
      isIterableArray(searchResult)
        ? setDropdownOpen(true)
        : setDropdownOpen(false);
    } else {
      setResultItem(autoCompleteItem);
    }

    // eslint-disable-next-line
  }, [searchInputValue]);

  return (
    <Dropdown onToggle={toggle} className="search-box">
      <Dropdown.Toggle
        id="staffSearchBoxToggle"
        as="div"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        bsPrefix="toggle"
      >
        <Form className="position-relative">
          <Form.Control
            type="search"
            placeholder="Search..."
            aria-label="Search"
            className="form-control search-input search-input--sm"
            value={searchInputValue}
            onChange={({ target }) => setSearchInputValue(target.value)}
            onClick={() => setDropdownOpen(false)}
          />
          <ZymranIcon
            name="search"
            className="position-absolute search-box-icon"
          />
          {searchInputValue && (
            <div className="search-box-close-btn-container">
              <button type="button" className="button-outline-none">
                <ZymranIcon
                  name="close"
                  onClick={() => setSearchInputValue("")}
                />
              </button>
            </div>
          )}
        </Form>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="dropdown-item-wrapper" style={{ maxHeight: "19rem" }}>
          {isIterableArray(recentlyBrowsedItems) && (
            <>
              <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium">
                Recently Browsed
              </Dropdown.Header>
              {recentlyBrowsedItems.map((item) => (
                <Dropdown.Item
                  as={Link}
                  to={item.url}
                  className="fs--1 px-card py-1 hover-primary "
                  key={item.id}
                >
                  <Flex alignItems="center">
                    <FontAwesomeIcon
                      icon="circle"
                      className="me-2 text-300 fs--2"
                    />
                    <div className="fw-normal">
                      {item.breadCrumbTexts.map((breadCrumbText, index) => {
                        return (
                          <Fragment key={breadCrumbText}>
                            {breadCrumbText}
                            {item.breadCrumbTexts.length - 1 > index && (
                              <FontAwesomeIcon
                                icon="chevron-right"
                                className="mx-1 text-500 fs--2"
                                transform="shrink 2"
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </div>
                  </Flex>
                </Dropdown.Item>
              ))}
              {(isIterableArray(suggestedFilters) ||
                isIterableArray(suggestionFiles) ||
                isIterableArray(suggestionMembers)) && (
                <hr className="bg-200 dark__bg-900" />
              )}
            </>
          )}

          {isIterableArray(suggestedFilters) && (
            <>
              <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium">
                Suggested Filter
              </Dropdown.Header>
              {suggestedFilters.map((item) => (
                <Dropdown.Item
                  as={Link}
                  to={item.url}
                  className="fs-0 px-card py-1 hover-primary "
                  key={item.id}
                >
                  <Flex alignItems="center">
                    <SoftBadge
                      bg={item.type}
                      className="fw-medium text-decoration-none me-2"
                    >
                      {item.key}:{" "}
                    </SoftBadge>
                    <div className="flex-1 fs--1">{item.text}</div>
                  </Flex>
                </Dropdown.Item>
              ))}
              {(isIterableArray(suggestionFiles) ||
                isIterableArray(suggestionMembers)) && (
                <hr className="bg-200 dark__bg-900" />
              )}
            </>
          )}

          {isIterableArray(resultItem) && (
            <>
              {/* <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium">
                Members
              </Dropdown.Header> */}
              {resultItem.map((item) => (
                <StaffSearchContent
                  item={item}
                  key={item.id}
                  handleShowViewStaffModal={handleShowViewStaffModal}
                />
              ))}
            </>
          )}

          {resultItem.length === 0 && (
            <p className="fs-1 py-3 fw-bold text-center mb-0">
              No Result Found.
            </p>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default StaffSearchBox;
