import { ReactComponent as AddUserIcon } from "assets/img/icons/add-user.svg";
import CardDropdown from "component/common/CardDropdown";
import Flex from "component/common/Flex";
import ZymranAvatar from "component/common/ZymranAvatar";
import ZymranIcon from "component/common/ZymranIcon";
import AdvanceTable from "component/common/advance-table/AdvanceTable";
import ZymranTablePagination from "component/common/zymran-table/ZymranTablePagination";
import ZymranTableWrapper from "component/common/zymran-table/ZymranTableWrapper";
import CustomerInfo from "component/customer/CustomerInfo";
import moment from "moment";
import React, { useState } from "react";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import {
  deleteCustomer,
  getCustomersWithPagination,
} from "service/customer/CustomerService";
import { notifyWarning } from "util/notify";
import BlockUI from "../../util/block-ui/block-ui";
import CreateCustomer from "./CreateCustomer";
import CustomerSearchBox from "./CustomerSearchBox";
import EditCustomer from "./EditCustomer";

const ListCustomers = () => {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [showCustomerModal, setCustomerModalShow] = useState(false);
  const [showCustomerCreateModal, setCustomerCreateModalShow] = useState(false);
  const [showCustomerEditModal, setCustomerEditModalShow] = useState(false);
  const [showDeleteCustomerModal, setShowDeleteCustomerModal] = useState(false);
  const handleShowCustomerCreateModal = () => setCustomerCreateModalShow(true);
  const [blocking, setBlocking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [genders] = useState([
    { label: t("male"), value: 1 },
    { label: t("female"), value: 2 },
    { label: t("all"), value: 3 },
  ]);

  const columns = [
    {
      accessor: "name",
      Header: t("name"),
      sortType: (rowA, rowB, desc) => {
        const nameA = `${rowA.original.firstName} ${rowA.original.lastName}`;
        const nameB = `${rowB.original.firstName} ${rowB.original.lastName}`;

        // If descending, swap the order of comparison
        if (desc) {
          return nameB.localeCompare(nameA);
        } else {
          return nameA.localeCompare(nameB);
        }
      },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { firstName, lastName } = rowData.row.original;
        const { avatarUrl } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <ZymranAvatar
              avatarUrl={avatarUrl}
              fullName={`${firstName} ${lastName}`}
              size="sm"
              shape="circle"
              className={`me-2`}
            />
            <div className="flex-1">
              <span className="mb-0 p2">
                {firstName} {lastName}
              </span>
            </div>
          </Flex>
        );
      },
    },
    {
      accessor: "phoneNumber",
      Header: t("phone_number"),
      disableSortBy: true,
      Cell: (rowData) => {
        const { phoneNumberPrimary } = rowData.row.original;
        return <a href={`tel:${phoneNumberPrimary}`}>{phoneNumberPrimary}</a>;
      },
    },
    {
      accessor: "email",
      Header: t("email"),
      disableSortBy: true,
      Cell: (rowData) => {
        const { email } = rowData.row.original;
        return <a href={`mailto:` + email}>{email}</a>;
      },
    },
    {
      accessor: "genderId",
      Header: t("gender"),
      Cell: (rowData) => {
        const { genderId } = rowData.row.original;
        return getGenderIcon(genderId);
      },
    },
    {
      accessor: "createdAt",
      Header: t("creation_date"),
      Cell: (rowData) => {
        const { createdAt } = rowData.row.original;
        return moment(createdAt).format("DD/MM/YYYY");
      },
    },
    {
      accessor: "sales",
      Header: t("sales"),
      Cell: (rowData) => {
        return (
          <div className="text-center">
            <span className="fw-bold">-</span>
          </div>
        );
      },
    },
    {
      accessor: "none",
      Header: "",
      disableSortBy: true,
      cellProps: {
        className: "text-end",
      },
      Cell: (rowData) => {
        const customer = rowData.row.original;
        return (
          <CardDropdown btnRevealClass="btn-reveal-sm" drop="start" dropdownMenuClass="staff-actions-dropdown-wrapper">
            <div className="staff-actions-dropdown p2">
              <Dropdown.Item
                href=""
                onClick={() => handleCustomerSelection(customer)}
              >
                <ZymranIcon name="info" />
                <Trans>more_info</Trans>
              </Dropdown.Item>
              <Dropdown.Item
                href=""
                onClick={() => handleShowCustomerEditModal(customer)}
              >
                <ZymranIcon name="pen" />
                <Trans>edit</Trans>
              </Dropdown.Item>
              <Dropdown.Item
                href=""
                className="text-danger"
                onClick={() => handleShowDeleteCustomerModal(customer)}
              >
                <ZymranIcon name="delete" />
                <Trans>delete</Trans>
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      },
    },
  ];

  const handleCustomerSelection = (customer) => {
    setSelectedCustomer(customer);
    setCustomerModalShow(true);
  };

  const handleShowCustomerEditModal = (customer) => {
    setSelectedCustomer(customer);
    setCustomerEditModalShow(true);
  };

  const handleShowDeleteCustomerModal = (customer) => {
    setSelectedCustomer(customer);
    setShowDeleteCustomerModal(true);
  };

  const handleCustomerCreateClose = () => {
    setCustomerCreateModalShow(false);
  };

  // const getGenderLabel = (genderId) => {
  //   const genderItem = genders.find((g) => g.value === genderId);
  //   return <div>{genderItem.label}</div>;
  // };

  const getGenderIcon = (genderId) => {
    if (genderId === 1) {
      return <ZymranIcon name="man" className="secondary-text-color" />;
    } else if (genderId === 2) {
      return <ZymranIcon name="woman" className="secondary-text-color" />;
    } else {
      return <ZymranIcon name="block" className="secondary-text-color" />;
    }
  };

  const updateCustomer = (updatedCustomer) => {
    const updatedCustomers = customers.map((customer) =>
      customer.id === updatedCustomer.id ? updatedCustomer : customer
    );
    setCustomers(updatedCustomers);
  };

  const handleDeleteCustomer = async () => {
    try {
      await deleteCustomer(selectedCustomer.id);
      notifyWarning(t("customer_deleted"));
      fetchCustomers(currentPageIndex, 10);
      setCustomerModalShow(false);
    } catch (error) {
      console.error(error);
    } finally {
      setShowDeleteCustomerModal(false);
    }
  };

  const fetchCustomers = React.useCallback(async (pageIndex, pageSize) => {
    setLoading(true);
    const response = await getCustomersWithPagination(pageIndex, pageSize);
    console.log("fetch customers response: ", response);
    setCustomers(response.data.customers);
    setPageCount(Math.ceil(response.data.totalCount / pageSize));
    setLoading(false);
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center align-selft-stretch mb-3">
        <h1 className="mb-0">
          <Trans>customer_list</Trans>
        </h1>
        <div className="d-flex align-items-center gap-3">
          <CustomerSearchBox
            autoCompleteItem={customers}
            handleCustomerSelection={handleCustomerSelection}
          />
          <Button
            name="addCustomerButton"
            variant="blue"
            size="sm"
            onClick={handleShowCustomerCreateModal}
          >
            <ZymranIcon name="plus" />
            <span className="d-none d-md-block">
              <Trans>add_customer</Trans>
            </span>
          </Button>
        </div>
      </div>
      <ZymranTableWrapper
        columns={columns}
        data={customers}
        sortable
        // pagination
        perPage={10}
        fetchData={fetchCustomers}
        pageCount={pageCount}
        onPageChange={(newPageIndex) => setCurrentPageIndex(newPageIndex)}
      >
        <Card className="mb-3">
          <Card.Body className="p-0 staff-list-table-wrapper">
            <AdvanceTable
              table
              headerClassName="text-nowrap align-middle secondary-text-color"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: false,
                className: "mb-0 staff-list-table overflow-hidden",
              }}
              customEmptyComponent={
                <div className="d-flex flex-column gap-5 p-4 align-items-center justify-content-center">
                  <div className="d-flex flex-column gap-4 align-items-center justify-content-center secondary-text-color">
                    <AddUserIcon className="no-employees-icon" />
                    <Trans>customers_not_added_yet</Trans>
                  </div>
                  <Button
                    name="addEmployeeInTableButton"
                    variant="blue"
                    size="sm"
                    type="button"
                    onClick={() => setCustomerCreateModalShow(true)}
                  >
                    <ZymranIcon name="plus" />
                    <Trans>add_customer</Trans>
                  </Button>
                </div>
              }
            />
          </Card.Body>
          <Card.Footer>
            <ZymranTablePagination table />
          </Card.Footer>
        </Card>
      </ZymranTableWrapper>

      <Modal
        className="customer-info-modal"
        show={showCustomerModal}
        onHide={() => setCustomerModalShow(false)}
        aria-labelledby="customer-info-modal-title"
        centered
      >
        <Modal.Header closeButton className="plain-modal-header">
          <Modal.Title id="customer-info-modal-title">
            <Trans>customer_card</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="plain-modal-body p-0">
          <CustomerInfo
            customerList={customers}
            customerData={selectedCustomer}
            getGenderIcon={getGenderIcon}
            updateCustomer={updateCustomer}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showCustomerCreateModal}
        fullscreen={true}
        onHide={() => setCustomerCreateModalShow(false)}
        aria-labelledby="customer-create-modal-title"
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title
            id="customer-create-modal-title"
            className="text-white light"
          >
            <Trans>customer_addition</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateCustomer
            handleCustomerCreateClose={handleCustomerCreateClose}
            setCustomers={setCustomers}
            setBlocking={setBlocking}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showCustomerEditModal}
        fullscreen={true}
        onHide={() => setCustomerEditModalShow(false)}
        aria-labelledby="customer-edit-modal-title"
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title
            id="customer-edit-modal-title"
            className="text-white light"
          >
            <Trans>editing_customer</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditCustomer
            customerId={selectedCustomer.id}
            customerData={selectedCustomer}
            onUpdateCustomer={updateCustomer}
            handleClose={() => setCustomerEditModalShow(false)}
          />
        </Modal.Body>
      </Modal>

      {/* Delete Customer Modal */}
      <Modal
        show={showDeleteCustomerModal}
        onHide={() => setShowDeleteCustomerModal(false)}
        className="confirmation-modal"
        centered
      >
        <Modal.Body className="bg-light">
          <div className="d-flex flex-column align-items-center align-self-stretch text-center mb-3">
            <h2>
              <Trans>delete_customer_queston</Trans>
            </h2>
            <p className="mb-0 p1 secondary-text-color">
              <Trans>all_customer_data_will_be_deleted</Trans>
            </p>
          </div>
          <div className="delete-info-area">
            <div className="view-info-block">
              <ZymranAvatar
                avatarUrl={selectedCustomer.avatarUrl}
                fullName={`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
                size="xl"
                shape="circle"
              />
              <div className="view-info-details">
                <div>
                  <p className="p1-bold mb-0 primary-text-color d-flex align-items-center gap-1">
                    {selectedCustomer.firstName} {selectedCustomer.lastName}{" "}
                    {getGenderIcon(selectedCustomer.genderId)}
                  </p>
                  <p className="p2 mb-0 secondary-text-color">
                    <Trans>birth_date</Trans>:{" "}
                    {moment(selectedCustomer.dob).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div>
                  <a href={`mailto:` + selectedCustomer.email} className="p2">
                    {selectedCustomer.email}
                  </a>
                  <p className="p2 mb-0 secondary-text-color">
                    {selectedCustomer.phoneNumberPrimary}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            name="deleteCustomerButton"
            variant="orange"
            size="sm"
            className="flex-grow-1"
            onClick={handleDeleteCustomer}
          >
            <Trans>delete_customer</Trans>
          </Button>
          <Button
            name="cancelDeleteCustomerButton"
            variant="blue"
            size="sm"
            className="flex-grow-1"
            onClick={() => setShowDeleteCustomerModal(false)}
          >
            <Trans>cancel</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
      <BlockUI tag="div" blocking={blocking} />
    </>
  );
};

export default ListCustomers;
