import React from "react";
import PropTypes from "prop-types";
import Flex from "component/common/Flex";
import SoftBadge from "component/common/SoftBadge";
import { useTranslation } from "react-i18next";
import ZymranIcon from "component/common/ZymranIcon";

const NavbarVerticalMenuItem = ({ route, isTopLevel }) => {
  const { t } = useTranslation();
  return (
    <Flex alignItems="center" className="align-self-stretch gap-125">
      {route.icon && (
        <span className="sidebar-icon-wrapper text-center">
          <ZymranIcon name={route.icon} size={isTopLevel ? 24 : 16} />
        </span>
      )}
      <span className="nav-link-text p2">{t(route.name)}</span>
      {route.badge && (
        <SoftBadge pill bg={route.badge.type} className="ms-2">
          {route.badge.text}
        </SoftBadge>
      )}
    </Flex>
  );
};

// prop-types
const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired,
};

export default React.memo(NavbarVerticalMenuItem);
