import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import {
  createLocation,
  uploadLocationAvatar,
} from "../../../service/location/LocationService";

import notFoundAnimation from "assets/img/animated-icons/not-found.json";
import typingAnimation from "assets/img/animated-icons/typing-in-chat.json";
import Flex from "component/common/Flex";
import ZymranAvatar from "component/common/ZymranAvatar";
import ZymranIcon from "component/common/ZymranIcon";
import LocationAddressForm from "component/location/forms/LocationAddressForm";
import LocationBasicInformationForm from "component/location/forms/LocationBasicInformationForm";
import LocationOpenHoursForm from "component/location/forms/LocationOpenHoursForm";
import { fromZonedTime } from "date-fns-tz";
import Fuse from "fuse.js";
import { isIterableArray } from "helpers/utils";
import { parsePhoneNumber } from "libphonenumber-js/core";
import metadata from "libphonenumber-js/metadata.min.json";
import isEqual from "lodash/isEqual";
import Lottie from "lottie-react";
import { getStaffList } from "service/staff/StaffService";
import BlockUI from "../../../util/block-ui/block-ui";
import { notifySuccess } from "../../../util/notify";

import { setHours, setMilliseconds, setMinutes, setSeconds } from "date-fns";

const CreateLocation = ({ setLocations, setShow, handleClose }) => {
  const defaultStartHour = 9;
  const defaultEndHour = 20;
  const currentDate = setHours(
    setMinutes(setSeconds(new Date(), 0), 0),
    defaultStartHour
  );
  const timeStep = 15;
  const minStartTime = currentDate;
  const maxEndTime = setHours(currentDate, defaultEndHour);
  const [avatar, setAvatar] = useState([]);

  const [locationData, setLocationData] = useState({
    basicInformation: {
      name: "",
      phoneNumber: "",
      email: "",
    },
    address: {
      country: "",
      region: "",
      city: "",
      district: "",
      street: "",
      houseNumber: "",
      aptNumber: "",
      postcode: "",
      directionHint: "",
      note: "",
      coordinates: "",
      timezone: "",
    },

    openHours: {
      monday: {
        startTime: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          defaultStartHour
        ),
        endTime: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          defaultEndHour
        ),
        dayOff: false,
      },
      tuesday: {
        startTime: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          defaultStartHour
        ),
        endTime: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          defaultEndHour
        ),
        dayOff: false,
      },
      wednesday: {
        startTime: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          defaultStartHour
        ),
        endTime: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          defaultEndHour
        ),
        dayOff: false,
      },
      thursday: {
        startTime: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          defaultStartHour
        ),
        endTime: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          defaultEndHour
        ),
        dayOff: false,
      },
      friday: {
        startTime: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          defaultStartHour
        ),
        endTime: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          defaultEndHour
        ),
        dayOff: false,
      },
      saturday: {
        startTime: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          defaultStartHour
        ),
        endTime: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          defaultEndHour
        ),
        dayOff: false,
      },
      sunday: {
        startTime: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          defaultStartHour
        ),
        endTime: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          defaultEndHour
        ),
        dayOff: true,
      },
    },
    staffList: [],
  });

  const [blocking, setBlocking] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [staffTotalList, setStaffTotalList] = useState([]);
  const fuseJsOptions = {
    includeScore: true,
    keys: ["firstName", "lastName", "middleName", "phoneNumberPrimary"],
    threshold: 0.4,
  };
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const [errors, setErrors] = useState({
    basicInformation: {},
    address: {},
    openHours: {},
  });
  const { t } = useTranslation();
  const setLocationAddress = (locationAddress) => {
    setLocationData((locationData) => ({
      ...locationData,
      address: {
        ...locationData.address,
        country: locationAddress.country ? locationAddress.country : "",
        region: locationAddress.region ? locationAddress.region : "",
        city: locationAddress.city ? locationAddress.city : "",
        district: locationAddress.district ? locationAddress.district : "",
        street: locationAddress.street ? locationAddress.street : "",
        houseNumber: locationAddress.houseNumber
          ? locationAddress.houseNumber
          : "",
        aptNumber: locationAddress.aptNumber ? locationAddress.aptNumber : "",
        postcode: locationAddress.postcode ? locationAddress.postcode : "",
        directionHint: locationAddress.directionHint
          ? locationAddress.directionHint
          : "",
        note: locationAddress.note ? locationAddress.note : "",
        coordinates: locationAddress.coordinates
          ? locationAddress.coordinates
          : "",
        timezone: locationAddress.timezone ? locationAddress.timezone : "",
      },
    }));
  };

  useEffect(() => {
    if (staffTotalList.length === 0) {
      loadStaffList();
    }
    // eslint-disable-next-line
  }, [staffTotalList.length]);

  useEffect(() => {
    if (searchInputValue) {
      const newSearchResult = new Fuse(staffTotalList, fuseJsOptions)
        .search(searchInputValue)
        .map((item) => item.item);

      isIterableArray(newSearchResult)
        ? setDropdownOpen(true)
        : setDropdownOpen(false);
      setSearchResults(newSearchResult);
    } else {
      setSearchResults([]);
    }
    // eslint-disable-next-line
  }, [searchInputValue, staffTotalList]);

  useEffect(() => {
    if (staffTotalList && staffTotalList.length > 0) {
      const tempSearchResults = staffTotalList.map((item) => ({
        ...item,
        added: isStaffMemberAdded(item.id),
      }));

      if (!isEqual(tempSearchResults, staffTotalList)) {
        setStaffTotalList(tempSearchResults);
      }
    }
    // eslint-disable-next-line
  }, [locationData.staffList, staffTotalList]);

  const isStaffMemberAdded = (staffId) => {
    return locationData.staffList.some((staff) => staff.id === staffId);
  };

  const loadStaffList = async () => {
    try {
      const response = await getStaffList();
      setStaffTotalList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const findFormErrors = () => {
    const {
      basicInformation: { name, phoneNumber, email },
      address: {
        country,
        region,
        city,
        district,
        street,
        houseNumber,
        aptNumber,
        postcode,
        directionHint,
        note,
      },
    } = locationData;

    let newErrors = {};

    newErrors.basicInformation = {};
    newErrors.address = {};

    // BasicInformation
    if (!name || name === "")
      newErrors.basicInformation.name = t("field_required");
    else if (name.length > 64)
      newErrors.basicInformation.name = t("too_long64");

    if (!phoneNumber || phoneNumber === "")
      newErrors.basicInformation.phoneNumber = t("field_required");
    else if (phoneNumber.length > 12)
      newErrors.basicInformation.phoneNumber = t("too_long");
    else {
      try {
        const phoneNumberObject = parsePhoneNumber(phoneNumber, metadata);
        if (!phoneNumberObject.isValid())
          newErrors.basicInformation.phoneNumber = t("invalid_phone_number");
      } catch (error) {
        newErrors.basicInformation.phoneNumber = t("invalid_phone_number");
      }
    }

    if (!email || email === "") newErrors.email = t("field_required");
    if (email.length > 64) newErrors.basicInformation.email = t("too_long64");

    // LocationAddress
    if (!country || country === "")
      newErrors.address.country = t("field_required");
    else if (country.length > 128)
      newErrors.address.country = t("too_long_128");

    if (region.length > 128) newErrors.region = t("too_long_128");

    if (!city || city === "") newErrors.address.city = t("field_required");
    else if (city.length > 128) newErrors.address.city = t("too_long_128");

    if (district.length > 128) newErrors.district = t("too_long_128");

    if (!street || street === "")
      newErrors.address.street = t("field_required");
    else if (street.length > 128) newErrors.address.street = t("too_long_128");

    if (!houseNumber || houseNumber === "")
      newErrors.address.houseNumber = t("field_required");
    else if (houseNumber.length > 16)
      newErrors.address.houseNumber = t("too_long_16");

    if (aptNumber.length > 16) newErrors.address.aptNumber = t("too_long_16");

    if (postcode.length > 16) newErrors.address.postcode = t("too_long_16");

    if (note.length > 1024) newErrors.address.note = t("too_long_1024");

    if (directionHint.length > 128)
      newErrors.address.directionHint = t("too_long_128");

    return newErrors;
  };

  const setField = (parentField, field, value) => {
    setLocationData({
      ...locationData,
      [parentField]: {
        ...locationData[parentField],
        [field]: value,
      },
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[parentField][field])
      setErrors({
        ...errors,
        [parentField]: {
          ...errors[parentField],
          [field]: null,
        },
      });
  };

  const setLocationOpenHoursField = (day, field, value) => {
    setLocationData({
      ...locationData,
      openHours: {
        ...locationData["openHours"],
        [day]: {
          ...locationData["openHours"][day],
          [field]: value,
        },
      },
    });
  };

  const handleFileChange = (uploadedFiles) => {
    setAvatar(uploadedFiles);
  };

  const sendFileToBackend = async (base64EncodedImage, mimeType) => {
    try {
      const response = await uploadLocationAvatar(base64EncodedImage, mimeType);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (
      Object.keys(newErrors.basicInformation).length > 0 ||
      Object.keys(newErrors.address).length > 0
    ) {
      // We got errors!
      setErrors(newErrors);
      return;
    }

    const payload = { ...locationData };

    if (avatar.length > 0) {
      const fileData = avatar[0];
      try {
        const avatarResponse = await sendFileToBackend(
          fileData.base64,
          fileData.type
        );
        payload.avatarFilename = avatarResponse.filename;
        payload.avatarUrl = avatarResponse.url;
      } catch (error) {}
    }

    try {
      setBlocking(true);
      payload.name = locationData.basicInformation.name;
      payload.email = locationData.basicInformation.email;
      payload.phoneNumber = locationData.basicInformation.phoneNumber;

      delete payload.basicInformation;

      let locationOpenHours = [];

      for (const [key, value] of Object.entries(locationData.openHours)) {
        locationOpenHours.push({
          dayOfWeek: key,
          startTime: fromZonedTime(
            value.startTime,
            locationData.address.timezone
          ),
          endTime: fromZonedTime(value.endTime, locationData.address.timezone),
          dayOff: value.dayOff,
        });
      }
      payload.openHours = locationOpenHours;
      payload.staffList = locationData.staffList.map((staff) => staff.id);

      const response = await createLocation(payload);
      const location = response.data;
      setLocations((oldLocations) => [...oldLocations, location]);
      notifySuccess(t("location_created"));
      setShow(false);
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const handleDeleteStaff = (staffIdToDelete) => {
    const updatedStaff = locationData.staffList.filter(
      (staffMember) => staffMember.id !== staffIdToDelete
    );

    setLocationData((prevLocationData) => ({
      ...prevLocationData,
      staffList: updatedStaff,
    }));
  };

  const StaffSearchContent = ({ item }) => {
    return (
      <div
        className="staff-search-dropdown-cell"
        onClick={() => handleStaffSelection(item)}
      >
        <ZymranAvatar
          avatarUrl={item.avatarUrl}
          fullName={`${item.firstName} ${item.lastName}`}
          size="sm"
          shape="circle"
        />
        <div className="d-block flex-grow-1">
          <span className="p2 primary-text-color">
            {item.firstName} {item.lastName}
          </span>
          <span className="p2 secondary-text-color"> - {item.position}</span>
        </div>
        {item.added ? <ZymranIcon name="check" className="ml-auto" /> : null}
      </div>
    );
  };

  const handleStaffSelection = (selectedStaff) => {
    const existingStaff = locationData.staffList.find(
      (staff) => staff.id === selectedStaff.id
    );

    if (!existingStaff) {
      const updatedStaffList = [...locationData.staffList, selectedStaff];

      setLocationData((prevLocationData) => ({
        ...prevLocationData,
        staffList: updatedStaffList,
      }));
    }
  };

  return (
    <div className="container central-content-container">
      <Form>
        <Card className="mb-3 h-100">
          <Card.Header as="h3">
            <Trans>basic_information</Trans>
          </Card.Header>
          <Card.Body className="border-top">
            <LocationBasicInformationForm
              basicInformation={locationData.basicInformation}
              setField={setField}
              errors={errors.basicInformation}
              avatar={avatar}
              handleFileChange={handleFileChange}
            />
          </Card.Body>
        </Card>
        <Card className="mb-3 h-100">
          <Card.Header as="h3">
            <Trans>address</Trans>
          </Card.Header>
          <Card.Body className="border-top">
            <LocationAddressForm
              setField={setField}
              errors={errors}
              locationAddress={locationData.address}
              setLocationAddress={setLocationAddress}
            />
          </Card.Body>
        </Card>
        <Card className="mb-3">
          <Card.Header as="h3">
            <Trans>working_hours</Trans>
          </Card.Header>
          <Card.Body className="border-top p-0">
            <LocationOpenHoursForm
              locationOpenHours={locationData.openHours}
              setField={setLocationOpenHoursField}
              minStartTime={minStartTime}
              maxEndTime={maxEndTime}
              step={timeStep}
            />
          </Card.Body>
        </Card>
        <Card className="mb-3">
          <Card.Header as="h3">
            <Trans>staff</Trans>
          </Card.Header>
          <Card.Body className="border-top">
            <Dropdown onToggle={toggle} className="search-box w-100 mb-4">
              <Dropdown.Toggle
                id="staffSearchBoxToggle"
                as="div"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
                bsPrefix="toggle"
              >
                {/* <Form> */}
                <Form.Control
                  type="search"
                  name="staffSearchInput"
                  placeholder={t("search")}
                  aria-label="Search"
                  className="search-input customer-pane__search-input"
                  value={searchInputValue}
                  onChange={({ target }) => setSearchInputValue(target.value)}
                  onClick={() => setDropdownOpen(false)}
                />
                <FontAwesomeIcon
                  icon="search"
                  className="position-absolute text-400 search-box-icon"
                />
                {searchInputValue && (
                  <button
                    name="closeSearch"
                    className="search-input customer-pane__search-close-btn"
                    onClick={() => setSearchInputValue("")}
                  >
                    <ZymranIcon name="close" />
                  </button>
                )}
                {/* </Form> */}
              </Dropdown.Toggle>
              <Dropdown.Menu className="staff-search-dropdown">
                {isIterableArray(searchResults) ? (
                  <>
                    {searchResults.map((item) => (
                      <StaffSearchContent item={item} key={item.id} />
                    ))}
                  </>
                ) : searchInputValue !== "" ? (
                  <Flex
                    direction="column"
                    className="py-3 align-self-stretch"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Lottie
                      animationData={notFoundAnimation}
                      loop={true}
                      style={{ height: "150px", width: "150px" }}
                    />
                    <Trans>search_not_found_try_another</Trans>
                  </Flex>
                ) : (
                  <Flex
                    direction="column"
                    className="py-3 align-self-stretch"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Lottie
                      animationData={typingAnimation}
                      loop={true}
                      style={{ height: "100px", width: "100px" }}
                    />
                    <div className="secondary-text-color">
                      <Trans>type_for_staff_search</Trans>...
                    </div>
                  </Flex>
                )}
              </Dropdown.Menu>
            </Dropdown>
            {locationData.staffList && locationData.staffList.length === 0 && (
              <div className="d-flex flex-column justify-content-center align-items-center mb-3">
                <Lottie
                  animationData={notFoundAnimation}
                  loop={true}
                  style={{ height: "150px", width: "150px" }}
                />
              </div>
            )}
            <div className="locations-staff-grid">
              {locationData.staffList &&
                locationData.staffList.map((staff) => (
                  <div
                    className="p-3 h-100 locations-staff-block"
                    key={staff.id}
                  >
                    <div className="locations-staff-image-wrapper">
                      <ZymranAvatar
                        avatarUrl={staff.avatarUrl}
                        fullName={`${staff.firstName} ${staff.lastName}`}
                        size="xl"
                        shape="circle"
                      />
                      <button
                        name={`deleteStaffButton-${staff.id}`}
                        type="button"
                        className="icon-button icon-button--orange icon-button--sm"
                        onClick={() => handleDeleteStaff(staff.id)}
                      >
                        <ZymranIcon name="delete" />
                      </button>
                    </div>
                    <div className="locations-staff-info">
                      <div>
                        <p className="mb-0 p1-bold">
                          {staff.firstName} {staff.lastName}
                        </p>
                        <p className="p2 secondary-text-color mb-0">
                          {staff.position}
                        </p>
                      </div>
                      <div>
                        <p className="p2 secondary-text-color mb-0">
                          <a href={`mailto:` + staff.email} className="ml-1">
                            {staff.email}
                          </a>
                        </p>
                        <p className="p2 secondary-text-color mb-0">
                          {staff.phoneNumber}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Card.Body>
        </Card>
        <div className="d-flex justify-content-end align-items-start gap-3">
          <Button name="cancelButton" variant="grey" onClick={handleClose}>
            <Trans>cancel</Trans>
          </Button>
          <Button
            name="saveButton"
            type="submit"
            variant="blue"
            onClick={handleSubmit}
          >
            <Trans>save_location</Trans>
          </Button>
        </div>
      </Form>
      <BlockUI tag="div" blocking={blocking} />
    </div>
  );
};

CreateLocation.propTypes = {
  setLocations: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CreateLocation;
