import React, { useContext, useEffect, useState } from "react";
import Scheduler from "./Scheduler";
import AppContext from "context/Context";

const Calendar = () => {
  const {
    config: { isFluid, isNavbarVerticalCollapsed },
    setConfig,
  } = useContext(AppContext);
  const [calendarIsFluid] = useState(isFluid);
  const [calendarIsNavbarVerticalCollapsed] = useState(
    isNavbarVerticalCollapsed
  );

  useEffect(() => {
    setConfig("isFluid", true);
    setConfig("isNavbarVerticalCollapsed", true);

    return () => {
      setConfig("isFluid", calendarIsFluid);
      setConfig("isNavbarVerticalCollapsed", calendarIsNavbarVerticalCollapsed);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Scheduler />
    </>
  );
};

export default Calendar;
