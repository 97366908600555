import { Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import { RESTORE_PASSWORD_STEPS } from "util/constants";

const Step3 = ({
  passwordData,
  restorePasswordStep3,
  errors,
  currentStep,
  setField,
}) => {
  const { t } = useTranslation();

  if (currentStep !== RESTORE_PASSWORD_STEPS.STEP3) {
    return null;
  }

  return (
    <>
      <EnhancedFormControl
        id="password"
        name="password"
        type="password"
        value={passwordData.password}
        onChange={(e) => setField("passwordData", "password", e.target.value)}
        isInvalid={!!errors.password}
        placeholder={t("password")}
        label={t("password")}
        errors={errors.password}
        groupClassName="mb-3"
      />
      <EnhancedFormControl
        id="confirmPassword"
        name="confirmPassword"
        type="password"
        value={passwordData.passwordConfirm}
        onChange={(e) =>
          setField("passwordData", "passwordConfirm", e.target.value)
        }
        isInvalid={!!errors.passwordConfirm}
        placeholder={t("confirm_password")}
        label={t("confirm_password")}
        errors={errors.passwordConfirm}
        groupClassName="mb-3"
      />
      <div className="d-flex justify-content-center">
        <Button
          name="confirmPasswordChangeButton"
          className="w-100 mx-auto mt-3"
          variant="blue"
          onClick={restorePasswordStep3}
        >
          <Trans>confirm_password_change</Trans>
        </Button>
      </div>
    </>
  );
};

export default Step3;
