import http from "../../http-common";
import Cookies from "js-cookie";

const signIn = async (payload) => {
  return http.post("auth/signin", payload);
};

const registerOAuth = async (token, provider, authType) => {
  const payload = {
    provider: provider,
    auth_type: authType,
  };

  const authHeader = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  return http.post(
    "auth/oauth/register/partner",
    payload,
    { headers: authHeader }
  );
};

const signInOAuth = async (token, provider, authType) => {
  const payload = {
    provider: provider,
    auth_type: authType,
  };

  const authHeader = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  return http.post("auth/oauth", payload, { headers: authHeader });
};

const signOut = () => {
  Cookies.remove("zymran_user");
};

const registerPartnerStep1 = async (payload) => {
  return http.post("auth/register/partner/step1", payload);
};

const registerPartnerStep2 = async (payload) => {
  return http.post("auth/register/partner/step2", payload);
};

const registerPartnerStep3 = async (payload) => {
  return http.post("auth/register/partner/step3", payload);
};

const registerPartnerStep4 = async (payload) => {
  return http.post("auth/register/partner/step4", payload);
};

const registerUser = async (payload) => {
  return http.post("auth/register", payload);
};

const confirmRegistration = async (uuid) => {
  return http.post(`auth/register/confirm/${uuid}`);
};

const resendOtp = async (payload) => {
  return http.post("auth/resend-otp", payload);
};

const restorePasswordStep1 = async (payload) => {
  return http.post("auth/restore-password/step1", payload);
};

const restorePasswordStep2 = async (payload) => {
  return http.post("auth/restore-password/step2", payload);
};

const restorePasswordStep3 = async (payload) => {
  return http.post("auth/restore-password/step3", payload);
};

export {
  signIn,
  signOut,
  registerUser,
  registerPartnerStep1,
  registerPartnerStep2,
  registerPartnerStep3,
  registerPartnerStep4,
  confirmRegistration,
  signInOAuth,
  registerOAuth,
  restorePasswordStep1,
  restorePasswordStep2,
  restorePasswordStep3,
  resendOtp,
};
