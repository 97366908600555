import { ReactComponent as UserAvatarIcon } from "assets/img/icons/user-avatar.svg";
import CustomDateInput from "component/common/CustomDateInput";
import { default as EnhancedFormControl } from "component/common/EnhancedFormControl";
import { default as EnhancedSelect } from "component/common/EnhancedSelect";
import { default as FalconDropzone } from "component/common/FalconDropzone";
import { default as Flex } from "component/common/Flex";
import { default as ZymranIcon } from "component/common/ZymranIcon";
import { toZonedTime } from "date-fns-tz";
import { parsePhoneNumber } from "libphonenumber-js/core";
import { default as metadata } from "libphonenumber-js/metadata.min.json";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Datepicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { default as PhoneInput } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { createCustomer } from "service/customer/CustomerService";
import { uploadAvatar } from "service/profile/UserProfileService";
import { notifySuccess } from "util/notify";
import useLocale from "hooks/useLocale";
import avatarFallback from "assets/img/avatar-fallback.png";

const CreateCustomer = ({
  handleCustomerCreateClose,
  setCustomers = () => { },
}) => {
  const [customerData, setCustomerData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    phoneNumberPrimary: "",
    dob: "",
    notes: "",
  });
  const { t } = useTranslation();
  const [selectedGender, setSelectedGender] = useState(null);
  const [avatar, setAvatar] = useState([]);
  const [errors, setErrors] = useState({});
  const { register } = useForm();
  const timezone = DateTime.local().zoneName;
  const [genders, setGenders] = useState([]);
  const [blocking, setBlocking] = useState(false);

  const locale = useLocale();

  useEffect(() => {
    initGenders();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const customerGender = genders.find(
      (gender) => gender.value === customerData.genderId
    );
    setSelectedGender(customerGender);
    // eslint-disable-next-line
  }, [customerData.id]);

  const initGenders = () => {
    const genders = [
      { label: t("male"), value: 1 },
      { label: t("female"), value: 2 },
    ];
    setGenders(genders);
    setSelectedGender(genders[2]);
  };

  const setField = (field, value) => {
    setCustomerData({
      ...customerData,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const handleGenderSelectionChange = (selectedOption) => {
    setSelectedGender(selectedOption);
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors["selectedGender"])
      setErrors({
        ...errors,
        selectedGender: null,
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      const payload = { ...customerData };
      if (avatar.length > 0) {
        const fileData = avatar[0];
        try {
          // Upload the avatar first
          const avatarResponse = await sendFileToBackend(
            fileData.base64,
            fileData.type
          );
          payload.avatarFilename = avatarResponse.data.filename;
          payload.avatarUrl = avatarResponse.data.url;
        } catch (error) { }
      }

      try {
        setBlocking(true);

        payload.genderId = selectedGender.value;
        payload.dob = toZonedTime(customerData.dob, timezone);
        const response = await createCustomer(payload);
        const customer = response.data;
        setCustomers((oldCustomers) => [...oldCustomers, customer]);
        notifySuccess(t("customer_created_successfully"));
        handleCustomerCreateClose();
      } catch (error) {
        console.error(error);
      } finally {
        setBlocking(false);
      }
    }
  };

  const handleFileChange = (uploadedFiles) => {
    setAvatar(uploadedFiles);
  };

  const sendFileToBackend = async (base64EncodedImage, mimeType) => {
    try {
      const response = await uploadAvatar(base64EncodedImage, mimeType);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const findFormErrors = () => {
    const { firstName, lastName, phoneNumberPrimary, phoneNumberSecondary } =
      customerData;

    const newErrors = {};

    if (!firstName || firstName === "")
      newErrors.firstName = t("field_required");

    if (!lastName || lastName === "")
      newErrors.lastName = t("field_required");

    if (!selectedGender || !selectedGender.value)
      newErrors.selectedGender = t("field_required");

    if (!phoneNumberPrimary || phoneNumberPrimary === "")
      newErrors.phoneNumberPrimary = t("field_required");
    else if (phoneNumberPrimary.length > 12)
      newErrors.phoneNumberPrimary = t("too_long");
    else {
      try {
        const phoneNumberObject = parsePhoneNumber(
          phoneNumberPrimary,
          metadata
        );
        if (!phoneNumberObject.isValid())
          newErrors.phoneNumberPrimary = t("invalid_phone_number");
      } catch (error) {
        newErrors.phoneNumberPrimary = t("invalid_phone_number");
      }
    }

    if (phoneNumberSecondary && phoneNumberSecondary !== "") {
      if (phoneNumberSecondary.length > 12)
        newErrors.phoneNumberSecondary = t("too_long");
      else {
        try {
          const phoneNumberSecondaryObject = parsePhoneNumber(
            phoneNumberSecondary,
            metadata
          );
          if (!phoneNumberSecondaryObject.isValid())
            newErrors.phoneNumberSecondary = t("invalid_phone_number");
        } catch (error) {
          newErrors.phoneNumberSecondary = t("invalid_phone_number");
        }
      }
    }
    return newErrors;
  };

  return (
    <div className="container central-content-container">
      <Card className="mb-3">
        <Card.Header>
          <h3 className="mb-0">
            <Trans>basic_information</Trans>
          </h3>
        </Card.Header>
        <Card.Body className="border-top">
          <div className="d-flex align-items-start gap-4 align-self-stretch">
            <FalconDropzone
              files={avatar}
              className="staff-avatar-dropzone"
              onChange={handleFileChange}
              multiple={false}
              accept="image/*"
              preview={true}
              isCircular={true}
              fallbackImage={avatarFallback}
              placeholder={
                <>
                  <Flex justifyContent="center" className="position-relative">
                    {/* <img src={cloudUpload} alt="" width={25} className="me-2" /> */}
                    <UserAvatarIcon className="profile-icon" />
                    <div className="staff-avatar-plus-button">
                      <ZymranIcon name="plus" />
                    </div>
                  </Flex>
                </>
              }
            />
            <div className="d-flex flex-column align-items-stretch gap-3 flex-grow-1">
              <Row className="gx-3">
                <Col xs={12} sm={4}>
                  <EnhancedFormControl
                    id="firstName"
                    name="firstName"
                    type="text"
                    label={t("firstname")}
                    value={customerData.firstName}
                    onChange={(e) => setField("firstName", e.target.value)}
                    isInvalid={!!errors.firstName}
                    placeholder={t("firstname")}
                    errors={errors.firstName}
                    groupClassName="w-100"
                    required
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <EnhancedFormControl
                    id="lastName"
                    name="lastName"
                    type="text"
                    label={t("lastname")}
                    value={customerData.lastName}
                    onChange={(e) => setField("lastName", e.target.value)}
                    isInvalid={!!errors.lastName}
                    placeholder={t("lastname")}
                    errors={errors.lastName}
                    groupClassName="w-100"
                    required
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <EnhancedFormControl
                    id="middleName"
                    name="middleName"
                    type="text"
                    label={t("middlename")}
                    value={customerData.middleName}
                    onChange={(e) => setField("middleName", e.target.value)}
                    isInvalid={!!errors.middleName}
                    placeholder={t("middlename")}
                    errors={errors.middleName}
                    groupClassName="w-100"
                  />
                </Col>
              </Row>
              <Row className="gx-3">
                <Col xs={12} sm={4}>
                  <Form.Group className="w-100">
                    <Form.Label>
                      <Trans>birth_day</Trans>
                    </Form.Label>
                    <Datepicker
                      className="w-100"
                      selected={customerData.dob}
                      defaultValue={customerData.dob}
                      onChange={(date) => setField("dob", date)}
                      dateFormat="dd-MM-yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      scrollableYearDropdown={true}
                      maxDate={new Date()}
                      yearDropdownItemNumber={100}
                      locale={locale}
                      wrapperClassName={
                        !!errors.dob
                          ? "is-invalid customer-datepicker"
                          : "customer-datepicker"
                      }
                      customInput={
                        <CustomDateInput
                          formControlProps={{
                            placeholder: "d/m/y",
                            name: "dob",
                            ...register("dob", {
                              required: true,
                            }),
                          }}
                        />
                      }
                    />
                    {errors.dob && (
                      <p className="text-red-600 text-sm cursor-default">
                        Birthdate is required
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} sm={4}>
                  <EnhancedSelect
                    id="gender"
                    name="gender"
                    placeholder={t("gender")}
                    label={t("gender")}
                    selectedValue={selectedGender}
                    options={genders}
                    onChange={(option) => handleGenderSelectionChange(option)}
                    errors={errors.selectedGender}
                    required
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header>
          <h3 className="mb-0">
            <Trans>contact_details</Trans>
          </h3>
        </Card.Header>
        <Card.Body className="border-top">
          <div className="d-flex flex-column align-items-stretch gap-3 flex-grow-1">
            <Row className="gx-3">
              <Col xs={12} sm={6}>
                <Form.Group className="w-100" controlId="phone-number">
                  <Form.Label
                    className={
                      !!errors.phoneNumberPrimary
                        ? "is-invalid phone-input-label"
                        : "phone-input-label"
                    }
                  >
                    <Trans>phone_number</Trans>:
                  </Form.Label>
                  <PhoneInput
                    id="phoneNumberPrimary"
                    name="phoneNumberPrimary"
                    defaultCountry="KZ"
                    placeholder="+7 777 777 7777*"
                    value={customerData.phoneNumberPrimary}
                    onChange={(value) => setField("phoneNumberPrimary", value)}
                    limitMaxLength={true}
                    maxLength={15}
                    className={!!errors.phoneNumberPrimary ? "is-invalid" : ""}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group className="w-100" controlId="phone-number">
                  <Form.Label
                    className={
                      !!errors.phoneNumberPrimary
                        ? "is-invalid phone-input-label"
                        : "phone-input-label"
                    }
                  >
                    <Trans>additional_phone</Trans>:
                  </Form.Label>
                  <PhoneInput
                    id="phoneNumberSecondary"
                    name="phoneNumberSecondary"
                    defaultCountry="KZ"
                    placeholder="+7 777 777 7777"
                    value={customerData.phoneNumberSecondary}
                    onChange={(value) =>
                      setField("phoneNumberSecondary", value)
                    }
                    limitMaxLength={true}
                    maxLength={15}
                    className={
                      !!errors.phoneNumberSecondary ? "is-invalid" : ""
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="gx-3">
              <Col xs={12} sm={6}>
                <EnhancedFormControl
                  id="email"
                  name="email"
                  type="email"
                  label={t("email")}
                  value={customerData.email}
                  onChange={(e) => setField("email", e.target.value)}
                  isInvalid={!!errors.email}
                  placeholder={t("email")}
                  errors={errors.email}
                  groupClassName="w-100"
                />
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header>
          <h3 className="mb-0">
            <Trans>important_information</Trans>
          </h3>
        </Card.Header>
        <Card.Body className="border-top">
          <Row className="gx-3">
            <Col sm={6}>
              <EnhancedFormControl
                id="notes"
                name="notes"
                type="text"
                label={t("type_information_about_customer")}
                value={customerData.notes}
                onChange={(e) => setField("notes", e.target.value)}
                isInvalid={!!errors.notes}
                placeholder={t("type_information_about_customer")}
                errors={errors.notes}
                groupClassName="w-100"
                as="textarea"
                rows={3}
              />
            </Col>
            <Col sm={6} className="d-flex align-items-center">
              <div className="p2">
                <Trans>customer_important_info_description</Trans>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-end align-items-start gap-3">
        <Button
          variant="grey"
          onClick={handleCustomerCreateClose}
          name="cancelButton"
        >
          <Trans>cancel</Trans>
        </Button>
        <Button
          type="submit"
          variant="blue"
          onClick={handleSubmit}
          name="saveCustomerButton"
        >
          <Trans>save_customer</Trans>
        </Button>
      </div>
    </div>
  );
};

export default CreateCustomer;
