import { useTranslation } from "react-i18next";
import { enUS, ru as russianLocale, kk as kazakhLocale } from "date-fns/locale";

const useLocale = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const locales = { en: enUS, ru: russianLocale, kz: kazakhLocale };
  const locale = locales[currentLanguage] || russianLocale;

  return locale;
};

export default useLocale;
