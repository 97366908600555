import React, { useState, useEffect } from "react";
import { Trans } from "react-i18next";
import { deleteStaff, getStaffList } from "../../service/staff/StaffService";
import { useTranslation } from "react-i18next";
import { getLocations } from "../../service/location/LocationService";
import { notifySuccess } from "../../util/notify";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import AdvanceTableWrapper from "component/common/advance-table/AdvanceTableWrapper";
import AdvanceTablePagination from "component/common/advance-table/AdvanceTablePagination";
import AdvanceTable from "component/common/advance-table/AdvanceTable";
import Flex from "component/common/Flex";
import Fuse from "fuse.js";
import Lottie from "lottie-react";
import loading from "assets/img/animated-icons/loading.json";
import EditStaff from "./EditStaff";
import CreateStaff from "./CreateStaff";
import ZymranIcon from "component/common/ZymranIcon";
import CardDropdown from "component/common/CardDropdown";
import ViewStaff from "./ViewStaff";
import StaffSearchBox from "./StaffSearchBox";
import { ReactComponent as AddUserIcon } from "assets/img/icons/add-user.svg";
import ZymranAvatar from "component/common/ZymranAvatar";

const ListStaff = () => {
  const [staffList, setStaffList] = useState([]);
  const [originalStaffDataList, setOriginalStaffDataList] = useState([]);
  const [staffDataList, setStaffDataList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({
    value: "",
    label: "",
  });
  const [staffLoading, setStaffLoading] = useState(false);

  const { t } = useTranslation();

  const [searchInputValue, setSearchInputValue] = useState("");
  const [showViewStaffModal, setShowViewStaffModal] = useState(false);
  const [showEditStaffModal, setShowEditStaffModal] = useState(false);
  const [showCreateStaffModal, setShowCreateStaffModal] = useState(false);
  const [showDeleteStaffModal, setShowDeleteStaffModal] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState(0);
  const [staffForDeletion, setStaffForDeletion] = useState({});

  const columns = [
    {
      accessor: "name",
      Header: t("name"),
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { firstName, lastName } = rowData.row.original;
        const { avatarUrl } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <ZymranAvatar
              avatarUrl={avatarUrl}
              fullName={`${firstName} ${lastName}`}
              size="sm"
              shape="circle"
              className={`me-2`}
            />
            <div className="flex-1">
              <span className="mb-0 p2">
                {firstName} {lastName}
              </span>
            </div>
          </Flex>
        );
      },
    },
    {
      accessor: "position",
      Header: t("position"),
    },
    {
      accessor: "email",
      Header: t("email"),
      Cell: (rowData) => {
        const { email } = rowData.row.original;
        return <a href={`mailto:` + email}>{email}</a>;
      },
    },
    {
      accessor: "phone",
      Header: t("phone_number"),
      Cell: (rowData) => {
        const { phoneNumber } = rowData.row.original;
        return <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>;
      },
    },
    {
      accessor: "location",
      Header: () => (
        <div className="d-flex gap-1 align-items-center">
          <span>{t("location")}</span>
          <Dropdown>
            <Dropdown.Toggle
              id="locationFilterDropdown"
              className="table-filter-button"
            >
              <ZymranIcon name="filter" className="secondary-text-color" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {locations.map((loc, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => onChangeLocation(loc)}
                >
                  {loc.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      headerProps: { style: { minWidth: "200px" }, className: "ps-5" },
      cellProps: { className: "ps-5" },
      disableSortBy: true,
      Cell: (rowData) => {
        const { staffLocations } = rowData.row.original;
        const matchingLocation = staffLocations.find(
          (loc) => loc.location.id === selectedLocation.value
        );

        if (matchingLocation) {
          return <div>{matchingLocation.location.name}</div>;
        } else {
          return (
            <>
              {staffLocations.map((loc, index) => (
                <div key={index}>{loc.location.name}</div>
              ))}
            </>
          );
        }
      },
    },
    {
      accessor: "none",
      Header: "",
      disableSortBy: true,
      cellProps: {
        className: "text-end",
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <CardDropdown btnRevealClass="btn-reveal-sm" drop="start" dropdownMenuClass="staff-actions-dropdown-wrapper">
            <div className="staff-actions-dropdown p2">
              <Dropdown.Item
                href=""
                onClick={() => handleShowViewStaffModal(id)}
              >
                <ZymranIcon name="info" />
                <Trans>more_info</Trans>
              </Dropdown.Item>
              <Dropdown.Item
                href=""
                onClick={() => handleShowEditStaffModal(id)}
              >
                <ZymranIcon name="pen" />
                <Trans>edit</Trans>
              </Dropdown.Item>
              <Dropdown.Item
                href=""
                className="text-danger"
                onClick={() => handleShowDeleteStaffModal(rowData.row.original)}
              >
                <ZymranIcon name="delete" />
                <Trans>delete</Trans>
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      },
    },
  ];

  useEffect(() => {
    loadLocations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // preselectLocation();
    if (locations.length > 0) {
      loadStaffList();
    }
  }, [locations.length]);

  useEffect(() => {
    if (staffList.length > 0) {
      prepareStaffTable();
    }
    // eslint-disable-next-line
  }, [staffList, selectedLocation]);

  const fuseJsOptions = {
    includeScore: true,
    keys: ["firstName", "lastName", "middleName", "email", "phoneNumber"],
    threshold: 0.4,
  };

  let searchResult = new Fuse(staffDataList, fuseJsOptions)
    .search(searchInputValue)
    .map((item) => item.item);

  useEffect(() => {
    if (searchInputValue) {
      setStaffDataList(searchResult);
    } else {
      setStaffDataList(originalStaffDataList);
    }

    // eslint-disable-next-line
  }, [searchInputValue]);

  const handleShowViewStaffModal = (staffId) => {
    setSelectedStaffId(staffId);
    setShowViewStaffModal(true);
  };

  const handleShowEditStaffModal = (staffId) => {
    setSelectedStaffId(staffId);
    setShowEditStaffModal(true);
  };

  const handleEditStaffModalClose = () => {
    setShowEditStaffModal(false);
  };

  const handleCreateStaffModalClose = () => {
    setShowCreateStaffModal(false);
  };

  const handleUpdate = (updatedStaffMember) => {
    setStaffList((previousState) =>
      previousState.map((item) =>
        item.id === updatedStaffMember.id ? updatedStaffMember : item
      )
    );
    setOriginalStaffDataList((previousState) =>
      previousState.map((item) =>
        item.id === updatedStaffMember.id ? updatedStaffMember : item
      )
    );
  };

  const handleCreate = (newStaffMember) => {
    setOriginalStaffDataList((prevData) => [...prevData, newStaffMember]);
    setStaffList((prevData) => [...prevData, newStaffMember]);
  };

  const prepareStaffTable = () => {
    const loc = selectedLocation;

    let dataList = [];
    if (loc.value === 0 || loc.value === "") {
      dataList = staffList;
    } else {
      dataList = staffList.filter((staff) =>
        includesLocation(loc.value, staff.staffLocations)
      );
    }

    setStaffDataList(dataList);
    setOriginalStaffDataList(dataList);
  };

  const loadLocations = async () => {
    try {
      const response = await getLocations();
      const locs = [];
      // locs.push({ value: 0, label: t('selectAll') })
      response.data.forEach((loc) =>
        locs.push({ value: loc.id, label: loc.name })
      );
      locs.sort((loc1, loc2) => {
        if (loc1.label === t("select_all")) {
          return -1;
        }
        if (loc2.label === t("select_all")) {
          return 1;
        }
        return loc1.label.localeCompare(loc2.label);
      });
      setLocations(locs);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const loadStaffList = async () => {
    try {
      setStaffLoading(true);
      const response = await getStaffList();
      setOriginalStaffDataList(response.data);
      setStaffList(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setStaffLoading(false);
    }
  };

  const onChangeLocation = (value) => {
    setSelectedLocation(value);
    setSearchInputValue("");
    setStaffList(staffList);
  };

  const includesLocation = (locId, staffLocs) => {
    for (let i = 0; i < staffLocs.length; i++) {
      if (staffLocs[i].location.id === locId) {
        return true;
      }
    }
    return false;
  };

  const handleShowDeleteStaffModal = (staff) => {
    setStaffForDeletion(staff);
    setShowDeleteStaffModal(true);
  };

  const handleDelete = async () => {
    try {
      await deleteStaff(staffForDeletion.id);
      setStaffList((previousState) =>
        previousState.filter((item) => item.id !== staffForDeletion.id)
      );
      setOriginalStaffDataList((previousState) =>
        previousState.filter((item) => item.id !== staffForDeletion.id)
      );
      notifySuccess(t("employee_deleted"));
    } catch (error) {
      console.error(error);
    } finally {
      setShowDeleteStaffModal(false);
    }
  };

  return (
    <>
      {staffLoading ? (
        <Card className="mb-3 d-flex justify-content-center align-items-center h-100 p-5">
          <Card.Body>
            <Lottie
              animationData={loading}
              loop={true}
              style={{ height: "220px", width: "220px" }}
            />
          </Card.Body>
        </Card>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center align-selft-stretch mb-3">
            <h1 className="mb-0">
              <Trans>staff_list</Trans>
            </h1>
            <div className="d-flex align-items-center gap-3">
              <StaffSearchBox
                autoCompleteItem={staffList}
                handleShowViewStaffModal={handleShowViewStaffModal}
              />
              <Button
                name="addEmployeeButton"
                variant="blue"
                size="sm"
                onClick={() => setShowCreateStaffModal(true)}
              >
                <ZymranIcon name="plus" />
                <span className="d-none d-md-block">
                  <Trans>add_employee</Trans>
                </span>
              </Button>
            </div>
          </div>
          <AdvanceTableWrapper
            columns={columns}
            data={staffDataList}
            sortable
            pagination
            perPage={10}
          >
            <Card className="mb-3">
              <Card.Body className="p-0 staff-list-table-wrapper">
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle secondary-text-color"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: "sm",
                    striped: false,
                    className: "mb-0 staff-list-table overflow-hidden",
                  }}
                  customEmptyComponent={
                    <div className="d-flex flex-column gap-5 p-4 align-items-center justify-content-center">
                      <div className="d-flex flex-column gap-4 align-items-center justify-content-center secondary-text-color">
                        <AddUserIcon className="no-employees-icon" />
                        <Trans>employees_not_added_yet</Trans>
                      </div>
                      <Button
                        name="addEmployeeInTableButton"
                        variant="blue"
                        size="sm"
                        type="button"
                        onClick={() => setShowCreateStaffModal(true)}
                      >
                        <ZymranIcon name="plus" />
                        <Trans>add_employee</Trans>
                      </Button>
                    </div>
                  }
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </>
      )}

      {/* View Staff Modal */}
      <Modal
        show={showViewStaffModal}
        onHide={() => setShowViewStaffModal(false)}
        size="lg"
        centered
        fullscreen={false}
        className="staff-view-modal"
      >
        <Modal.Header className="staff-view-modal-header plain-modal-header">
          <h3 className="mb-0">
            <Trans>employee_card</Trans>
          </h3>
          <div className="d-flex align-items-center gap-2">
            <Button
              name="editStaffButtonInView"
              variant="blue"
              size="sm"
              onClick={() => handleShowEditStaffModal(selectedStaffId)}
            >
              <ZymranIcon name="pen" />
              <Trans>edit</Trans>
            </Button>
            <Button
              name="closeViewStaffButton"
              className="button-outline-none close-button"
              onClick={() => setShowViewStaffModal(false)}
            >
              <ZymranIcon name="close" />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ViewStaff staffId={selectedStaffId} />
        </Modal.Body>
      </Modal>

      {/* Edit Staff Modal */}
      <Modal
        show={showEditStaffModal}
        onHide={handleEditStaffModalClose}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title>
            <Trans>edit_staff</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditStaff
            staffId={selectedStaffId}
            // setServiceCategories={setServiceCategories}
            onUpdate={handleUpdate}
            handleClose={handleEditStaffModalClose}
          />
        </Modal.Body>
      </Modal>

      {/* Create Staff Modal */}
      <Modal
        show={showCreateStaffModal}
        onHide={handleCreateStaffModalClose}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title>
            <Trans>create</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateStaff
            onCreate={handleCreate}
            handleClose={handleCreateStaffModalClose}
          />
        </Modal.Body>
      </Modal>

      {/* Delete Staff Modal */}
      <Modal
        show={showDeleteStaffModal}
        onHide={() => setShowDeleteStaffModal(false)}
        className="confirmation-modal"
        centered
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center align-self-stretch text-center mb-3">
            <h2>
              <Trans>delete_staff_question</Trans>
            </h2>
            <p className="mb-0 p1 secondary-text-color">
              <Trans>all_employee_data_will_be_deleted</Trans>
            </p>
          </div>
          <div className="delete-info-area">
            <div className="view-info-block">
              <ZymranAvatar
                avatarUrl={staffForDeletion.avatarUrl}
                fullName={`${staffForDeletion.firstName} ${staffForDeletion.lastName}`}
                size="xl"
                shape="circle"
              />
              <div className="view-info-details">
                <div>
                  <p className="p1-bold mb-0 primary-text-color">
                    {staffForDeletion.firstName} {staffForDeletion.lastName}
                  </p>
                  <p className="p2 mb-0 secondary-text-color">
                    {staffForDeletion.position}
                  </p>
                </div>
                <div>
                  <a href={`mailto:` + staffForDeletion.email} className="p2">
                    {staffForDeletion.email}
                  </a>
                  <p className="p2 mb-0 secondary-text-color">
                    {staffForDeletion.phoneNumber}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            name="confirmDeleteButton"
            variant="orange"
            size="sm"
            id="delete-location-confirm"
            className="flex-grow-1"
            onClick={handleDelete}
          >
            <Trans>delete_employee</Trans>
          </Button>
          <Button
            name="closeDeleteWindowButton"
            variant="blue"
            size="sm"
            id="delete-location-close"
            className="flex-grow-1"
            onClick={() => setShowDeleteStaffModal(false)}
          >
            <Trans>cancel</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListStaff;
