import { useEffect, useState } from "react";
import { Card, Dropdown, Modal } from "react-bootstrap";
import { Trans } from "react-i18next";
import { getIncomeServiceByCode } from "service/income/IncomeService";
import { toZonedTime } from "date-fns-tz";
import { DateTime } from "luxon";
import ServiceAppointmentTypeData from "./ServiceAppointmentTypeData";
import ViewAppointment from "component/calendar/ViewAppointment";
import ManualCashTypeData from "./ManualCashTypeData";
import Lottie from "lottie-react";
import loadingAnimation from "assets/img/animated-icons/loading.json";

const IncomeDetail = ({ selectedIncome }) => {
  const [loading, setLoading] = useState(true);
  const [incomeDetail, setIncomeDetail] = useState({});
  const timezone = DateTime.local().zoneName;
  const [showViewAppointmentModal, setShowViewAppointmentModal] =
    useState(false);
  const INCOME_TYPE_SERVICE_APPOINTMENT = "service-appointment";

  const [calendarStartTime, setCalendarStartTime] = useState({});

  useEffect(() => {
    loadIncomeDetail();
    // eslint-disable-next-line
  }, []);

  const loadIncomeDetail = async () => {
    try {
      const response = await getIncomeServiceByCode(selectedIncome.code);
      const incomeDetail = response.data;

      if (incomeDetail.appointments) {
        const updatedAppointments = incomeDetail.appointments.map(
          (appointment) => ({
            ...appointment,
            selectedService: {
              value: appointment.service.id,
              label: appointment.service.name,
            },
            selectedStaffLocation: {
              value: appointment.staffLocation.id,
              label:
                appointment.staffLocation.staff.firstName +
                " " +
                appointment.staffLocation.staff.lastName,
            },
            checkoutInfo: incomeDetail.checkoutInfo,
            price: appointment.service.price,
          })
        );

        const updatedIncomeDetail = {
          ...incomeDetail,
          appointments: updatedAppointments,
        };

        setIncomeDetail(updatedIncomeDetail);
      } else {
        setIncomeDetail(incomeDetail);
      }

      if (response.data.type === INCOME_TYPE_SERVICE_APPOINTMENT) {
        const dateObject = toZonedTime(response.data.date, timezone);
        setCalendarStartTime(dateObject);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container central-content-container">
        <Card className="mb-3">
          <Card.Header className="plain-modal-header">
            <div className="d-flex flex-between-center">
              <h3 className="mb-0 finance-code-color">
                {incomeDetail && incomeDetail.code}
              </h3>
              <h3>
                <Trans>incoming_order</Trans>
              </h3>
              <div></div>
              {/* <Dropdown align="end">
                <Dropdown.Toggle
                  variant="blue"
                  size="sm"
                  id="createDropdown"
                  className="d-md-block d-none"
                >
                  <Trans>action</Trans>
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-2">
                  <Dropdown.Item>
                    <Trans>print</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Trans>download_pdf</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Trans>make_refund</Trans>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
          </Card.Header>
          <Card.Body>
            {loading ? (
              <div className="d-flex justify-content-center">
                <Lottie
                  animationData={loadingAnimation}
                  style={{ width: 100, height: 100 }}
                />
              </div>
            ) : (
              <div className="table-responsive">
                {incomeDetail &&
                  incomeDetail.type === INCOME_TYPE_SERVICE_APPOINTMENT ? (
                  <ServiceAppointmentTypeData
                    incomeDetail={incomeDetail}
                    setShowViewAppointmentModal={setShowViewAppointmentModal}
                  />
                ) : (
                  <ManualCashTypeData incomeDetail={incomeDetail} />
                )}
              </div>
            )}
          </Card.Body>
        </Card>
      </div>

      <Modal
        id="viewAppointmentModal"
        name="viewAppointmentModal"
        show={showViewAppointmentModal}
        onShow={() => setShowViewAppointmentModal(true)}
        onHide={() => setShowViewAppointmentModal(false)}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="appointment-header px-card border-bottom-0"
        >
          {" "}
          <Modal.Title as="h5" className="text-white light">
            <Trans>view_appointment</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="appointment-body">
          <ViewAppointment
            chainId={
              incomeDetail.appointments && incomeDetail.appointments[0].chainId
            }
            calendarStartTime={calendarStartTime}
            timezone={timezone}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IncomeDetail;
