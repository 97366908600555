import ZymranAvatar from "component/common/ZymranAvatar";
import ZymranIcon from "component/common/ZymranIcon";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Trans, useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { getServicesByStaffLocation } from "../../service/service-entity/ServiceEntityService";

const Appointment = ({
  appointmentIndex,
  appointment,
  staffLocations,
  setAppointmentField,
  calendarStartTime,
  calendarEndTime,
  step,
  handleRemoveAppointment,
}) => {
  const [services, setServices] = useState([]);
  const [servicesFromDB, setServicesFromDB] = useState([]);
  const [staffList, setStaffList] = useState([]);

  const [durationTimes, setDurationTimes] = useState([]);
  const { t } = useTranslation();
  const [focusedInput, setFocusedInput] = useState("");

  const initDurationTimes = () => {
    const durationTimes = [
      { label: "15 ".concat(t("minute")), value: 15 },
      { label: "30 ".concat(t("minute")), value: 30 },
      { label: "45 ".concat(t("minute")), value: 45 },
      { label: "1 ".concat(t("hour")), value: 60 },
      {
        label: "1".concat(t("h")).concat(" 15").concat(t("minute")),
        value: 75,
      },
      {
        label: "1".concat(t("h")).concat(" 30").concat(t("minute")),
        value: 90,
      },
      {
        label: "1".concat(t("h")).concat(" 45").concat(t("minute")),
        value: 105,
      },
      { label: "2".concat(t("h")), value: 120 },
      { label: "3".concat(t("h")), value: 180 },
      { label: "4".concat(t("h")), value: 240 },
      { label: "5".concat(t("h")), value: 300 },
      { label: "6".concat(t("h")), value: 360 },
      { label: "7".concat(t("h")), value: 420 },
      { label: "8".concat(t("h")), value: 480 },
    ];
    setDurationTimes(durationTimes);
  };

  useEffect(() => {
    initDurationTimes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const staffLocation = staffLocations.find((sl) => {
      return sl.id === appointment.resourceId;
    });
    if (staffLocation && staffLocation.staff) {
      const selectedStaffLocation = {
        value: staffLocation.id,
        label:
          staffLocation.staff.firstName + " " + staffLocation.staff.lastName,
      };
      setAppointmentField(
        appointment.id,
        "selectedStaffLocation",
        selectedStaffLocation
      );
    }
    let staffList = [];
    staffLocations.forEach((sl) => {
      const staff = {
        value: sl.id,
        label: sl.staff.firstName + " " + sl.staff.lastName,
        avatarUrl: sl.staff.avatarUrl,
      };
      staffList.push(staff);
    });
    setStaffList(staffList);
    // eslint-disable-next-line
  }, []);

  // TODO: Lift up the loadServices method
  useEffect(() => {
    if (appointment.selectedStaffLocation.value !== 0) {
      loadServices();
    }
    // eslint-disable-next-line
  }, [appointment.selectedStaffLocation.value]);

  const loadServices = async () => {
    try {
      const response = await getServicesByStaffLocation(
        appointment.selectedStaffLocation.value
      );
      const servicesFromDB = response.data;
      setServicesFromDB(servicesFromDB);
      const services = [];
      servicesFromDB.forEach((service) => {
        services.push({
          value: service.id,
          label: service.name,
        });
      });
      setServices(services);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeStaffLocation = (staffLocation) => {
    setAppointmentField(appointment.id, "selectedStaffLocation", staffLocation);
    setAppointmentField(appointment.id, "selectedService", {}); // Reset the selected service
    setAppointmentField(appointment.id, "duration", 15);
  };

  const handleChangeService = (selectedService) => {
    // Find the service in the original services data
    const service = servicesFromDB.find(
      (service) => service.id === selectedService.value
    );

    // Find the selected staff location ID
    const selectedStaffLocationId = appointment.selectedStaffLocation.value;

    // Find the staffLocationService with the selected staff location ID
    const staffLocationService = service.staffLocationServices.find(
      (sls) => sls.staffLocation.id === selectedStaffLocationId
    );

    // Get the appropriate duration based on the servicePrice and locationPrices
    let duration;
    if (staffLocationService.servicePrice) {
      duration = staffLocationService.servicePrice.duration;
    } else {
      const location = staffLocationService.staffLocation.location;
      const locationPrice = service.locationPrices.find(
        (lp) => lp.location.id === location.id
      );
      if (locationPrice) {
        duration = locationPrice.duration;
      } else {
        duration = 15; // Default duration value if no matching locationPrice is found
      }
    }

    setAppointmentField(appointment.id, "selectedService", selectedService);
    setAppointmentField(appointment.id, "duration", duration);
  };

  const handleSelectFocus = (name) => {
    setFocusedInput(name);
  };

  const handleSelectBlur = () => {
    setFocusedInput("");
  };

  const Option = ({ isFocused, isSelected, ...props }) => {
    const { data } = props;
    return (
      <div
        className={`staff-select-option ${isFocused ? "focused" : ""} ${
          isSelected ? "selected" : ""
        }`}
      >
        <ZymranAvatar
          avatarUrl={data.avatarUrl}
          fullName={`${data.firstName} ${data.lastName}`}
          size="sm"
          shape="circle"
        />
        <components.Option {...props} />
      </div>
    );
  };

  return (
    <>
      <div className="appointment-order-number">
        <h3 className="secondary-text-color mb-0">{appointmentIndex + 1}</h3>
      </div>
      <div className="appointment-form-wrapper">
        <Form className="appointment-form">
          <Row className="gx-3 align-self-stretch">
            <Col md="4">
              <Form.Group className="w-100">
                <Form.Label className="p3">
                  <Trans>start_time</Trans>:
                </Form.Label>
                <DatePicker
                  selected={appointment.startTime}
                  onChange={(date) =>
                    setAppointmentField(appointment.id, "startTime", date)
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={calendarStartTime}
                  maxTime={calendarEndTime}
                  timeIntervals={step}
                  timeCaption="Time"
                  dateFormat="h:mm"
                  timeFormat="HH:mm"
                  className="form-control"
                  wrapperClassName="w-100"
                  popperClassName="w-auto"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <Form.Label
                  className={`${
                    focusedInput === "service" ? "is-focused" : ""
                  }`}
                >
                  <Trans>service</Trans>:
                </Form.Label>
                <Select
                  id="service"
                  name="service"
                  placeholder={t("select_service")}
                  className="zymran-select"
                  classNamePrefix="react-select"
                  value={appointment.selectedService}
                  options={services}
                  onChange={(option) => handleChangeService(option)}
                  onFocus={() => handleSelectFocus("service")}
                  onBlur={handleSelectBlur}
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <Form.Label
                  className={`${
                    focusedInput === "duration" ? "is-focused" : ""
                  }`}
                >
                  <Trans>duration</Trans>:
                </Form.Label>
                <Select
                  id="duration"
                  name="duration"
                  classNamePrefix="react-select"
                  placeholder={t("duration")}
                  maxMenuHeight={200}
                  value={durationTimes.find(
                    (option) => option.value === appointment.duration
                  )}
                  options={durationTimes}
                  onChange={(e) =>
                    setAppointmentField(appointment.id, "duration", e.value)
                  }
                  onFocus={() => handleSelectFocus("duration")}
                  onBlur={handleSelectBlur}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3 align-self-stretch">
            <Col md="8">
              <Form.Group>
                <Form.Label
                  className={`${
                    focusedInput === "staffLocation" ? "is-focused" : ""
                  }`}
                >
                  <Trans>staff_member</Trans>:
                </Form.Label>
                <Select
                  id="staffLocation"
                  name="staffLocation"
                  placeholder={t("select_staff")}
                  maxMenuHeight={180}
                  classNamePrefix="react-select"
                  value={appointment.selectedStaffLocation}
                  options={staffList}
                  components={{ Option }}
                  onChange={(option) => handleChangeStaffLocation(option)}
                  onFocus={() => handleSelectFocus("staffLocation")}
                  onBlur={handleSelectBlur}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3 align-self-stretch">
            <Col md="8">
              <Form.Group className="mb-3">
                <Form.Label>
                  <Trans>comment</Trans>
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  name="comment"
                  value={appointment.comment}
                  onChange={(e) =>
                    setAppointmentField(
                      appointment.id,
                      "comment",
                      e.target.value
                    )
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <Button
          variant="orange-light"
          size="sm"
          name="removeFromAppointment"
          onClick={() => handleRemoveAppointment(appointment)}
        >
          <ZymranIcon name="delete" /> <Trans>remove_from_appointment</Trans>
        </Button>
      </div>
    </>
  );
};

Appointment.propTypes = {
  appointment: PropTypes.object.isRequired,
  staffLocations: PropTypes.array.isRequired,
  setAppointmentField: PropTypes.func.isRequired,
  calendarStartTime: PropTypes.instanceOf(Date).isRequired,
  calendarEndTime: PropTypes.instanceOf(Date).isRequired,
  step: PropTypes.number.isRequired,
};

export default Appointment;
