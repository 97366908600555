import ZymranAvatar from "component/common/ZymranAvatar";
import { Table } from "react-bootstrap";
import { Trans } from "react-i18next";
import { format, toZonedTime } from "date-fns-tz";
import useLocale from "hooks/useLocale";
import { DateTime } from "luxon";

const ExpenseDetailEncashment = ({ expenseDetail }) => {
  const timezone = DateTime.local().zoneName;
  const locale = useLocale();

  return (
    <>
      <div className="d-flex justify-content-between align-items-start align-self-stretch mb-3">
        <Table borderless size="sm" className="p1 primary-text-color w-auto">
          <tbody>
            <tr>
              <th className="p1-bold secondary-text-color">
                <Trans>document_number</Trans>
              </th>
              <td>{expenseDetail.code}</td>
            </tr>
            <tr>
              <th className="p1-bold secondary-text-color">
                <Trans>date</Trans>
              </th>
              <td>
                {format(
                  toZonedTime(expenseDetail.date, timezone),
                  "dd MMM yyyy HH:mm",
                  { locale }
                )}
              </td>
            </tr>
            <tr>
              <th className="p1-bold secondary-text-color">
                <Trans>location</Trans>
              </th>
              <td>{expenseDetail.location && expenseDetail.location.name}</td>
            </tr>
            <tr>
              <th className="p1-bold secondary-text-color">
                <Trans>expense_reason</Trans>
              </th>
              <td>
                <Trans>{expenseDetail.reason}</Trans>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="finances-issued-by-block">
          <p className="p1-bold mb-0">
            <Trans>encashed_by</Trans>
          </p>
          <div className="d-flex flex-row align-items-center">
            <div className="d-flex">
              <ZymranAvatar
                avatarUrl={expenseDetail.providedByUser.avatarUrl}
                size="lg"
                shape="circle"
                className={"me-2"}
              />
            </div>
            {expenseDetail.providedByUser && (
              <div className="d-flex flex-column align-items-start text-start">
                <p className="p2-bold mb-0 text-blue mb-0 ">
                  {expenseDetail.providedByUser.firstName}{" "}
                  {expenseDetail.providedByUser.lastName}
                </p>
                <p className="p3 mb-0 secondary-text-color">
                  <Trans>{expenseDetail.providedByUser.type}</Trans>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="table-rounded-wrapper mb-3">
        <Table className="cash-register-table mb-0">
          <thead className="p2-bold secondary-text-color">
            <tr>
              <th>№</th>
              <th>
                <Trans>cash_register</Trans>
              </th>
              <th>
                <Trans>encashed</Trans>, ₸
              </th>
              <th>
                <Trans>remainder</Trans>, ₸
              </th>
            </tr>
          </thead>
          <tbody className="p2 primary-text-color">
            {expenseDetail.encashmentData &&
              expenseDetail.encashmentData.map((encashment, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Trans>{`cash_register_type_${encashment.cashRegisterName}`}</Trans>
                  </td>
                  <td>{encashment.amount}</td>
                  <td>{encashment.remainder}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="d-flex align-items-start gap-3 align-self-stretch">
        <span className="p1 secondary-text-color">
          <Trans>remainder_at_start_of_day</Trans>
        </span>
        <span className="p1 primary-text-color">
          {expenseDetail.remainderTotal}
        </span>
      </div>
    </>
  );
};

export default ExpenseDetailEncashment;

