import { Fragment } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Button } from "react-bootstrap";
import Lottie from "lottie-react";
import notFoundAnimation from "assets/img/animated-icons/not-found.json";
import ZymranIcon from "component/common/ZymranIcon";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import EnhancedSelect from "component/common/EnhancedSelect";
import ZymranAvatar from "component/common/ZymranAvatar";
import { isNumericInput } from "util/validation";

const ServiceLocationStaffSection = ({
  selectedStaffLocationsForThisSection,
  setSelectedStaffLocationsForThisSection,
  setTotalSelectedStaffLocations,
  locationStaffPriceData,
  setLocationStaffPriceData,
  selectedLocationOption,
  durationOptions,
  priceTypeOptions,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();

  const setStaffField = (staffId, field, value) => {
    let staffPriceData = locationStaffPriceData.get(
      selectedLocationOption.value
    );
    let priceData = staffPriceData.get(staffId);
    
    if (field === "price" && !isNumericInput(value)) {
      return;
    }
    
    priceData[field] = value;
    priceData["toSave"] = true;
    staffPriceData.set(staffId, priceData);
    setLocationStaffPriceData(
      new Map(
        locationStaffPriceData.set(selectedLocationOption.value, staffPriceData)
      )
    );

    if (
      errors.locationStaffPrice &&
      errors.locationStaffPrice[selectedLocationOption.value] &&
      errors.locationStaffPrice[selectedLocationOption.value][staffId] &&
      errors.locationStaffPrice[selectedLocationOption.value][staffId][field]
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        locationStaffPrice: {
          ...prevErrors.locationStaffPrice,
          [selectedLocationOption.value]: {
            ...prevErrors.locationStaffPrice[selectedLocationOption.value],
            [staffId]: {
              ...prevErrors.locationStaffPrice[selectedLocationOption.value][
                staffId
              ],
              [field]: null,
            },
          },
        },
      }));
    }
  };

  const handleStaffPriceTypeChange = (slId, selectedOption) => {
    setStaffField(slId, "priceType", selectedOption);
    if (selectedOption.value === "free") {
      setStaffField(slId, "price", 0);
    }
  };

  const removeSelectedStaffLocation = (id) => {
    setSelectedStaffLocationsForThisSection((prevStaffLocations) =>
      prevStaffLocations.filter((sl) => sl.id !== id)
    );
    setTotalSelectedStaffLocations((prevTotalStaffLocations) =>
      prevTotalStaffLocations.filter((sl) => sl.id !== id)
    );
  };

  return (
    <>
      <div className="d-flex flex-column gap-3">
        {selectedStaffLocationsForThisSection &&
          selectedStaffLocationsForThisSection.length === 0 && (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Lottie
                animationData={notFoundAnimation}
                loop={true}
                style={{ height: "150px", width: "150px" }}
              />
              <span className="text-info">
                <Trans>no_staff_assigned_to_service</Trans>
              </span>
            </div>
          )}
        {selectedStaffLocationsForThisSection &&
          selectedStaffLocationsForThisSection.length > 0 &&
          locationStaffPriceData.size > 0 &&
          Array.from(locationStaffPriceData.values()).map(
            (locationData, index) => {
              return (
                <Fragment key={index}>
                  {selectedStaffLocationsForThisSection.map((sl, index) => {
                    const staffData = locationData.get(sl.id);
                    return (
                      <Fragment key={index}>
                        {staffData && (
                          <div
                            className="row service-staff-list p2 py-2"
                            key={sl.id}
                          >
                            <div className="col-3">
                              <div className="d-flex flex-row align-items-center">
                                <div className="d-flex">
                                  <ZymranAvatar
                                    avatarUrl={sl.staff.avatarUrl}
                                    fullName={`${sl.staff.firstName} ${sl.staff.lastName}`}
                                    size="md"
                                    shape="circle"
                                    className={"me-3"}
                                  />
                                </div>
                                <div className="d-flex flex-column align-items-start p2">
                                  <span className="primary-text-color">
                                    {sl.staff.firstName +
                                      " " +
                                      sl.staff.lastName}
                                  </span>
                                  {sl.staff.position && (
                                    <span className="secondary-text-color">
                                      {sl.staff.position}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="row h-100 align-items-start">
                                <div className="col-4">
                                  <EnhancedSelect
                                    id={`duration-${index}`}
                                    name={`duration-${index}`}
                                    label={t("duration")}
                                    placeholder={t("select_duration")}
                                    options={durationOptions}
                                    onChange={(option) =>
                                      setStaffField(sl.id, "duration", option)
                                    }
                                    selectedValue={staffData.duration}
                                    isInvalid={
                                      errors.locationStaffPrice[
                                        selectedLocationOption?.value
                                      ]?.[sl.id]?.duration
                                    }
                                    errors={
                                      errors.locationStaffPrice[
                                        selectedLocationOption?.value
                                      ]?.[sl.id]?.duration
                                    }
                                  />
                                </div>
                                <div className="col-4">
                                  <EnhancedSelect
                                    id={`priceType-${index}`}
                                    name={`priceType-${index}`}
                                    label={t("price_type")}
                                    placeholder={t("select_price_type")}
                                    options={priceTypeOptions}
                                    onChange={(option) =>
                                      handleStaffPriceTypeChange(sl.id, option)
                                    }
                                    selectedValue={staffData.priceType}
                                    isInvalid={
                                      errors.locationStaffPrice[
                                        selectedLocationOption?.value
                                      ]?.[sl.id]?.priceType
                                    }
                                    errors={
                                      errors.locationStaffPrice[
                                        selectedLocationOption?.value
                                      ]?.[sl.id]?.priceType
                                    }
                                  />
                                </div>
                                <div className="col-4">
                                  <EnhancedFormControl
                                    id={`price-${index}`}
                                    name={`price-${index}`}
                                    type="text"
                                    value={staffData.price || ""}
                                    onChange={(e) =>
                                      setStaffField(
                                        sl.id,
                                        "price",
                                        e.target.value
                                      )
                                    }
                                    isInvalid={
                                      errors.locationStaffPrice[
                                        selectedLocationOption?.value
                                      ]?.[sl.id]?.price
                                    }
                                    placeholder={t("price")}
                                    label={t("price")}
                                    errors={
                                      errors.locationStaffPrice[
                                        selectedLocationOption?.value
                                      ]?.[sl.id]?.price
                                    }
                                    groupClassName="mb-0"
                                    disabled={
                                      staffData.priceType && staffData.priceType.value === "free"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-1">
                              <div className="d-flex h-100 justify-content-center align-items-center">
                                <Button
                                  type="button"
                                  className="button-outline-none service-staff-remove-button"
                                  onClick={() =>
                                    removeSelectedStaffLocation(sl.id)
                                  }
                                >
                                  <ZymranIcon name="close" size="16" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            }
          )}
      </div>
    </>
  );
};

export default ServiceLocationStaffSection;
