import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import {
  encashCashRegisterStep1,
  encashCashRegisterStep2
} from "service/encashment/EncashmentService";
import { isNumericInput } from "util/validation";
import FinalEncashmentDataBlock from "./FinalEncashmentDataBlock";

const CashRegisterEncashmentProcessing = ({
  selectedCashRegisterData,
  selectedLocation,
  handleClose,
  onSuccessfulEncashment,
}) => {
  const [data, setData] = useState({ inputSum: "", isChecked: false });
  const [isFinalized, setIsFinalized] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    loadEncashmentDataByCashRegister();
    // eslint-disable-next-line
  }, []);

  const loadEncashmentDataByCashRegister = async () => {
    try {
      const payload = {
        cashRegisterId: selectedCashRegisterData.cashRegisterId,
      };
      const response = await encashCashRegisterStep1(payload);
      const dataWithInputs = {
        ...response.data.balance,
        inputSum: "",
        isChecked: false,
      };
      setData(dataWithInputs);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSumInputChange = (value) => {
    if (isNumericInput(value) || value === "") {
      const updatedData = { ...data };
      updatedData.inputSum = value;
      setData(updatedData);
    }
  };

  const handleCheckboxChange = (isChecked) => {
    const newData = { ...data };
    newData.isChecked = isChecked;
    if (isChecked) {
      newData.inputSum = selectedCashRegisterData.balance;
    }
    setData(newData);
  };

  const findFormErrors = () => {
    const newErrors = {};

    if (!data.inputSum) {
      newErrors.inputSum = t("encashment_sum_required");
    }

    return newErrors;
  };

  const handleNext = () => {
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const atTheStartOfDay = data.balance - data.inputSum || 0;
      const finalData = { ...data, atTheStartOfDay };
      setFinalData(finalData);
      setIsFinalized(true);
    }
  };

  const handleEdit = () => {
    setIsFinalized(false);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSend = async () => {
    console.log(finalData);
    try {
      const payload = {
        cashRegisterId: finalData.cashRegisterId,
        sum: finalData.inputSum,
      };
      const response = await encashCashRegisterStep2(payload);
      onSuccessfulEncashment(response.data);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const atTheStartOfDay = data.balance - data.inputSum || 0;

  return (
    <>
      {!isFinalized ? (
        <div className="checkout-body-layout">
          <div className="checkout-container">
            <Card className="checkout-card mb-3">
              <Card.Header className="checkout-card-header">
                <h3 className="mb-0">
                  <Trans>encashment</Trans>. {selectedLocation.name}
                </h3>
              </Card.Header>
              <Card.Body className="d-flex flex-column align-self-stretch gap-4">
                <div className="d-flex flex-column align-items-start gap-3">
                  <Row className="w-100 gx-3">
                    <Col xs={4} className="p1-bold primary-text-color">
                      <Trans>cash_register</Trans>
                    </Col>
                    <Col xs={8}>
                      <Trans>{`cash_register_type_${selectedCashRegisterData.cashRegisterName}`}</Trans>
                    </Col>
                  </Row>
                  <Row className="w-100 gx-3">
                    <Col xs={4} className="p1-bold primary-text-color">
                      <Trans>balance</Trans>, ₸
                    </Col>
                    <Col xs={8}>{selectedCashRegisterData.balance}</Col>
                  </Row>
                  <Row className="w-100 gx-3">
                    <Col xs={4} className="p1-bold primary-text-color">
                      <Trans>sum</Trans>, ₸
                    </Col>
                    <Col xs={8}>
                      <div className="mb-3">
                        <Form.Control
                          type="text"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          value={data.inputSum}
                          onChange={(e) => handleSumInputChange(e.target.value)}
                          disabled={data.isChecked}
                        />
                        {errors.inputSum && (
                          <div className="text-danger">{errors.inputSum}</div>
                        )}
                      </div>
                      <Form.Check
                        type="checkbox"
                        id="cashRegisterCheckbox"
                        name="cashRegisterCheckbox"
                        label={t("encash_fully")}
                        checked={data.isChecked}
                        onChange={(e) => handleCheckboxChange(e.target.checked)}
                      />
                    </Col>
                  </Row>
                  <Row className="w-100 gx-3">
                    <Col xs={4} className="p1-bold primary-text-color">
                      <Trans>at_the_start_of_day</Trans>, ₸
                    </Col>
                    <Col xs={8}>{atTheStartOfDay}</Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
            <div className="checkout-buttons-block">
              <Button variant="grey" onClick={handleClose}>
                <Trans>cancel</Trans>
              </Button>
              <Button variant="blue" onClick={handleNext}>
                <Trans>next</Trans>
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="container central-content-container">
          <FinalEncashmentDataBlock
            selectedLocation={selectedLocation}
            // currentUser={currentUser}
            finalData={[finalData]}
            sumAtTheStartOfDay={atTheStartOfDay}
          />
          {isFinalized && (
            <div className="d-flex justify-content-between">
              <Button variant="grey" onClick={handleEdit}>
                <Trans>edit</Trans>
              </Button>
              <div className="d-flex gap-3">
                <Button variant="grey" onClick={handleCancel}>
                  <Trans>cancel</Trans>
                </Button>
                <Button variant="blue" onClick={handleSend}>
                  <Trans>complete_the_encashment</Trans>
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CashRegisterEncashmentProcessing;
