import classNames from "classnames";
import React from "react";
import { Nav } from "react-bootstrap";
import "react-step-progress-bar/styles.css";
import "./registration-progress-bar.css";

const RegistrationProgressBar = ({ currentStep, maxSteps }) => {
  let stepPercentage = 0;

  if (currentStep <= maxSteps) {
    stepPercentage =
      Math.round(((currentStep - 1) / (maxSteps - 1)) * 100 * 10) / 10;
  }

  return (
    <>
      <div className="theme-wizard d-flex justify-content-center registration-progress-bar">
        {[...Array(maxSteps)].map((x, i) => (
          <NavItem key={i} index={i + 1} step={currentStep} label="" />
        ))}
      </div>
    </>
  );
};

const NavItem = ({ index, step, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames("fw-semi-bold", {
          done: index < 4 ? step > index : step > 3,
          active: step === index,
        })}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle h2">{index}</span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

export default RegistrationProgressBar;
