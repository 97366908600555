import React, { useEffect, useState } from "react";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import {
  createCustomerQuick,
  searchCustomer,
} from "service/customer/CustomerService";
import { Trans, useTranslation } from "react-i18next";
import { Button, Dropdown } from "react-bootstrap";
import { isIterableArray } from "helpers/utils";
import ZymranAvatar from "component/common/ZymranAvatar";
import _ from "lodash";

const CustomerSearchContent = ({ item, handleCustomerSelection }) => {
  return (
    <div
      className="customer-pane__dropdown-cell"
      onClick={() => handleCustomerSelection(item)}
    >
      <ZymranAvatar
        avatarUrl={item.avatarUrl}
        fullName={`${item.firstName} ${item.lastName}`}
        size="sm"
        shape="circle"
      />
      <div className="customer-pane__dropdown-customer-info">
        <div className="customer-pane__dropdown-customer-name p2 primary-text-color">
          {item.firstName} {item.lastName}
        </div>
        <div className="customer-pane__dropdown-customer-contacts p2">
          {item.phoneNumberPrimary && <span>{item.phoneNumberPrimary}</span>}
          {item.phoneNumberPrimary && item.email && <span>, </span>}
          {item.email && <span>{item.email}</span>}
        </div>
      </div>
    </div>
  );
};

const CreateCustomerQuick = ({
  onCustomerCreated,
  onClose,
  hanldeCreateWithoutCustomer,
}) => {
  const { t } = useTranslation();
  const [emailOrPhoneNumber, setEmailOrPhoneNumber] = useState("");
  const [customerData, setCustomerData] = useState({
    firstName: "",
    phoneNumber: "",
    email: "",
  });
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\+?\d+$/;
  const [dropdownNameOpen, setDropdownNameOpen] = useState(false);
  const [dropdownPhoneOpen, setDropdownPhoneOpen] = useState(false);
  const [searchNameInputValue, setSearchNameInputValue] = useState("");
  const [searchPhoneInputValue, setSearchPhoneInputValue] = useState("");
  const [resultItem, setResultItem] = useState([]);
  const [errors, setErrors] = useState({});

  const debouncedSearch = _.debounce(
    (searchTerm, pageNumber = 1, pageSize = 5) => {
      searchCustomer({ searchKey: searchTerm, pageNumber, pageSize })
        .then((response) => {
          setResultItem(response.data.customers);
        })
        .catch((error) => console.error(error));
    },
    300
  );

  useEffect(() => {
    if (searchNameInputValue) {
      debouncedSearch(searchNameInputValue);
    } else {
      setResultItem([]);
    }
    // eslint-disable-next-line
  }, [searchNameInputValue]);

  useEffect(() => {
    if (searchPhoneInputValue) {
      debouncedSearch(searchPhoneInputValue);
    } else {
      setResultItem([]);
    }
    // eslint-disable-next-line
  }, [searchPhoneInputValue]);

  useEffect(() => {
    if (searchNameInputValue && isIterableArray(resultItem)) {
      setDropdownNameOpen(true);
    } else {
      setDropdownNameOpen(false);
    }
    // eslint-disable-next-line
  }, [searchNameInputValue, resultItem]);

  useEffect(() => {
    if (searchPhoneInputValue && isIterableArray(resultItem)) {
      setDropdownPhoneOpen(true);
    } else {
      setDropdownPhoneOpen(false);
    }
    // eslint-disable-next-line
  }, [searchPhoneInputValue, resultItem]);

  const setField = (field, value) => {
    setCustomerData({
      ...customerData,
      [field]: value,
    });
  };

  const handleNameInputChange = (event) => {
    setSearchNameInputValue(event.target.value);
    setField("firstName", event.target.value);
  };

  const handlePhoneNumberInputChange = (event) => {
    setSearchPhoneInputValue(event.target.value);
    setEmailOrPhone(event.target.value);
  };

  const setEmailOrPhone = (inputValue) => {
    const phoneNumber = inputValue.replace(/[^\d+]/g, "");
    if (emailRegex.test(inputValue)) {
      setCustomerData((prevData) => ({
        ...prevData,
        email: inputValue,
        phoneNumber: "",
      }));
    } else if (phoneRegex.test(phoneNumber)) {
      setCustomerData((prevData) => ({
        ...prevData,
        phoneNumber: phoneNumber,
        email: "",
      }));
    } else {
      setCustomerData((prevData) => ({
        ...prevData,
        email: "",
        phoneNumber: "",
      }));
    }
    setEmailOrPhoneNumber(inputValue);
  };

  const findFormErrors = () => {
    const newErrors = {};

    if (!customerData.firstName) {
      newErrors.firstName = t("field_required");
    }

    if (!customerData.email && !customerData.phoneNumber) {
      newErrors.emailOrPhoneNumber = t("field_required");
    }
    return newErrors;
  };

  const handleCreateCustomerQuick = async () => {
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        const payload = {
          firstName: customerData.firstName,
        };

        if (customerData.email) {
          payload.email = customerData.email;
        }

        if (customerData.phoneNumber) {
          payload.phoneNumber = customerData.phoneNumber;
        }

        const response = await createCustomerQuick(payload);
        const createdCustomer = response.data;
        onCustomerCreated(createdCustomer);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex flex-column align-items-center gap-2 text-center">
        <h2 className="mb-0">
          <Trans>quick_customer_creation</Trans>
        </h2>
        <p className="mb-0 p1 sexondary-text-color">
          <Trans>we_recommend_not_saving_without_customer</Trans>
        </p>
      </div>
      <div className="d-flex align-items-start gap-3">
        <Dropdown show={dropdownNameOpen} className="search-box">
          <Dropdown.Toggle
            as="div"
            data-toggle="dropdown"
            aria-expanded={dropdownNameOpen}
            bsPrefix="toggle"
          >
            <EnhancedFormControl
              label={t("firstname")}
              placeholder={t("firstname")}
              name="firstName"
              value={customerData.firstName}
              onChange={(event) => handleNameInputChange(event)}
              isInvalid={!!errors.firstName}
              errors={errors.firstName}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="customer-pane__dropdown customer-pane__short-dropdown">
            {isIterableArray(resultItem) && searchNameInputValue !== "" && (
              <>
                {resultItem.map((item) => (
                  <CustomerSearchContent
                    item={item}
                    key={item.id}
                    handleCustomerSelection={onCustomerCreated}
                  />
                ))}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown show={dropdownPhoneOpen} className="search-box">
          <Dropdown.Toggle
            as="div"
            data-toggle="dropdown"
            aria-expanded={dropdownPhoneOpen}
            bsPrefix="toggle"
          >
            <EnhancedFormControl
              label={t("email_or_phone_number")}
              name="phoneNumber"
              value={emailOrPhoneNumber}
              placeholder={t("email_or_phone_number")}
              onChange={(event) => handlePhoneNumberInputChange(event)}
              isInvalid={!!errors.emailOrPhoneNumber}
              errors={errors.emailOrPhoneNumber}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="customer-pane__dropdown customer-pane__short-dropdown">
            {isIterableArray(resultItem) && searchPhoneInputValue !== "" && (
              <>
                {resultItem.map((item) => (
                  <CustomerSearchContent
                    item={item}
                    key={item.id}
                    handleCustomerSelection={onCustomerCreated}
                  />
                ))}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="d-flex justify-content-end align-items-start gap-3 align-self-stretch">
        <Button
          variant="orange"
          size="sm"
          onClick={hanldeCreateWithoutCustomer}
        >
          <Trans>save_without_customer</Trans>
        </Button>
        <Button
          variant="blue"
          name="cancelDeleteButton"
          size="sm"
          className="flex-grow-1"
          onClick={handleCreateCustomerQuick}
        >
          <Trans>add_customer_to_appointment</Trans>
        </Button>
      </div>
      <Button
        variant="grey"
        size="sm"
        className="flex-grow-1"
        onClick={onClose}
      >
        <Trans>close</Trans>
      </Button>
    </div>
  );
};

export default CreateCustomerQuick;
