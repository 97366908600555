import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { getLocations } from "service/location/LocationService";
import { getCustomers, searchCustomer } from "service/customer/CustomerService";
import { createIncomeRecord } from "service/income/IncomeService";
import { getCashRegistersAll } from "service/cash-register/CashRegisterService";
import { getStaffListByLocationId } from "service/staff/StaffService";
import { notifySuccess } from "util/notify";
import { fromZonedTime } from "date-fns-tz";
import _ from "lodash";
import { isNumericInput } from "util/validation";
import BlockUI from "../../../util/block-ui/block-ui";

const CreateIncomeRecord = ({ handleClose, setIncomes }) => {
  const [blocking, setBlocking] = useState(false);
  const { t } = useTranslation();
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocationOption, setSelectedLocationOption] = useState({
    value: "",
    label: "",
  });
  // const [orderTypes, setOrderTypes] = useState([]);
  // const [selectedOrderType, setSelectedOrderType] = useState({
  //   value: "",
  //   label: "",
  // });
  const [selectedGroup, setSelectedGroup] = useState(null);
  const groupOptions = [
    { value: "customer", label: t("customer") },
    { value: "staff", label: t("staff") },
    { value: "manager", label: t("manager") },
  ];
  const [personOptions, setPersonOptions] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null);
  // const [customers, setCustomers] = useState([]);
  // const [staffList, setStaffList] = useState([]);
  const [cashRegisters, setCashRegisters] = useState([]);
  const [selectedCashRegisterType, setSelectedCashRegisterType] =
    useState(null);
  const [cashRegisterTypes, setCashRegisterTypes] = useState([]);
  const [errors, setErrors] = useState({});
  const [incomeData, setIncomeData] = useState({
    incomeSum: 0,
    incomeReason: "",
  });

  useEffect(() => {
    // initOrderTypes();
    loadCashRegisters();
    loadLocations();
  }, []);

  useEffect(() => {
    if (selectedLocationOption && selectedLocationOption.value) {
      if (selectedGroup && selectedGroup.value === "staff") {
        loadStaffList();
      }
    }
    // eslint-disable-next-line
  }, [selectedLocationOption]);

  useEffect(() => {
    if (selectedLocationOption && cashRegisters.length > 0) {
      console.log("cashRegisters: ", cashRegisters);
      const filteredCashRegisterTypes = cashRegisters.filter(
        (item) => item.location.id === selectedLocationOption.value
      );

      const cashRegisterTypeOptions = filteredCashRegisterTypes.map((item) => ({
        value: item.id,
        label: t("cash_register_type_" + item.cashRegisterType.name),
      }));

      setCashRegisterTypes(cashRegisterTypeOptions);
    }
    // eslint-disable-next-line
  }, [selectedLocationOption, cashRegisters]);

  //  const initOrderTypes = () => {
  //    const orderTypes = [
  //      { label: t("select_all"), value: 0 },
  //      { label: "service-appointment", value: 1 },
  //      { label: "manual-cash", value: 2 },
  //    ];
  //    setOrderTypes(orderTypes);
  //    setSelectedOrderType(orderTypes[0]);
  //  };

  const setField = (field, value) => {
    setIncomeData({
      ...incomeData,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const loadLocations = async () => {
    try {
      const response = await getLocations();
      let locs = [];
      response.data.forEach((loc) => {
        const location = {
          value: loc.id,
          label:
            loc.name + " " + loc.address.street + " " + loc.address.houseNumber,
        };
        locs.push(location);
      });
      setLocationOptions(locs);
      setSelectedLocationOption(locs[0]);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleGroupChange = (option) => {
    setSelectedGroup(option);
    switch (option.value) {
      case "customer":
        // TODO: need to handle processing of the response by chunks
        // loadCustomers();
        break;
      case "staff":
        loadStaffList();
        break;
      case "manager":
        //TODO load managers
        break;
      default:
        break;
    }
    setSelectedPerson({ value: "", label: "" });
  };

  const loadCustomers = async () => {
    try {
      setBlocking(true);
      const response = await getCustomers();
      const people = [];

      response.data.customers.forEach(p => {
        const person = {
          value: p.id,
          label: p.firstName + " " + p.lastName,
        };
        people.push(person);
      });
      setPersonOptions(people);
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const debouncedFetchCustomers = _.debounce((inputValue, callback) => {
    searchCustomer({ searchKey: inputValue, pageNumber: 1, pageSize: 5 })
      .then((response) => {
        const options = response.data.customers.map((customer) => ({
          value: customer.id,
          label: `${customer.firstName} ${customer.lastName}`,
        }));
        callback(options);
      })
      .catch((error) => {
        console.error(error);
        callback([]);
      });
  }, 300);

  const loadStaffList = async () => {
    try {
      // setStaffLoading(true);
      console.log("selectedLocationOption: ", selectedLocationOption);
      const response = await getStaffListByLocationId(
        selectedLocationOption.value
      );
      let people = [];
      response.data.forEach((p) => {
        const person = {
          value: p.id,
          label: p.firstName + " " + p.lastName,
        };
        people.push(person);
      });
      setPersonOptions(people);
      // setStaffList(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      // setStaffLoading(false);
    }
  };

  const loadCashRegisters = async () => {
    try {
      const response = await getCashRegistersAll();
      setCashRegisters(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLocationSelectionChange = (selectedOption) => {
    setSelectedLocationOption(selectedOption);
    if (!!errors["selectedLocationOption"])
      setErrors({
        ...errors,
        selectedLocationOption: null,
      });
    setSelectedCashRegisterType({
      value: "",
      label: "",
    });
    setSelectedPerson({
      value: "",
      label: "",
    });
  };

  const handleRegisterTypeSelectionChange = (selectedOption) => {
    setSelectedCashRegisterType(selectedOption);
    if (!!errors["selectedCashRegisterType"])
      setErrors({
        ...errors,
        selectedCashRegisterType: null,
      });
  };

  const findFormErrors = () => {
    const { incomeSum, incomeReason } = incomeData;

    const newErrors = {};

    if (!selectedLocationOption.value || selectedLocationOption.value === "")
      newErrors.selectedLocationOption = t("field_required");

    if (!incomeSum || incomeSum === "" || parseInt(incomeSum, 10) === 0)
      newErrors.incomeSum = t("field_required");
    else if (!isNumericInput(incomeSum) || parseInt(incomeSum, 10) < 0)
      newErrors.incomeSum = t("field_must_be_positive_number");

    if (!incomeReason || incomeReason === "")
      newErrors.incomeReason = t("field_required");
    if (
      !selectedPerson ||
      selectedPerson.value === "" ||
      selectedPerson.value === 0
    )
      newErrors.selectedPerson = t("field_required");

    if (!selectedCashRegisterType || selectedCashRegisterType.value === "")
      newErrors.selectedCashRegisterType = t("field_required");
    return newErrors;
  };

  const handleSubmit = async () => {
    const newErrors = findFormErrors();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        const payload = {
          locationId: selectedLocationOption.value,
          cashRegisterId: selectedCashRegisterType.value,
          sum: incomeData.incomeSum,
          reason: incomeData.incomeReason,
        };

        if (selectedGroup && selectedGroup.value === "staff") {
          payload.staffId = selectedPerson.value;
        } else if (selectedGroup && selectedGroup.value === "customers") {
          payload.customerId = selectedPerson.value;
        }
        const response = await createIncomeRecord(payload);
        setIncomes((oldIncomes) => [
          ...oldIncomes,
          {
            ...response.data,
            date: fromZonedTime(response.data.date, "UTC"),
          },
        ]);
        notifySuccess(t("income_record_created_successfully"));
        handleClose();
      } catch (error) {
        console.log(error);
      } finally {
      }
    }
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      maxWidth: "340px",
    }),
  };

  return (
    <div className="checkout-body-layout">
      <div className="checkout-container">
        <Card className="mb-3 checkout-card">
          <Card.Header className="checkout-card-header">
            <h3 className="mb-0">
              <Trans>basic_information</Trans>
            </h3>
          </Card.Header>
          <Card.Body className="d-flex flex-column align-self-stretch gap-4">
            <div className="d-flex flex-column align-items-start gap-3">
              <Row className="w-100 gx-3">
                <Col xs={4} className="p1-bold primary-text-color">
                  <Trans>select_location</Trans>
                </Col>
                <Col xs={8}>
                  <Select
                    id="location"
                    name="location"
                    classNamePrefix="react-select"
                    placeholder={t("select_location")}
                    value={selectedLocationOption}
                    options={locationOptions}
                    required
                    onChange={(option) => handleLocationSelectionChange(option)}
                    className={
                      !!errors.selectedLocationOption
                        ? "is-invalid-react-select"
                        : ""
                    }
                    styles={customSelectStyles}
                  />
                  {!!errors.selectedLocationOption && (
                    <div className="invalid-feedback-react-select">
                      {errors.selectedLocationOption}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            <div className="d-flex flex-column align-items-start gap-3">
              <Row className="w-100 gx-3">
                <Col xs={4} className="p1-bold primary-text-color">
                  <Trans>income_order_processed_by</Trans>
                </Col>
                <Col xs={8}>
                  <Select
                    id="group-type"
                    name="group-type"
                    classNamePrefix="react-select"
                    placeholder={t("select")}
                    value={selectedGroup}
                    options={groupOptions}
                    required
                    onChange={handleGroupChange}
                    styles={customSelectStyles}
                  />
                </Col>
              </Row>
              <Row className="w-100 gx-3">
                <Col xs={4} className="p1-bold"></Col>
                <Col xs={8}>
                  {selectedGroup && selectedGroup.value === "customers" ? (
                    <AsyncSelect
                      id="person-select"
                      name="person-select"
                      cacheOptions
                      defaultOptions
                      loadOptions={(inputValue) =>
                        new Promise((resolve) => {
                          debouncedFetchCustomers(inputValue, resolve);
                        })
                      }
                      onChange={setSelectedPerson}
                      value={selectedPerson}
                      placeholder={t("start_typing")}
                    />
                  ) : (
                    <Select
                      id="person-select"
                      name="person-select"
                      classNamePrefix="react-select"
                      placeholder={t("select")}
                      value={selectedPerson}
                      options={personOptions}
                      required
                      onChange={(option) => setSelectedPerson(option)}
                      styles={customSelectStyles}
                    />
                  )}
                </Col>
              </Row>
            </div>
            <div className="d-flex flex-column align-items-start gap-3">
              <Row className="w-100 gx-3">
                <Col xs={4} className="p1-bold primary-text-color">
                  <Trans>payment_method</Trans>
                </Col>
                <Col xs={8}>
                  <Select
                    id="order-type"
                    name="order-type"
                    classNamePrefix="react-select"
                    placeholder={t("select_payment_method_register")}
                    value={selectedCashRegisterType}
                    options={cashRegisterTypes}
                    required
                    onChange={(option) =>
                      handleRegisterTypeSelectionChange(option)
                    }
                    className={
                      !!errors.selectedCashRegisterType
                        ? "is-invalid-react-select"
                        : ""
                    }
                    styles={customSelectStyles}
                  />
                  {!!errors.selectedCashRegisterType && (
                    <div className="invalid-feedback-react-select">
                      {errors.selectedCashRegisterType}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            <div className="d-flex flex-column align-items-start gap-3">
              <Row className="w-100 gx-3">
                <Col xs={4} className="p1-bold primary-text-color">
                  <Trans>sum</Trans>
                </Col>
                <Col xs={8}>
                  <Form.Control
                    id="sum"
                    name="sum"
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    className="form-control"
                    value={incomeData.incomeSum}
                    onChange={(e) => setField("incomeSum", e.target.value)}
                    isInvalid={!!errors.incomeSum}
                    placeholder={t("sum")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.incomeSum}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </div>
            <div className="d-flex flex-column align-items-start gap-3">
              <Row className="w-100 gx-3">
                <Col xs={4} className="p1-bold primary-text-color">
                  <Trans>income_return_reason</Trans>
                </Col>
                <Col xs={8}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={incomeData.incomeReason}
                    id="income-reason"
                    name="income-reason"
                    placeholder={t("enter_income_reason")}
                    // onChange={e => setIncomeReason(e.target.value)}
                    onChange={(e) => setField("incomeReason", e.target.value)}
                    isInvalid={!!errors.incomeReason}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.incomeReason}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
        <div className="checkout-buttons-block">
          <Button name="cancelButton" variant="grey" onClick={handleClose}>
            <Trans>cancel</Trans>
          </Button>
          <Button name="saveButton" variant="blue" onClick={handleSubmit}>
            <Trans>save</Trans>
          </Button>
        </div>
      </div>
      <BlockUI tag="div" blocking={blocking} />
    </div>
  );
};

export default CreateIncomeRecord;
