import { useEffect, useRef, useState } from "react";
import { Button, Form, Card } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import {
  createCashRegisters,
  getCashRegisterTypes,
} from "service/cash-register/CashRegisterService";
import { getLocations } from "service/location/LocationService";
import { notifySuccess } from "util/notify";
import EnhancedSelect from "component/common/EnhancedSelect";

const CreateCashRegister = ({ handleClose, onCreate }) => {
  const [cashRegisterTypes, setCashRegisterTypes] = useState([]);
  const [selectedCashRegisterType, setSelectedCashRegisterType] =
    useState(null);
  const [locations, setLocations] = useState([]);
  const [locationAddresses, setLocationAddresses] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState({});
  const [errors, setErrors] = useState({});
  const [itemsToShow, setItemsToShow] = useState(6);
  const allLocationsCheckboxRef = useRef();
  const { t } = useTranslation();
  const isSelectedLocationsInitialized = useRef(false);

  useEffect(() => {
    loadCashRegisterTypes();
    loadLocations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      initSelectedLocations();
      isSelectedLocationsInitialized.current = true;
    }
    // eslint-disable-next-line
  }, [locations.length]);

  useEffect(() => {
    if (locations.length > 0) {
      prepareLocationsDivs();
    }
    // eslint-disable-next-line
  }, [
    isSelectedLocationsInitialized,
    selectedLocations,
    errors.selectedLocations,
    itemsToShow,
  ]);

  useEffect(() => {
    if (allLocationsCheckboxRef.current) {
      if (checkAllLocationsSelected()) {
        allLocationsCheckboxRef.current.indeterminate = false;
        allLocationsCheckboxRef.current.checked = true;
      } else if (checkAtLeastOneLocationSelected()) {
        allLocationsCheckboxRef.current.indeterminate = true;
        allLocationsCheckboxRef.current.checked = false;
      } else {
        allLocationsCheckboxRef.current.indeterminate = false;
        allLocationsCheckboxRef.current.checked = false;
      }
    }
    // eslint-disable-next-line
  }, [selectedLocations]);

  const checkAllLocationsSelected = () => {
    for (let i = 0; i < locations.length; i++) {
      if (!selectedLocations[locations[i].id]) {
        return false;
      }
    }
    return true;
  };

  const checkAtLeastOneLocationSelected = () => {
    for (let i = 0; i < locations.length; i++) {
      if (selectedLocations[locations[i].id]) {
        return true;
      }
    }
    return false;
  };

  const initSelectedLocations = () => {
    const selectedLocs = {};
    locations.forEach((item) => {
      selectedLocs[item.id] = false;
    });
    setSelectedLocations(selectedLocs);
  };

  const loadCashRegisterTypes = async () => {
    let response = [];
    try {
      response = await getCashRegisterTypes();
      let crTypes = [];
      response.data.forEach((cashRegisterType) => {
        crTypes.push({
          value: cashRegisterType.id,
          label: t(`cash_register_type_${cashRegisterType.name}`),
        });
      });
      setCashRegisterTypes(crTypes);
      setSelectedCashRegisterType(crTypes[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const loadLocations = async () => {
    try {
      const response = await getLocations();
      setLocations(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const prepareLocationsDivs = () => {
    let locationAddresses = [];

    locationAddresses = locations.slice(0, itemsToShow).map((loc, index) => {
      let la = loc.address;

      return (
        <Form.Check
          key={loc.id}
          label={la.city + " " + la.street + " " + la.houseNumber}
          type="checkbox"
          id={`la-checkbox-${loc.id}`}
          name={`la-checkbox-${loc.id}`}
          value={loc.id}
          isInvalid={!!errors.selectedLocations}
          className="location-checkbox"
        >
          <Form.Check.Input
            type="checkbox"
            checked={selectedLocations[loc.id]}
            onChange={(e) => onChangeLocation(loc.id, e.target.checked)}
          />
          <Form.Check.Label className="d-flex flex-column align-items-start mb-0">
            <p className="mb-0 p2">{loc.name}</p>
            <p className="mb-0 p3 secondary-text-color">
              {la.city + " " + la.street + " " + la.houseNumber}
            </p>
          </Form.Check.Label>
        </Form.Check>
      );
    });
    setLocationAddresses(locationAddresses);
  };

  const onChangeAllLocations = (e) => {
    let selectedLocs = {};
    if (e.target.checked) {
      locations.forEach((item) => {
        selectedLocs[item.id] = true;
      });
    } else {
      locations.forEach((item) => {
        selectedLocs[item.id] = false;
      });
    }
    if (!!errors.selectedLocations) {
      setErrors({
        ...errors,
        selectedLocations: null,
      });
    }
    setSelectedLocations(selectedLocs);
  };

  const onChangeLocation = (locId, checked) => {
    setSelectedLocations((prevSelectedLocations) => ({
      ...prevSelectedLocations,
      [locId]: checked,
    }));

    if (!!errors.selectedLocations) {
      setErrors({
        ...errors,
        selectedLocations: null,
      });
    }
  };

  const findFormErrors = () => {
    const newErrors = {};
    let atLeastOneLocationSelected = false;
    for (let loc in selectedLocations) {
      if (selectedLocations[loc]) {
        atLeastOneLocationSelected = true;
      }
    }
    if (!atLeastOneLocationSelected)
      newErrors.selectedLocations = t("field_required");
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      try {
        const payload = {};
        payload.cashRegisterTypeId = selectedCashRegisterType.value;
        payload.locations = Object.keys(selectedLocations)
          .filter((key) => selectedLocations[key]);
        const response = await createCashRegisters(payload);
        notifySuccess(t("cash_register_created"));
        onCreate(response.data);
        handleClose();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="container central-content-container">
      <Card className="mb-3">
        <Card.Header className="plain-card-header">
          <h3 className="mb-0">
            <Trans>basic_information</Trans>
          </h3>
        </Card.Header>
        <Card.Body>
          <EnhancedSelect
            id="cashRegisterType"
            name="cashRegisterType"
            classNamePrefix="react-select"
            placeholder={t("select_payment_method_name")}
            label={t("payment_method")}
            selectedValue={selectedCashRegisterType}
            options={cashRegisterTypes}
            onChange={(option) => setSelectedCashRegisterType(option)}
          />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header className="plain-card-header">
          <h3 className="mb-0">
            <Trans>locations</Trans>
          </h3>
        </Card.Header>
        <Card.Body className="d-flex flex-column gap-3">
          {locations && locations.length > 0 ? (
            <>
              <div className="form-check location-checkbox">
                <input
                  ref={allLocationsCheckboxRef}
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="la-all-checkbox"
                  name="la-all-checkbox"
                  onChange={onChangeAllLocations}
                />
                <label className="form-check-label" htmlFor="la-all-checkbox">
                  <Trans>select_all</Trans>
                </label>
              </div>
              <div className="cash-register-check-grid">{locationAddresses}</div>
              <div className="text-danger">{errors.selectedLocations}</div>
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <Trans>locations_have_not_been_added_yet</Trans>
            </div>
          )}
          {locations.length > itemsToShow && (
            <div className="d-flex justify-content-center">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setItemsToShow(locations.length);
                }}
                className="btn btn-link"
              >
                <Trans>show_more</Trans>
              </button>
            </div>
          )}
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-end align-items-start gap-3">
        <Button
          variant="grey"
          name="cancelButton"
          className="me-2"
          onClick={handleClose}
        >
          <Trans>cancel</Trans>
        </Button>
        <Button
          variant="blue"
          type="submit"
          name="saveButton"
          onClick={handleSubmit}
        >
          <Trans>save</Trans>
        </Button>
      </div>
    </div>
  );
};

export default CreateCashRegister;
