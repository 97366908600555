import team2 from "assets/img/team/2.jpg";
import Avatar from "component/common/Avatar";
import { format, toZonedTime } from "date-fns-tz";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { encashLocationStep1, encashLocationStep2 } from "service/encashment/EncashmentService";
import { notifySuccess } from "util/notify";
import { isNumericInput } from "util/validation";
import { Cookies } from "js-cookie";

const EncashmentProcessing = ({
  selectedLocationId,
  handleSuccessfulEncashment,
}) => {
  const { t } = useTranslation();
  const [cashRegistersByLocation, setCashRegistersByLocation] = useState(null);
  const [confirmationMode, setConfirmationMode] = useState(false);

  const [enteredData, setEnteredData] = useState(
    cashRegistersByLocation?.balances.map((cashRegister) => ({
      cashRegisterId: cashRegister.cashRegisterId,
      cashRegisterName: cashRegister.cashRegisterName,
      amount: 0,
      difference: cashRegister.balance,
      balance: cashRegister.balance,
    })) || []
  );

  const [errors, setErrors] = useState({});
  const timezone = DateTime.local().zoneName;
  const currentDate = new Date();
  const zonedDate = toZonedTime(currentDate, timezone);
  const dateFormat = "dd/MM/yyyy";
  const formattedDate = format(zonedDate, dateFormat);

  const currentUser = Cookies.get("zymran_user") ? JSON.parse(Cookies.get("zymran_user")) : null;

  useEffect(() => {
    handleEncashStep1();
    // eslint-disable-next-line
  }, []);

  const handleEncashStep1 = async () => {
    try {
      const payload = {
        locationId: selectedLocationId,
      };
      const response = await encashLocationStep1(payload);
      setCashRegistersByLocation(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const calculateTotalAmount = () => {
    let total = 0;
    for (const data of enteredData) {
      if (data && data.amount) {
        total += parseFloat(data.amount);
      }
    }
    return total;
  };

  const calculateTotalDifference = () => {
    let total = 0;
    for (const data of enteredData) {
      if (data && data.difference) {
        total += parseFloat(data.difference);
      }
    }
    return total;
  };

  const handleCheckChange = (index, checked) => {
    const newData = [...enteredData];
    const cashRegister = cashRegistersByLocation.balances[index];
    newData[index] = {
      ...newData[index],
      cashRegisterId: cashRegister.cashRegisterId,
      cashRegisterName: cashRegister.cashRegisterName,
      balance: cashRegister.balance,
      disabled: checked,
      amount: checked ? cashRegistersByLocation.balances[index].balance : "",
      difference: checked ? 0 : newData[index]?.difference || 0,
    };
    setEnteredData(newData);
  };

  const handleInputChange = (index, value) => {
    if (isNumericInput(value)) {
      const newData = [...enteredData];
      const cashRegister = cashRegistersByLocation.balances[index];
      const enteredAmount = parseFloat(value) || 0;
      newData[index] = {
        cashRegisterId: cashRegister.cashRegisterId,
        cashRegisterName: cashRegister.cashRegisterName,
        amount: enteredAmount,
        difference: cashRegister.balance - enteredAmount,
        balance: cashRegister.balance,
      };
      setEnteredData(newData);
    }
  };

  const handleConfirm = async () => {
    const payload = {
      locationId: cashRegistersByLocation.location.id,
      cashRegisterSums: enteredData.reduce((acc, data) => {
        if (data) {
          acc[data.cashRegisterId] = data.amount;
          return acc;
        } else {
          return acc;
        }
      }, {}),
    };
    try {
      await encashLocationStep2(payload);
      notifySuccess(t("encashment_success"));
    } catch (error) {
      console.log(error);
    }
    handleSuccessfulEncashment();
  };

  const findFormErrors = () => {
    const newErrors = {};
    let hasEnteredValue = false;

    cashRegistersByLocation.balances.forEach((cashRegister, index) => {
      const enteredAmount = enteredData[index]?.amount;
      if (enteredAmount && enteredAmount > cashRegister.balance) {
        newErrors[index] = t("Amount exceeds the balance");
      }
      if (enteredAmount) {
        hasEnteredValue = true;
      }
    });

    if (!hasEnteredValue) {
      newErrors["general"] = t("At least one value should be entered");
    }

    return newErrors;
  };

  const handlePreConfirm = () => {
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setConfirmationMode(true);
    }
  };

  return (
    <div className="container central-content-container">
      <Card className="mb-3">
        <Card.Header className="plain-card-header">
          <div className="d-flex flex-row justify-content-between">
            <Button
              variant="grey"
              name="backButton"
              size="sm"
              onClick={() => setConfirmationMode(false)}
            >
              <Trans>back</Trans>
            </Button>
            {confirmationMode && (
              <h3 className="mb-0">
                <Trans>encashment_end</Trans>
              </h3>
            )}
            {!confirmationMode && (
              <Button
                variant="blue"
                name="nextButton"
                size="sm"
                onClick={() => handlePreConfirm()}
              >
                <Trans>next</Trans>
              </Button>
            )}
            {confirmationMode && (
              <Button
                variant="blue"
                name="confirmButton"
                size="sm"
                onClick={() => handleConfirm()}
              >
                <Trans>confirm</Trans>
              </Button>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          {!confirmationMode && (
            <Table borderless>
              <thead className="p1-bold secondary-text-color">
                <tr>
                  <td>
                    <Trans>name</Trans>
                  </td>
                  <td>
                    <Trans>balance</Trans>
                  </td>
                  <td>
                    <Trans>sum</Trans>
                  </td>
                  <td></td>
                  <td>
                    <Trans>at_the_start_of_day</Trans>
                  </td>
                </tr>
              </thead>
              <tbody className="p1 primary-text-color">
                {cashRegistersByLocation &&
                  cashRegistersByLocation.balances.map(
                    (cashRegister, index) => (
                      <tr key={index}>
                        <td className="align-middle">
                          <Trans>{`cash_register_type_${cashRegister.cashRegisterName}`}</Trans>
                        </td>
                        <td className="align-middle">{cashRegister.balance}</td>
                        <td className="align-middle">
                          <Form.Control
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            placeholder="Enter amount"
                            id={`cashRegisterAmount-${index}`}
                            name={`cashRegisterAmount-${index}`}
                            value={enteredData[index]?.amount || 0}
                            max={cashRegister.balance}
                            onChange={(e) =>
                              handleInputChange(index, e.target.value)
                            }
                            isInvalid={!!errors[index] || !!errors["general"]}
                            disabled={
                              enteredData[index]?.disabled ||
                              cashRegister.balance === 0
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors[index] || errors["general"]}
                          </Form.Control.Feedback>
                        </td>
                        <td className="align-middle">
                          <Form.Check
                            type="checkbox"
                            id={`cashRegisterCheckbox-${index}`}
                            name={`cashRegisterCheckbox-${index}`}
                            label={t("encash_fully")}
                            checked={enteredData[index]?.disabled || false}
                            onChange={(e) =>
                              handleCheckChange(index, e.target.checked)
                            }
                          />
                        </td>
                        <td className="align-middle">
                          {enteredData[index]?.difference || 0}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </Table>
          )}

          {confirmationMode && (
            <>
              <div className="d-flex align-items-start align-self-stretch mb-3">
                <div className="d-flex flex-column align-self-stretch flex-grow-1">
                  <h2>
                    <Trans>confirmation_data</Trans>:
                  </h2>
                  <Table
                    borderless
                    size="sm"
                    className="p1 primary-text-color w-auto"
                  >
                    <tbody>
                      <tr>
                        <th
                          className="p1-bold secondary-text-color"
                          style={{ width: "1%", whiteSpace: "nowrap" }}
                        >
                          <Trans>location</Trans>
                        </th>
                        <td>{cashRegistersByLocation.location.name}</td>
                      </tr>
                      <tr>
                        <th className="p1-bold secondary-text-color">
                          <Trans>date</Trans>
                        </th>
                        <td>{formattedDate}</td>
                      </tr>
                      <tr>
                        <th className="p1-bold secondary-text-color">
                          <Trans>encashed_by</Trans>
                        </th>
                        <td>{currentUser.login}</td>
                      </tr>
                      <tr>
                        <th className="p1-bold secondary-text-color">
                          <Trans>encashed_amount</Trans>:
                        </th>
                        <td>{calculateTotalAmount()}</td>
                      </tr>
                      <tr>
                        <th className="p1-bold secondary-text-color">
                          <Trans>remainder_at_start_of_day</Trans>:
                        </th>
                        <td>{calculateTotalDifference()}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="finances-issued-by-block">
                  <p className="p1-bold mb-0">
                    <Trans>issued_by</Trans>
                  </p>
                  <div className="d-flex flex-row align-items-center">
                    <div className="d-flex">
                      <Avatar src={team2} size="xl" className="me-3" />
                    </div>
                    <div className="d-flex flex-column align-items-start text-start">
                      <p className="p2-bold mb-0 text-blue mb-0 ">
                        {currentUser.login} {currentUser.login}
                      </p>
                      <p className="p3 mb-0 secondary-text-color">
                        {currentUser.login}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-rounded-wrapper">
                <Table className="cash-register-table mb-0">
                  <thead className="p2-bold secondary-text-color">
                    <tr>
                      <th>№</th>
                      <th>
                        <Trans>cash_register</Trans>
                      </th>
                      <th>
                        <Trans>balance</Trans>
                      </th>
                      <th>
                        <Trans>sum</Trans>
                      </th>
                      <th>
                        <Trans>at_the_start_of_day</Trans>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="p2 primary-text-color">
                    {(() => {
                      let orderNumber = 0;
                      return enteredData.map((data, index) => {
                        if (!data) {
                          return null;
                        }
                        orderNumber += 1;
                        return (
                          <tr key={index}>
                            <td>{orderNumber}</td>
                            <td>
                              <Trans>{`cash_register_type_${data.cashRegisterName}`}</Trans>
                            </td>
                            <td>{data.balance}</td>
                            <td>{data.amount}</td>
                            <td>{data.difference}</td>
                          </tr>
                        );
                      });
                    })()}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default EncashmentProcessing;
