import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const EnhancedFormControl = ({ id, name, type, value, onChange, isInvalid, readOnly, placeholder, label, errors, groupClassName = '', required, description, disabled, ...rest }) => {
  const [focusedInput, setFocusedInput] = useState('');

  const handleInputFocus = (e) => {
    const { name } = e.target;
    setFocusedInput(name);
  };

  const handleInputBlur = () => {
    setFocusedInput('');
  };

  const renderPlaceholder = () => {
    return required ? `${placeholder}*` : placeholder;
  };

  return (
    <Form.Group className={`position-relative ${groupClassName}`}>
      {value && (
        <Form.Label className={`${errors ? 'is-invalid' : ''} ${focusedInput === name ? 'is-focused' : ''}`}>
          {label}:
        </Form.Label>
      )}
      <Form.Control
        className="form-control"
        id={id}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        isInvalid={isInvalid}
        placeholder={renderPlaceholder()}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        readOnly={readOnly}
        disabled={disabled}
        {...rest}
      />
      {errors || !description ? null : <Form.Text className="text-muted">{description}</Form.Text>}
      <Form.Control.Feedback type='invalid'>{errors}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default EnhancedFormControl;
