import ZymranAvatar from "component/common/ZymranAvatar";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import useLocale from "hooks/useLocale";
import { DateTime } from "luxon";
import { Table } from "react-bootstrap";
import { Trans } from "react-i18next";

const ManualCashTypeData = ({ incomeDetail }) => {
  const timezone = DateTime.local().zoneName;
  const locale = useLocale();

  return (
    <div className="d-flex justify-content-between align-items-start align-self-stretch">
      <Table borderless size="sm" className="p1 primary-text-color w-auto mb-0">
        <tbody>
          <tr>
            <th className="p1-bold secondary-text-color">
              <Trans>document_number</Trans>:
            </th>
            <td>{incomeDetail.code}</td>
          </tr>
          <tr>
            <th className="p1-bold secondary-text-color">
              <Trans>date</Trans>:
            </th>
            <td>
              {format(
                toZonedTime(incomeDetail.date, timezone),
                "dd MMM yyyy HH:mm",
                { locale }
              )}
            </td>
          </tr>
          <tr>
            <th className="p1-bold secondary-text-color">
              <Trans>income_order_processing_method</Trans>:
            </th>
            <td>
              <Trans>{`cash_register_type_${incomeDetail.cashRegisterName}`}</Trans>
            </td>
          </tr>
          <tr>
            <th className="p1-bold secondary-text-color">
              <Trans>income_return_reason</Trans>:
            </th>
            <td>{incomeDetail.reason}</td>
          </tr>
          <tr>
            <th className="p1-bold secondary-text-color text-uppercase">
              <Trans>sum</Trans>:
            </th>
            <td className="p1-bold">{incomeDetail.sum}</td>
          </tr>
        </tbody>
      </Table>
      {incomeDetail.acceptedFromUser && (
        <div className="finances-issued-by-block">
          <p className="p1-bold mb-0">
            <Trans>payment_accepted_from</Trans>
          </p>
          <div className="d-flex flex-row align-items-center">
            <div className="d-flex">
              <ZymranAvatar
                avatarUrl={incomeDetail.acceptedFromUser.avatarUrl}
                size="lg"
                shape="circle"
                className={"me-2"}
              />
            </div>
            <div className="d-flex flex-column align-items-start text-start">
              <p className="p2-bold mb-0 text-blue">
                {incomeDetail.acceptedFromUser.firstName}{" "}
                {incomeDetail.acceptedFromUser.lastName}
              </p>
              <p className="p3 mb-0 secondary-text-color">
                <Trans>{incomeDetail.acceptedFromUser.type}</Trans>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManualCashTypeData;
