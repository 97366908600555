import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import is from "is_js";
import AuthSimpleLayout from "./AuthSimpleLayout";
import ErrorLayout from "./ErrorLayout";
import MainLayout from "./MainLayout";
import FullWidthLayout from "./FullWidthLayout";
import MultiStepRegisterLayout from "./MultiStepRegisterLayout";
import UserProfile from "component/user-profile/UserProfile";
import Staff from "component/staff/Staff";
import Location from "component/location/Location";
import Service from "component/service/Service";
import Customer from "component/customer/Customer";
import Calendar from "component/calendar/Calendar";
import Forbidden from "component/error/Forbidden403";
import NotFound from "component/error/NotFound404";
import AppContext from "context/Context";
import Landing from "component/pages/landing/Landing";
import { toast, ToastContainer } from "react-toastify";
import { CloseButton } from "component/common/Toast";
import PartnerRegistration from "component/auth/partner/PartnerRegistration";
import ConfirmRegistration from "component/auth/ConfirmRegistration";
import RegistrationComplete from "component/auth/RegistrationComplete";
import ProtectedRoute from "security/protected-route";
import Dashboard from "component/dashboards/default";
import StaffSchedule from "component/staff-schedule/StaffSchedule";
import CashRegister from "component/finance/cash-register/CashRegister";
import IncomeView from "component/finance/income-view/IncomeView";
import ExpenseView from "component/finance/expense/ExpenseView";
import EncashmentView from "component/finance/encashment/EncashmentView";
import PasswordRestoreWrapper from "component/auth/PasswordRestoreWrapper";
import SigninPartnerWrapper from "component/auth/SigninPartnerWrapper";
import SalaryStaffView from "component/finance/salary/SalaryStaffView";
import Cookies from "js-cookie";

const Layout = () => {
  const currentUser = Cookies.get("zymran_user");
  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add("windows");
    }
    if (is.chrome()) {
      HTMLClassList.add("chrome");
    }
    if (is.firefox()) {
      HTMLClassList.add("firefox");
    }
  }, [HTMLClassList]);


  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            currentUser ? <Navigate replace to={"/profile"} /> : <Landing />
          }
        />

        <Route element={<ErrorLayout />}>
          <Route path="/403" element={<Forbidden />} />
          <Route path="/404" element={<NotFound />} />
        </Route>

        {/*- ------------- Authentication ---------------------------  */}

        {/*- ------------- simple ---------------------------  */}
        <Route element={<AuthSimpleLayout />}>
          {/*
                    <Route
                        path="/signin/customer"
                        element={<SignInCustomer />}
                    />                                        
                    */}
          <Route
            path="/register/confirm/:uuid"
            element={<ConfirmRegistration />}
          />
          <Route
            path="/registration-complete"
            element={<RegistrationComplete />}
          />
        </Route>

        <Route path="/signin/partner" element={<SigninPartnerWrapper />} />
        <Route path="/reset-password" element={<PasswordRestoreWrapper />} />

        <Route element={<MultiStepRegisterLayout />}>
          <Route
            path="/register/partner"
            element={
              currentUser && currentUser.registrationPassed ? (
                <Navigate replace to={"/profile"} />
              ) : (
                <PartnerRegistration />
              )
            }
          />
        </Route>

        <Route element={<FullWidthLayout />}>
          <Route element={<ProtectedRoute />}>
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/settings/location/*" element={<Location />} />
            <Route path="/settings/staff/*" element={<Staff />} />
            <Route path="/settings/customer/*" element={<Customer />} />
            <Route
              path="/settings/staff-schedule"
              element={<StaffSchedule />}
            />
          </Route>
        </Route>

        {/* //--- MainLayout Starts  */}

        <Route element={<MainLayout />}>
          {/*Dashboard*/}
          <Route element={<ProtectedRoute />}>
            <Route path="/profile" element={<UserProfile />} />
            <Route
              path="/settings"
              element={<Navigate to={"/settings/customer"} replace />}
            />
            <Route path="/settings/service/*" element={<Service />} />
          </Route>
          <Route element={<NotFound />} />
        </Route>
        <Route path="/403" element={<Forbidden />} />
        {/* //--- MainLayout end  */}

        <Route element={<MainLayout />}>
          <Route element={<ProtectedRoute />}>
            <Route
              path="/finance"
              element={<Navigate to={"/finance/cash-register"} replace />}
            />
            <Route path="/finance/cash-register" element={<CashRegister />} />
            <Route path="/finance/income-view" element={<IncomeView />} />
            <Route path="/finance/expense" element={<ExpenseView />} />
            <Route path="/finance/salary" element={<SalaryStaffView />} />
            <Route path="/finance/encashment" element={<EncashmentView />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
