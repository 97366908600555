import React from "react";
import { Trans } from "react-i18next";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

const LocationOpenHoursForm = ({
  locationOpenHours,
  setField,
  minStartTime,
  maxEndTime,
  step,
}) => {
  const { t } = useTranslation();

  const handleChange = (e, day) => {
    setField(day, "dayOff", e.target.checked);
  };

  return (
    <>
      <div className="location-open-hours-form-grid primary-text-color">
        <div className="location-open-hours-form-cell">
          <p className="p1 mb-0">
            <Trans>monday</Trans>
          </p>
          <div className="d-flex align-items-center gap-1 align-self-stretch">
            <DatePicker
              id="monday-start-time"
              selected={locationOpenHours.monday.startTime}
              onChange={(date) => setField("monday", "startTime", date)}
              showTimeSelect
              showTimeSelectOnly
              minTime={minStartTime}
              maxTime={maxEndTime}
              timeIntervals={step}
              timeCaption="Time"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              onChangeRaw={(e) => e.preventDefault()}
              className="form-control"
              disabled={locationOpenHours.monday.dayOff}
            />
            <span>-</span>
            <Form.Group>
              <DatePicker
                id="monday-end-time"
                selected={locationOpenHours.monday.endTime}
                onChange={(date) => setField("monday", "endTime", date)}
                showTimeSelect
                showTimeSelectOnly
                minTime={minStartTime}
                maxTime={maxEndTime}
                timeIntervals={step}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                onChangeRaw={(e) => e.preventDefault()}
                className="form-control"
                disabled={locationOpenHours.monday.dayOff}
              />
            </Form.Group>
          </div>
          <Form.Group>
            <Form.Check
              key="1"
              label={t("day_off")}
              type="checkbox"
              id={`la-checkbox-${1}`}
              name={`la-checkbox-${1}`}
              checked={locationOpenHours.monday.dayOff}
              onChange={(e) => handleChange(e, "monday")}
            />
          </Form.Group>
        </div>
        <div className="location-open-hours-form-cell">
          <p className="p1 mb-0">
            <Trans>tuesday</Trans>
          </p>
          <div className="d-flex align-items-center gap-1 align-self-stretch">
            <Form.Group>
              <DatePicker
                id="tuesday-start-time"
                selected={locationOpenHours.tuesday.startTime}
                onChange={(date) => setField("tuesday", "startTime", date)}
                showTimeSelect
                showTimeSelectOnly
                minTime={minStartTime}
                maxTime={maxEndTime}
                timeIntervals={step}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                onChangeRaw={(e) => e.preventDefault()}
                className="form-control"
                disabled={locationOpenHours.tuesday.dayOff}
              />
            </Form.Group>
            <span>-</span>
            <Form.Group>
              <DatePicker
                id="tuesday-end-time"
                selected={locationOpenHours.tuesday.endTime}
                onChange={(date) => setField("tuesday", "endTime", date)}
                showTimeSelect
                showTimeSelectOnly
                minTime={minStartTime}
                maxTime={maxEndTime}
                timeIntervals={step}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                onChangeRaw={(e) => e.preventDefault()}
                className="form-control"
                disabled={locationOpenHours.tuesday.dayOff}
              />
            </Form.Group>
          </div>
          <Form.Group>
            <Form.Check
              key="2"
              label={t("day_off")}
              type="checkbox"
              id={`la-checkbox-${2}`}
              name={`la-checkbox-${2}`}
              checked={locationOpenHours.tuesday.dayOff}
              onChange={(e) => handleChange(e, "tuesday")}
            />
          </Form.Group>
        </div>
        <div className="location-open-hours-form-cell">
          <p className="p1 mb-0">
            <Trans>wednesday</Trans>
          </p>
          <div className="d-flex align-items-center gap-1 align-self-stretch">
            <Form.Group>
              <DatePicker
                id="wednesday-start-time"
                selected={locationOpenHours.wednesday.startTime}
                onChange={(date) => setField("wednesday", "startTime", date)}
                showTimeSelect
                showTimeSelectOnly
                minTime={minStartTime}
                maxTime={maxEndTime}
                timeIntervals={step}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                onChangeRaw={(e) => e.preventDefault()}
                className="form-control"
                disabled={locationOpenHours.wednesday.dayOff}
              />
            </Form.Group>
            <span>-</span>
            <Form.Group>
              <DatePicker
                id="wednesday-end-time"
                selected={locationOpenHours.wednesday.endTime}
                onChange={(date) => setField("wednesday", "endTime", date)}
                showTimeSelect
                showTimeSelectOnly
                minTime={minStartTime}
                maxTime={maxEndTime}
                timeIntervals={step}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                onChangeRaw={(e) => e.preventDefault()}
                className="form-control"
                disabled={locationOpenHours.wednesday.dayOff}
              />
            </Form.Group>
          </div>
          <Form.Group>
            <Form.Check
              key="3"
              label={t("day_off")}
              type="checkbox"
              id={`la-checkbox-${3}`}
              name={`la-checkbox-${3}`}
              checked={locationOpenHours.wednesday.dayOff}
              onChange={(e) => handleChange(e, "wednesday")}
            />
          </Form.Group>
        </div>
        <div className="location-open-hours-form-cell">
          <p className="p1 mb-0">
            <Trans>thursday</Trans>
          </p>
          <div className="d-flex align-items-center gap-1 align-self-stretch">
            <Form.Group>
              <DatePicker
                id="thursday-start-time"
                selected={locationOpenHours.thursday.startTime}
                onChange={(date) => setField("thursday", "startTime", date)}
                showTimeSelect
                showTimeSelectOnly
                minTime={minStartTime}
                maxTime={maxEndTime}
                timeIntervals={step}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                onChangeRaw={(e) => e.preventDefault()}
                className="form-control"
                disabled={locationOpenHours.thursday.dayOff}
              />
            </Form.Group>
            <span>-</span>
            <Form.Group>
              <DatePicker
                id="thursday-end-time"
                selected={locationOpenHours.thursday.endTime}
                onChange={(date) => setField("thursday", "endTime", date)}
                showTimeSelect
                showTimeSelectOnly
                minTime={minStartTime}
                maxTime={maxEndTime}
                timeIntervals={step}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                onChangeRaw={(e) => e.preventDefault()}
                className="form-control"
                disabled={locationOpenHours.thursday.dayOff}
              />
            </Form.Group>
          </div>
          <Form.Group>
            <Form.Check
              key="4"
              label={t("day_off")}
              type="checkbox"
              id={`la-checkbox-${4}`}
              name={`la-checkbox-${4}`}
              checked={locationOpenHours.thursday.dayOff}
              onChange={(e) => handleChange(e, "thursday")}
            />
          </Form.Group>
        </div>
        <div className="location-open-hours-form-cell">
          <p className="p1 mb-0">
            <Trans>friday</Trans>
          </p>
          <div className="d-flex align-items-center gap-1 align-self-stretch">
            <Form.Group>
              <DatePicker
                id="friday-start-time"
                selected={locationOpenHours.friday.startTime}
                onChange={(date) => setField("friday", "startTime", date)}
                showTimeSelect
                showTimeSelectOnly
                minTime={minStartTime}
                maxTime={maxEndTime}
                timeIntervals={step}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                onChangeRaw={(e) => e.preventDefault()}
                className="form-control"
                disabled={locationOpenHours.friday.dayOff}
              />
            </Form.Group>
            <span>-</span>
            <Form.Group>
              <DatePicker
                id="friday-end-time"
                selected={locationOpenHours.friday.endTime}
                onChange={(date) => setField("friday", "endTime", date)}
                showTimeSelect
                showTimeSelectOnly
                minTime={minStartTime}
                maxTime={maxEndTime}
                timeIntervals={step}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                onChangeRaw={(e) => e.preventDefault()}
                className="form-control"
                disabled={locationOpenHours.friday.dayOff}
              />
            </Form.Group>
          </div>
          <Form.Group>
            <Form.Check
              key="5"
              label={t("day_off")}
              type="checkbox"
              id={`la-checkbox-${5}`}
              name={`la-checkbox-${5}`}
              checked={locationOpenHours.friday.dayOff}
              onChange={(e) => handleChange(e, "friday")}
            />
          </Form.Group>
        </div>
        <div className="location-open-hours-form-cell">
          <p className="p1 mb>-0">
            <Trans>saturday</Trans>
          </p>
          <div className="d-flex align-items-center gap-1 align-self-stretch">
            <Form.Group>
              <DatePicker
                id="saturday-start-time"
                selected={locationOpenHours.saturday.startTime}
                onChange={(date) => setField("saturday", "startTime", date)}
                showTimeSelect
                showTimeSelectOnly
                minTime={minStartTime}
                maxTime={maxEndTime}
                timeIntervals={step}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                onChangeRaw={(e) => e.preventDefault()}
                className="form-control"
                disabled={locationOpenHours.saturday.dayOff}
              />
            </Form.Group>
            <span>-</span>
            <Form.Group>
              <DatePicker
                id="saturday-end-time"
                selected={locationOpenHours.saturday.endTime}
                onChange={(date) => setField("saturday", "endTime", date)}
                showTimeSelect
                showTimeSelectOnly
                minTime={minStartTime}
                maxTime={maxEndTime}
                timeIntervals={step}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                onChangeRaw={(e) => e.preventDefault()}
                className="form-control"
                disabled={locationOpenHours.saturday.dayOff}
              />
            </Form.Group>
          </div>
          <Form.Group>
            <Form.Check
              key="6"
              label={t("day_off")}
              type="checkbox"
              id={`la-checkbox-${6}`}
              name={`la-checkbox-${6}`}
              checked={locationOpenHours.saturday.dayOff}
              onChange={(e) => handleChange(e, "saturday")}
            />
          </Form.Group>
        </div>
        <div className="location-open-hours-form-cell">
          <p className="p1 mb-0">
            <Trans>sunday</Trans>
          </p>
          <div className="d-flex align-items-center gap-1 align-self-stretch">
            <Form.Group>
              <DatePicker
                id="sunday-start-time"
                selected={locationOpenHours.sunday.startTime}
                onChange={(date) => setField("sunday", "startTime", date)}
                showTimeSelect
                showTimeSelectOnly
                minTime={minStartTime}
                maxTime={maxEndTime}
                timeIntervals={step}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                onChangeRaw={(e) => e.preventDefault()}
                className="form-control"
                disabled={locationOpenHours.sunday.dayOff}
              />
            </Form.Group>
            <span>-</span>
            <Form.Group>
              <DatePicker
                id="sunday-end-time"
                selected={locationOpenHours.sunday.endTime}
                onChange={(date) => setField("sunday", "endTime", date)}
                showTimeSelect
                showTimeSelectOnly
                minTime={minStartTime}
                maxTime={maxEndTime}
                timeIntervals={step}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                onChangeRaw={(e) => e.preventDefault()}
                className="form-control"
                disabled={locationOpenHours.sunday.dayOff}
              />
            </Form.Group>
          </div>
          <Form.Group>
            <Form.Check
              key="7"
              label={t("day_off")}
              type="checkbox"
              id={`la-checkbox-${7}`}
              name={`la-checkbox-${7}`}
              checked={locationOpenHours.sunday.dayOff}
              onChange={(e) => handleChange(e, "sunday")}
            />
          </Form.Group>
        </div>
        <div className="location-open-hours-form-cell">
          {/* An empty div to fill up the grid */}
        </div>
      </div>
    </>
  );
};

LocationOpenHoursForm.propTypes = {
  locationOpenHours: PropTypes.object.isRequired,
  setField: PropTypes.func.isRequired,
  minStartTime: PropTypes.instanceOf(Date).isRequired,
  maxEndTime: PropTypes.instanceOf(Date).isRequired,
  step: PropTypes.number.isRequired,
};

export default LocationOpenHoursForm;
