import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import AppContext from "context/Context";
import LanguageSwitcher from "component/common/LanguageSwitcher";
import { useTranslation } from "react-i18next";

const LandingRightSideNavItem = () => {
  const {
    config: { isDark, isRTL },
    setConfig,
  } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Nav navbar className="ms-auto">
      <Nav.Item as={"li"}>
        <Nav.Link
          className="pe-2 theme-switch-toggle"
          onClick={() => setConfig("isDark", !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? "bottom" : "left"}
            overlay={
              <Tooltip id="ThemeColor" className="d-none d-lg-block">
                {isDark ? "Switch to light theme" : "Switch to dark theme"}
              </Tooltip>
            }
          >
            <span>
              <div className="theme-switch-toggle-label">
                <FontAwesomeIcon
                  icon={isDark ? "sun" : "moon"}
                  className="me-2"
                />
              </div>
              <p className="d-lg-none mb-0">
                {isDark ? "Switch to light theme " : "Switch to dark theme"}
              </p>
            </span>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item as={"li"}><LanguageSwitcher /></Nav.Item>

      <Nav.Item>
        <Nav.Link as={Link} to="/signin/partner">
          {t('sign_in')}
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link as={Link} to="/register/partner">
          {t('sign_up')}
        </Nav.Link>
      </Nav.Item>
    </Nav >
  );
};

export default LandingRightSideNavItem;
