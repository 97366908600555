import React from "react";
import { Card, Table } from "react-bootstrap";
import { Trans } from "react-i18next";
import Avatar from "component/common/Avatar";
import team2 from "assets/img/team/2.jpg";
import Cookies from "js-cookie";

const FinalEncashmentDataBlock = ({
  finalData,
  sumAtTheStartOfDay,
  selectedLocation,
}) => {

  const currentUser = Cookies.get("zymran_user") ? JSON.parse(Cookies.get("zymran_user")) : null;

  return (
    <Card className="mb-3">
      <Card.Header className="plain-card-header">
        <div className="d-flex justify-content-center">
          <h3 className="mb-0">
            <Trans>encashment</Trans>
          </h3>
        </div>
      </Card.Header>
      <Card.Body>
        <div>
          <div className="d-flex justify-content-between align-items-start align-self-stretch mb-4">
            <Table
              borderless
              size="sm"
              className="p1 primary-text-color w-auto"
            >
              <tbody>
                <tr>
                  <th className="p1-bold secondary-text-color">
                    <Trans>date</Trans>
                  </th>
                  <td>{new Date().toLocaleDateString()}</td>
                </tr>
                <tr>
                  <th className="p1-bold secondary-text-color">
                    <Trans>location</Trans>
                  </th>
                  <td>{selectedLocation.name}</td>
                </tr>
                <tr>
                  <th className="p1-bold secondary-text-color">
                    <Trans>basis</Trans>
                  </th>
                  <td>
                    <Trans>encashment</Trans>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="d-flex flex-column align-items-start align-self-stretch gap-3">
              <div className="finances-issued-by-block">
                <p className="p1-bold mb-0">
                  <Trans>encashed_by</Trans>
                </p>
                <div className="d-flex flex-row align-items-center">
                  <div className="d-flex">
                    <Avatar src={team2} size="xl" className="me-3" />
                  </div>
                  <div className="d-flex flex-column align-items-start text-start">
                    <p className="p2-bold mb-0 text-blue">
                      {currentUser.fullName}
                    </p>
                    <p className="p3 mb-0 secondary-text-color">
                      {currentUser.position}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-rounded-wrapper mb-3">
            <Table
              borderless
              size="sm"
              className="jazu-list-table p2 primary-text-color w-100 mb-0"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <Trans>cash_register</Trans>
                  </th>
                  <th>
                    <Trans>encashed</Trans>
                  </th>
                  <th>
                    <Trans>remainder</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                {finalData.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <Trans>{`cash_register_type_${data.cashRegisterName}`}</Trans>
                    </td>
                    <td>{data.inputSum}</td>
                    <td>{data.atTheStartOfDay}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <p className="p1">
            <Trans>remainder_at_start_of_day</Trans> {sumAtTheStartOfDay}
          </p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default FinalEncashmentDataBlock;
