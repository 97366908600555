import React, { useEffect, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import {
  encashLocationStep1,
  encashLocationStep2,
} from "service/encashment/EncashmentService";
import { isNumericInput } from "util/validation";
import FinalEncashmentDataBlock from "./FinalEncashmentDataBlock";
import Cookies from "js-cookie";

const LocationEncashmentProcessing = ({
  selectedLocation,
  handleClose,
  onSuccessfulEncashment,
}) => {
  const currentUser = Cookies.get("zymran_user") ? JSON.parse(Cookies.get("zymran_user")) : null;
  const [locationEncashmentData, setLocationEncashmentData] = useState([]);
  const [isFinalized, setIsFinalized] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    loadEncashmentByLocationData();
    // eslint-disable-next-line
  }, []);

  const loadEncashmentByLocationData = async () => {
    try {
      const payload = {
        locationId: selectedLocation.id,
      };
      const response = await encashLocationStep1(payload);
      const dataWithInputs = response.data.balances.map((item) => ({
        ...item,
        inputSum: "",
        isChecked: false,
      }));
      setLocationEncashmentData(dataWithInputs);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSumInputChange = (index, value) => {
    if (isNumericInput(value) || value === "") {
      const updatedData = [...locationEncashmentData];
      updatedData[index].inputSum = value;
      setLocationEncashmentData(updatedData);

      // Clear error message if input is valid
      if (errors[index]) {
        const newErrors = { ...errors };
        delete newErrors[index];
        setErrors(newErrors);
      }
    }
  };

  const handleCheckboxChange = (index, isChecked) => {
    const newData = [...locationEncashmentData];
    newData[index].isChecked = isChecked;
    if (isChecked) {
      newData[index].inputSum = newData[index].balance;
    }
    setLocationEncashmentData(newData);
  };

  const findFormErrors = () => {
    const newErrors = {};

    if (!Object.values(locationEncashmentData).some((data) => data.inputSum)) {
      locationEncashmentData.forEach((data, index) => {
        newErrors[index] = t("at_least_one_encashment_sum_required");
      });
    }

    return newErrors;
  };

  const finalizeData = () => {
    const errors = findFormErrors();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setFinalData(
      locationEncashmentData.map((data) => ({
        ...data,
        atTheStartOfDay: Math.max(0, data.balance - (data.inputSum || 0)),
      }))
    );
    setIsFinalized(true);
  };

  const handleEdit = () => {
    setIsFinalized(false);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSend = async () => {
    try {
      const cashRegisterSums = finalData.reduce((acc, curr) => {
        if (curr.inputSum !== null && curr.inputSum !== "") {
          acc[curr.cashRegisterId] = curr.inputSum;
        }
        return acc;
      }, {});

      const payload = {
        locationId: selectedLocation.id,
        cashRegisterSums: cashRegisterSums,
      };
      const response = await encashLocationStep2(payload);
      onSuccessfulEncashment(response.data);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const sumAtTheStartOfDay = finalData.reduce(
    (acc, curr) => acc + curr.atTheStartOfDay,
    0
  );

  return (
    <div className="container central-content-container">
      {!isFinalized ? (
        <Card className="mb-3">
          <Card.Header className="plain-card-header">
            <div className="d-flex justify-content-center">
              <h3 className="mb-0">
                <Trans>encashment</Trans>. {selectedLocation.name}
              </h3>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="d-flex justify-content-center">
              <Table borderless>
                <thead>
                  <tr>
                    <th>
                      <Trans>cash_register</Trans>
                    </th>
                    <th>
                      <Trans>balance</Trans>, ₸
                    </th>
                    <th>
                      <Trans>sum_for_encashment</Trans>, ₸
                    </th>
                    <th></th>
                    <th>
                      <Trans>at_the_start_of_day</Trans>, ₸
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {locationEncashmentData.map((data, index) => {
                    const atTheStartOfDay = Math.max(
                      0,
                      data.balance - (data.inputSum || 0)
                    );
                    return (
                      <tr key={index}>
                        <td className="align-middle">
                          <Trans>{`cash_register_type_${data.cashRegisterName}`}</Trans>
                        </td>
                        <td className="align-middle">{data.balance}</td>
                        <td className="align-middle">
                          <Form.Control
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            value={data.inputSum}
                            onChange={(e) =>
                              handleSumInputChange(index, e.target.value)
                            }
                            disabled={data.isChecked}
                          />
                          {errors[index] && (
                            <div className="text-danger">{errors[index]}</div>
                          )}
                        </td>
                        <td className="align-middle">
                          <Form.Check
                            type="checkbox"
                            id={`cashRegisterCheckbox-${index}`}
                            name={`cashRegisterCheckbox-${index}`}
                            label={t("encash_fully")}
                            checked={data.isChecked}
                            onChange={(e) =>
                              handleCheckboxChange(index, e.target.checked)
                            }
                          />
                        </td>
                        <td className="text-end align-middle">
                          {atTheStartOfDay}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <FinalEncashmentDataBlock
          selectedLocation={selectedLocation}
          currentUser={currentUser}
          finalData={finalData}
          sumAtTheStartOfDay={sumAtTheStartOfDay}
        />
      )}
      {!isFinalized && (
        <Button variant="blue" onClick={finalizeData}>
          <Trans>next</Trans>
        </Button>
      )}
      {isFinalized && (
        <div className="d-flex justify-content-between">
          <Button variant="grey" onClick={handleEdit}>
            <Trans>edit</Trans>
          </Button>
          <div className="d-flex gap-3">
            <Button variant="grey" onClick={handleCancel}>
              <Trans>cancel</Trans>
            </Button>
            <Button variant="blue" onClick={handleSend}>
              <Trans>complete_the_encashment</Trans>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationEncashmentProcessing;
