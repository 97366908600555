import React from 'react';
import { ReactComponent as ArrowLeftIcon } from "assets/img/icons/zymran-icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "assets/img/icons/zymran-icons/arrow-right.svg";
import { ReactComponent as BillCheckIcon } from "assets/img/icons/zymran-icons/bill-check.svg";
import { ReactComponent as BlockIcon } from "assets/img/icons/zymran-icons/block.svg";
import { ReactComponent as CalendarIcon } from "assets/img/icons/zymran-icons/calendar.svg";
import { ReactComponent as Calendar2Icon } from "assets/img/icons/zymran-icons/calendar-2.svg";
import { ReactComponent as CashierIcon } from "assets/img/icons/zymran-icons/cashier.svg";
import { ReactComponent as CheckIcon } from "assets/img/icons/zymran-icons/check.svg";
import { ReactComponent as ChevronDownIcon } from "assets/img/icons/zymran-icons/chevron-down.svg";
import { ReactComponent as ChevronUpIcon } from "assets/img/icons/zymran-icons/chevron-up.svg";
import { ReactComponent as ClientsIcon } from "assets/img/icons/zymran-icons/clients.svg";
import { ReactComponent as CloseIcon } from "assets/img/icons/zymran-icons/close.svg";
import { ReactComponent as DeleteIcon } from "assets/img/icons/zymran-icons/delete.svg";
import { ReactComponent as DotsIcon } from "assets/img/icons/zymran-icons/dots.svg";
import { ReactComponent as EmployeeIcon } from "assets/img/icons/zymran-icons/employee.svg";
import { ReactComponent as EmployeeIcon2 } from "assets/img/icons/zymran-icons/employee-2.svg";
import { ReactComponent as EncashmentIcon } from "assets/img/icons/zymran-icons/encashment.svg";
import { ReactComponent as FilterIcon } from "assets/img/icons/zymran-icons/filter.svg";
import { ReactComponent as FinanceIcon } from "assets/img/icons/zymran-icons/finance.svg";
import { ReactComponent as HomeIcon } from "assets/img/icons/zymran-icons/home.svg";
import { ReactComponent as IncomeIcon } from "assets/img/icons/zymran-icons/income.svg";
import { ReactComponent as InfoIcon } from "assets/img/icons/zymran-icons/info.svg";
import { ReactComponent as ManIcon } from "assets/img/icons/zymran-icons/man.svg";
import { ReactComponent as MapPointIcon } from "assets/img/icons/zymran-icons/map-point.svg";
import { ReactComponent as MoonIcon } from "assets/img/icons/zymran-icons/moon.svg";
import { ReactComponent as OutcomeIcon } from "assets/img/icons/zymran-icons/outcome.svg";
import { ReactComponent as PenIcon } from "assets/img/icons/zymran-icons/pen.svg";
import { ReactComponent as PercentIcon } from "assets/img/icons/zymran-icons/percent.svg";
import { ReactComponent as PieChart } from "assets/img/icons/zymran-icons/pie-chart.svg";
import { ReactComponent as Plus } from "assets/img/icons/zymran-icons/plus.svg";
import { ReactComponent as SalaryIcon } from "assets/img/icons/zymran-icons/salary.svg";
import { ReactComponent as SearchIcon } from "assets/img/icons/zymran-icons/search.svg";
import { ReactComponent as ServiceIcon } from "assets/img/icons/zymran-icons/service.svg";
import { ReactComponent as SettingsIcon } from "assets/img/icons/zymran-icons/solar_settings-broken.svg";
import { ReactComponent as SunIcon } from "assets/img/icons/zymran-icons/sun.svg";
import { ReactComponent as TengeIcon } from "assets/img/icons/zymran-icons/tenge.svg";
import { ReactComponent as WomanIcon } from "assets/img/icons/zymran-icons/woman.svg";

const iconMap = {
  'arrow-left': ArrowLeftIcon,
  'arrow-right': ArrowRightIcon,
  'bill-check': BillCheckIcon,
  'block': BlockIcon,
  'calendar': CalendarIcon,
  'calendar-2': Calendar2Icon,
  'cashier': CashierIcon,
  'check': CheckIcon,
  'chevron-down': ChevronDownIcon,
  'chevron-up': ChevronUpIcon,
  'clients': ClientsIcon,
  'close': CloseIcon,
  'delete': DeleteIcon,
  'dots': DotsIcon,
  'employee': EmployeeIcon,
  'employee-2': EmployeeIcon2,
  'encashment': EncashmentIcon,
  'filter': FilterIcon,
  'finance': FinanceIcon,
  'home': HomeIcon,
  'income': IncomeIcon,
  'info': InfoIcon,
  'man': ManIcon,
  'map-point': MapPointIcon,
  'outcome': OutcomeIcon,
  'pen': PenIcon,
  'percent': PercentIcon,
  'pie-chart': PieChart,
  'plus': Plus,
  'moon': MoonIcon,
  'salary': SalaryIcon,
  'search': SearchIcon,
  'service': ServiceIcon,
  'settings': SettingsIcon,
  'sun': SunIcon,
  'tenge': TengeIcon,
  'woman': WomanIcon,
};

const MyIcon = ({ name, size = 16, className, strokeWidth = 4, color, ...props }) => {
  const IconComponent = iconMap[name];
  if (!IconComponent) {
    console.warn(`Icon "${name}" not found.`);
    return null;
  }

  const iconStyle = {
    width: size,
    height: 'auto',
    strokeWidth: strokeWidth,
    fill: color,
  };

  return <IconComponent className={className} style={iconStyle} {...props} />;
};

export default MyIcon;
