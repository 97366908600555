import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  registerPartnerStep1 as _registerPartnerStep1,
  registerPartnerStep2 as _registerPartnerStep2,
  registerPartnerStep3 as _registerPartnerStep3,
  resendOtp as _resendOtp,
} from "../../../service/auth/AuthService";

import { registerOAuth } from "../../../service/auth/AuthService";

import BlockUI from "../../../util/block-ui/block-ui";
import { notifySuccess } from "../../../util/notify";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { parsePhoneNumber } from "libphonenumber-js/core";
import metadata from "libphonenumber-js/metadata.min.json";
import RegistrationProgressBar from "./RegistrationProgressBar";

import { setHours, setMilliseconds, setMinutes, setSeconds } from "date-fns";

import { fromZonedTime } from "date-fns-tz";
import { HTTP_STATUS, REGISTRATION_STEPS } from "util/constants";

import { isEmail, isPhoneNumber } from "util/validation";

library.add(fas, fab);

const PartnerRegistration = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const defaultStartHour = 9;
  const defaultEndHour = 20;
  const [countryCode, setCountryCode] = useState("");
  const [showWarningModal, setShowWarningModal] = useState(false);

  const [registrationData, setRegistrationData] = useState({
    userInformation: {
      login: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      code: "",
    },

    locationWithBusinessTypes: {
      login: "",
      skipCreateLocation: false,
      locationEmail: "",
      name: "",
      phoneNumber: "",

      businessTypes: [],

      basicInformation: {
        name: "",
        phoneNumber: "",
        email: "",
      },

      address: {
        country: "",
        region: "",
        city: "",
        district: "",
        street: "",
        houseNumber: "",
        aptNumber: "",
        postcode: "",
        directionHint: "",
        note: "",
        coordinates: "",
        timezone: "",
      },
      openHours: {
        monday: {
          startTime: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            defaultStartHour
          ),
          endTime: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            defaultEndHour
          ),
          dayOff: false,
        },
        tuesday: {
          startTime: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            defaultStartHour
          ),
          endTime: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            defaultEndHour
          ),
          dayOff: false,
        },
        wednesday: {
          startTime: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            defaultStartHour
          ),
          endTime: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            defaultEndHour
          ),
          dayOff: false,
        },
        thursday: {
          startTime: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            defaultStartHour
          ),
          endTime: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            defaultEndHour
          ),
          dayOff: false,
        },
        friday: {
          startTime: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            defaultStartHour
          ),
          endTime: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            defaultEndHour
          ),
          dayOff: false,
        },
        saturday: {
          startTime: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            defaultStartHour
          ),
          endTime: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            defaultEndHour
          ),
          dayOff: false,
        },
        sunday: {
          startTime: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            defaultStartHour
          ),
          endTime: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            defaultEndHour
          ),
          dayOff: true,
        },
      },
    },
  });

  const [errors, setErrors] = useState({
    userInformation: {},
    locationWithBusinessTypes: {
      address: {},
      basicInformation: {},
    },
  });

  const [blocking, setBlocking] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();


  const currentUser = Cookies.get("zymran_user") ? JSON.parse(Cookies.get("zymran_user")) : null;

  useEffect(() => {
    if (Cookies.get("zymran_oauth_user")) {
      const oAuthUser = JSON.parse(Cookies.get("zymran_oauth_user"));
      if (oAuthUser && oAuthUser.loginVerified && !oAuthUser.businessTypesCreated && !oAuthUser.registrationFinished) {
        setCurrentStep(REGISTRATION_STEPS.STEP3);
      }
    }
  }, []);

  const registerPartnerStep1 = async () => {
    const newErrors = findUserLoginFormErrors();

    if (Object.keys(newErrors).length > 0) {
      // We've got errors!
      setErrors({
        ...errors,
        userInformation: newErrors,
      });
    } else {
      try {
        setBlocking(true);
        const payload = {
          login: registrationData.userInformation.login,
          password: registrationData.userInformation.password,
          passwordConfirm: registrationData.userInformation.confirmPassword,
        };
        const response = await _registerPartnerStep1(payload);

        const isValiedEmail = isEmail(registrationData.userInformation.login);
        const isValidPhoneNumber = isPhoneNumber(registrationData.userInformation.login);
        if (isValiedEmail) {
          notifySuccess(t("email_verification_instruction"));
        } else if (isValidPhoneNumber) {
          notifySuccess(t("phone_verification_instruction"));
        }

        if (response.status === HTTP_STATUS.OK) {
          setField("locationWithBusinessTypes", "login", response.data.login);
          if (!response.data.loginVerified) {
            // setField("locationWithBusinessTypes", "login", response.data.login);
            setCurrentStep(REGISTRATION_STEPS.STEP2);
          } else if (
            response.data.loginVerified &&
            !response.data.businessTypesCreated
          ) {
            setCurrentStep(REGISTRATION_STEPS.STEP3);
          } else if (
            response.data.loginVerified &&
            response.data.businessTypesCreated
          ) {
            setCurrentStep(REGISTRATION_STEPS.STEP4);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setBlocking(false);
      }
    }
  };

  const resendOtp = async (e) => {
    try {
      setBlocking(true);
      const payload = {
        login:
          registrationData.userInformation.email ||
          registrationData.userInformation.phoneNumber,
      };
      await _resendOtp(payload);
      registrationData.userInformation.email
        ? notifySuccess(t("email_verification_instruction"))
        : notifySuccess(t("phone_verification_instruction"));
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const registerPartnerStep2 = async (e) => {
    const newErrors = findUserCodeFormErrors();

    if (Object.keys(newErrors).length > 0) {
      // We've got errors!
      setErrors({
        ...errors,
        userInformation: newErrors,
      });
    } else {
      setBlocking(true);

      let payload = {};
      if (
        registrationData.userInformation.phoneNumber &&
        registrationData.userInformation.phoneNumber !== ""
      ) {
        payload = {
          login: registrationData.userInformation.phoneNumber,
          code: registrationData.userInformation.code,
          password: registrationData.userInformation.password,
        };
      } else if (
        registrationData.userInformation.email &&
        registrationData.userInformation.email !== ""
      ) {
        payload = {
          login: registrationData.userInformation.email,
          code: registrationData.userInformation.code,
          password: registrationData.userInformation.password,
        };
      }

      try {
        await _registerPartnerStep2(payload);
        setCurrentStep(REGISTRATION_STEPS.STEP3);
      } catch (error) {
        console.error(error);
      } finally {
        setBlocking(false);
      }
    }
  };

  const registerPartnerStep3 = async () => {
    setCurrentStep(REGISTRATION_STEPS.STEP4);
    return;
  };

  const registerPartnerStep4 = async () => {
    let newErrors = {};
    if (!registrationData.locationWithBusinessTypes.skipCreateLocation) {
      newErrors = findLocationWithBusinessTypesFormErrors();
    }

    const topLevelErrorKeys = Object.keys(newErrors).filter(
      (key) => typeof newErrors[key] !== "object"
    );
    if (
      !registrationData.locationWithBusinessTypes.skipCreateLocation &&
      (topLevelErrorKeys.length > 0 ||
        Object.keys(newErrors.address).length > 0)
    ) {
      // We've got errors!
      setErrors({
        ...errors,
        locationWithBusinessTypes: newErrors,
      });
    } else {
      let response = {};
      setBlocking(true);
      try {
        let payload = {};
        if (!registrationData.locationWithBusinessTypes.skipCreateLocation) {
          payload.location = {};
          payload.location.name =
            registrationData.locationWithBusinessTypes.name;
          payload.location.email =
            registrationData.locationWithBusinessTypes.locationEmail;
          payload.location.phoneNumber =
            registrationData.locationWithBusinessTypes.phoneNumber;
          payload.location.address =
            registrationData.locationWithBusinessTypes.address;

          const locationOpenHours = [];

          for (const [key, value] of Object.entries(
            registrationData.locationWithBusinessTypes.openHours
          )) {
            locationOpenHours.push({
              dayOfWeek: key,
              startTime: fromZonedTime(
                value.startTime,
                registrationData.locationWithBusinessTypes.address.timezone
              ),
              endTime: fromZonedTime(
                value.endTime,
                registrationData.locationWithBusinessTypes.address.timezone
              ),
              dayOff: value.dayOff,
            });
          }

          payload.location.openHours = locationOpenHours;
          payload.skipCreateLocation =
            registrationData.locationWithBusinessTypes.skipCreateLocation;
          payload.businessTypes =
            registrationData.locationWithBusinessTypes.businessTypes;
          payload.login = registrationData.locationWithBusinessTypes.login;
        } else {
          payload.skipCreateLocation = registrationData.locationWithBusinessTypes.skipCreateLocation;
          payload.login = registrationData.locationWithBusinessTypes.login;
          payload.businessTypes =
            registrationData.locationWithBusinessTypes.businessTypes;
        }
        if (Cookies.get("zymran_oauth_user")) {
          const oAuthUser = JSON.parse(Cookies.get("zymran_oauth_user"));
          payload.login = oAuthUser.login;
        }

        response = await _registerPartnerStep3(payload);
        notifySuccess(t("registration_completed"));

        const backendResponse = response.data.authResponse;

        if (
          backendResponse.loginVerified &&
          backendResponse.registrationFinished
        ) {
          Cookies.set("zymran_user", JSON.stringify(backendResponse), {
            expires: 7,
            sameSite: "Lax",
          });
          navigate("/profile");
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setBlocking(false);
        Cookies.remove("zymran_oauth_user");
      }
    }
  };

  const nextStep = () => {
    if (currentStep === REGISTRATION_STEPS.STEP2) {
      registerPartnerStep2();
    } else if (currentStep === REGISTRATION_STEPS.STEP3) {
      registerPartnerStep3();
    }
  };

  const previousStep = () => {
    let step = currentStep;
    // If the current step is 2, 3 or 4, then subtract one on "previous" button click
    step =
      currentStep <= REGISTRATION_STEPS.STEP1
        ? REGISTRATION_STEPS.STEP1
        : currentStep - 1;
    setCurrentStep(step);
  };

  // The "next" and "previous" button functions
  const getPreviousButton = () => {
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <div className="registration-button-wrapper">
          <Button
            name="backButton"
            variant="blue-light"
            className="w-100"
            onClick={previousStep}
          >
            <Trans>back</Trans>
          </Button>
        </div>
      );
    }
    // ...else return nothing
    return null;
  };

  const getNextButton = () => {
    // If the current step is not 3, then render the "next" button
    if (currentStep < REGISTRATION_STEPS.STEP4) {
      return (
        <>
          {currentStep === REGISTRATION_STEPS.STEP1 ? (
            <Col sm={6} className="pe-sm-1"></Col>
          ) : (
            ""
          )}
          <div className="registration-button-wrapper">
            <Button
              name="continueButton"
              className="w-100"
              onClick={nextStep}
            >
              <Trans>continue</Trans>
            </Button>
          </div>
        </>
      );
    }
    // ...else render nothing
    return null;
  };

  const getSubmitButton = () => {
    // If the current step is the last step, then render the "submit" button
    if (currentStep > REGISTRATION_STEPS.STEP3) {
      return (
        <div className="registration-button-wrapper">
          <Button
            name="submitButton"
            className="w-100"
            onClick={registerPartnerStep4}
          >
            <Trans>
              continue
            </Trans>
          </Button>
        </div>
      );
    }
    // ...else render nothing
    return null;
  };

  const handleGoogleOAuthResponse = async (response) => {
    try {
      setBlocking(true);
      const oAuthResponse = await registerOAuth(response.credential, "google", "partner");
      const status = oAuthResponse.status;
      const backendResponse = oAuthResponse.data;

      if (
        status === HTTP_STATUS.OK &&
        backendResponse.loginVerified &&
        !backendResponse.businessTypesCreated &&
        !backendResponse.registrationFinished
      ) {
        setField("userInformation", "email", backendResponse.login);
        setField("locationWithBusinessTypes", "login", backendResponse.login);
        setCurrentStep(REGISTRATION_STEPS.STEP3);
      } else if (
        status === HTTP_STATUS.OK &&
        backendResponse.loginVerified &&
        backendResponse.businessTypesCreated &&
        !backendResponse.registrationFinished
      ) {
        setField("userInformation", "email", oAuthResponse.data.email);
        setField("locationWithBusinessTypes", "login", backendResponse.login);
        setCurrentStep(REGISTRATION_STEPS.STEP4);
      } else if (
        status === HTTP_STATUS.OK &&
        backendResponse.loginVerified &&
        backendResponse.businessTypesCreated &&
        backendResponse.registrationFinished
      ) {
        Cookies.set("zymran_user", JSON.stringify(backendResponse), {
          expires: 7,
          sameSite: "Lax",
        });
        navigate("/profile");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const setField = (parentField, field, value) => {
    setRegistrationData((prevState) => ({
      ...prevState,
      [parentField]: {
        ...prevState[parentField],
        [field]: value,
      },
    }));

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[parentField][field])
      setErrors((prevErrors) => ({
        ...prevErrors,
        [parentField]: {
          ...prevErrors[parentField],
          [field]: null,
        },
      }));
  };

  const setLocationField = (parentField, field, value) => {
    setRegistrationData({
      ...registrationData,
      locationWithBusinessTypes: {
        ...registrationData["locationWithBusinessTypes"],
        [parentField]: {
          ...registrationData["locationWithBusinessTypes"][parentField],
          [field]: value,
        },
      },
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors["locationWithBusinessTypes"][parentField][field])
      setErrors({
        ...errors,
        locationWithBusinessTypes: {
          ...errors["locationWithBusinessTypes"],
          [parentField]: {
            ...errors["locationWithBusinessTypes"][parentField],
            [field]: null,
          },
        },
      });
  };

  const setLocationAddress = (locationAddress) => {
    setRegistrationData({
      ...registrationData,
      locationWithBusinessTypes: {
        ...registrationData["locationWithBusinessTypes"],
        address: {
          ...registrationData["locationWithBusinessTypes"]["address"],
          country: locationAddress.country ? locationAddress.country : "",
          region: locationAddress.region ? locationAddress.region : "",
          city: locationAddress.city ? locationAddress.city : "",
          district: locationAddress.district ? locationAddress.district : "",
          street: locationAddress.street ? locationAddress.street : "",
          houseNumber: locationAddress.houseNumber
            ? locationAddress.houseNumber
            : "",
          aptNumber: locationAddress.aptNumber ? locationAddress.aptNumber : "",
          postcode: locationAddress.postcode ? locationAddress.postcode : "",
          directionHint: locationAddress.directionHint
            ? locationAddress.directionHint
            : "",
          note: locationAddress.note ? locationAddress.note : "",
          coordinates: locationAddress.coordinates
            ? locationAddress.coordinates
            : "",
          timezone: locationAddress.timezone ? locationAddress.timezone : "",
        },
      },
    });
  };

  const findUserLoginFormErrors = () => {
    const { login, password, confirmPassword } =
      registrationData.userInformation;

    const newErrors = {};

    if (!login || login === "") {
      newErrors.login = t("field_required");
    } else {
      const isValidEmail = isEmail(login);
      const isValidPhoneNumber = isPhoneNumber(login);

      if (isValidEmail && login.length > 255) newErrors.login = t("too_long");

      if (isValidPhoneNumber) {
        if (!login.startsWith("+7")) {
          newErrors.login = t("not_available_outside_of_kz");
          setShowWarningModal(true);
        } else if (login.length > 12) newErrors.login = t("too_long");
        else {
          try {
            const phoneNumberObject = parsePhoneNumber(login, metadata);
            if (!phoneNumberObject.isValid())
              newErrors.login = t("invalid_phone_number");
          } catch (error) {
            newErrors.login = t("invalid_phone_number");
          }
        }
      }

      if (isValidEmail) {
        setField("userInformation", "email", login);
      } else if (isValidPhoneNumber) {
        setField("userInformation", "phoneNumber", login);
      }

      if (!isValidEmail && !isValidPhoneNumber) {
        newErrors.login = t("invalid_login");
      }
    }

    if (!password || password === "") newErrors.password = t("field_required");
    else if (password.length > 32) newErrors.password = t("too_long");

    if (!confirmPassword || confirmPassword === "")
      newErrors.confirmPassword = t("field_required");
    else if (confirmPassword.length > 32)
      newErrors.confirmPassword = t("too_long");

    if (password !== confirmPassword)
      newErrors.confirmPassword = t("repeat_password_does_not_match");

    return newErrors;
  };

  const findUserCodeFormErrors = () => {
    const { code } = registrationData.userInformation;

    const newErrors = {};

    if (!code || code === "") newErrors.code = t("field_required");
    else if (code.length !== 4) newErrors.code = t("wrong_length");

    return newErrors;
  };

  const findLocationWithBusinessTypesFormErrors = () => {
    const {
      name,
      locationEmail,
      phoneNumber,
      address: {
        country,
        region,
        city,
        district,
        street,
        houseNumber,
        aptNumber,
        postcode,
        directionHint,
        note,
      },
    } = registrationData.locationWithBusinessTypes;

    const newErrors = {};
    newErrors.address = {};
    newErrors.basicInformation = {};

    if (!name || name === "") newErrors.name = t("field_required");
    else if (name.length > 64) newErrors.name = t("too_long64");

    if (!phoneNumber || phoneNumber === "")
      newErrors.phoneNumber = t("field_required");
    else if (phoneNumber.length < 11 || phoneNumber.length > 12)
      newErrors.phoneNumber = t("invalidInput");
    else {
      try {
        const phoneNumberObject = parsePhoneNumber(phoneNumber, metadata);
        if (!phoneNumberObject.isValid())
          newErrors.phoneNumber = t("invalid_phone_number");
      } catch (error) {
        newErrors.phoneNumber = t("invalid_phone_number");
      }
    }

    if (locationEmail.length > 64) newErrors.locationEmail = t("too_long64");

    if (!country || country === "")
      newErrors.address.country = t("field_required");
    else if (country.length > 128)
      newErrors.address.country = t("too_long_128");

    if (region.length > 128) newErrors.region = t("too_long_128");

    if (city.length > 128) newErrors.address.city = t("too_long_128");

    if (district.length > 128) newErrors.district = t("too_long_128");

    if (!street || street === "")
      newErrors.address.street = t("field_required");
    else if (street.length > 128) newErrors.address.street = t("too_long_128");

    if (!houseNumber || houseNumber === "")
      newErrors.address.houseNumber = t("field_required");
    else if (houseNumber.length > 16)
      newErrors.address.houseNumber = t("too_long_16");

    if (aptNumber.length > 16) newErrors.address.aptNumber = t("too_long_16");

    if (postcode.length > 16) newErrors.address.postcode = t("too_long_16");

    if (note.length > 1024) newErrors.address.note = t("too_long_1024");

    if (directionHint.length > 128)
      newErrors.address.directionHint = t("too_long_128");

    // if ( coordinates.length > 256 ) newErrors.address.coordinates = t('tooLong256');

    return newErrors;
  };

  if (
    currentUser &&
    !currentUser.registrationPassed &&
    currentStep === REGISTRATION_STEPS.STEP1
  ) {
    setField("userInformation", "email", currentUser.login);
    setCurrentStep(REGISTRATION_STEPS.STEP2);
  }

  return (
    <>
      <h1 className="mb-0">
        <Trans>partner_registration</Trans>
      </h1>
      <RegistrationProgressBar currentStep={currentStep} maxSteps={4} />
      <Form>
        <Step1
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          errors={errors.userInformation}
          setField={setField}
          userInformation={registrationData.userInformation}
          handleGoogleOAuthResponse={handleGoogleOAuthResponse}
          setCountryCode={setCountryCode}
          registerPartnerStep1={registerPartnerStep1}
        />
        <Step2
          resendOtp={resendOtp}
          currentStep={currentStep}
          errors={errors.userInformation}
          setField={setField}
          userInformation={registrationData.userInformation}
          setCountryCode={setCountryCode}
        />
        <Step3
          currentStep={currentStep}
          setField={setField}
        />
        <Step4
          currentStep={currentStep}
          errors={errors.locationWithBusinessTypes}
          laErrors={errors.locationWithBusinessTypes.address}
          locationAddress={registrationData.locationWithBusinessTypes.address}
          setLocationAddressField={setLocationField}
          setLocationAddress={setLocationAddress}
          location={registrationData.locationWithBusinessTypes}
          setField={setField}
          t={t}
        />
        {currentStep !== REGISTRATION_STEPS.STEP1 && (
          <>
            <div className="registration-step-buttons">
              {getPreviousButton()}
              {getNextButton()}
              {getSubmitButton()}
            </div>
          </>
        )}
        <BlockUI tag="div" blocking={blocking} />
      </Form>
      <Modal
        show={showWarningModal}
        onHide={() => setShowWarningModal(false)}
        fullscreen={false}
        centered
        className="dialog-window-modal"
        style={{ backdropFilter: "blur(8px)", zIndex: "9999" }}
      >
        <Modal.Header closeButton closeVariant="white" className="bg-primary">
          <Modal.Title className="text-white light">
            <Trans>important</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <div className="d-flex flex-column">
            <div className="mb-3">
              <Trans>not_available_outside_of_kz_full_message</Trans>{" "}
              igor@zymran.com
            </div>
            <Button
              name="confirmButton"
              variant="blue"
              size="sm"
              onClick={() => setShowWarningModal(false)}
            >
              <Trans>confirm</Trans>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PartnerRegistration;
