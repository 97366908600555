import loadingAnimation from "assets/img/animated-icons/loading.json";
import AdvanceTable from "component/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "component/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "component/common/advance-table/AdvanceTableWrapper";
import ZymranIcon from "component/common/ZymranIcon";
import {
  endOfMonth,
  endOfWeek,
  format,
  isToday as isTodayDateFns,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";
import useLocale from "hooks/useLocale";
import Lottie from "lottie-react";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Modal,
  ToggleButton,
} from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { getExpenses } from "service/expense/ExpenseService";
import { getLocations } from "service/location/LocationService";
import AddExpense from "./AddExpense";
import ExpenseDetail from "./expense-detail/ExpenseDetail";

const ExpenseView = () => {
  const timezone = DateTime.local().zoneName;
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [showExpenseDetailModal, setShowExpenseDetailModal] = useState(false);
  const [showAddExpenseModal, setShowAddExpenseModal] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({
    value: "",
    label: "",
  });
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const { t } = useTranslation();
  const locale = useLocale();
  const columns = [
    {
      accessor: "code",
      Header: t("income_code_label"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { code } = rowData.row.original;
        return (
          <>
            <Link
              to="#"
              onClick={() => handleExpenseSelect(rowData.row.original)}
            >
              {code}
            </Link>
          </>
        );
      },
    },
    {
      accessor: "date",
      Header: t("date"),
      Cell: (rowData) => {
        const { date } = rowData.row.original;
        const zonedTime = toZonedTime(date, timezone);
        return <>{format(zonedTime, "dd MMM yyyy hh:mm", { locale })}</>;
      },
    },
    {
      accessor: "givenByUser",
      Header: t("income_order_processed_by"),
      Cell: (rowData) => {
        const { providedByUser } = rowData.row.original;
        return (
          <>
            {providedByUser
              ? `${providedByUser.firstName} ${providedByUser.lastName}`
              : null}
          </>
        );
      },
    },
    {
      accessor: "location",
      Header: t("location"),
      Cell: (rowData) => {
        const { location } = rowData.row.original;
        return <>{location.name}</>;
      },
    },
    {
      accessor: "reason",
      Header: t("income_return_reason"),
      Cell: (rowData) => {
        const { reason } = rowData.row.original;
        return <Trans>{reason}</Trans>;
      },
    },
    {
      accessor: "sum",
      Header: t("sum") + ", ₸",
      Cell: (rowData) => {
        const { sum } = rowData.row.original;
        return <>{sum}</>;
      },
    },
  ];

  const handleExpenseDetailModalClose = () => {
    setShowExpenseDetailModal(false);
  };

  useEffect(() => {
    loadLocations();
    loadExpenses();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let filteredExpensesByLocation = [];

    if (selectedLocation.value !== "" && selectedLocation.value !== 0) {
      filteredExpensesByLocation = expenses.filter(
        (expense) => expense.location.id === selectedLocation.value
      );
    } else {
      filteredExpensesByLocation = expenses;
    }

    let filteredExpensesByDate = [];

    switch (selectedDateFilter) {
      case 1: // today
        filteredExpensesByDate = filteredExpensesByLocation.filter((expense) =>
          isToday(expense.date)
        );
        break;
      case 2: // week
        filteredExpensesByDate = filteredExpensesByLocation.filter((expense) =>
          isWithinThisWeek(expense.date)
        );
        break;
      case 3: // current_month
        filteredExpensesByDate = filteredExpensesByLocation.filter((expense) =>
          isInCurrentMonth(expense.date)
        );
        break;
      default:
        filteredExpensesByDate = filteredExpensesByLocation;
        break;
    }
    setFilteredExpenses(filteredExpensesByDate);
    // eslint-disable-next-line
  }, [selectedLocation, expenses, selectedDateFilter]);

  function isToday(date) {
    const today = startOfDay(new Date());
    const comparedDate = startOfDay(toZonedTime(date, timezone));
    return isTodayDateFns(comparedDate, today);
  }

  function isWithinThisWeek(date) {
    const now = new Date();
    const startOfCurrentWeek = startOfWeek(now);
    const endOfCurrentWeek = endOfWeek(now);
    const comparedDate = startOfDay(toZonedTime(date, timezone));
    return isWithinInterval(comparedDate, {
      start: startOfCurrentWeek,
      end: endOfCurrentWeek,
    });
  }

  function isInCurrentMonth(date) {
    const now = new Date();
    const startOfCurrentMonth = startOfMonth(now);
    const endOfCurrentMonth = endOfMonth(now);
    const comparedDate = startOfDay(toZonedTime(date, timezone));
    return isWithinInterval(comparedDate, {
      start: startOfCurrentMonth,
      end: endOfCurrentMonth,
    });
  }

  const loadExpenses = async () => {
    try {
      setLoadingCounter(loadingCounter + 1);
      const response = await getExpenses();
      setExpenses(response.data);
      setFilteredExpenses(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCounter(loadingCounter - 1);
    }
  };

  const loadLocations = async () => {
    try {
      setLoadingCounter(loadingCounter + 1);
      const response = await getLocations();
      let locs = [];
      locs.push({ value: 0, label: t("select_all") });
      response.data.forEach((loc) => {
        const location = {
          value: loc.id,
          label:
            loc.name + " " + loc.address.street + " " + loc.address.houseNumber,
        };
        locs.push(location);
      });
      setSelectedLocation(locs[0]);
      setLocationOptions(locs);
    } catch (error) {
    } finally {
      setLoadingCounter(loadingCounter - 1);
    }
  };

  const handleExpenseSelect = (selectedExpense) => {
    setSelectedExpense(selectedExpense);
    setShowExpenseDetailModal(true);
  };

  const handleAddExpenseModalClose = () => {
    setShowAddExpenseModal(false);
  };

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      fontSize: ".8333333333rem",
      minWidth: "170px",
      maxWidth: "170px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: ".8333333333rem",
      lineHeight: "21px",
    }),
    menu: (base) => ({
      ...base,
      fontSize: ".8333333333rem",
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ZymranIcon name="chevron-down" className="secondary-text-color" />
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="mb-0">
          <Trans>expense_order</Trans>
        </h1>
        <Button
          variant="blue"
          name="addExpenseButton"
          size="sm"
          onClick={() => setShowAddExpenseModal(true)}
        >
          <ZymranIcon name="plus" />
          <Trans>add</Trans>
        </Button>
      </div>

      {loadingCounter > 0 ? (
        <Card className="mb-3 d-flex justify-content-center align-items-center h-100 p-5">
          <Card.Body>
            <Lottie
              animationData={loadingAnimation}
              loop={true}
              style={{ height: "220px", width: "220px" }}
            />
          </Card.Body>
        </Card>
      ) : (
        <AdvanceTableWrapper
          columns={columns}
          data={filteredExpenses}
          sortable
          pagination
          perPage={10}
        >
          <Card className="mb-3">
            <Card.Header>
              <div className="finance-filter-area">
                <div className="d-flex align-items-center gap-2">
                  <span className="p2">
                    <Trans>location</Trans>:
                  </span>
                  <Select
                    id="order-type"
                    name="order-type"
                    classNamePrefix="react-select"
                    className="react-select--sm finance-select"
                    placeholder={t("select_location")}
                    value={selectedLocation}
                    options={locationOptions}
                    required
                    onChange={(option) => setSelectedLocation(option)}
                    styles={customSelectStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
                <div className="d-flex align-items-start gap-2">
                  <ButtonGroup className="finance-filter-pills" size="sm">
                    <ToggleButton
                      id="radio-1"
                      type="radio"
                      variant="grey"
                      name="radio"
                      value={1}
                      checked={selectedDateFilter === 1}
                      onChange={() => setSelectedDateFilter(1)}
                    >
                      <Trans>today</Trans>
                    </ToggleButton>
                    <ToggleButton
                      id="radio-2"
                      type="radio"
                      variant="grey"
                      name="radio"
                      value={2}
                      checked={selectedDateFilter === 2}
                      onChange={() => setSelectedDateFilter(2)}
                    >
                      <Trans>week</Trans>
                    </ToggleButton>
                    <ToggleButton
                      id="radio-3"
                      type="radio"
                      variant="grey"
                      name="radio"
                      value={3}
                      checked={selectedDateFilter === 3}
                      onChange={() => setSelectedDateFilter(3)}
                    >
                      <Trans>current_month</Trans>
                    </ToggleButton>
                  </ButtonGroup>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="pt-2">
              <div className="table-rounded-wrapper">
                <AdvanceTable
                  table
                  headerClassName="p2-bold secondary-text-color text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: "sm",
                    className: "p2 mb-0 overflow-hidden jazu-list-table",
                  }}
                />
              </div>
            </Card.Body>
            <Card.Footer className="pt-0">
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      )}

      <Modal
        show={showExpenseDetailModal}
        onHide={handleExpenseDetailModalClose}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title>
            <Trans>income_detail</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExpenseDetail
            handleClose={handleExpenseDetailModalClose}
            selectedExpense={selectedExpense}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddExpenseModal}
        fullscreen={true}
        onHide={() => setShowAddExpenseModal(false)}
        aria-labelledby="add-expense-modal-title"
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title id="add-expense-modal-title">
            <Trans>add_expense_to_cash_register</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddExpense
            handleClose={handleAddExpenseModalClose}
            setExpenses={setExpenses}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExpenseView;
