import AuthCardLayout from "layouts/AuthCardLayout";
import SignInPartner from "./SigninPartner";

const SigninPartnerWrapper = () => {
  return (
    <AuthCardLayout isLoginPage={true}>
      <SignInPartner layout="split" hasLabel />
    </AuthCardLayout>
  );
};

export default SigninPartnerWrapper;
