import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavbarTop from "component/navbar/top/NavbarTop";
import NavbarVerticalZymran from "component/navbar/vertical/NavbarVerticalZymran";
import AppContext from "context/Context";
import Footer from "component/footer/Footer";
import classNames from "classnames";
import { Container } from "react-bootstrap";

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes("kanban");

  const {
    config: { isFluid, navbarPosition },
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    }, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? "container-fluid" : "container"}>
      {(navbarPosition === "vertical" || navbarPosition === "combo") && (
        <NavbarVerticalZymran />
      )}

      <div className={classNames("content", { "pb-0": isKanban })}>
        <NavbarTop />
        {/*------ Main Routes ------*/}
        <Container fluid="lg" className="central-content-container">
          <Outlet />
        </Container>
        {!isKanban && <Footer />}
      </div>
    </div>
  );
};

export default MainLayout;
