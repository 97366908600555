import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import classNames from 'classnames';
import { isIterableArray } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import ZymranIcon from './ZymranIcon';

const getSize = size => {
  if (size < 1024) {
    return (
      <>
        <strong>{size}</strong> Byte
      </>
    );
  } else if (size < 1024 * 1024) {
    return (
      <>
        <strong>{(size / 1024).toFixed(2)}</strong> KB
      </>
    );
  } else {
    return (
      <>
        <strong>{(size / (1024 * 1024)).toFixed(2)}</strong> MB
      </>
    );
  }
};

const FalconDropzone = ({
  placeholder,
  className,
  onChange,
  files,
  preview,
  isCircular = false,
  fallbackImage,
  ...rest
}) => {

  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (files && files.length > 0) {
      setImageSrc(files[0].url || files[0].base64);
    } else {
      setImageSrc(null);
    }
  }, [files]);

  const handleImageError = () => {
    setImageSrc(fallbackImage);
  };

  return (
    <>
      <Dropzone
        onDrop={acceptedFiles => {
          const stringFiles = [];
          if (acceptedFiles.length) {
            acceptedFiles.map(file => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                stringFiles.push({
                  // id: uuid(),
                  base64: reader.result,
                  size: file.size,
                  path: file.path,
                  type: file.type
                });
                onChange([...stringFiles]);
                setImageSrc(reader.result);
              };
              return true;
            });
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: classNames('dropzone-area avatar-preview-wrapper', className)
            })}
          >
            <input {...{ ...getInputProps(), ...rest }} />
            {placeholder}

            {preview && (
              <img
                src={imageSrc}
                alt=""
                className={`avatar-preview ${isCircular ? 'circular-avatar' : ''}`}
                onError={handleImageError}
              />
            )}
            <div className="staff-avatar-plus-button" style={{ zIndex: 2 }}>
              {(!files || files.length === 0) ? (
                <ZymranIcon name="plus" />
              ) : (
                <ZymranIcon name="pen" />
              )}
            </div>
          </div>
        )}
      </Dropzone>
      {/* {preview && isIterableArray(files) && (
      <div className="border-top mt-3">
        {files.map(({ id, path, base64, size }) => (
          <Flex
            alignItems="center"
            className="py-3 border-bottom btn-reveal-trigger"
            key={id}
          >
            <img className="img-fluid" width={38} src={base64} alt={path} />
            <Flex justifyContent="between" alignItems="center" className="ms-3">
              <div>
                <h6 data-dz-name="">{path}</h6>
                <Flex className="position-relative" align="center">
                  <p className="mb-0 fs--1 text-400 line-height-1">
                    {getSize(size)}
                  </p>
                </Flex>
              </div>
            </Flex>
          </Flex>
        ))}
      </div>
    )} */}
    </>
  )
}

FalconDropzone.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  files: PropTypes.array,
  preview: PropTypes.bool,
  isMulti: PropTypes.bool
};

FalconDropzone.defaultProps = {
  placeholder: <img src={cloudUpload} alt="" width={25} className="me-2" />,
  files: [],
  preview: false
};

export default FalconDropzone;
