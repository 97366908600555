import React, { useState } from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';

const EnhancedSelect = ({
  id,
  name,
  label,
  placeholder,
  options,
  onChange,
  customComponents,
  groupClassName = '',
  required,
  selectedValue,
  errors,
  disabled = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const renderPlaceholder = () => {
    return required ? `${placeholder}*` : placeholder;
  };

  return (
    <Form.Group className={`position-relative ${groupClassName}`}>
      {selectedValue && (
        <Form.Label
          className={`${errors ? 'is-invalid' : ''
            } ${isFocused ? 'is-focused' : ''}`}
        >
          {label}:
        </Form.Label>
      )}
      <Select
        id={id}
        name={name}
        className={`zymran-select ${errors ? 'is-invalid-react-select' : ''}`}
        classNamePrefix="react-select"
        placeholder={renderPlaceholder()}
        value={selectedValue}
        // defaultValue={preselectedValue}
        options={options}
        onChange={onChange}
        onMenuOpen={() => setIsFocused(true)}
        onMenuClose={() => setIsFocused(false)}
        components={customComponents}
        isDisabled={disabled}
      />
      <Form.Control.Feedback type='invalid'>{errors}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default EnhancedSelect;
