import React, { useState, useRef, useEffect } from "react";
import { signIn } from "../../service/auth/AuthService";
import { signInOAuth } from "../../service/auth/AuthService";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import BlockUI from "../../util/block-ui/block-ui";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "styles/css/main.css";
import { notifyError } from "../../util/notify";
import { GoogleLogin } from "@react-oauth/google";
import { roles } from "../../security/roles";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import Cookies from "js-cookie";

library.add(fas, fab);

const SignInPartner = () => {
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({ login: "", password: "" });
  const [errors, setErrors] = useState({});
  const [blocking, setBlocking] = useState(false);
  const currentUser = Cookies.get("zymran_user") ? JSON.parse(Cookies.get("zymran_user")) : null;
  const form = useRef();
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const getGoogleLocale = (languageCode) => {
    return languageCode.split('-')[0];
  };
  const googleLocale = getGoogleLocale(i18n.language); 

  useEffect(() => {
    if (currentUser != null) {
      if (
        currentUser.token != null &&
        currentUser.roles[0].name === roles.PARTNER &&
        currentUser.emailVerified &&
        currentUser.registrationPassed
      ) {
        setBlocking(false);
        navigate("/profile");
      } else if (currentUser.emailVerified && !currentUser.registrationPassed) {
        setBlocking(false);
        navigate("/register/partner");
      } else if (
        !currentUser.emailVerified &&
        !currentUser.registrationPassed
      ) {
        setBlocking(false);
        navigate("/signin/partner");
      }
    }
    // eslint-disable-next-line
  }, []);

  const findFormErrors = () => {
    const { login, password } = credentials;
    const newErrors = {};
    if (!login || login === "") newErrors.login = t("field_required");
    if (!password || password === "") newErrors.password = t("field_required");
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        setBlocking(true);
        const response = await signIn(credentials);
        if (response && response.data && response.data.token) {
          Cookies.set("zymran_user", JSON.stringify(response.data), {
            expires: 7,
            sameSite: "Lax",
          });
        }
        navigate("/profile");
      } catch (error) {
        console.error(error)
      } finally {
        setBlocking(false);
      }
    }
  };

  const handleGoogleOAuthResponse = async (response) => {
    setBlocking(true);
    try {
      const backendResponse = await signInOAuth(response.credential, "google", "partner");
      const oAuthResponse = backendResponse.data;
      if (oAuthResponse.loginVerified && oAuthResponse.registrationFinished) {
        Cookies.set("zymran_user", JSON.stringify(oAuthResponse), {
          expires: 7,
          sameSite: "Lax",
        });
        navigate("/profile");
      }
      else if (oAuthResponse.loginVerified && !oAuthResponse.registrationFinished) {
        Cookies.set("zymran_oauth_user", JSON.stringify(oAuthResponse), {
          expires: 1,
          sameSite: "Lax",
        });
        navigate("/register/partner");
      } else {
        notifyError("something_went_wrong");
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const setField = (field, value) => {
    setCredentials({
      ...credentials,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  return (
    <>
      <h1>
        <Trans>login</Trans>
      </h1>
      <Form onSubmit={handleSubmit} ref={form}>
        <EnhancedFormControl
          id="login"
          name="login"
          type="text"
          value={credentials.login}
          onChange={(e) => setField("login", e.target.value)}
          isInvalid={!!errors.login}
          placeholder={t("email_or_phone_number")}
          label={t("email_or_phone_number")}
          errors={errors.login}
          groupClassName="mb-3 w-100"
        />
        <EnhancedFormControl
          id="password"
          name="password"
          type="password"
          value={credentials.password}
          onChange={(e) => setField("password", e.target.value)}
          isInvalid={!!errors.password}
          placeholder={t("password")}
          label={t("password")}
          errors={errors.password}
          groupClassName="mb-2 w-100"
        />
        <div className="d-flex justify-content-end mb-3">
          <Link className="p2-bold mb-0" to={`/reset-password`}>
            <Trans>forgot_password_question</Trans>
          </Link>
        </div>

        <div className="d-flex justify-content-between align-items-center align-self-stretch">
          <Link
            className="p1-bold mb-0 text-decoration-none"
            to={`/register/partner`}
          >
            <Button name="signUpButton" className="btn-blue-light">
              <Trans>sign_up</Trans>
            </Button>
          </Link>
          <Button
            name="continueButton"
            type="submit"
            onClick={handleSubmit}
            className="btn-blue"
          >
            <Trans>continue</Trans>
          </Button>
        </div>
        <BlockUI tag="div" blocking={blocking} />
      </Form>
      <div className="w-100 position-relative text-center">
        <hr className="my-0" />
      </div>
      <div className="d-flex justify-content-center w-100">
        <GoogleLogin
          width="400px"
          logo_alignment="center"
          locale={googleLocale} 
          render={(renderProps) => (
            <button
              className="btn btn-outline-google-plus btn-sm d-block w-100"
              onClick={renderProps.onClick}
              style={{ borderRadius: "20px" }}
            >
              <FontAwesomeIcon
                icon={["fab", "google"]}
                transform="grow-8"
                className="me-2"
              />{" "}
              google
            </button>
          )}
          text={t("google_sign_in")}
          onSuccess={handleGoogleOAuthResponse}
          onFailure={handleGoogleOAuthResponse}
        />
      </div>
    </>
  );
};
export default SignInPartner;
