import EnhancedFormControl from "component/common/EnhancedFormControl";
import ZymranAvatar from "component/common/ZymranAvatar";
import ZymranIcon from "component/common/ZymranIcon";
import EditCustomer from "component/customer/EditCustomer";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import {
  getCountOfFinishedAppointments,
  getSumOfFinishedAppointments,
} from "service/customer/CustomerService";
import CustomerInfoAdditionalData from "./CustomerInfoAdditionalData";

const CustomerInfo = ({ customerData, getGenderIcon, updateCustomer }) => {
  const [showCustomerEditModal, setCustomerEditModalShow] = useState(false);
  const { t } = useTranslation();
  const [countOfFinishedAppointments, setCountOfFinishedAppointments] =
    useState(0);
  const [sumOfFinishedAppointments, setSumOfFinishedAppointments] = useState(0);
  const countOfCanceledAppointments = 0;

  useEffect(() => {
    loadNumberOfServicesProvided();
    loadSumOfServicesProvided();
    // eslint-disable-next-line
  }, []);

  const handleCustomerEditClose = () => {
    setCustomerEditModalShow(false);
  };


  const loadNumberOfServicesProvided = async () => {
    try {
      const response = await getCountOfFinishedAppointments(customerData.id);
      setCountOfFinishedAppointments(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const loadSumOfServicesProvided = async () => {
    try {
      const response = await getSumOfFinishedAppointments(customerData.id);
      setSumOfFinishedAppointments(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="d-flex align-items-start align-self-stretch border-bottom">
        <div className="staff-view-info-block">
          <ZymranAvatar
            avatarUrl={customerData.avatarUrl}
            fullName={`${customerData.firstName} ${customerData.lastName}`}
            size="xl"
            shape="circle"
          />
          <div className="staff-view-info-details">
            <div>
              <p className="p1-bold mb-0 primary-text-color">
                {customerData.firstName} {customerData.lastName}{" "}
                {getGenderIcon(customerData.genderId)}
              </p>
              <p className="p2 mb-0 secondary-text-color">
                <Trans>birth_date</Trans>:{" "}
                {moment(customerData.dob).format("DD/MM/YYYY")}
              </p>
            </div>
            <div>
              <a href={`mailto:` + customerData.email} className="p2">
                {customerData.email}
              </a>
              <p className="p2 mb-0 secondary-text-color">
                {customerData.phoneNumberPrimary}
              </p>
              <p className="p2 mb-0 secondary-text-color">
                {customerData.phoneNumberSecondary}
              </p>
            </div>
            <div>
              <p className="p2 mb-0 primary-text-color">{customerData.notes}</p>
            </div>
          </div>
        </div>
        <div className="p1 primary-text-color staff-view-locations-block">
          <div className="d-flex flex-column align-items-start gap-1">
            <span className="p3 secondary-text-color">
              <Trans>client_since</Trans>
            </span>
            <span className="p2-bold">
              {moment(customerData.createdAt).format("DD/MM/YYYY")}
            </span>
          </div>
          <div className="d-flex flex-column align-items-start gap-1">
            <span className="p3 secondary-text-color">
              <Trans>number_of_provided_services</Trans>
            </span>
            <span className="p2-bold">{countOfFinishedAppointments}</span>
          </div>
          <div className="d-flex flex-column align-items-start gap-1">
            <span className="p3 secondary-text-color">
              <Trans>sum_of_provided_services</Trans>
            </span>
            <span className="p2-bold">{sumOfFinishedAppointments}</span>
          </div>
          <div className="d-flex flex-column align-items-start gap-1">
            <span className="p3 secondary-text-color">
              <Trans>number_of_cancelled_appointments</Trans>
            </span>
            <span className="p2-bold">{countOfCanceledAppointments}</span>
          </div>
        </div>
      </div>

      {/* <CustomerInfoAdditionalData customerData={customerData} /> */}

      <Modal
        show={showCustomerEditModal}
        fullscreen={true}
        onHide={() => setCustomerEditModalShow(false)}
        aria-labelledby="customer-edit-modal-title"
      >
        <Modal.Header closeButton closeVariant="white" className="bg-primary">
          <Modal.Title
            id="customer-edit-modal-title"
            className="text-white light"
          >
            <Trans>editing_customer</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <EditCustomer
            customerId={customerData.id}
            customerData={customerData}
            onUpdateCustomer={updateCustomer}
            handleClose={handleCustomerEditClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomerInfo;
