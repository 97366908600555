import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getServiceCategories = () => {
  return http.get(`service-category/`, {
    headers: authHeader(),
  });
};

const createServiceCategory = (payload) => {
  return http.post(`service-category/`, payload, { headers: authHeader() });
};

const getServiceCategory = (id) => {
  return http.get(`service-category/${id}`, { headers: authHeader() });
};

const updateServiceCategory = (payload) => {
  return http.put(`service-category/`, payload, { headers: authHeader() });
};

const deleteServiceCategory = (id) => {
  return http.delete(`service-category/${id}`, { headers: authHeader() });
};

export {
  getServiceCategories,
  createServiceCategory,
  getServiceCategory,
  updateServiceCategory,
  deleteServiceCategory,
};
