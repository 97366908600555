import React from "react";
import PropTypes from "prop-types";
import { Nav, Row, Col } from "react-bootstrap";

const NavbarDropdownApp = ({ items }) => {
  return (
    <Row>
      <Col md={5}>
        <Nav className="flex-column"></Nav>
      </Col>
      <Col md={5}></Col>
    </Row>
  );
};

NavbarDropdownApp.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      name: PropTypes.string.isRequired,
      to: PropTypes.string,
      children: PropTypes.array,
    }).isRequired
  ).isRequired,
};

export default NavbarDropdownApp;
