import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { createService } from "../../../service/service-entity/ServiceEntityService";
import { getServiceCategories } from "../../../service/service-category/ServiceCategoryService";
import { getLocations } from "../../../service/location/LocationService";
import { Form, Button, Col, Card, Row } from "react-bootstrap";
import BlockUI from "../../../util/block-ui/block-ui";
import { notifySuccess, notifyError } from "../../../util/notify";
import EnhancedSelect from "component/common/EnhancedSelect";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import ZymranIcon from "component/common/ZymranIcon";
import { getSystemServices } from "service/system-service/SystemServiceEntityService";
import ServiceLocationSection from "component/service/ServiceLocationSection";
import EnhancedCreatableSelect from "component/common/EnhancedCreatableSelect";
import { isNumericInput } from "util/validation";

const CreateService = ({ categoryId, handleClose, setServices }) => {
  const { t } = useTranslation();

  const [serviceData, setServiceData] = useState({
    name: "",
    serviceCategoryId: "",
    availableFor: "",
    description: "",

    priceData: {
      duration: { label: "15 ".concat(t("minute")), value: 15 },
      priceType: { label: t("fixed"), value: "fixed" },
      price: 0,
    },
  });

  const [selectedServiceCategory, setSelectedServiceCategory] = useState(null);
  const [selectedServiceName, setSelectedServiceName] = useState(null);
  const [selectedAvailableFor, setSelectedAvailableFor] = useState(null);
  const [totalSelectedStaffLocations, setTotalSelectedStaffLocations] =
    useState([]);
  const [locationStaffPriceData, setLocationStaffPriceData] = useState(
    new Map()
  );
  const [locationPriceData, setLocationPriceData] = useState(new Map());
  const [locationSections, setLocationSections] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [errors, setErrors] = useState({
    basicInformation: {
      name: null,
      selectedServiceCategory: null,
      availableFor: null,
      description: null,
    },
    locationPrice: {},
    locationStaffPrice: {},
  });
  const [blocking, setBlocking] = useState(false);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [genders, setGenders] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const availableLocationOptions = locationOptions.filter(
    (option) => !selectedLocations.includes(option.value)
  );
  const [priceTypes, setPriceTypes] = useState([]);
  const [durationTimes, setDurationTimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [systemServicesOptions, setSystemServicesOptions] = useState([]);

  useEffect(() => {
    if (categoryId !== 0) {
      setField("serviceCategoryId", categoryId);
      let selectedServiceCategory = serviceCategories.find(
        (sc) => sc.value === categoryId
      );
      setSelectedServiceCategory(selectedServiceCategory);
    }
    // eslint-disable-next-line
  }, [categoryId, serviceCategories]);

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        loadServiceCategories(),
        initGenders(),
        loadLocations(),
        initPriceTypes(),
        initDurationTimes(),
        loadSystemServices(),
      ]);
      setLoading(false);
    };
    loadData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const defaultPriceData = serviceData.priceData;
    if (locationPriceData.size > 0) {
      const locationPriceData = new Map();
      locationPriceData.forEach((value, key) => {
        const priceData = { ...value };
        if (!priceData.toSave) {
          priceData["duration"] = defaultPriceData.duration;
          priceData["priceType"] = defaultPriceData.priceType;
          priceData["price"] = defaultPriceData.price;
        }
        locationPriceData.set(key, priceData);
      });
      setLocationPriceData(locationPriceData);
    }
    // eslint-disable-next-line
  }, [serviceData.priceData]);

  useEffect(() => {
    if (locationOptions.length > 0) {
      initLocationPriceData();
    }
    // eslint-disable-next-line
  }, [locationOptions.length]);

  useEffect(() => {
    initLocationStaffPriceData();
    // eslint-disable-next-line
  }, [totalSelectedStaffLocations]);

  const initLocationPriceData = () => {
    const locationPriceData = new Map();
  
    locationOptions.forEach((location) => {
      const priceData = {
        duration: null,
        priceType: null,
        price: 0,
        toSave: false,
      };
      locationPriceData.set(location.value, priceData);
    });
  
    setLocationPriceData(locationPriceData);
  };  

  const initLocationStaffPriceData = () => {
    const newLocationStaffPriceData = new Map(locationStaffPriceData);

    // Group totalSelectedStaffLocations by locationId
    const groupedSelectedStaffLocations = totalSelectedStaffLocations.reduce(
      (acc, sl) => {
        if (!acc[sl.location.id]) {
          acc[sl.location.id] = [];
        }
        acc[sl.location.id].push(sl);
        return acc;
      },
      {}
    );

    Object.keys(groupedSelectedStaffLocations).forEach((locationId) => {
      const numericLocationId = locationId;
      const staffPriceData = new Map(
        newLocationStaffPriceData.get(numericLocationId)
      );

      groupedSelectedStaffLocations[locationId].forEach((sl) => {
        const staffLocationId = sl.id;

        // Check if the staffLocationId already exists in the staffPriceData map
        if (!staffPriceData.has(staffLocationId)) {
          const priceData = {
            duration: {
              label: "15 ".concat(t("minute")),
              value: 15,
            },
            priceType: {
              label: t("fixed"),
              value: "fixed",
            },
            price: 0,
            toSave: false,
          };
          staffPriceData.set(staffLocationId, priceData);
        }
      });

      newLocationStaffPriceData.set(numericLocationId, staffPriceData);
    });
    setLocationStaffPriceData(newLocationStaffPriceData);
  };

  const initPriceTypes = () => {
    const priceTypes = [
      { label: t("fixed"), value: "fixed" },
      { label: t("from"), value: "from" },
      { label: t("free"), value: "free" },
    ];
    setPriceTypes(priceTypes);
  };

  const initDurationTimes = () => {
    const durationTimes = [
      { label: "15 ".concat(t("minute")), value: 15 },
      { label: "30 ".concat(t("minute")), value: 30 },
      { label: "45 ".concat(t("minute")), value: 45 },
      { label: "1 ".concat(t("hour")), value: 60 },
      {
        label: "1".concat(t("h")).concat(" 15").concat(t("minute")),
        value: 75,
      },
      {
        label: "1".concat(t("h")).concat(" 30").concat(t("minute")),
        value: 90,
      },
      {
        label: "1".concat(t("h")).concat(" 45").concat(t("minute")),
        value: 105,
      },
      { label: "2".concat(t("h")), value: 120 },
      { label: "3".concat(t("h")), value: 180 },
      { label: "4".concat(t("h")), value: 240 },
      { label: "5".concat(t("h")), value: 300 },
      { label: "6".concat(t("h")), value: 360 },
      { label: "7".concat(t("h")), value: 420 },
      { label: "8".concat(t("h")), value: 480 },
    ];
    setDurationTimes(durationTimes);
  };

  const addLocationSection = () => {
    //location id is used as a unique id for sections and it is completely decoupled from the locationOptions
    const nextLocationValue = locationOptions.find(
      (loc) => !locationSections.includes(loc.value)
    );
    if (nextLocationValue) {
      setLocationSections((prevSections) => [
        ...prevSections,
        nextLocationValue.value,
      ]);
    }
  };

  const removeLocationSection = (sectionValue, locationValue) => {
    setLocationSections((prevSections) =>
      prevSections.filter((value) => value !== sectionValue)
    );

    // Update the toSave flag in locationPriceData
    setLocationPriceData((prevData) => {
      const updatedData = new Map(prevData);
      const data = updatedData.get(locationValue);
      if (data) {
        data.toSave = false;
        updatedData.set(locationValue, data);
      }
      return updatedData;
    });
    setSelectedLocations((prevLocations) =>
      prevLocations.filter((loc) => loc !== locationValue)
    );
    setTotalSelectedStaffLocations((prevStaff) =>
      prevStaff.filter((sl) => sl.location.id !== locationValue)
    );
  };

  const loadServiceCategories = async () => {
    try {
      const scList = [];
      const response = await getServiceCategories();
      response.data.forEach((sc) => {
        scList.push({ value: sc.id, label: sc.name });
      });
      setServiceCategories(scList);
    } catch (error) {
    } finally {
    }
  };

  const initGenders = () => {
    const genders = [
      { label: t("male"), value: 1 },
      { label: t("female"), value: 2 },
      { label: t("all"), value: 3 },
    ];
    setGenders(genders);
  };

  const loadLocations = async () => {
    try {
      const response = await getLocations();
      const locs = [];

      response.data.forEach((loc) => {
        const location = {
          value: loc.id,
          label:
            loc.name + " " + loc.address.street + " " + loc.address.houseNumber,
        };
        locs.push(location);
      });
      if (locs.length > 0) {
        setLocationOptions(locs);
        //location id is used as a unique id for sections and it is completely decoupled from the locationOptions
        setLocationSections([locs[0].value]);
      } else {
        notifyError(t("locations_not_added"));
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadSystemServices = async () => {
    try {
      const response = await getSystemServices();
      let sysServices = [];

      response.data.forEach((sysService) => {
        const service = {
          value: sysService.id,
          label: sysService.name,
        };
        sysServices.push(service);
      });

      setSystemServicesOptions(sysServices);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const setField = (field, value) => {
    setServiceData({
      ...serviceData,
      [field]: value,
    });
    // Check and see if basicInformation errors exist, and remove them from the error object:
    if (!!errors["basicInformation"][field])
      setErrors({
        ...errors,
        basicInformation: {
          ...errors["basicInformation"],
          [field]: null,
        },
      });
  };

  const setLocationField = (locationId, field, value) => {
    const priceData = locationPriceData.get(locationId);

    if (field === "price" && !isNumericInput(value)) {
      return;
    }

    priceData[field] = value;
    priceData["toSave"] = true;
    setLocationPriceData(new Map(locationPriceData.set(locationId, priceData)));

    if (
      errors.locationPrice &&
      errors.locationPrice[locationId] &&
      errors.locationPrice[locationId][field]
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        locationPrice: {
          ...prevErrors.locationPrice,
          [locationId]: {
            ...prevErrors.locationPrice[locationId],
            [field]: null,
          },
        },
      }));
    }
  };

  const handleServiceCategoryChange = (selectedOption) => {
    setSelectedServiceCategory(selectedOption);
    // Check and see if errors exist, and remove them from the error object:
    if (
      errors.basicInformation &&
      errors.basicInformation.selectedServiceCategory
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        basicInformation: {
          ...prevErrors.basicInformation,
          selectedServiceCategory: null,
        },
      }));
    }
  };

  const handleAvailableForChange = (selectedOption) => {
    setSelectedAvailableFor(selectedOption);
    // Check and see if errors exist, and remove them from the error object:
    if (errors.basicInformation && errors.basicInformation.availableFor) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        basicInformation: {
          ...prevErrors.basicInformation,
          availableFor: null,
        },
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    const hasBasicInfoErrors =
      Object.keys(newErrors.basicInformation).length > 0;
    const hasAtLeastOneSelectedLocationError =
      newErrors.atLeastOneSelectedLocation !== null &&
      newErrors.atLeastOneSelectedLocation !== "";

    // Check if there are any locationPrice errors
    const hasLocationPriceErrors = Object.values(newErrors.locationPrice).some(
      (location) => {
        return location && Object.keys(location).length > 0;
      }
    );

    // Check if there are any locationStaffPrice errors
    const hasLocationStaffPriceErrors = Object.values(
      newErrors.locationStaffPrice
    ).some((location) => {
      return (
        location &&
        Object.values(location).some((staffLocation) => {
          return staffLocation && Object.keys(staffLocation).length > 0;
        })
      );
    });

    if (
      hasBasicInfoErrors ||
      hasAtLeastOneSelectedLocationError ||
      hasLocationPriceErrors ||
      hasLocationStaffPriceErrors
    ) {
      // We got errors!
      setErrors(newErrors);
    } else {
      // No errors! Put any logic here for the form submission!

      const payload = { ...serviceData };

      let staffLocations = [];

      totalSelectedStaffLocations.forEach((sl) => {
        staffLocations.push(sl.id);
      });

      payload.name = selectedServiceName.label;
      payload.systemServiceId = selectedServiceName.value;
      payload.staffLocations = staffLocations;
      payload.duration = payload.priceData.duration.value;
      payload.priceType = payload.priceData.priceType.value;
      payload.price = payload.priceData.price;
      payload.availableFor = selectedAvailableFor.value;
      payload.serviceCategoryId = selectedServiceCategory.value;

      let locStaffPrice = {};
      let locPrice = {};

      let locationStaffPriceDataToSend = new Map();

      locationStaffPriceData.forEach((staffPriceData, locationId) => {
        let temp1 = new Map();
        staffPriceData.forEach((priceData, staffId) => {
          if (priceData.toSave) {
            temp1.set(staffId, priceData);
          }
        });
        locationStaffPriceDataToSend.set(locationId, temp1);
      });

      locationStaffPriceDataToSend.forEach((staffPrice, locationId) => {
        let newStaffPrice = {};
        staffPrice.forEach((priceData, slId) => {
          const newPriceData = {
            duration: priceData.duration.value,
            priceType: priceData.priceType.value,
            price: Number(priceData.price),
          };
          newStaffPrice[slId] = newPriceData;
        });
        locStaffPrice[locationId] = newStaffPrice;
      });

      let locationPriceDataToSend = new Map();

      locationPriceData.forEach((priceData, locationId) => {
        if (priceData.toSave) {
          locationPriceDataToSend.set(locationId, priceData);
        }
      });

      locationPriceDataToSend.forEach((priceData, locationId) => {
        const newPriceData = {
          duration: priceData.duration.value,
          priceType: priceData.priceType.value,
          price: Number(priceData.price),
        };
        locPrice[locationId] = newPriceData;
      });

      payload.locationStaffPrice = locStaffPrice;
      payload.locationPrice = locPrice;

      payload.price = Number(payload.price);

      delete payload.priceData;

      try {
        setBlocking(true);
        const response = await createService(payload);
        const service = response.data;
        setServices((oldServices) => [...oldServices, service]);
        notifySuccess(t("service_created"));
        handleClose();
      } catch (error) {
        console.error(error);
      } finally {
        setBlocking(false);
      }
    }
  };

  const findFormErrors = () => {
    const { name, description } = serviceData;

    const newErrors = {
      basicInformation: {},
      locationPrice: {},
      locationStaffPrice: {},
      atLeastOneSelectedLocation: null,
    };

    if (!name || name === "")
      newErrors.basicInformation.name = t("field_required");

    if (!selectedServiceCategory || !selectedServiceCategory.value)
      newErrors.basicInformation.selectedServiceCategory = t("field_required");

    if (!selectedAvailableFor || !selectedAvailableFor.value)
      newErrors.basicInformation.availableFor = t("field_required");

    if (description.length > 1024)
      newErrors.basicInformation.description = t("too_long");
    if (selectedLocations.length === 0)
      newErrors.atLeastOneSelectedLocation = t("field_required");

    locationPriceData.forEach((data, locationId) => {
      if (data.toSave) {
        if (!data.duration) {
          if (!newErrors.locationPrice[locationId])
            newErrors.locationPrice[locationId] = {};
          newErrors.locationPrice[locationId].duration = t("field_required");
        }
        if (!data.priceType) {
          if (!newErrors.locationPrice[locationId])
            newErrors.locationPrice[locationId] = {};
          newErrors.locationPrice[locationId].priceType = t("field_required");
        }
        if (data.priceType !== "free" && data.price <= 0) {
          if (!newErrors.locationPrice[locationId])
            newErrors.locationPrice[locationId] = {};
          newErrors.locationPrice[locationId].price = t(
            "price_should_be_greater_than_zero"
          );
        }
      }
    });

    // Location staff price validation
    locationStaffPriceData.forEach((staffPrice, locationId) => {
      staffPrice.forEach((data, staffLocationId) => {
        if (!data.duration) {
          if (!newErrors.locationStaffPrice[locationId])
            newErrors.locationStaffPrice[locationId] = {};
          if (!newErrors.locationStaffPrice[locationId][staffLocationId])
            newErrors.locationStaffPrice[locationId][staffLocationId] = {};
          newErrors.locationStaffPrice[locationId][staffLocationId].duration =
            t("field_required");
        }
        if (!data.priceType) {
          if (!newErrors.locationStaffPrice[locationId])
            newErrors.locationStaffPrice[locationId] = {};
          if (!newErrors.locationStaffPrice[locationId][staffLocationId])
            newErrors.locationStaffPrice[locationId][staffLocationId] = {};
          newErrors.locationStaffPrice[locationId][staffLocationId].priceType =
            t("field_required");
        }
        if (data.priceType !== "free" && data.price <= 0) {
          if (!newErrors.locationStaffPrice[locationId])
            newErrors.locationStaffPrice[locationId] = {};
          if (!newErrors.locationStaffPrice[locationId][staffLocationId])
            newErrors.locationStaffPrice[locationId][staffLocationId] = {};
          newErrors.locationStaffPrice[locationId][staffLocationId].price = t(
            "price_should_be_greater_than_zero"
          );
        }
      });
    });
    return newErrors;
  };

  const handleSelectedLocationAddition = (locationId) => {
    if (!selectedLocations.includes(locationId)) {
      setSelectedLocations((prevSelectedLocations) => [
        ...prevSelectedLocations,
        locationId,
      ]);
    }
  };

  const handleCreateOption = (inputValue) => {
    setField("name", inputValue);
    setSelectedServiceName({ value: null, label: inputValue });
  };

  const handleCrateableSelectChange = (newValue) => {
    if (newValue) {
      setField("name", newValue.label);
      setSelectedServiceName(newValue);
    } else {
      setField("name", "");
      setSelectedServiceName(null);
    }
  };

  const formatCreateLabel = () => (
    <div className="format-create-label">
      <ZymranIcon name="plus" /> <Trans>select_as_new_service</Trans>
    </div>
  );

  if (loading) {
    return (
      <div className="container central-content-container">Loading...</div>
    );
  }

  return (
    <div className="container central-content-container">
      <Form>
        <Card className="mb-3">
          <Card.Header className="plain-card-header">
            <h3 className="mb-0">
              <Trans>service_information</Trans>
            </h3>
          </Card.Header>
          <Card.Body className="d-flex flex-column gap-3">
            <Row className="g-3">
              <Col md={5}>
                <EnhancedCreatableSelect
                  label={t("service_name")}
                  placeholder={t("select_service_name_or_type_new_one")}
                  value={selectedServiceName}
                  options={systemServicesOptions}
                  onChange={(option) => handleCrateableSelectChange(option)}
                  onCreateOption={handleCreateOption}
                  errors={errors.basicInformation.name}
                  formatCreateLabel={formatCreateLabel}
                />
              </Col>
              <Col md={4}>
                <EnhancedSelect
                  label={t("service_category")}
                  placeholder={t("select_service_category")}
                  selectedValue={selectedServiceCategory}
                  options={serviceCategories}
                  onChange={(option) => handleServiceCategoryChange(option)}
                  errors={errors.basicInformation.selectedServiceCategory}
                  groupClassName="flex-grow-1"
                />
              </Col>
              <Col md={3}>
                <EnhancedSelect
                  label={t("available_for")}
                  placeholder={t("available_for")}
                  selectedValue={selectedAvailableFor}
                  options={genders}
                  onChange={(option) => handleAvailableForChange(option)}
                  errors={errors.basicInformation.availableFor}
                  groupClassName="flex-grow-1"
                />
              </Col>
            </Row>
            <EnhancedFormControl
              id="description"
              name="description"
              type="text"
              label={t("description")}
              placeholder={t("description")}
              value={serviceData.description}
              onChange={(e) => setField("description", e.target.value)}
              errors={errors.basicInformation.description}
              isInvalid={!!errors.basicInformation.description}
              description={t("service_description_instruction")}
            />
          </Card.Body>
        </Card>
        <Card className="mb-3">
          <Card.Header className="plain-card-header">
            <h3 className="mb-0">
              <Trans>locations_and_staff</Trans>
            </h3>
          </Card.Header>
          <Card.Body className="d-flex flex-column p-0">
            {locationSections.map((sectionValue) => (
              <ServiceLocationSection
                key={sectionValue}
                sectionValue={sectionValue}
                preselectedLocationId={
                  locationOptions.length === 1 ? sectionValue : null
                }
                locationOptions={availableLocationOptions}
                durationOptions={durationTimes}
                priceTypeOptions={priceTypes}
                locationPriceData={locationPriceData}
                locationStaffPriceData={locationStaffPriceData}
                setLocationPriceData={setLocationPriceData}
                setLocationStaffPriceData={setLocationStaffPriceData}
                errors={errors}
                setErrors={setErrors}
                locationSections={locationSections}
                removeLocationSection={removeLocationSection}
                setSelectedLocations={setSelectedLocations}
                totalSelectedStaffLocations={totalSelectedStaffLocations}
                setTotalSelectedStaffLocations={setTotalSelectedStaffLocations}
                handleSelectedLocationAddition={handleSelectedLocationAddition}
                setLocationField={setLocationField}
              />
            ))}
            <div className="service-location-section-remove-button-area">
              <Button
                variant="blue-light"
                size="sm"
                onClick={addLocationSection}
                disabled={locationSections.length === locationOptions.length}
              >
                <ZymranIcon name="plus" />
                <Trans>add_location</Trans>
              </Button>
            </div>
          </Card.Body>
        </Card>
        <BlockUI tag="div" blocking={blocking} />
        <div className="d-flex justify-content-end align-items-start gap-3">
          <Button variant="grey" onClick={handleClose}>
            <Trans>cancel</Trans>
          </Button>
          <Button type="submit" variant="blue" onClick={handleSubmit}>
            <Trans>save_service</Trans>
          </Button>
        </div>
      </Form>
    </div>
  );
};

CreateService.propTypes = {
  categoryId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  setServices: PropTypes.func.isRequired,
};

export default CreateService;
