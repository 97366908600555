import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "react-i18next";

const ProfileStatsLegend = () => {
  return (
    <div className="profile-stats-card__legend-block">
      <div className="chart-map">
        <FontAwesomeIcon icon="circle" className="expected-sum-badge" />
        <span className="p4 secondary-text-color">
          <Trans>stats_expected</Trans>
        </span>
      </div>
      <div className="chart-map">
        <FontAwesomeIcon icon="circle" className="received-sum-badge" />
        <span className="p4 secondary-text-color">
          <Trans>stats_received</Trans>
        </span>
      </div>
      <div className="chart-map">
        <FontAwesomeIcon icon="circle" className="extra-sum-badge" />
        <span className="p4 secondary-text-color">
          <Trans>stats_extra</Trans>
        </span>
      </div>
      <div className="chart-map">
        <FontAwesomeIcon icon="circle" className="canceled-sum-badge" />
        <span className="p4 secondary-text-color">
          <Trans>stats_cancelled</Trans>
        </span>
      </div>
    </div>
  );
};

export default ProfileStatsLegend;
