import EnhancedCreatableSelect from "component/common/EnhancedCreatableSelect";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import ZymranIcon from "component/common/ZymranIcon";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { getSystemServiceCategories } from "service/system-service-category/SystemServiceCategoryService";
import { createServiceCategory } from "../../../service/service-category/ServiceCategoryService";
import BlockUI from "../../../util/block-ui/block-ui";
import { notifySuccess } from "../../../util/notify";

const CreateCategory = ({ handleClose, setServiceCategories }) => {
  const [categoryData, setCategoryData] = useState({
    name: "",
    description: "",
  });
  const [systemCategoriesOptions, setSystemCategoriesOptions] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [errors, setErrors] = useState({});
  const [blocking, setBlocking] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    loadSystemServiceCategories();
  }, []);

  const loadSystemServiceCategories = async () => {
    try {
      const response = await getSystemServiceCategories();
      let sysCategories = [];

      response.data.forEach((sysCategory) => {
        sysCategories.push({
          value: sysCategory.id,
          label: sysCategory.name,
        });
      });

      setSystemCategoriesOptions(sysCategories);
    } catch (error) {
      console.error();
    }
  };

  const setField = (field, value) => {
    setCategoryData({
      ...categoryData,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const findFormErrors = () => {
    const { name, description } = categoryData;
    const newErrors = {};

    if (!name || name === "") newErrors.name = t("field_required");
    else if (name.length > 30) newErrors.name = t("too_long");

    if (!description || description === "")
      newErrors.description = t("field_required");
    else if (description.length > 100) newErrors.description = t("too_long");

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      try {
        setBlocking(true);
        if (selectedCategoryName.value) {
          categoryData.systemServiceCategoryId = selectedCategoryName.value;
        }
        const response = await createServiceCategory(categoryData);
        const serviceCategory = response.data;
        setServiceCategories((oldServiceCategories) => [
          ...oldServiceCategories,
          serviceCategory,
        ]);
        setBlocking(false);
        notifySuccess(t("service_category_created"));
        handleClose();
      } catch (error) {
        console.error(error);
      } finally {
        setBlocking(false);
      }
    }
  };

  const handleCrateableSelectChange = (newValue) => {
    if (newValue) {
      setField("name", newValue.label);
      setSelectedCategoryName(newValue);
    } else {
      setField("name", "");
      setSelectedCategoryName(null);
    }
  };

  const handleCreateOption = (inputValue) => {
    setField("name", inputValue);
    setSelectedCategoryName({ value: null, label: inputValue });
  };

  const formatCreateLabel = () => (
    <div className="format-create-label">
      <ZymranIcon name="plus" /> <Trans>select_as_new_service_categroy</Trans>
    </div>
  );

  return (
    <Form>
      <div className="d-flex flex-column align-items-stretch align-self-stretch gap-3">
        <EnhancedCreatableSelect
          label={t("select_service_category_name_or_type_new_one")}
          placeholder={t("select_service_category_name_or_type_new_one")}
          options={systemCategoriesOptions}
          onChange={(newValue) => handleCrateableSelectChange(newValue)}
          onCreateOption={handleCreateOption}
          value={selectedCategoryName}
          formatCreateLabel={formatCreateLabel}
          errors={errors.name}
        />
        <EnhancedFormControl
          id="description"
          name="description"
          type="text"
          value={categoryData.description}
          onChange={(e) => setField("description", e.target.value)}
          isInvalid={!!errors.description}
          placeholder={t("description")}
          label={t("description")}
          errors={errors.description}
        />
        <div className="d-flex justify-content-end align-items-start gap-3 align-self-stretch">
          <Button
            name="cancelButton"
            variant="orange-light"
            className="flex-grow-1"
            onClick={handleClose}
          >
            <Trans>cancel</Trans>
          </Button>
          <Button
            name="addCategoryButton"
            variant="blue"
            type="submit"
            onClick={handleSubmit}
            className="flex-grow-1"
          >
            <Trans>add</Trans>
          </Button>
        </div>
        <BlockUI tag="div" blocking={blocking} />
      </div>
    </Form>
  );
};

CreateCategory.propTypes = {
  handleClose: PropTypes.func.isRequired,
  setServiceCategories: PropTypes.func.isRequired,
};

export default CreateCategory;
