import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fuse from "fuse.js";
// import { isIterableArray } from "helpers/utils";
import Flex from "component/common/Flex";
import AdvanceTableWrapper from "component/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "component/common/advance-table/AdvanceTable";
import { useTranslation } from "react-i18next";
import ServiceStaffSearchBoxButton from "./ServiceStaffSearchBoxButton";
import ZymranAvatar from "component/common/ZymranAvatar";

const ServiceStaffSearchBox = ({ autoCompleteItem, handleStaffSelection }) => {
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [resultItem, setResultItem] = useState(autoCompleteItem);

  const { t } = useTranslation();
  const columns = [
    {
      accessor: "name",
      Header: t("name"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { firstName, lastName, avatarUrl } = rowData.row.original.staff;
        return (
          <Flex alignItems="center" className="avatar-name-wrapper">
            <ZymranAvatar
              avatarUrl={avatarUrl}
              fullName={`${firstName} ${lastName}`}
              size="sm"
              shape="circle"
            />
            <div className="flex-1">
              <span className="mb-0 fs--1">
                {firstName} {lastName}
              </span>
            </div>
          </Flex>
        );
      },
    },
    {
      accessor: "position",
      Header: t("position"),
      Cell: (rowData) => {
        const { position } = rowData.row.original.staff;
        return (
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{position}</h5>
            </div>
          </Flex>
        );
      },
    },
    {
      accessor: "phone",
      Header: t("phone_number"),
      Cell: (rowData) => {
        const { phoneNumber } = rowData.row.original.staff;
        return <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>;
      },
    },
  ];

  const fuseJsOptions = {
    includeScore: true,
    keys: [
      "staff.firstName",
      "staff.lastName",
      "staff.middleNmae",
      "staff.position",
      "staff.phoneNumber",
    ],
    threshold: 0.4,
  };

  let searchResult = new Fuse(autoCompleteItem, fuseJsOptions)
    .search(searchInputValue)
    .map((item) => item.item);

  useEffect(() => {
    if (searchInputValue) {
      setResultItem(searchResult);
      // isIterableArray(searchResult)
      //   ? setDropdownOpen(true)
      //   : setDropdownOpen(false);
    } else {
      setResultItem(autoCompleteItem);
    }

    // eslint-disable-next-line
  }, [searchInputValue]);

  return (
    <>
      <AdvanceTableWrapper columns={columns} data={resultItem} selection>
        <div className="search-box w-100 d-flex flex-row justify-content-between">
          <Form className="position-relative w-100 me-3">
            <Form.Control
              type="search"
              name="search-input"
              placeholder="Search..."
              aria-label="Search"
              className="search-input search-input--sm"
              value={searchInputValue}
              onChange={({ target }) => setSearchInputValue(target.value)}
              // onClick={() => setDropdownOpen(false)}
            />
            <FontAwesomeIcon
              icon="search"
              className="position-absolute text-400 search-box-icon"
            />
          </Form>
          <ServiceStaffSearchBoxButton
            table
            rows
            handleStaffSelection={handleStaffSelection}
          />
        </div>
        <div className="services-staff-table-wrapper">
          <AdvanceTable
            table
            headerClassName="p2-bold secondary-text-color text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: "sm",
              className: "p2 primary-text-color mb-0",
            }}
          />
        </div>
      </AdvanceTableWrapper>
    </>
  );
};

export default ServiceStaffSearchBox;
