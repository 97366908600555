import React from "react";
import { Route, Routes } from "react-router-dom";
import ListServices from "./ListServices";

const Service = () => {
  return (
    <>
      <Routes>
        {/*
        <Route path="/create" element={<CreateService />} />
        <Route path="/edit/:serviceId" element={<EditService />} />
        */}
        <Route path="/*" element={<ListServices />} />
      </Routes>
    </>
  );
};

export default Service;
