import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Trans } from "react-i18next";
import ZymranIcon from "component/common/ZymranIcon";
import { getServices } from "service/service-entity/ServiceEntityService";
import SalaryForService from "./SalaryForService";
import { isNumericInput } from "util/validation";

const SalaryForServicesSection = ({
  staffData,
  setStaffData,
  errors,
  setErrors,
  toggleOptions,
}) => {
  const [selectedServiceIds, setSelectedServiceIds] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [services, setServices] = useState([]);

  const filteredServiceOptions = services.filter(
    (option) => !selectedOptions.includes(option)
  );

  useEffect(() => {
    loadServices();
  }, []);

  const loadServices = async () => {
    const sList = [];
    try {
      const response = await getServices();
      response.data.forEach((s) => {
        sList.push({ value: s.id, label: s.name });
      });
      setServices(sList);
    } catch (error) {
      console.error(error);
    }
  };

  const handleServiceChange = (index, option) => {
    const updatedSelectedOptions = [...selectedOptions];
    const previouslySelectedServiceId =
      staffData.salaryForServices[index].serviceId;
    if (previouslySelectedServiceId) {
      setSelectedOptions(
        selectedOptions.filter(
          (option) => option.value !== previouslySelectedServiceId
        )
      );
    }

    if (option.value && !updatedSelectedOptions.includes(option.value)) {
      setSelectedOptions((prevOptions) => [...prevOptions, option]);
    }

    const updatedStaffData = { ...staffData };
    updatedStaffData.salaryForServices[index].serviceId = option.value;
    setStaffData(updatedStaffData);
  };

  const handleSalaryChange = (index, newValue) => {
    if (isNumericInput(newValue)) {
      const updatedStaffData = { ...staffData };
      updatedStaffData.salaryForServices[index].salary = newValue;
      setStaffData(updatedStaffData);
    }
  };

  const addService = () => {
    setSelectedServiceIds([...selectedServiceIds, null]);

    setStaffData((prevData) => ({
      ...prevData,
      salaryForServices: [
        ...prevData.salaryForServices,
        { salary: 0, isPercentage: false, serviceId: 0 },
      ],
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      salaryForServices: [...prevErrors.salaryForServices, {}],
    }));
  };

  const removeService = (index) => {
    const updatedIds = [...selectedServiceIds];
    updatedIds.splice(index, 1);
    setSelectedServiceIds(updatedIds);

    const removedServiceId = staffData.salaryForServices[index].serviceId;
    const updatedSelectedOptions = selectedOptions.filter(
      (option) => option.value !== removedServiceId
    );
    setSelectedOptions(updatedSelectedOptions);

    setStaffData((prevData) => {
      const updatedServices = [...prevData.salaryForServices];
      updatedServices.splice(index, 1);
      return { ...prevData, salaryForServices: updatedServices };
    });
  };

  const handleSalaryToggleChange = (index, optionValue) => {
    const isPercentage = optionValue === "percentage";
    const updatedStaffData = { ...staffData };
    updatedStaffData.salaryForServices[index].isPercentage = isPercentage;
    setStaffData(updatedStaffData);
  };

  return (
    <div className="salary-block">
      {services && services.length > 0 ? (
        <>
          <p className="p1-bold mb-0">
            <Trans>specification_by_specific_services</Trans>
          </p>
          {staffData.salaryForServices.map((salaryForServiceItem, index) => (
            <SalaryForService
              key={index}
              uniqueId={index}
              services={filteredServiceOptions}
              selectedService={services.find(
                (option) => option.value === salaryForServiceItem.serviceId
              )}
              salary={salaryForServiceItem.salary}
              isPercentage={salaryForServiceItem.isPercentage}
              onServiceChange={(option) => handleServiceChange(index, option)}
              onSalaryChange={(newValue) => handleSalaryChange(index, newValue)}
              onRemove={() => removeService(index)}
              errors={errors}
              toggleOptions={toggleOptions}
              onToggleChange={(optionValue) =>
                handleSalaryToggleChange(index, optionValue)
              }
            />
          ))}
          <Button
            id="addMoreSelects"
            variant="blue-light"
            size="sm"
            type="button"
            onClick={addService}
            disabled={services.length === selectedServiceIds.length}
          >
            <ZymranIcon name="plus" />
            <Trans>add_service</Trans>
          </Button>
        </>
      ) : (
        <p className="p2 secondary-text-color">
          <Trans>add_services_in_service_menu</Trans>
        </p>
      )}
    </div>
  );
};

export default SalaryForServicesSection;
