import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BlockUI from "../../../util/block-ui/block-ui";
import { Trans } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import EnhancedCreatableSelect from "component/common/EnhancedCreatableSelect";
import { getSystemServiceCategories } from "service/system-service-category/SystemServiceCategoryService";
import ZymranIcon from "component/common/ZymranIcon";
import { getServiceCategory } from "../../../service/service-category/ServiceCategoryService";
import { updateServiceCategory } from "../../../service/service-category/ServiceCategoryService";
import { notifySuccess } from "../../../util/notify";
import { useTranslation } from "react-i18next";

const EditCategory = ({
  categoryId,
  serviceCategories,
  setServiceCategories,
  ...props
}) => {
  const [categoryData, setCategoryData] = useState({
    id: "",
    name: "",
    description: "",
  });
  const [systemCategoriesOptions, setSystemCategoriesOptions] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [errors, setErrors] = useState({});
  const [blocking, setBlocking] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    loadSystemServiceCategories();
  }, []);

  useEffect(() => {
    loadServiceCategory();
    // eslint-disable-next-line
  }, [categoryId]);

  const loadServiceCategory = async () => {
    try {
      const response = await getServiceCategory(categoryId);
      setCategoryData(response.data);
      setSelectedCategoryName({
        value: response.data.systemServiceCategoryId,
        label: response.data.name,
      });
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const loadSystemServiceCategories = async () => {
    try {
      const response = await getSystemServiceCategories();
      let sysCategories = [];

      response.data.forEach((sysCategory) => {
        sysCategories.push({
          value: sysCategory.id,
          label: sysCategory.name,
        });
      });

      setSystemCategoriesOptions(sysCategories);
    } catch (error) {
    } finally {
    }
  };

  const setField = (field, value) => {
    setCategoryData({
      ...categoryData,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const findFormErrors = () => {
    const { name, description } = categoryData;
    const newErrors = {};

    if (!name || name === "") newErrors.name = t("field_required");
    else if (name.length > 30) newErrors.name = t("too_long");

    if (!description || description === "")
      newErrors.description = t("field_required");
    else if (description.length > 100) newErrors.description = t("too_long");

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      try {
        setBlocking(true);
        const response = await updateServiceCategory(categoryData);
        setBlocking(false);
        notifySuccess(t("service_category_updated"));
        const serviceCategoriesToUpdate = [...serviceCategories];
        const serviceCategory = response.data;
        const index = serviceCategoriesToUpdate.findIndex((sc) => {
          return sc.id === serviceCategory.id;
        });

        serviceCategoriesToUpdate[index].name = serviceCategory.name;
        serviceCategoriesToUpdate[index].description =
          serviceCategory.description;

        setServiceCategories(serviceCategoriesToUpdate);
        props.handleClose();
      } catch (error) {
        console.error(error);
      } finally {
        setBlocking(false);
      }
    }
  };

  const handleCrateableSelectChange = (newValue) => {
    if (newValue) {
      setField("name", newValue.label);
      setSelectedCategoryName(newValue);
    } else {
      setField("name", "");
      setSelectedCategoryName(null);
    }
  };

  const handleCreateOption = (inputValue) => {
    setField("name", inputValue);
    setSelectedCategoryName({ value: null, label: inputValue });
  };

  const formatCreateLabel = () => (
    <div className="format-create-label">
      <ZymranIcon name="plus" /> <Trans>select_as_new_service_categroy</Trans>
    </div>
  );

  return (
    <Form>
      <div className="d-flex flex-column align-items-stretch align-self-stretch gap-3">
        <EnhancedCreatableSelect
          label={t("select_service_category_name_or_type_new_one")}
          placeholder={t("select_service_category_name_or_type_new_one")}
          options={systemCategoriesOptions}
          onChange={(newValue) => handleCrateableSelectChange(newValue)}
          onCreateOption={handleCreateOption}
          value={selectedCategoryName}
          formatCreateLabel={formatCreateLabel}
          errors={errors.name}
        />
        <EnhancedFormControl
          id="description"
          name="description"
          type="text"
          value={categoryData.description || ""}
          onChange={(e) => setField("description", e.target.value)}
          isInvalid={!!errors.description}
          placeholder={t("description")}
          label={t("description")}
          errors={errors.description}
        />
        <div className="d-flex justify-content-end align-items-start gap-3 align-self-stretch">
          <Button
            name="cancelButton"
            variant="orange-light"
            className="flex-grow-1"
            onClick={props.handleClose}
          >
            <Trans>cancel</Trans>
          </Button>
          <Button
            name="saveCategoryButton"
            variant="blue"
            type="submit"
            className="flex-grow-1"
            onClick={handleSubmit}
          >
            <Trans>save</Trans>
          </Button>
        </div>
        <BlockUI tag="div" blocking={blocking} />
      </div>
    </Form>
  );
};

EditCategory.propTypes = {
  categoryId: PropTypes.string.isRequired,
  setServiceCategories: PropTypes.func.isRequired,
  serviceCategories: PropTypes.array.isRequired,
};

export default EditCategory;
