import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getStaffSchedules = (locationId, startOfWeek, endOfWeek) => {
  const payload = {
    locationId: locationId,
    startOfWeek: startOfWeek,
    endOfWeek: endOfWeek,
  };
  // console.log("getStaffSchedules payload: ", payload);
  return http.post(`staff-schedule/list`, payload, { headers: authHeader() });
};

const createStaffSchedule = (payload) => {
  return http.post(`staff-schedule/`, payload, { headers: authHeader() });
};

const updateStaffSchedule = (payload) => {
  return http.put(`staff-schedule/`, payload, { headers: authHeader() });
};

const deleteStaffSchedule = (id) => {
  return http.delete(`staff-schedule/${id}`, { headers: authHeader() });
};

export { getStaffSchedules, createStaffSchedule, updateStaffSchedule, deleteStaffSchedule };
