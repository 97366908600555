import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Nav, Collapse } from "react-bootstrap";
import NavbarVerticalMenuItem from "./NavbarVerticalMenuItem";
import classNames from "classnames";
import AppContext from "context/Context";

const CollapseItems = ({ route }) => {
  const { pathname } = useLocation();

  const openCollapse = (childrens) => {
    const checkLink = (children) => {
      if (children.to === pathname) {
        return true;
      }
      return (
        Object.prototype.hasOwnProperty.call(children, "children") &&
        children.children.some(checkLink)
      );
    };
    return childrens.some(checkLink);
  };

  const [open, setOpen] = useState(openCollapse(route.children));

  return (
    <Nav.Item as="li" className="top-level">
      <Nav.Link
        onClick={() => {
          setOpen(!open);
        }}
        className={classNames("dropdown-indicator cursor-pointer", {
          "text-500": !route.active,
        })}
        aria-expanded={open}
        // {...route}
      >
        <NavbarVerticalMenuItem route={route} isTopLevel={true} />
      </Nav.Link>
      <Collapse in={open}>
        <Nav className="flex-column nav collapsible-submenu" as="ul">
          <NavbarVerticalMenu routes={route.children} />
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool,
  }).isRequired,
};

const NavbarVerticalMenu = ({ routes, isTopLevel = false }) => {
  const {
    config: { showBurgerMenu },
    setConfig,
  } = useContext(AppContext);

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig("showBurgerMenu", !showBurgerMenu);
    }
  };
  return routes.map((route, index) => {
    const isLastItem = index === routes.length - 1;

    return (
      <React.Fragment key={route.name}>
        {!route.children ? (
          <Nav.Item
            as="li"
            key={route.name}
            onClick={handleNavItemClick}
            className={isTopLevel ? "top-level" : ""}
          >
            <NavLink
              end={route.exact}
              to={route.to}
              state={{ open: route.to === "/authentication-modal" }}
              className={({ isActive }) =>
                isActive ? "active nav-link" : "nav-link"
              }
            >
              <NavbarVerticalMenuItem route={route} isTopLevel={isTopLevel} />
            </NavLink>
          </Nav.Item>
        ) : (
          <CollapseItems route={route} key={route.name} />
        )}
        {!isLastItem && isTopLevel && <hr className="nav-divider m-0" />}
      </React.Fragment>
    );
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired,
};

export default NavbarVerticalMenu;
