import React, { useState, useEffect, forwardRef, useRef } from "react";
import { Table } from "react-bootstrap";
import {
  getStaffSchedules,
  updateStaffSchedule,
  createStaffSchedule,
} from "../../service/staff-schedule/StaffScheduleService";
import EditStaffSchedule from "../staff-schedule/EditStaffSchedule";
import { getLocations } from "../../service/location/LocationService";
import { notifySuccess } from "../../util/notify";
import { useTranslation, Trans } from "react-i18next";
import BlockUI from "../../util/block-ui/block-ui";
import DatePicker from "react-datepicker";
import { Button, Card, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select, { components } from "react-select";
import {
  startOfDay,
  startOfWeek,
  endOfWeek,
  addDays,
  format,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
  subDays,
} from "date-fns";
import useLocale from "hooks/useLocale";

import moment from "moment-timezone";
import { toZonedTime, fromZonedTime } from "date-fns-tz";
import Lottie from "lottie-react";
import loadingAnimation from "assets/img/animated-icons/loading.json";
import classNames from "classnames";
import { ReactComponent as AddUserIcon } from "assets/img/icons/add-user.svg";
import ZymranIcon from "component/common/ZymranIcon";
import CreateStaff from "component/staff/CreateStaff";
import ZymranAvatar from "component/common/ZymranAvatar";
import { WEEKDAYS } from "util/constants";

const StaffSchedule = (props) => {
  const locale = useLocale();
  const [staffSchedules, setStaffSchedules] = useState([]);
  const [staffScheduleRows, setStaffScheduleRows] = useState([]);
  const [blocking, setBlocking] = useState(false);
  const [show, setShow] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({
    value: 0,
    label: "",
  });
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const [customerCreateModalShow, setCustomerCreateModalShow] = useState(false);

  const locationSelect = useRef();
  const [selectedSlwh, setSelectedSlwh] = useState({});
  const [selectedStaff, setSelectedStaff] = useState({});
  const [currentDate, setCurrentDate] = useState(
    toZonedTime(
      setHours(setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0), 0),
      timezone
    )
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isCreateOperation, setIsCreateOperation] = useState(false);

  const { t } = useTranslation();
  const [loadingCounter, setLoadingCounter] = useState(0);

  //TODO: replace it by format from date-fns
  const dayOfWeekNumber = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 0,
  };

  useEffect(() => {
    loadLocations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedLocation && selectedLocation.value !== 0) {
      loadStaffSchedules();
    }
    // eslint-disable-next-line
  }, [selectedLocation, currentDate]);

  useEffect(() => {
    if (staffSchedules.length > 0) {
      constructStaffScheduleTableTrTags();
    }
    // eslint-disable-next-line
  }, [staffSchedules.length, staffSchedules]);

  useEffect(() => {
    updateDateRange(currentDate);
  }, [currentDate]);

  const loadLocations = async () => {
    try {
      setLoadingCounter(loadingCounter + 1);
      const response = await getLocations();
      const locs = [];
      response.data.forEach((loc) =>
        locs.push({ value: loc.id, label: loc.name })
      );
      locs.sort((loc1, loc2) => loc1.label.localeCompare(loc2.label));
      setLocationOptions(locs);
      setLocations(response.data);
      if (locs.length > 0) {
        setSelectedLocation(locs[0]);
        const selectedLocation = response.data.find((location) => {
          return location.id === locs[0].value;
        });
        setTimezone(selectedLocation.address.timezone);
      } else {
        setSelectedLocation(null);
        setTimezone(moment.tz.guess());
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCounter(loadingCounter - 1);
    }
  };

  const onChangeLocation = (loc) => {
    setSelectedLocation(loc);
    const selectedLocation = locations.find((location) => {
      return location.id === loc.value;
    });
    if (loc.value !== 0) {
      setTimezone(selectedLocation.address.timezone);
    }
  };

  const handleShowEditStaffSchedule = (slwh, staff) => {
    setSelectedSlwh(slwh);
    setSelectedStaff(staff);
    setIsCreateOperation(false);
    setShow(true);
  };

  const handleShowEditStaffScheduleInCreateMode = (slwh, staff) => {
    setSelectedSlwh(slwh);
    setSelectedStaff(staff);
    setIsCreateOperation(true);
    setShow(true);
  }

  const loadStaffSchedules = async () => {
    try {
      setLoadingCounter(loadingCounter + 1);
      const response = await getStaffSchedules(
        selectedLocation.value,
        fromZonedTime(startOfWeek(currentDate, { weekStartsOn: 1 }), timezone),
        fromZonedTime(endOfWeek(currentDate, { weekStartsOn: 1 }), timezone)
      );
      setStaffSchedules(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCounter(loadingCounter - 1);
    }
  };

  const constructStaffScheduleTableTdTags = (
    staffLocationWorkingHours,
    staffLocation
  ) => {

    const daysOfWeek = Object.values(WEEKDAYS);
    // console.log("STAFF LOCATION WORKING HOURS: ", staffLocationWorkingHours);

    const workingHoursMap = staffLocationWorkingHours.reduce((map, slwh) => {
      map[slwh.dayOfWeek.toLowerCase()] = slwh;
      return map;
    }, {});

    // console.log("WORKING HOURS MAP: ", workingHoursMap);

    const staff = staffLocation.staff;
    const staffScheduleTdTags = daysOfWeek.map((day, index) => {
      let slwh = workingHoursMap[day];

      if (!slwh) {
        slwh = {
          dayOfWeek: day,
          staffLocationId: staffLocation.id,
          dayOff: false,
        };
        return (
          <td key={`empty-${index}`} className="staff-table-cell">
            <div className="container">
              <div className="row">
                <div className="col d-flex justify-content-center">
                  <button
                    className="icon-button icon-button--sm icon-button--grey"
                    onClick={() => handleShowEditStaffScheduleInCreateMode(slwh, staff)}
                  >
                    <ZymranIcon name="plus" />
                  </button>
                </div>
              </div>
            </div>
          </td>
        );
      }

      if (
        !slwh.id ||
        slwh.dayOff ||
        (!slwh.primaryShiftStart &&
          !slwh.primaryShiftEnd &&
          !slwh.secondaryShiftStart &&
          !slwh.secondaryShiftEnd)
      ) {
        slwh = {
          dayOfWeek: day,
          staffLocationId: staffLocation.id,
          dayOff: false,
        };
        return (
          <td key={index} className="staff-table-cell">
            <div className="container">
              <div className="row">
                <div className="col d-flex justify-content-center">
                  <button
                    className="icon-button icon-button--sm icon-button--grey"
                    onClick={() => handleShowEditStaffScheduleInCreateMode(slwh, staff)}
                  >
                    <ZymranIcon name="plus" />
                  </button>
                </div>
              </div>
            </div>
          </td>
        );
      }

      return (
        <td key={slwh.id} className="staff-table-cell">
          <div className="container h-100">
            {/* Primary Shift */}
            <div className="row">
              <div className="col">
                <div className="staff-table-cell_shift">
                  {slwh.primaryShiftStart && slwh.primaryShiftEnd ? (
                    <Button
                      className="mb-1 p2 staff-schedule-button"
                      onClick={() => handleShowEditStaffSchedule(slwh, staff)}
                    >
                      {format(
                        toZonedTime(slwh.primaryShiftStart, timezone),
                        "HH:mm"
                      )}{" "}
                      -{" "}
                      {format(
                        toZonedTime(slwh.primaryShiftEnd, timezone),
                        "HH:mm"
                      )}
                    </Button>
                  ) : (
                    <button
                      className="icon-button icon-button--sm icon-button--grey empty-shift-button"
                      onClick={() => handleShowEditStaffSchedule(slwh, staff)}
                    >
                      <ZymranIcon name="plus" />
                    </button>
                  )}
                </div>
              </div>
            </div>
            {/* Secondary Shift */}
            <div className="row">
              <div className="col">
                <div className="staff-table-cell_shift">
                  {slwh.secondaryShiftStart && slwh.secondaryShiftEnd ? (
                    <Button
                      className="mb-1 staff-schedule-button"
                      onClick={() => handleShowEditStaffSchedule(slwh, staff)}
                    >
                      {format(
                        toZonedTime(slwh.secondaryShiftStart, timezone),
                        "HH:mm"
                      )}{" "}
                      -{" "}
                      {format(
                        toZonedTime(slwh.secondaryShiftEnd, timezone),
                        "HH:mm"
                      )}
                    </Button>
                  ) : (
                    <button
                      className="icon-button icon-button--sm icon-button--grey empty-shift-button"
                      onClick={() => handleShowEditStaffSchedule(slwh, staff)}
                    >
                      <ZymranIcon name="plus" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </td>
      );
    });
    return staffScheduleTdTags;
  };

  const constructStaffScheduleTableTrTags = () => {
    let staffSchedulesAdded = [];
    let staffScheduleRows = staffSchedules.reduce((rows, staffSchedule) => {
      if (!includesStaff(staffSchedule.staff.id, staffSchedulesAdded)) {
        staffSchedulesAdded.push(staffSchedule.staff.id);
        rows.push(
          <tr key={staffSchedule.id}>
            <td>
              <div className="d-flex align-items-center gap-3 align-self-stretch">
                <ZymranAvatar
                  avatarUrl={staffSchedule.staff.avatarUrl}
                  fullName={`${staffSchedule.staff.firstName} ${staffSchedule.staff.lastName}`}
                  size="md"
                  shape="circle"
                />
                <div className="d-flex- flex-column align-items-start align-selft-stretch">
                  <p className="p2-bold primary-text-color mb-0">
                    {staffSchedule.staff.firstName} {staffSchedule.staff.lastName}
                  </p>
                  <span className="p2 secondary-text-color">
                    {staffSchedule.staff.position}
                  </span>
                </div>
              </div>
            </td>
            {constructStaffScheduleTableTdTags(
              staffSchedule.staffLocationWorkingHours,
              staffSchedule
            )}
          </tr>
        );
      }
      return rows;
    }, []);
    setStaffScheduleRows(staffScheduleRows);
  };

  const includesStaff = (staffId, staffSchedules) => {
    for (let i = 0; i < staffSchedules.length; i++) {
      if (staffSchedules[i] === staffId) {
        return true;
      }
    }
    return false;
  };

  const handleClose = () => {
    setShow(false);
  };

  const updateStaffSchedules = (slwh) => {
    let staffSchedulesToUpdate = [...staffSchedules];
    staffSchedulesToUpdate.forEach((sl) => {
      sl.staffLocationWorkingHours.forEach((item) => {
        if (item.id === slwh.id) {
          item.primaryShiftStart = fromZonedTime(
            slwh.primaryShiftStart,
            timezone
          );
          item.primaryShiftEnd = fromZonedTime(slwh.primaryShiftEnd, timezone);
          item.secondaryShiftStart = slwh.secondaryShiftStart
            ? fromZonedTime(slwh.secondaryShiftStart, timezone)
            : null;
          item.secondaryShiftEnd = slwh.secondaryShiftEnd
            ? fromZonedTime(slwh.secondaryShiftEnd, timezone)
            : null;
          item.dayOff = slwh.dayOff;
        }
      });
    });
    setStaffSchedules(staffSchedulesToUpdate);
  };

  const updateStaffSchedulesAfterCreate = (slwh) => {
    let staffSchedulesToUpdate = [...staffSchedules];
    staffSchedulesToUpdate.forEach((sl) => {
      if (sl.id === slwh.staffLocationId) {
        sl.staffLocationWorkingHours.push(slwh);
      }
    });
    setStaffSchedules(staffSchedulesToUpdate);
  };

  const handleEditSubmit = async (slwh) => {
    const data = {
      id: slwh.id,
      dayOfWeek: slwh.dayOfWeek,
      staffLocationId: slwh.staffLocationId,
      shiftEffectiveStartDate: getDateObjectForDayOfWeek(
        startDate,
        slwh.dayOfWeek
      ),
      primaryShiftStart: fromZonedTime(slwh.primaryShiftStart, timezone),
      primaryShiftEnd: fromZonedTime(slwh.primaryShiftEnd, timezone),
      secondaryShiftStart: slwh.secondaryShiftStart
        ? fromZonedTime(slwh.secondaryShiftStart, timezone)
        : null,
      secondaryShiftEnd: slwh.secondaryShiftEnd
        ? fromZonedTime(slwh.secondaryShiftEnd, timezone)
        : null,
      repeat: slwh.repeat,
      endRepeat: slwh.repeatEndDate
        ? fromZonedTime(slwh.repeatEndDate, timezone)
        : null,
      dayOff: slwh.dayOff,
    };

    try {
      setBlocking(true);
      const response = await updateStaffSchedule(data);
      setBlocking(false);
      if (response.status === 200 || response.status === 201) {
        updateStaffSchedules(slwh);
      }
      notifySuccess(t("staff_location_working_hour_updated"));
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const handleCreateSubmit = async (slwh) => {
    const data = {
      dayOfWeek: slwh.dayOfWeek,
      staffLocationId: slwh.staffLocationId,
      shiftEffectiveStartDate: getDateObjectForDayOfWeek(startDate, slwh.dayOfWeek),
      primaryShiftStart: fromZonedTime(slwh.primaryShiftStart, timezone),
      primaryShiftEnd: fromZonedTime(slwh.primaryShiftEnd, timezone),
      secondaryShiftStart: slwh.secondaryShiftStart
        ? fromZonedTime(slwh.secondaryShiftStart, timezone)
        : null,
      secondaryShiftEnd: slwh.secondaryShiftEnd
        ? fromZonedTime(slwh.secondaryShiftEnd, timezone)
        : null,
      repeat: slwh.repeat,
      endRepeat: slwh.repeatEndDate ? fromZonedTime(slwh.repeatEndDate, timezone) : null,
      dayOff: slwh.dayOff,
    };

    try {
      setBlocking(true);
      const response = await createStaffSchedule(data);
      setBlocking(false);
      if (response.status === 200 || response.status === 201) {
        const newSlwh = response.data;
        updateStaffSchedulesAfterCreate(newSlwh);
      }
      notifySuccess(t("staff_location_working_hour_updated"));
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const CustomInput = forwardRef((props, ref) => {
    return (
      <button
        type="button"
        className="icon-button icon-button--grey icon-button--sm position-relative"
        onClick={props.onClick}
      >
        <ZymranIcon name="calendar" />
      </button>
    );
  });

  const onChangeDate = (date) => {
    setCurrentDate(toZonedTime(date, timezone));
    updateDateRange(date);
  };

  const updateDateRange = (date) => {
    const start = startOfWeek(date, { weekStartsOn: 1 });
    const end = endOfWeek(date, { weekStartsOn: 1 });

    const startDate = fromZonedTime(startOfDay(start), "UTC");
    const endDate = fromZonedTime(startOfDay(end), "UTC");
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onClickBack = () => {
    setCurrentDate(toZonedTime(subDays(currentDate, 7), timezone));
  };

  const onClickToday = () => {
    setCurrentDate(
      toZonedTime(
        setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          0
        ),
        timezone
      )
    );
  };

  const onClickForward = () => {
    setCurrentDate(toZonedTime(addDays(currentDate, 7), timezone));
  };

  function capitalizeMonth(dateStr) {
    // First, capitalize the first letter of the month
    dateStr = dateStr.replace(
      /(\d{1,2}\s)([a-zа-яёқ])/iu,
      (match, p1, p2) => p1 + p2.toUpperCase()
    );

    // Then check if the string ends with a dot, if not, add one
    if (!dateStr.endsWith(".")) {
      dateStr += ".";
    }

    return dateStr;
  }

  const getDateForDayOfWeek = (startDate, dayOfWeek) => {
    if (startDate !== null && startDate !== undefined) {
      if (dayOfWeek === 0) {
        return capitalizeMonth(
          format(
            toZonedTime(addDays(startDate, dayOfWeek + 6), timezone),
            "dd MMM",
            { locale }
          )
        );
      } else {
        return capitalizeMonth(
          format(
            toZonedTime(addDays(startDate, dayOfWeek - 1), timezone),
            "dd MMM",
            { locale }
          )
        );
      }
    }
  };

  const getDateObjectForDayOfWeek = (startDate, dayOfWeekName) => {
    const dayOfWeek = dayOfWeekNumber[dayOfWeekName];

    if (startDate !== null && startDate !== undefined) {
      if (dayOfWeek === 0) {
        return addDays(startDate, dayOfWeek + 6);
      } else {
        return addDays(startDate, dayOfWeek - 1);
      }
    }
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ZymranIcon name="chevron-down" className="secondary-text-color" />
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      {loadingCounter > 0 ? (
        <div className="card-chat d-flex align-items-center justify-content-center">
          <Lottie
            animationData={loadingAnimation}
            loop={true}
            style={{ height: "220px", width: "220px" }}
          />
        </div>
      ) : (
        <Card className="staff-schedule-card">
          <Card.Header>
            <div className="d-flex justify-content-between align-self-stretch">
              <Select
                name="location"
                placeholder={t("select_location")}
                value={selectedLocation}
                options={locationOptions}
                onChange={onChangeLocation}
                ref={locationSelect}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                className="staff-schedule-location-select react-select--sm"
                components={{ DropdownIndicator }}
                styles={{
                  container: (base) => ({
                    ...base,
                    zIndex: 999,
                    minWidth: "150px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "8px",
                    flex: "none",
                    flexGrow: 0,
                  }),
                  menu: (base) => ({
                    ...base,
                    minWidth: "100%",
                  }),
                }}
              />
              <div className="d-flex justify-content-center align-items-center gap-3">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="nextTooltip">Previous</Tooltip>}
                >
                  <button
                    type="button"
                    className="icon-button icon-button--grey-light icon-button--sm"
                    onClick={onClickBack}
                  >
                    <ZymranIcon name="arrow-left" />
                  </button>
                </OverlayTrigger>
                <h2 className="mb-0">
                  {moment(startDate).format("DD")} -{" "}
                  {moment(endDate).format("DD.MM.YYYY")}
                </h2>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="previousTooltip">Next</Tooltip>}
                >
                  <button
                    type="button"
                    className="icon-button icon-button--grey-light icon-button--sm"
                    onClick={onClickForward}
                  >
                    <ZymranIcon name="arrow-right" />
                  </button>
                </OverlayTrigger>
              </div>
              <div className="d-flex align-items-start gap-2">
                <div className="custom-datepicker-wrapper">
                  <DatePicker
                    selected={currentDate}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(date) => onChangeDate(date)}
                    dateFormat="dd MMM yyyy"
                    locale={locale}
                    calendarStartDay={1}
                    customInput={<CustomInput />}
                    wrapperClassName="staff-schedule-datepicker"
                  />
                </div>
                <Button
                  size="sm"
                  variant="grey"
                  type="button"
                  onClick={onClickToday}
                >
                  <Trans>today</Trans>
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body
            className="p-0 d-flex"
            style={{
              flexGrow:
                staffSchedules && staffSchedules.length === 0 ? 1 : "initial",
            }}
          >
            {staffSchedules && staffSchedules.length ? (
              <div className="staff-schedule-empty-wrapper align-items-start justify-content-start">
                <Table
                  bordered
                  className={classNames(
                    { "align-self-start": staffSchedules.length < 7 },
                    { "align-self-stretch": staffSchedules.length >= 7 },
                    "staff-schedule-table mb-0"
                  )}
                >
                  <thead>
                    <tr>
                      <th className="align-middle p2-bold">
                        <div className="d-flex gap-1 align-items-center">
                          <Trans>staff_member</Trans>
                        </div>
                      </th>
                      <th className="align-middle p2-bold">
                        <div>
                          <Trans>mon</Trans>
                        </div>
                        <div>{getDateForDayOfWeek(startDate, 1)}</div>
                      </th>
                      <th className="align-middle p2-bold">
                        <div>
                          <Trans>tue</Trans>
                        </div>
                        <div>{getDateForDayOfWeek(startDate, 2)}</div>
                      </th>
                      <th className="align-middle p2-bold">
                        <div>
                          <Trans>wed</Trans>
                        </div>
                        <div>{getDateForDayOfWeek(startDate, 3)}</div>
                      </th>
                      <th className="align-middle p2-bold">
                        <div>
                          <Trans>thu</Trans>
                        </div>
                        <div>{getDateForDayOfWeek(startDate, 4)}</div>
                      </th>
                      <th className="align-middle p2-bold">
                        <div>
                          <Trans>fri</Trans>
                        </div>
                        <div>{getDateForDayOfWeek(startDate, 5)}</div>
                      </th>
                      <th className="align-middle p2-bold">
                        <div>
                          <Trans>sat</Trans>
                        </div>
                        <div>{getDateForDayOfWeek(startDate, 6)}</div>
                      </th>
                      <th className="align-middle p2-bold">
                        <div>
                          <Trans>sun</Trans>
                        </div>
                        <div>{getDateForDayOfWeek(startDate, 0)}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{staffScheduleRows}</tbody>
                </Table>
              </div>
            ) : (
              <div className="staff-schedule-empty-wrapper py-4">
                <div className="d-flex flex-column gap-4 align-items-center justify-content-center secondary-text-color">
                  <AddUserIcon className="no-employees-icon" />
                  <Trans>employees_not_added_yet</Trans>
                </div>
                <Button
                  name="addEmployeeInTableButton"
                  variant="blue"
                  size="sm"
                  type="button"
                  onClick={() => setCustomerCreateModalShow(true)}
                >
                  <ZymranIcon name="plus" />
                  <Trans>add_staff_to_location</Trans>
                </Button>
              </div>
            )}
          </Card.Body>
          <EditStaffSchedule
            timezone={timezone}
            show={show}
            parentSlwh={selectedSlwh}
            selectedStaff={selectedStaff}
            isCreateOperation={isCreateOperation}
            handleEditSubmit={handleEditSubmit}
            handleCreateSubmit={handleCreateSubmit}
            updateStaffSchedules={updateStaffSchedules}
            handleCloseEditStaffScheduleModal={handleClose}
          />

          {/* Create Staff Modal */}
          <Modal
            show={customerCreateModalShow}
            onHide={() => setCustomerCreateModalShow(false)}
            fullscreen={true}
          >
            <Modal.Header
              closeButton
              closeVariant="white"
              className="fullscreen-modal-header"
            >
              <Modal.Title>
                <Trans>create</Trans>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateStaff
                onCreate={loadStaffSchedules}
                handleClose={() => setCustomerCreateModalShow(false)}
              />
            </Modal.Body>
          </Modal>

          <BlockUI tag="div" blocking={blocking} />
        </Card>
      )}
    </>
  );
};

export default StaffSchedule;
