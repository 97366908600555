import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getDashboardIncome = (startDate, endDate) => {
  return http.get(`dashboard/`, {
    headers: authHeader(),
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
};

const getDashboardServices = (startDate, endDate) => {
  return http.get(`dashboard/services`, {
    headers: authHeader(),
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
};

const getDashboardLocationServicesSum = (startDate, endDate) => {
  return http.get(`dashboard/sum-per-service-per-location`, {
    headers: authHeader(),
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
};

const getDashboardLocationRegistrySum = (startDate, endDate) => {
  return http.get(`dashboard/registry-sum-per-location`, {
    headers: authHeader(),
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
};

const getDashboardEmployeeServicesStats = (startDate, endDate) => {
  return http.get(`dashboard/services-stats-per-employee`, {
    headers: authHeader(),
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
};

export {
  getDashboardIncome,
  getDashboardServices,
  getDashboardLocationServicesSum,
  getDashboardLocationRegistrySum,
  getDashboardEmployeeServicesStats,
};
