import { Button, Form } from "react-bootstrap";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { useState } from "react";
import {
  restorePasswordStep1 as _restorePasswordStep1,
  restorePasswordStep2 as _restorePasswordStep2,
  restorePasswordStep3 as _restorePasswordStep3,
  resendOtp as _resendOtp,
} from "service/auth/AuthService";
import { Trans, useTranslation } from "react-i18next";
import { notifySuccess } from "util/notify";
import RegistrationProgressBar from "../partner/RegistrationProgressBar";
import Flex from "component/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { parsePhoneNumber } from "libphonenumber-js/core";
import metadata from "libphonenumber-js/metadata.min.json";
import { Link } from "react-router-dom";
import {
  HTTP_STATUS,
  REGISTRATION_STEPS,
  RESTORE_PASSWORD_STEPS,
} from "util/constants";

library.add(far);

const PasswordRestore = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [blocking, setBlocking] = useState(false);
  const [userData, setUserData] = useState({
    userInformation: {
      login: "",
      email: "",
      phoneNumber: "",
      code: "",
    },
    passwordData: {
      token: "",
      password: "",
      passwordConfirm: "",
    },
  });

  const [errors, setErrors] = useState({
    userInformation: {},
    passwordData: {},
  });

  const activeStep1Tab = "emailTab";
  const { t } = useTranslation();

  const setField = (parentField, field, value) => {
    setUserData({
      ...userData,
      [parentField]: {
        ...userData[parentField],
        [field]: value,
      },
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[parentField][field])
      setErrors({
        ...errors,
        [parentField]: {
          ...errors[parentField],
          [field]: null,
        },
      });
  };

  const restorePasswordStep1 = async (e) => {
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      // We've got errors!
      setErrors({
        ...errors,
        userInformation: newErrors,
      });
    } else {
      try {
        setBlocking(true);
        const payload = {
          login: userData.userInformation.login,
        };
        await _restorePasswordStep1(payload);
        activeStep1Tab === "emailTab"
          ? notifySuccess(t("email_password_restore_instruction"))
          : notifySuccess(t("phone_password_restore_instruction"));
        setCurrentStep(RESTORE_PASSWORD_STEPS.STEP2);
      } catch (error) {
        console.error(error);
      } finally {
        setBlocking(false);
      }
    }
  };

  const restorePasswordStep2 = async (e) => {
    const newErrors = findUserCodeFormErrors();

    if (Object.keys(newErrors).length > 0) {
      // We've got errors!
      setErrors({
        ...errors,
        userInformation: newErrors,
      });
    } else {
      setBlocking(true);
      let payload = {};
      if (
        userData.userInformation.phoneNumber &&
        userData.userInformation.phoneNumber !== ""
      ) {
        payload = {
          login: userData.userInformation.phoneNumber,
          code: userData.userInformation.code,
        };
      } else if (
        userData.userInformation.email &&
        userData.userInformation.email !== ""
      ) {
        payload = {
          login: userData.userInformation.email,
          code: userData.userInformation.code,
        };
      }

      try {
        const response = await _restorePasswordStep2(payload);
        setField("passwordData", "token", response.data.token);
        setCurrentStep(REGISTRATION_STEPS.STEP3);
      } catch (error) {
        console.error(error);
      } finally {
        setBlocking(false);
      }
    }
  };

  const resendOtp = async (e) => {
    try {
      setBlocking(true);
      const payload = {
        login:
          userData.userInformation.email ||
          userData.userInformation.phoneNumber,
      };
      await _resendOtp(payload);
      notifySuccess(t("otp_was_resent"));
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const restorePasswordStep3 = async (e) => {
    const newErrors = findPasswordFormErrors();

    if (Object.keys(newErrors).length > 0) {
      // We've got errors!
      setErrors({
        ...errors,
        passwordData: newErrors,
      });
    } else {
      try {
        setBlocking(true);
        const payload = {
          token: userData.passwordData.token,
          password: userData.passwordData.password,
          passwordConfirm: userData.passwordData.passwordConfirm,
        };
        const response = await _restorePasswordStep3(payload);
        notifySuccess(t("password_successfully_reset"));
        if (response.status === HTTP_STATUS.OK) {
          setCurrentStep(RESTORE_PASSWORD_STEPS.STEP4);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setBlocking(false);
      }
    }
  };

  const findFormErrors = () => {
    const { login } = userData.userInformation;

    const newErrors = {};

    if (!login || login === "") {
      newErrors.login = t("field_required");
    } else {
      // Check if the login is an email
      const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(login);

      // Check if the login is a phone number (assuming it starts with '+')
      const isPhoneNumber = /^\+?[0-9\s]+$/.test(login);

      if (isEmail && login.length > 256) newErrors.login = t("too_long");

      if (isPhoneNumber) {
        if (login.length > 12) newErrors.login = t("too_long");
        else {
          try {
            const phoneNumberObject = parsePhoneNumber(login, metadata);
            if (!phoneNumberObject.isValid())
              newErrors.login = t("invalid_phone_number");
          } catch (error) {
            newErrors.login = t("invalid_phone_number");
          }
        }
      }

      if (isEmail) {
        setField("userInformation", "email", login);
      } else if (isPhoneNumber) {
        setField("userInformation", "phoneNumber", login);
      }

      if (!isEmail && !isPhoneNumber) {
        newErrors.login = t("invalid_login");
      }
    }
    return newErrors;
  };

  const findUserCodeFormErrors = () => {
    const { code } = userData.userInformation;

    const newErrors = {};

    if (!code || code === "") newErrors.code = t("field_required");
    else if (code.length !== 4) newErrors.code = t("wrong_length");

    return newErrors;
  };

  const findPasswordFormErrors = () => {
    const { password, passwordConfirm } = userData.passwordData;

    const newErrors = {};

    if (!password || password === "") newErrors.password = t("field_required");
    else if (password.length > 32) newErrors.password = t("too_long");

    if (!passwordConfirm || passwordConfirm === "")
      newErrors.passwordConfirm = t("field_required");
    else if (passwordConfirm.length > 32)
      newErrors.passwordConfirm = t("too_long");

    if (password !== passwordConfirm)
      newErrors.passwordConfirm = t("repeat_password_does_not_match");

    return newErrors;
  };

  return (
    <>
      <h1 className="mb-0">
        <Trans>password_restore</Trans>
      </h1>
      <RegistrationProgressBar currentStep={currentStep} maxSteps={3} />
      <Form>
        <Step1
          currentStep={currentStep}
          userInformation={userData.userInformation}
          errors={errors.userInformation}
          setField={setField}
          restorePasswordStep1={restorePasswordStep1}
        />
        <Step2
          currentStep={currentStep}
          errors={errors.userInformation}
          setField={setField}
          restorePasswordStep2={restorePasswordStep2}
          resendOtp={resendOtp}
        />
        <Step3
          passwordData={userData.passwordData}
          currentStep={currentStep}
          errors={errors.passwordData}
          setField={setField}
          restorePasswordStep3={restorePasswordStep3}
        />
        {currentStep === RESTORE_PASSWORD_STEPS.STEP4 && (
          <Flex justifyContent="center" alignItems="center" direction="column">
            <FontAwesomeIcon
              icon="fa-regular fa-circle-check"
              className="fs-4 mb-3"
              bounce
            />
            <p className="p1-bold text-success">
              <Trans>password_change_finished</Trans>
            </p>
            <Link to="/signin/partner" className="text-decoration-none">
              <Button name="loginButton" className="btn-blue-light">
                <Trans>login</Trans>
              </Button>
            </Link>
          </Flex>
        )}
      </Form>
    </>
  );
};

export default PasswordRestore;
