import { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import Select from "react-select";
import { getCashRegistersAll } from "service/cash-register/CashRegisterService";
import { notifyError, notifySuccess } from "util/notify";
import { checkoutAppointment } from "service/appointment/AppointmentService";
import { getChainedAppointments } from "../../../service/appointment/AppointmentService";
import { toZonedTime } from "date-fns-tz";
import { isNumericInput } from "util/validation";

const NewCheckout = ({
  appointment,
  handleClose,
  locationId,
  updateCheckoutInfo,
  currentDateFormatted,
  handleOpenViewAppointmentModal,
  timezone,
}) => {
  const [customer, setCustomer] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    phoneNumberPrimary: "",
    phoneNumberSecondary: "",
    dob: "",
    createdAt: "",
    notes: "",
  });
  const [appointments, setAppointments] = useState([]);
  const [cashRegisters, setCashRegisters] = useState([]);
  const [cashRegisterOptions, setCashRegisterOptions] = useState([]);
  const [cashRegisterOptionsWithoutMixed, setCashRegisterOptionsWithoutMixed] =
    useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [acceptedAmountForPayment, setAcceptedAmountForPayment] = useState(0);
  const [isPaymentInputDisabled, setIsPaymentInputDisabled] = useState(false);

  const [
    selectedCashRegistersForMixedPayment,
    setSelectedCashRegistersForMixedPayment,
  ] = useState([]);
  const [mixedPaymentValueByCashRegister, setMixedPaymentValueByCashRegister] =
    useState({});

  const [changeValue, setChangeValue] = useState(0);
  const [showChangePaymentMethods, setShowChangePaymentMethods] =
    useState(false);
  const [selectedChangePaymentMethod, setSelectedChangePaymentMethod] =
    useState(null);
  const [cashRegisterOptionsForChange, setCashRegisterOptionsForChange] =
    useState([]);
  const [selectedCashRegistersForChange, setSelectedCashRegistersForChange] =
    useState([]);
  const [changeValueByCashRegister, setChangeValueByCashRegister] = useState(
    {}
  );
  const [isChangeReturnInputDisabled, setIsChangeReturnInputDisabled] =
    useState(false);

  const [isCheckoutButtonDisabled, setIsCheckoutButtonDisabled] =
    useState(true);
  const isOnePayment =
    selectedPaymentMethod && selectedPaymentMethod.alias !== "mixed";
  const isMixedPayment =
    selectedPaymentMethod && selectedPaymentMethod.alias === "mixed";
  const isOneChangeReturn =
    selectedChangePaymentMethod &&
    selectedChangePaymentMethod.alias !== "mixed";
  const isMixedChangeReturn =
    selectedChangePaymentMethod &&
    selectedChangePaymentMethod.alias === "mixed";

  const totalSum = appointments.reduce((acc, curr) => acc + curr.price, 0);
  const [mixedPaymentTotal, setMixedPaymentTotal] = useState(0);

  const isOnePaymentWithoutChange =
    isOnePayment && parseFloat(acceptedAmountForPayment) === totalSum;
  const isMixedPaymentWithoutChange =
    isMixedPayment && mixedPaymentTotal === totalSum;
  const [blocking, setBlocking] = useState(false);

  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (appointments.length === 0) {
      loadChainedAppointments(appointment.chainId);
    }
    // eslint-disable-next-line
  }, [appointments.length, appointments]);

  useEffect(() => {
    if (appointments.length > 0) {
      const firstAppointment = appointments[0];
      setCustomer(firstAppointment.customer);
    }
    // eslint-disable-next-line
  }, [appointments.length, appointments]);

  useEffect(() => {
    const total = Object.values(mixedPaymentValueByCashRegister)
      .filter((value) => !isNaN(parseFloat(value)))
      .reduce((acc, value) => acc + parseFloat(value), 0);

    setMixedPaymentTotal(total);
    // eslint-disable-next-line
  }, [mixedPaymentValueByCashRegister]);

  useEffect(() => {
    if (mixedPaymentTotal > totalSum) {
      setChangeValue(mixedPaymentTotal - totalSum);
      setShowChangePaymentMethods(true);
    } else if (mixedPaymentTotal === totalSum) {
      setIsCheckoutButtonDisabled(false);
      setShowChangePaymentMethods(false);
    } else {
      setShowChangePaymentMethods(false);
      setIsCheckoutButtonDisabled(true);
    }
    // eslint-disable-next-line
  }, [mixedPaymentTotal, totalSum]);

  useEffect(() => {
    loadCashRegisters();
    // eslint-disable-next-line
  }, []);

  const loadChainedAppointments = async (chainId) => {
    try {
      setBlocking(true);
      const response = await getChainedAppointments(chainId);
      const appointmentList = response.data;
      const appts = [];
      appointmentList.forEach((appointment) => {
        let appt = {
          id: appointment.id,
          chainId: appointment.chainId,
          startTime: toZonedTime(new Date(appointment.startTime), timezone),
          endTime: toZonedTime(new Date(appointment.endTime), timezone),
          duration: calculateDuration(
            appointment.startTime,
            appointment.endTime
          ),
          resourceId: appointment.staffLocation.id,
          selectedStaffLocation: {
            value: appointment.staffLocation.id,
            label:
              appointment.staffLocation.staff.firstName +
              " " +
              appointment.staffLocation.staff.lastName,
          },
          selectedService: {
            value: appointment.service.id,
            label: appointment.service.name,
          },
          customer: appointment.customer,
          price: appointment.service.price,
          checkoutInfo: appointment.checkoutInfo,
          status: appointment.status,
          fromDB: true,
          toSave: false,
          toDelete: false,
          comment: appointment.comment,
        };
        appts.push(appt);
      });
      setAppointments(appts);
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const duration = (end - start) / 60000; // Calculate the duration in minutes
    return duration;
  };

  const loadCashRegisters = async () => {
    try {
      const response = await getCashRegistersAll();
      setCashRegisters(response.data);
      const localCashRegisters = response.data.filter(
        (cashRegister) => cashRegister.location.id === locationId
      );
      let cashRegisterOptions = localCashRegisters.map((cashRegister) => ({
        label: `${t(
          "cash_register_type_" + cashRegister.cashRegisterType.name
        )}`,
        value: cashRegister.id,
        alias: cashRegister.cashRegisterType.name,
        balance: cashRegister.balance,
      }));
      let cashRegisterOptionsChange = localCashRegisters.map(
        (cashRegister) => ({
          label: `${t(
            "cash_register_type_" + cashRegister.cashRegisterType.name
          )} (${cashRegister.balance})`,
          value: cashRegister.id,
          alias: cashRegister.cashRegisterType.name,
          balance: cashRegister.balance,
          isDisabled: cashRegister.balance === 0,
        })
      );

      setCashRegisterOptionsWithoutMixed(cashRegisterOptions);

      const defaultOptions = [
        { label: t("split_payment"), value: "mixed", alias: "mixed" },
      ];

      cashRegisterOptions = [...cashRegisterOptions, ...defaultOptions];
      cashRegisterOptionsChange = [
        ...cashRegisterOptionsChange,
        ...defaultOptions,
      ];

      setCashRegisterOptionsForChange(cashRegisterOptionsChange);
      setCashRegisterOptions(cashRegisterOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePaymentMethodChange = (selectedOption) => {
    setSelectedPaymentMethod(selectedOption);
    setAcceptedAmountForPayment("");
    setSelectedChangePaymentMethod(null);
    setMixedPaymentValueByCashRegister({});
    setErrors({});

    if (selectedOption.alias !== "cash" && selectedOption.alias !== "mixed") {
      setAcceptedAmountForPayment(totalSum);
      setIsPaymentInputDisabled(true);
      setIsCheckoutButtonDisabled(false);
    } else {
      setIsPaymentInputDisabled(false);
      setIsCheckoutButtonDisabled(true);
    }

    setChangeValue(0);
    setShowChangePaymentMethods(false);
  };

  const handleChangePaymentMethodChange = (selectedOption) => {
    setSelectedChangePaymentMethod(selectedOption);

    if (selectedOption?.alias !== "mixed") {
      setIsChangeReturnInputDisabled(true);
    }
    setIsCheckoutButtonDisabled(false);
  };

  const handleAcceptedAmountForPaymentInput = (e) => {
    const value = e.target.value;
    if (value === "") {
      setAcceptedAmountForPayment("");
    }
    else if (isNumericInput(value)) {
      setAcceptedAmountForPayment(value);

      if (
        selectedPaymentMethod.alias === "cash" &&
        parseFloat(value) > totalSum
      ) {
        setChangeValue(parseFloat(value) - totalSum);
        setShowChangePaymentMethods(true);
        console.log(
          "parseFloat(value) - totalSum : ",
          parseFloat(value) - totalSum
        );
      } else {
        setShowChangePaymentMethods(false);
        setSelectedChangePaymentMethod(null);
      }
      if (
        selectedPaymentMethod.alias === "cash" &&
        parseFloat(value) === totalSum
      ) {
        setIsCheckoutButtonDisabled(false);
      }
    }
  };

  const handleMixedPaymentInput = (e, cashRegisterId) => {
    const value = e.target.value;
    if (isNumericInput(value)) {
      setMixedPaymentValueByCashRegister((prevValues) => ({
        ...prevValues,
        [cashRegisterId]: value,
      }));
    }
  };

  const handleMixedChangeReturnInput = (e, cashRegisterId) => {
    const value = e.target.value;
    if (isNumericInput(value)) {
      const updatedChangeValueByCashRegister = {
        ...changeValueByCashRegister,
        [cashRegisterId]: value,
      };

      setChangeValueByCashRegister(updatedChangeValueByCashRegister);

      const newTotal = Object.values(updatedChangeValueByCashRegister)
        .filter((val) => !isNaN(parseFloat(val)))
        .reduce((acc, val) => acc + parseFloat(val), 0);

      if (
        parseFloat(value) >
        cashRegisters.find((cr) => cr.id === cashRegisterId).balance
      ) {
        setIsCheckoutButtonDisabled(true);
      } else if (newTotal < changeValue) {
        setIsCheckoutButtonDisabled(true);
      } else {
        setIsCheckoutButtonDisabled(false);
      }
      setErrors({});
    }
  };

  const handleMixedPaymentCashRegisterCheckboxChange = (
    cashRegisterId,
    checked
  ) => {
    if (checked) {
      setSelectedCashRegistersForMixedPayment((prevSelected) => [
        ...prevSelected,
        cashRegisterId,
      ]);
    } else {
      setSelectedCashRegistersForMixedPayment((prevSelected) =>
        prevSelected.filter((id) => id !== cashRegisterId)
      );
    }
  };

  const handleCashRegisterCheckboxChange = (cashRegisterId, checked) => {
    if (checked) {
      setSelectedCashRegistersForChange((prevSelected) => [
        ...prevSelected,
        cashRegisterId,
      ]);
    } else {
      setSelectedCashRegistersForChange((prevSelected) =>
        prevSelected.filter((id) => id !== cashRegisterId)
      );
    }
  };

  const findFormErrors = () => {
    const newErrors = {};
    let isValid = true;
    const mixedChangeTotal = calculateMixedChangeTotal();

    if (isMixedPayment) {
      if (selectedCashRegistersForMixedPayment.length === 0) {
        newErrors.mixedPaymentValue = t("at_least_one_field_required");
        isValid = false;
      } else if (mixedPaymentTotal < totalSum) {
        newErrors.mixedPaymentValue = t("payment_less_than_total_sum");
        isValid = false;
      }
    }

    if (isMixedChangeReturn) {
      if (mixedChangeTotal !== changeValue) {
        newErrors.mixedChangeValue = t("change_value_mismatch");
        isValid = false;
      }
    }
    return newErrors;
  };

  const calculateMixedChangeTotal = () => {
    return Object.values(changeValueByCashRegister)
      .filter((value) => !isNaN(parseFloat(value)))
      .reduce((acc, value) => acc + parseFloat(value), 0);
  };

  const checkoutDefault = async () => {
    if (
      acceptedAmountForPayment === undefined ||
      acceptedAmountForPayment === 0
    ) {
      notifyError(t("accepted_amount_for_payment_required"));
      return;
    }

    const newErrors = findFormErrors();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const mixedPayment = selectedCashRegistersForMixedPayment.reduce(
        (acc, cashRegisterId) => {
          const inputValue = mixedPaymentValueByCashRegister[cashRegisterId];
          if (inputValue) {
            acc[cashRegisterId] = parseFloat(inputValue);
          }
          return acc;
        },
        {}
      );

      const change = selectedCashRegistersForChange.reduce(
        (acc, cashRegisterId) => {
          const inputValue = changeValueByCashRegister[cashRegisterId];
          if (inputValue) {
            acc[cashRegisterId] = parseFloat(inputValue);
          }
          return acc;
        },
        {}
      );

      let payload = {};

      if (isOnePaymentWithoutChange) {
        payload = {
          chainId: appointments[0].chainId,
          sums: { [selectedPaymentMethod.value]: acceptedAmountForPayment },
          change: null,
          locationId: locationId,
        };
      } else if (isMixedPaymentWithoutChange) {
        payload = {
          chainId: appointments[0].chainId,
          sums: mixedPayment,
          change: null,
          locationId: locationId,
        };
      } else if (isOnePayment && isOneChangeReturn) {
        payload = {
          chainId: appointments[0].chainId,
          sums: { [selectedPaymentMethod.value]: acceptedAmountForPayment },
          change: { [selectedChangePaymentMethod.value]: changeValue },
          locationId: locationId,
        };
      } else if (isMixedPayment && isMixedChangeReturn) {
        payload = {
          chainId: appointments[0].chainId,
          sums: mixedPayment,
          change: Object.keys(change).length > 0 ? change : null,
          locationId: locationId,
        };
      } else if (isOnePayment && isMixedChangeReturn) {
        payload = {
          chainId: appointments[0].chainId,
          sums: { [selectedPaymentMethod.value]: acceptedAmountForPayment },
          change: Object.keys(change).length > 0 ? change : null,
          locationId: locationId,
        };
      } else if (isMixedPayment && isOneChangeReturn) {
        payload = {
          chainId: appointments[0].chainId,
          sums: mixedPayment,
          change: { [selectedChangePaymentMethod.value]: changeValue },
          locationId: locationId,
        };
      }
      try {
        const response = await checkoutAppointment(payload);
        notifySuccess(t("appointment_processed_successfully"));
        updateCheckoutInfo(response.data, appointments[0].chainId);
        handleClose();
        if (handleOpenViewAppointmentModal) {
          handleOpenViewAppointmentModal(appointments[0]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="checkout-body-layout">
      <div className="checkout-container">
        <Card className="checkout-card">
          <Card.Header className="checkout-card-header">
            <h3 className="mb-0">{currentDateFormatted}</h3>
          </Card.Header>
          <Card.Body className="checkout-card-body">
            <div className="checkout-info-and-payment-method primary-text-color">
              <div className="checkout-info">
                <div className="checkout-info-line">
                  {customer ? (
                    <>
                      <span className="p1-bold">
                        <Trans>customer</Trans>
                      </span>
                      <span className="p1">
                        {customer.firstName} {customer.lastName}
                      </span>
                    </>
                  ) : (
                    <Trans>no_customer_data_available</Trans>
                  )}
                </div>
                <div className="checkout-info-line">
                  <span className="p1-bold">
                    <Trans>sum_to_pay_short</Trans>
                  </span>
                  <span className="p1">{totalSum}</span>
                </div>
              </div>
              <div className="checkout-payment-method">
                <span className="p1-bold">
                  <Trans>payment_method</Trans>
                </span>
                <Select
                  id="paymentMethod"
                  name="paymentMethod"
                  className="zymran-select"
                  classNamePrefix="react-select"
                  placeholder={t("choose_payment_method")}
                  options={cashRegisterOptions}
                  onChange={handlePaymentMethodChange}
                />
              </div>
              {selectedPaymentMethod &&
                selectedPaymentMethod.alias === "mixed" && (
                  <>
                    <div className="checkout-checkbox-block">
                      {cashRegisterOptionsWithoutMixed.map(
                        (cashRegister, index) => (
                          <div
                            className="checkout-checkbox-and-input"
                            key={index}
                          >
                            <Form.Check
                              type="checkbox"
                              id={`cashRegisterCheckboxForMixedPay-${index}`}
                              label={cashRegister.label}
                              checked={selectedCashRegistersForMixedPayment.includes(
                                cashRegister.value
                              )}
                              onChange={(e) =>
                                handleMixedPaymentCashRegisterCheckboxChange(
                                  cashRegister.value,
                                  e.target.checked
                                )
                              }
                            />
                            <Form.Group>
                              <Form.Control
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                className="form-control"
                                name={`mixedPaymentValue-${index}`}
                                disabled={
                                  !selectedCashRegistersForMixedPayment.includes(
                                    cashRegister.value
                                  )
                                }
                                onChange={(e) =>
                                  handleMixedPaymentInput(e, cashRegister.value)
                                }
                                isInvalid={!!errors.mixedPaymentValue}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.mixedPaymentValue}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        )
                      )}
                    </div>
                  </>
                )}
              {selectedPaymentMethod &&
                selectedPaymentMethod.alias !== "mixed" && (
                  <>
                    <div className="checkout-payment-method-line">
                      <span className="p1-bold">
                        <Trans>accepted_for_payment</Trans>
                      </span>
                      <Form.Control
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        className="form-control"
                        name="acceptedAmountForPayment"
                        value={acceptedAmountForPayment}
                        onChange={handleAcceptedAmountForPaymentInput}
                        disabled={isPaymentInputDisabled}
                        isInvalid={!!errors.acceptedAmountForPayment}
                      />
                    </div>
                  </>
                )}
              {showChangePaymentMethods && (
                <>
                  <div className="checkout-change-value-line">
                    <span className="p1-bold">
                      <Trans>payment_change</Trans>
                    </span>
                    {changeValue}
                  </div>
                  <hr className="checkout-change-divider" />
                  <div className="checkout-payment-method-line">
                    <span className="p1-bold">
                      <Trans>change_return_method</Trans>
                    </span>
                    <Select
                      id="changePaymentMethod"
                      name="changePaymentMethod"
                      className="zymran-select"
                      classNamePrefix="react-select"
                      placeholder={t("choose_change_return_method")}
                      options={cashRegisterOptionsForChange}
                      onChange={handleChangePaymentMethodChange}
                      value={selectedChangePaymentMethod}
                    />
                  </div>
                </>
              )}
              {selectedChangePaymentMethod &&
                showChangePaymentMethods &&
                selectedChangePaymentMethod.alias === "mixed" && (
                  <>
                    <div className="checkout-checkbox-block">
                      {cashRegisterOptionsWithoutMixed.map(
                        (cashRegister, index) => (
                          <div
                            className="checkout-checkbox-and-input"
                            key={index}
                          >
                            <Form.Check
                              type="checkbox"
                              id={`cashRegisterCheckboxForMixedChange-${index}`}
                              label={
                                <>
                                  <div>{cashRegister.label}</div>
                                  <div style={{ fontSize: "smaller" }}>
                                    <Trans>balance</Trans> :{" "}
                                    {cashRegister.balance}
                                  </div>
                                </>
                              }
                              checked={selectedCashRegistersForChange.includes(
                                cashRegister.value
                              )}
                              onChange={(e) =>
                                handleCashRegisterCheckboxChange(
                                  cashRegister.value,
                                  e.target.checked
                                )
                              }
                              className="align-items-center"
                              disabled={cashRegister.balance === 0}
                            />
                            <Form.Group>
                              <Form.Control
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                className="form-control"
                                name={`mixedChangeValue-${index}`}
                                disabled={
                                  !selectedCashRegistersForChange.includes(
                                    cashRegister.value
                                  )
                                }
                                onChange={(e) =>
                                  handleMixedChangeReturnInput(
                                    e,
                                    cashRegister.value
                                  )
                                }
                                isInvalid={!!errors.mixedChangeValue}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.mixedChangeValue}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        )
                      )}
                    </div>
                  </>
                )}
              {selectedChangePaymentMethod &&
                selectedChangePaymentMethod.alias !== "mixed" && (
                  <>
                    <div className="checkout-payment-method-line">
                      <span className="p1-bold">
                        <Trans>amount_to_be_issued</Trans>
                      </span>
                      <Form.Control
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        className="form-control"
                        name="acceptedAmountForPayment"
                        value={changeValue}
                        onChange={handleAcceptedAmountForPaymentInput}
                        disabled={isChangeReturnInputDisabled}
                        isInvalid={!!errors.acceptedAmountForPayment}
                      />
                    </div>
                  </>
                )}
            </div>
          </Card.Body>
        </Card>
        <div className="checkout-buttons-block">
          <Button name="cancelButton" variant="grey" onClick={handleClose}>
            <Trans>cancel</Trans>
          </Button>
          <Button
            name="confirmPaymentButton"
            onClick={checkoutDefault}
            disabled={isCheckoutButtonDisabled}
          >
            <Trans>confirm_payment</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewCheckout;
