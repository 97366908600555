import { isNumeric } from "validator";

const isNumericInput = (value) => {
  const stringValue = String(value);
  return isNumeric(stringValue, { no_symbols: true });
}

const isEmail = (value) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(value);
};

const isPhoneNumber = (value) => {
  const phoneRegex = /^\+?[0-9\s]+$/;
  return phoneRegex.test(value);
};

export {
  isNumericInput,
  isEmail,
  isPhoneNumber,
}