import React, { useContext, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Nav, Navbar, Row, Col } from "react-bootstrap";
import { navbarBreakPoint, topNavbarBreakpoint } from "config";
import AppContext from "context/Context";
import Flex from "component/common/Flex";
import Logo from "component/common/Logo";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import ToggleButton from "./ToggleButton";
import routes from "routes/routes";
import { capitalize } from "helpers/utils";
import NavbarTopDropDownMenus from "component/navbar/top/NavbarTopDropDownMenus";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bgNavbar from "assets/img/generic/bg-navbar.png";
import ZymranIcon from "component/common/ZymranIcon";

const NavbarVerticalZymran = () => {
  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu,
    },
    setConfig,
  } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  const currentLocation = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add("navbar-vertical-collapsed");
    } else {
      HTMLClassList.remove("navbar-vertical-collapsed");
    }
    return () => {
      HTMLClassList.remove("navbar-vertical-collapsed-hover");
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add("navbar-vertical-collapsed-hover");
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove("navbar-vertical-collapsed-hover");
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig("showBurgerMenu", !showBurgerMenu);
    }
  };

  const isHomePageMenuItemActive = () => {
    // Check if the current path is '/', '/profile', or '/register'
    return (
      ["/profile"].includes(currentLocation.pathname) ||
      currentLocation.pathname === "/"
    );
  };

  return (
    <>
      <Navbar
        expand={navbarBreakPoint}
        className={classNames("navbar-vertical jazu-navbar", {
          [`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
        })}
        variant="light"
      >
        <div className="align-items-center sidebar-toggle-icon-area">
          <ToggleButton />
          <Logo at="navbar-vertical" width={40} />
        </div>

        <Navbar.Collapse
          in={showBurgerMenu}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            backgroundImage:
              navbarStyle === "vibrant"
                ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
                : "none",
          }}
        >
          <div className="navbar-vertical-content scrollbar">
            <div className="d-flex flex-column justify-content-between h-100">
              <Nav className="flex-column" as="ul">
                <Nav.Item
                  as="li"
                  key="homePage"
                  onClick={handleNavItemClick}
                  className="home-page-nav-item"
                >
                  <NavLink
                    end={true}
                    to="/"
                    className={
                      isHomePageMenuItemActive()
                        ? "active nav-link"
                        : "nav-link"
                    }
                  >
                    <Flex
                      alignItems="center"
                      className="align-self-stretch gap-125"
                    >
                      <span className="nav-link-icon text-center">
                        <ZymranIcon name="home" size={24} />
                      </span>
                      <span className="nav-link-text p2">{t("home_page")}</span>
                    </Flex>
                  </NavLink>
                </Nav.Item>
              </Nav>
              <Nav className="flex-column mt-auto mb-auto" as="ul">
                {routes.map((route) => (
                  <Fragment key={route.label}>
                    {!route.labelDisable && (
                      <NavbarLabel label={capitalize(route.label)} />
                    )}
                    <NavbarVerticalMenu
                      routes={route.children}
                      isTopLevel={true}
                    />
                  </Fragment>
                ))}
              </Nav>
              <div style={{ height: "2rem" }}></div>
            </div>

            <>
              {navbarPosition === "combo" && (
                <div className={`d-${topNavbarBreakpoint}-none`}>
                  <div className="navbar-vertical-divider">
                    <hr className="navbar-vertical-hr my-2" />
                  </div>
                  <Nav navbar>
                    <NavbarTopDropDownMenus />
                  </Nav>
                </div>
              )}
            </>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

NavbarVerticalZymran.propTypes = {
  label: PropTypes.string,
};

export default NavbarVerticalZymran;
