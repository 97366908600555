import React, { useEffect } from "react";
import { confirmRegistration } from "../../service/auth/AuthService";
import { Trans, useTranslation } from "react-i18next";
import { notifySuccess } from "../../util/notify";
import { useParams } from "react-router-dom";

const ConfirmRegistration = () => {
  const { t } = useTranslation();
  const { uuid } = useParams();

  useEffect(() => {
    const confirm = async (uuid) => {
      try {
        await confirmRegistration(uuid);
        notifySuccess(t("email_confirmed"));
      } catch (error) {
        console.error(error);
      }
    };
    confirm(uuid);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <h1>
        <Trans>email_confirmation</Trans>
      </h1>
    </div>
  );
};

export default ConfirmRegistration;
