import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { Trans } from "react-i18next";
import barber from "assets/img/icons/business-types/barber.svg";
import beautySalon from "assets/img/icons/business-types/beauty_salon.svg";
import cosmetologist from "assets/img/icons/business-types/cosmetologist.svg";
import spa from "assets/img/icons/business-types/spa.svg";
import hairDresser from "assets/img/icons/business-types/haircut.svg";
import massage from "assets/img/icons/business-types/massage.svg";
import nails from "assets/img/icons/business-types/nails.svg";
import tattoo from "assets/img/icons/business-types/tattoo.svg";

const BusinessTypeButton = ({ alias, checked, attribute, onChange }) => {
  const businessTypeMap = {
    beauty_saloon: { image: beautySalon, translationKey: "beauty" },
    haircut: { image: hairDresser, translationKey: "hairdresser" },
    nails: { image: nails, translationKey: "nails" },
    barbershop: { image: barber, translationKey: "hair" },
    massage: { image: massage, translationKey: "massage" },
    spa: { image: spa, translationKey: "spa" },
    tatoo: { image: tattoo, translationKey: "tattoo" },
  };

  if (!(alias in businessTypeMap)) {
    return null;
  }

  const { image, translationKey } = businessTypeMap[alias];

  return (
    <ButtonGroup>
      <ToggleButton
        id={`toggle-check-${alias}`}
        type="checkbox"
        name={alias}
        variant="outline-primary"
        className="business-type-btn"
        checked={checked}
        value="1"
        onChange={onChange}
        data-counter={attribute}
      >
        <img src={image} alt={alias} width={64} />
        <Trans>{translationKey}</Trans>
      </ToggleButton>
    </ButtonGroup>
  );
};

export default BusinessTypeButton;
