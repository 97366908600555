import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getCashRegisterTypes = () => {
  return http.get(`finance/cash-register-type/`, {
    headers: authHeader(),
  });
};

const getCashRegisters = () => {
  return http.get(`finance/cash-register/`, {
    headers: authHeader(),
  });
};

const getCashRegistersAll = () => {
  return http.get(`finance/cash-register/all/`, {
    headers: authHeader(),
  });
};

const getCashRegistersByLocationid = (locationId) => {
  return http.get(`finance/cash-register/location/${locationId}`, {
    headers: authHeader(),
  });
};

const createCashRegisters = (payload) => {
  return http.post(`finance/cash-register/`, payload, {
    headers: authHeader(),
  });
};

const updateCashRegisters = (payload) => {
  return http.put(`finance/cash-register/`, payload, { headers: authHeader() });
};

export {
  getCashRegisterTypes,
  getCashRegisters,
  getCashRegistersAll,
  getCashRegistersByLocationid,
  createCashRegisters,
  updateCashRegisters,
};
