import React from "react";
import PropTypes from "prop-types";
import { Card, Row } from "react-bootstrap";
import Section from "component/common/Section";
import zymranLogoBackground from "assets/img/zymran-bg.png";
import Logo from "component/common/Logo";
import classNames from "classnames";

const AuthCardLayout = ({ children, isLoginPage, isRegistrationPage }) => {
  return (
    <Section fluid className="py-0 login-background">
      <Row className="g-0 min-vh-100 p-5 flex-center">
        <div
          className="d-flex flex-center align-self-stretch"
          style={{
            backgroundImage: `url(${zymranLogoBackground})`,
            backgroundSize: "contain",
            minHeight: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <Card
            className={classNames("overflow-hidden z-index-1", {
              "login-card": isLoginPage,
              "registration-card": isRegistrationPage,
            })}
          >
            <Card.Body className="">
              <Logo className="mb-0" />
              {children}
            </Card.Body>
          </Card>
        </div>
      </Row>
    </Section>
  );
};
AuthCardLayout.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool,
};

export default AuthCardLayout;
