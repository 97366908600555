import { Button } from "react-bootstrap";
import { Trans } from "react-i18next";

const ServiceStaffSearchBoxButton = ({
  rows,
  selectedRowIds,
  handleStaffSelection,
}) => {
  const handleAddition = () => {
    const selectedRows = rows.filter((row) => selectedRowIds[row.id]);
    const selectedRowData = selectedRows.map((row) => row.original);
    handleStaffSelection(selectedRowData);
  };

  return (
    <>
      <Button variant="primary" size="sm" onClick={handleAddition}>
        <Trans>add</Trans>
      </Button>
    </>
  );
};

export default ServiceStaffSearchBoxButton;
