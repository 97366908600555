import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getStaffList = () => {
  return http.get(`staff/`, { headers: authHeader() });
};

const getStaffListByLocationId = (locationId) => {
  return http.get(`staff/location/${locationId}`, { headers: authHeader() });
};

const getStaff = (id) => {
  return http.get(`staff/${id}`, { headers: authHeader() });
};

const createStaff = (payload) => {
  delete payload.id;
  return http.post(`staff/`, payload, { headers: authHeader() });
};

const updateStaff = (payload) => {
  return http.put(`staff/`, payload, { headers: authHeader() });
};

const deleteStaff = (id) => {
  return http.delete(`staff/${id}`, { headers: authHeader() });
};

export {
  getStaffList,
  getStaffListByLocationId,
  getStaff,
  createStaff,
  updateStaff,
  deleteStaff,
};
