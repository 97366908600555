/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';

export const ZymranTablePagination = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  gotoPage
}) => {
  const createPageRange = (start, end) => {
    return Array.from({ length: (end - start) }, (_, i) => start + i);
  };

  const siblingCount = 1; // Number of pages to show around the current page
  const totalVisiblePages = siblingCount * 2 + 5; // Current page + siblings + first, last, and ellipses
  let visiblePages;

  if (pageCount > totalVisiblePages) {
    const startPages = createPageRange(0, 2); // Always show first 2 pages
    const endPages = createPageRange(pageCount - 2, pageCount); // Always show last 2 pages

    const middlePagesStart = Math.max(pageIndex - siblingCount, 2);
    const middlePagesEnd = Math.min(pageIndex + siblingCount + 1, pageCount - 2);

    visiblePages = [...startPages, '...', ...createPageRange(middlePagesStart, middlePagesEnd), '...', ...endPages];
  } else {
    visiblePages = createPageRange(0, pageCount);
  }

  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => previousPage()}
        className={classNames({ disabled: !canPreviousPage })}
      >
        <FontAwesomeIcon icon="chevron-left" />
      </Button>
      <ul className="pagination mb-0 mx-1">
        {visiblePages.map((page, index) => (
          <li key={page === '...' ? `ellipsis-${index}` : page} className={classNames({ active: pageIndex === page })}>
            {page === '...' ? (
              <span className="pagination-ellipsis me-1">…</span>
            ) : (
              <Button
                size="sm"
                variant="falcon-default"
                className={classNames('page', {
                  'me-1': page + 1 !== pageCount
                })}
                onClick={() => gotoPage(page)}
              >
                {page + 1}
              </Button>
            )}
          </li>
        ))}
      </ul>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => nextPage()}
        className={classNames({ disabled: !canNextPage })}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>
    </Flex>
  );
};

export default ZymranTablePagination;
