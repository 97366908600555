export const roleRoutesMap = {
    "ADMIN": [
        "Admin"
    ],
    "PARTNER": [
      "Calendar",
      "Staff",
      "UserProfile",
      "Location",
      "Service",
    ]
};
