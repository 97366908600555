import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ZymranIcon from "component/common/ZymranIcon";
import EnhancedFormControlWithToggle from "component/common/EnhancedFormControlWithToggle";
import { Trans } from "react-i18next";

const SalaryForCategory = ({
  serviceCategories,
  selectedServiceCategory,
  uniqueId,
  salary,
  onCategoryChange,
  onSalaryChange,
  isPercentage,
  errors,
  toggleOptions,
  onToggleChange,
  onRemove,
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center gap-3 align-self-stretch">
      <div className="category-and-salary-box">
        <Form.Group className="w-100">
          <Select
            name={`serviceCategory-${uniqueId}`}
            classNamePrefix="react-select"
            placeholder={t("select_service_category")}
            value={selectedServiceCategory}
            options={serviceCategories}
            onChange={onCategoryChange}
            className={
              errors.salaryForCategories.length > uniqueId &&
              errors.salaryForCategories[uniqueId]?.serviceCategory
                ? "is-invalid-react-select category-select"
                : "category-select"
            }
          />
          {errors.salaryForCategories[uniqueId]?.serviceCategory && (
            <Form.Control.Feedback type="invalid">
              {errors.salaryForCategories[uniqueId].serviceCategory}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <EnhancedFormControlWithToggle
          id={`salaryForCategory-${uniqueId}`}
          uniqueId={uniqueId}
          name={`salaryForCategory-${uniqueId}`}
          type="text"
          inputmode="numeric"
          pattern="[0-9]*"
          toggleOptions={toggleOptions}
          value={salary || ""}
          onChange={(e) => onSalaryChange(e.target.value || 0)}
          isInvalid={!!errors.salaryForCategories[uniqueId]?.salary}
          placeholder={t("salary")}
          label={t("salary")}
          errors={errors.salaryForCategories[uniqueId]?.salary}
          groupClassName="salary-input"
          onToggleChange={onToggleChange}
          initiallySelectedOption={
            isPercentage ? toggleOptions[1].value : toggleOptions[0].value
          }
        />
      </div>
      <Button
        id="removeSelect"
        name="removeSelect"
        variant="orange-light"
        size="sm"
        type="button"
        onClick={onRemove}
      >
        <ZymranIcon name="delete" />
        <Trans>delete</Trans>
      </Button>
    </div>
  );
};

export default SalaryForCategory;
