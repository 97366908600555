import ZymranAvatar from "component/common/ZymranAvatar";
import { format, toZonedTime } from "date-fns-tz";
import useLocale from "hooks/useLocale";
import { DateTime } from "luxon";
import { Fragment } from "react";
import { Table } from "react-bootstrap";
import { Trans } from "react-i18next";

const ExpenseDetailSalary = ({ expenseDetail, location }) => {
  const timezone = DateTime.local().zoneName;
  const locale = useLocale();

  return (
    <>
      <div className="d-flex justify-content-between align-items-start align-self-stretch mb-3">
        <Table borderless size="sm" className="p1 primary-text-color w-auto">
          <tbody>
            <tr>
              <th className="p1-bold secondary-text-color">
                <Trans>document_number</Trans>:
              </th>
              <td>{expenseDetail.code}</td>
            </tr>
            <tr>
              <th className="p1-bold secondary-text-color">
                <Trans>date</Trans>:
              </th>
              <td>
                {format(
                  toZonedTime(expenseDetail.date, timezone),
                  "dd MMM yyyy HH:mm",
                  { locale }
                )}
              </td>
            </tr>
            <tr>
              <th className="p1-bold secondary-text-color">
                <Trans>location</Trans>:
              </th>
              <td>{location && location.name}</td>
            </tr>
            <tr>
              <th className="p1-bold secondary-text-color">
                <Trans>expense_reason</Trans>:
              </th>
              <td>
                <Trans>{expenseDetail.reason}</Trans>{" "}
                <span className="fw-bold">
                  {format(
                    toZonedTime(expenseDetail.salaryDate, timezone),
                    "dd MMM yyyy HH:mm",
                    { locale }
                  )}
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="finances-issued-by-block">
          <p className="p1-bold mb-0">
            <Trans>issued_by</Trans>
          </p>
          <div className="d-flex flex-row align-items-center">
            <div className="d-flex">
              <ZymranAvatar
                avatarUrl={expenseDetail.providedByUser.avatarUrl}
                size="lg"
                shape="circle"
                className={"me-2"}
              />
            </div>
            {expenseDetail.providedByUser && (
              <div className="d-flex flex-column align-items-start text-start">
                <p className="p2-bold mb-0 text-blue mb-0 ">
                  {expenseDetail.providedByUser.firstName}{" "}
                  {expenseDetail.providedByUser.lastName}
                </p>
                <p className="p3 mb-0 secondary-text-color">
                  <Trans>{expenseDetail.providedByUser.type}</Trans>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="table-rounded-wrapper">
        <Table className="cash-register-table mb-0">
          <thead className="p2-bold secondary-text-color">
            <tr>
              <th>№</th>
              <th>
                <Trans>staff_member</Trans>
              </th>
              <th>
                <Trans>salary_payment_source</Trans>
              </th>
              <th>
                <Trans>sum</Trans>
              </th>
              <th>
                <Trans>debt</Trans>
              </th>
            </tr>
          </thead>
          <tbody className="p2 primary-text-color">
            {expenseDetail.staffSalaryData &&
              expenseDetail.staffSalaryData.map((item, index) => (
                <Fragment key={index}>
                  {item.salary.map((salaryItem, salaryIndex) => (
                    <tr key={salaryIndex}>
                      {salaryIndex === 0 && (
                        <Fragment>
                          <td
                            rowSpan={item.salary.length}
                            className={index === expenseDetail.staffSalaryData.length - 1 ? "border-bottom-0" : ""}
                          >
                            {index + 1}
                          </td>
                          <td
                            rowSpan={item.salary.length}
                            className={`text-blue salary-expense-name-cell ${index === expenseDetail.staffSalaryData.length - 1 ? 'border-bottom-0' : ''}`}
                          >
                            {item.staff.firstName} {item.staff.lastName}
                          </td>
                        </Fragment>
                      )}
                      <td>
                        <Trans>{`cash_register_type_${salaryItem.cashRegisterName}`}</Trans>
                      </td>
                      <td>{salaryItem.amount}</td>
                      {salaryIndex === 0 && (
                        <td
                          rowSpan={item.salary.length}
                          className={`salary-expense-debt-cell ${index === expenseDetail.staffSalaryData.length - 1 ? 'border-bottom-0' : ''}`}
                        >
                          {item.debt}
                        </td>
                      )}
                    </tr>
                  ))}
                </Fragment>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ExpenseDetailSalary;

