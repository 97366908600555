import { authHeader } from "../auth/auth-header";
import http from "../../http-common";

const getUserProfile = async (login) => {
  return http.get(`user/${login}`, {
    headers: authHeader(),
  });
};

const updateUserProfile = async (login, payload) => {
  return http.put(`user/${login}`, payload, {
    headers: authHeader(),
  });
};

const uploadAvatar = (fileData, mimeType) => {
  const binaryStr = atob(fileData.split(",")[1]);
  const len = binaryStr.length;
  const arrayBuffer = new ArrayBuffer(len);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < len; i++) {
    uint8Array[i] = binaryStr.charCodeAt(i);
  }

  const formData = new FormData();
  formData.append("file_upload", new Blob([uint8Array], { type: mimeType }));

  return http.post("/user/avatar", formData, {
    headers: {
      ...authHeader(),
    },
  });
};

export { getUserProfile, updateUserProfile, uploadAvatar };
