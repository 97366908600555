import { fromZonedTime } from "date-fns-tz";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import Select from "react-select";
import { getCashRegistersAll } from "service/cash-register/CashRegisterService";
import { createExpenseRecord } from "service/expense/ExpenseService";
import { getLocations } from "service/location/LocationService";
import { notifySuccess } from "util/notify";
import { isNumericInput } from "util/validation";
import Cookies from "js-cookie";

const AddExpense = ({ handleClose, setExpenses }) => {
  const currentUser = Cookies.get("zymran_user") ? JSON.parse(Cookies.get("zymran_user")) : null;
  const { t } = useTranslation();
  const [blocking, setBlocking] = useState(false);
  const [errors, setErrors] = useState({});
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocationOption, setSelectedLocationOption] = useState({
    value: "",
    label: "",
  });
  const [cashRegisters, setCashRegisters] = useState([]);
  const [cashRegistersByLocation, setCashRegistersByLocation] = useState([]);
  const [cashRegisterSums, setCashRegisterSums] = useState({});
  const [incomeReason, setIncomeReason] = useState("");

  useEffect(() => {
    loadLocations();
    loadCashRegisters();
  }, []);

  useEffect(() => {
    if (selectedLocationOption && cashRegisters.length > 0) {
      const filteredCashRegisters = cashRegisters.filter(
        (item) => item.location.id === selectedLocationOption.value
      );

      setCashRegistersByLocation(filteredCashRegisters);
    }
  }, [selectedLocationOption, cashRegisters]);

  const loadLocations = async () => {
    try {
      setBlocking(true);
      const response = await getLocations();
      let locs = [];
      response.data.forEach((loc) => {
        const location = {
          value: loc.id,
          label:
            loc.name + " " + loc.address.street + " " + loc.address.houseNumber,
        };
        locs.push(location);
      });
      setLocationOptions(locs);
      setSelectedLocationOption(locs[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const loadCashRegisters = async () => {
    try {
      const response = await getCashRegistersAll();
      setCashRegisters(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLocationSelectionChange = (selectedOption) => {
    setSelectedLocationOption(selectedOption);
  };

  const handleCashRegisterSumChange = (cashRegisterId, value) => {
    if (isNumericInput(value) || value === "") {
      setCashRegisterSums((prevSums) => ({
        ...prevSums,
        [cashRegisterId]: value,
      }));
    }
  };

  const findFormErrors = () => {
    const newErrors = {};

    if (!selectedLocationOption.value || selectedLocationOption.value === "")
      newErrors.selectedLocationOption = t("field_required");

    if (!incomeReason || incomeReason === "")
      newErrors.incomeReason = t("field_required");

    let isAnyCashRegisterFilled = false;

    cashRegistersByLocation.forEach((cashRegister) => {
      if (
        cashRegisterSums[cashRegister.id] &&
        cashRegisterSums[cashRegister.id] !== ""
      ) {
        isAnyCashRegisterFilled = true;
        if (cashRegisterSums[cashRegister.id] === 0) {
          newErrors[`cashRegisterSum_${cashRegister.id}`] = t(
            "non_zero_value_required"
          );
        } else if (cashRegisterSums[cashRegister.id] < 0) {
          newErrors[`cashRegisterSum_${cashRegister.id}`] =
            t("positive_number");
        }
      }
    });
    if (!isAnyCashRegisterFilled) {
      cashRegistersByLocation.forEach((cashRegister) => {
        newErrors[`cashRegisterSum_${cashRegister.id}`] = t(
          "at_least_one_input_required"
        );
      });
    }
    return newErrors;
  };

  const handleSubmit = async () => {
    const newErrors = findFormErrors();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        setBlocking(true);
        const payload = {
          locationId: selectedLocationOption.value,
          cashRegisterSums: cashRegisterSums,
          reason: incomeReason,
        };
        const response = await createExpenseRecord(payload);
        setExpenses((oldExpenses) => [
          ...oldExpenses,
          {
            ...response.data,
            date: fromZonedTime(response.data.date, "UTC"),
          },
        ]);
        notifySuccess(t("income_record_created_successfully"));
        handleClose();
      } catch (error) {
        console.error(error);
      } finally {
        setBlocking(false);
      }
    }
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      maxWidth: "340px",
    }),
  };

  return (
    <div className="checkout-body-layout">
      <div className="checkout-container">
        <Card className="checkout-card mb-3">
          <Card.Header className="checkout-card-header">
            <h3 className="mb-0">
              <Trans>expense_order</Trans>
            </h3>
          </Card.Header>
          <Card.Body className="d-flex flex-column align-self-stretch gap-4">
            <div className="d-flex flex-column align-items-start gap-3">
              <Row className="w-100 gx-3">
                <Col xs={4} className="p1-bold primary-text-color">
                  <Trans>select_location</Trans>
                </Col>
                <Col xs={8}>
                  <Select
                    id="location"
                    name="location"
                    classNamePrefix="react-select"
                    placeholder="test"
                    value={selectedLocationOption}
                    options={locationOptions}
                    required
                    onChange={(option) => handleLocationSelectionChange(option)}
                    className={
                      !!errors.selectedLocationOption
                        ? "is-invalid-react-select"
                        : ""
                    }
                    styles={customSelectStyles}
                  />
                  {!!errors.selectedLocationOption && (
                    <div className="invalid-feedback-react-select">
                      {errors.selectedLocationOption}
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="w-100 gx-3">
                <Col xs={4} className="p1-bold primary-text-color">
                  <Trans>issued_by</Trans>
                </Col>
                <Col xs={8}>{currentUser.fullName}</Col>
              </Row>
              {cashRegistersByLocation.map((cashRegister, index) => (
                <Row className="w-100 gx-3" key={index}>
                  <Col xs={4} className="p1-bold primary-text-color">
                    <Trans>{`cash_register_type_${cashRegister.cashRegisterType.name}`}</Trans>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      id={`cashRegisterSum_${cashRegister.id}`}
                      name={`cashRegisterSum_${cashRegister.id}`}
                      placeholder={t("enter_withdrawn_amount")}
                      value={cashRegisterSums[cashRegister.id]}
                      onChange={(e) =>
                        handleCashRegisterSumChange(
                          cashRegister.id,
                          e.target.value
                        )
                      }
                      isInvalid={!!errors[`cashRegisterSum_${cashRegister.id}`]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors[`cashRegisterSum_${cashRegister.id}`]}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              ))}
              <hr className="w-100" />
              <Row className="w-100 gx-3">
                <Col>
                  <p className="mb-2 p1-bold primary-text-color">
                    <Trans>income_return_reason</Trans>
                  </p>
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={incomeReason}
                      id="incomeReason"
                      name="incomeReason"
                      placeholder={t("enter_reason_for_withdrawing")}
                      onChange={(e) => setIncomeReason(e.target.value)}
                      isInvalid={!!errors.incomeReason}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.incomeReason}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
        <div className="checkout-buttons-block">
          <Button name="cancelButton" variant="grey" onClick={handleClose}>
            <Trans>cancel</Trans>
          </Button>
          <Button name="saveButton" variant="blue" onClick={handleSubmit}>
            <Trans>save</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddExpense;
