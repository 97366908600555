import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-shadow.png";
import "../../map/map.css";
import Map from "../../map/Map";
import EnhancedFormControl from "component/common/EnhancedFormControl";

const LocationAddressForm = ({
  setField,
  errors,
  locationAddress,
  setLocationAddress,
  requestUserLocationAccess = true
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-3">
        <Map
          setLocationAddress={setLocationAddress}
          locationAddress={locationAddress}
          cn={"location-map-container"}
          requestUserLocation={requestUserLocationAccess}
        />
      </div>
      <div className="d-flex flex-row gap-3 align-items-stretch top-buffer mb-3">
        <EnhancedFormControl
          id="country"
          name="country"
          type="text"
          value={locationAddress.country}
          onChange={(e) => setField("address", "country", e.target.value)}
          isInvalid={!!errors.country}
          placeholder={t("country")}
          label={t("country")}
          errors={errors.country}
          groupClassName="flex-grow-1"
          readOnly={true}
          required={true}
        />
        <EnhancedFormControl
          id="region"
          name="region"
          type="text"
          value={locationAddress.region}
          onChange={(e) => setField("address", "region", e.target.value)}
          isInvalid={!!errors.region}
          placeholder={t("region")}
          label={t("region")}
          errors={errors.region}
          groupClassName="flex-grow-1"
          readOnly={true}
          required={true}
        />
      </div>
      <div className="d-flex flex-row gap-3 top-buffer mb-3">
        <EnhancedFormControl
          id="locality"
          name="locality"
          type="text"
          value={locationAddress.city}
          onChange={(e) => setField("address", "city", e.target.value)}
          isInvalid={!!errors.city}
          placeholder={t("city")}
          label={t("city")}
          errors={errors.city}
          groupClassName="flex-grow-1"
          required={true}
        />
        <EnhancedFormControl
          id="district"
          name="district"
          type="text"
          value={locationAddress.district}
          onChange={(e) => setField("address", "district", e.target.value)}
          isInvalid={!!errors.district}
          placeholder={t("district")}
          label={t("district")}
          errors={errors.district}
          groupClassName="flex-grow-1"
          required={true}
        />
      </div>
      <div className="d-flex flex-row gap-3 top-buffer mb-3">
        <EnhancedFormControl
          id="street"
          name="street"
          type="text"
          value={locationAddress.street}
          onChange={(e) => setField("address", "street", e.target.value)}
          isInvalid={!!errors.street}
          placeholder={t("street")}
          label={t("street")}
          errors={errors.street}
          groupClassName="flex-grow-1"
          required={true}
        />
      </div>
      <div className="d-flex flex-row gap-3 top-buffer mb-3">
        <EnhancedFormControl
          id="houseNumber"
          name="houseNumber"
          type="text"
          value={locationAddress.houseNumber}
          onChange={(e) => setField("address", "houseNumber", e.target.value)}
          isInvalid={!!errors.houseNumber}
          placeholder={t("house_number")}
          label={t("house_number")}
          errors={errors.houseNumber}
          groupClassName="flex-grow-1"
          required={true}
        />
        <EnhancedFormControl
          id="aptNumber"
          name="aptNumber"
          type="text"
          value={locationAddress.aptNumber}
          onChange={(e) => setField("address", "aptNumber", e.target.value)}
          isInvalid={!!errors.aptNumber}
          placeholder={t("apt_number")}
          label={t("apt_number")}
          errors={errors.aptNumber}
          groupClassName="flex-grow-1"
        />
        <EnhancedFormControl
          id="postcode"
          name="postcode"
          type="text"
          value={locationAddress.postcode}
          onChange={(e) => setField("address", "postcode", e.target.value)}
          isInvalid={!!errors.postcode}
          placeholder={t("postcode")}
          label={t("postcode")}
          errors={errors.postcode}
          groupClassName="flex-grow-1"
          required={true}
        />
      </div>
      <div className="d-flex flex-row gap-3 top-buffer mb-3">
        <EnhancedFormControl
          id="directionHint"
          name="directionHint"
          type="text"
          value={locationAddress.directionHint}
          onChange={(e) => setField("address", "directionHint", e.target.value)}
          isInvalid={!!errors.postcode}
          placeholder={t("direction_hint")}
          label={t("direction_hint")}
          errors={errors.directionHint}
          groupClassName="flex-grow-1"
        />
      </div>
      <div className="d-flex flex-row gap-3 top-buffer mb-3">
        <EnhancedFormControl
          id="note"
          name="note"
          type="text"
          value={locationAddress.note}
          onChange={(e) => setField("address", "note", e.target.value)}
          isInvalid={!!errors.note}
          placeholder={t("note")}
          label={t("note")}
          errors={errors.note}
          groupClassName="flex-grow-1"
        />
      </div>
    </>
  );
};

LocationAddressForm.propTypes = {
  setField: PropTypes.func.isRequired,
  locationAddress: PropTypes.object.isRequired,
  setLocationAddress: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default LocationAddressForm;
