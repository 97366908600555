export const version = "alpha-0.0.5";
export const navbarBreakPoint = "xl"; // Vertical navbar breakpoint
export const topNavbarBreakpoint = "lg";
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  navbarPosition: "vertical",
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: "$",
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  isSecondaryNavbarVerticalCollapsed: false, // toggle secondary vertical navbar collapse
  navbarStyle: "inverted",
};

// eslint-disable-next-line
export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
