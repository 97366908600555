import React from "react";
import { Outlet } from "react-router-dom";
import AuthCardLayout from "./AuthCardLayout";

const MultiStepRegisterLayout = () => (
  <AuthCardLayout isRegistrationPage={true}>
    <Outlet />
  </AuthCardLayout>
);

export default MultiStepRegisterLayout;
