import { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import ZymranIcon from "component/common/ZymranIcon";
import { Trans, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { setHours, setMinutes, setSeconds, setMilliseconds } from "date-fns";

const DayOfWeekSchedule = ({
  dayOfWeek,
  selectedLocationOptions,
  dayOfWeekData,
  getDataByDayOfWeek,
  setDataByDayOfWeek,
  errors,
  shouldAddDefaults = true,
}) => {
  const { t } = useTranslation();
  const [step] = useState(15);
  const [optionalSecondaryShifts, setOptionalSecondaryShifts] = useState(
    dayOfWeekData.map(() => false)
  );
  const [
    selectedLocationsWithinComponent,
    setSelectedLocationsWithinComponent,
  ] = useState([]);

  useEffect(() => {
    setOptionalSecondaryShifts(
      dayOfWeekData.map(
        (data) =>
          data.secondaryShiftStart !== null && data.secondaryShiftEnd !== null
      )
    );
  }, [dayOfWeekData]);

  useEffect(() => {
    if (selectedLocationOptions.length === 0) {
      setDataByDayOfWeek(dayOfWeek, []);
    } else if (!dayOfWeekData || dayOfWeekData.length === 0) {
      if (shouldAddDefaults) {
        const firstLocation = selectedLocationOptions[0];
        const defaultData = {
          locationId: firstLocation.value,
          location: firstLocation,
          dayOff: false,
          primaryShiftStart: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            9
          ),
          // primaryShiftStart: utcToZonedTime(
          //   setHours(
          //     setMinutes(
          //       setSeconds(
          //         setMilliseconds(new Date(), 0),
          //         0),
          //       0),
          //     9),
          //   timezone),
          primaryShiftEnd: setHours(
            setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
            13
          ),
          // primaryShiftEnd: utcToZonedTime(
          //   setHours(
          //     setMinutes(
          //       setSeconds(
          //         setMilliseconds(new Date(), 0),
          //         0),
          //       0),
          //     13),
          //   timezone),
          secondaryShiftStart: null,
          secondaryShiftEnd: null,
        };
        setSelectedLocationsWithinComponent([firstLocation.value]);
        setDataByDayOfWeek(dayOfWeek, [defaultData]);
      }
    } else {
      const updatedData = dayOfWeekData.filter((data) => {
        return selectedLocationOptions.some(
          (option) => option.value === data.locationId
        );
      });

      const updatedLocationIds = updatedData.map((data) => data.locationId);
      setSelectedLocationsWithinComponent(updatedLocationIds);
      setDataByDayOfWeek(dayOfWeek, updatedData);
    }
    // eslint-disable-next-line
  }, [selectedLocationOptions]);

  const handleAddLocationSelect = (dayOfWeek) => {
    const updatedData = [
      ...getDataByDayOfWeek(dayOfWeek),
      {
        dayOff: false,
        locationId: null,
        primaryShiftStart: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          9
        ),
        // primaryShiftStart: utcToZonedTime(
        //   setHours(
        //     setMinutes(
        //       setSeconds(
        //         setMilliseconds(new Date(), 0),
        //         0),
        //       0),
        //     9),
        //   timezone),
        primaryShiftEnd: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          13
        ),
        // primaryShiftEnd: utcToZonedTime(
        //   setHours(
        //     setMinutes(
        //       setSeconds(
        //         setMilliseconds(new Date(), 0),
        //         0),
        //       0),
        //     13),
        //   timezone),
        secondaryShiftStart: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          14
        ),
        // secondaryShiftStart: utcToZonedTime(
        //   setHours(
        //     setMinutes(
        //       setSeconds(
        //         setMilliseconds(new Date(), 0),
        //         0),
        //       0),
        //     14),
        //   timezone),
        secondaryShiftEnd: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          18
        ),
        // secondaryShiftEnd: utcToZonedTime(
        //   setHours(
        //     setMinutes(
        //       setSeconds(
        //         setMilliseconds(new Date(), 0),
        //         0),
        //       0),
        //     18),
        //   timezone)
      },
    ];
    setDataByDayOfWeek(dayOfWeek, updatedData);
  };

  const handleRemoveLocationSelect = (dayOfWeek, index) => {
    const updatedData = [...getDataByDayOfWeek(dayOfWeek)];

    const removedLocation = updatedData[index].locationId;
    if (removedLocation) {
      setSelectedLocationsWithinComponent((prevSelectedLocations) =>
        prevSelectedLocations.filter((loc) => loc !== removedLocation)
      );
    }

    updatedData.splice(index, 1);
    setDataByDayOfWeek(dayOfWeek, updatedData);
  };

  const handleLocationChange = (selectedOption, dayOfWeek, index) => {
    const updatedData = [...getDataByDayOfWeek(dayOfWeek)];

    const previouslySelectedLocation = updatedData[index].locationId;
    if (previouslySelectedLocation) {
      setSelectedLocationsWithinComponent(
        selectedLocationsWithinComponent.filter(
          (value) => value !== previouslySelectedLocation
        )
      );
    }

    if (
      selectedOption.value &&
      !selectedLocationsWithinComponent.includes(selectedOption.value)
    ) {
      setSelectedLocationsWithinComponent((prevSelectedLocations) => [
        ...prevSelectedLocations,
        selectedOption.value,
      ]);
    }

    updatedData[index] = {
      ...updatedData[index],
      locationId: selectedOption.value,
    };
    setDataByDayOfWeek(dayOfWeek, updatedData);
  };

  const handleShiftStartChange = (dayOfWeek, isPrimaryShift, index, value) => {
    const currentData = getDataByDayOfWeek(dayOfWeek);

    const updatedEntry = {
      ...currentData[index],
      [isPrimaryShift ? "primaryShiftStart" : "secondaryShiftStart"]: value,
    };

    const isEmptyData = currentData.every(
      (entry) => Object.keys(entry).length === 0
    );
    if (isEmptyData) {
      setDataByDayOfWeek(dayOfWeek, [updatedEntry]);
      return;
    }

    const existingIndex = currentData.findIndex(
      (entry) => entry.locationId === currentData[index].locationId
    );

    if (existingIndex !== -1) {
      currentData[existingIndex] = updatedEntry;
    } else {
      currentData.push(updatedEntry);
    }

    const updatedData = [...currentData];
    updatedData[index] = updatedEntry;
    setDataByDayOfWeek(dayOfWeek, updatedData);
  };

  const handleShiftEndChange = (dayOfWeek, isPrimaryShift, index, value) => {
    const currentData = getDataByDayOfWeek(dayOfWeek);

    const updatedEntry = {
      ...currentData[index],
      [isPrimaryShift ? "primaryShiftEnd" : "secondaryShiftEnd"]: value,
    };

    const isEmptyData = currentData.every(
      (entry) => Object.keys(entry).length === 0
    );

    // If currentData is empty or contains empty objects, initialize it with the new entry
    if (isEmptyData) {
      setDataByDayOfWeek(dayOfWeek, [updatedEntry]);
      return;
    }

    const existingIndex = currentData.findIndex(
      (entry) => entry.locationId === currentData[index].locationId
    );

    if (existingIndex !== -1) {
      currentData[existingIndex] = updatedEntry;
    } else {
      currentData.push(updatedEntry);
    }

    const updatedData = [...currentData];
    updatedData[index] = updatedEntry;
    setDataByDayOfWeek(dayOfWeek, updatedData);
  };

  const handleDayOffChange = (isChecked, index) => {
    const updatedData = [...getDataByDayOfWeek(dayOfWeek)];
    updatedData[index] = {
      ...updatedData[index],
      dayOff: isChecked,
    };
    setDataByDayOfWeek(dayOfWeek, updatedData);
  };

  const handleOptionalSecondaryShiftChange = (isChecked, index) => {
    const updatedOptionalSecondaryShifts = [...optionalSecondaryShifts];
    updatedOptionalSecondaryShifts[index] = isChecked;

    const updatedData = [...dayOfWeekData];

    if (!isChecked) {
      updatedData[index] = {
        ...updatedData[index],
        secondaryShiftStart: null,
        secondaryShiftEnd: null,
      };
    } else {
      updatedData[index] = {
        ...updatedData[index],
        secondaryShiftStart: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          14
        ),
        // secondaryShiftStart: utcToZonedTime(
        //   setHours(
        //     setMinutes(
        //       setSeconds(
        //         setMilliseconds(new Date(), 0),
        //         0),
        //       0),
        //     14),
        //   timezone),
        secondaryShiftEnd: setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
          18
        ),
        // secondaryShiftEnd: utcToZonedTime(
        //   setHours(
        //     setMinutes(
        //       setSeconds(
        //         setMilliseconds(new Date(), 0),
        //         0),
        //       0),
        //     18),
        //   timezone),
      };
    }

    setOptionalSecondaryShifts(updatedOptionalSecondaryShifts);
    setDataByDayOfWeek(dayOfWeek, updatedData);
  };

  return (
    <div className="staff-work-time-card">
      <div className="d-flex justify-content-between align-items-center align-self-stretch primary-text-color p1-bold">
        <Trans>{dayOfWeek}</Trans>
      </div>
      {dayOfWeekData.map((data, index) => (
        <div
          key={index}
          className="d-flex flex-column align-items-start gap-3 align-selft-stretch primary-text-color"
        >
          <div className="d-flex align-items-center gap-3 align-self-stretch">
            <p className="p1 mb-0">
              <Trans>location</Trans>
            </p>
            <Form.Group className="w-100">
              <Select
                name={`selectLocation-${dayOfWeek}-${index}`}
                classNamePrefix="react-select"
                placeholder={t("select_location")}
                // options={selectedLocationOptions}
                options={selectedLocationOptions.filter(
                  (option) =>
                    !selectedLocationsWithinComponent.includes(option.value)
                )}
                // value={data.locationId}
                defaultValue={selectedLocationOptions.find(
                  (option) => option.value === data.locationId
                )}
                onChange={(selectedOption) =>
                  handleLocationChange(selectedOption, dayOfWeek, index)
                }
                className={
                  errors.schedule[dayOfWeek][index] &&
                  !!errors.schedule[dayOfWeek][index].location
                    ? "is-invalid-react-select w-100"
                    : "w-100"
                }
              />
              {!!errors.schedule[dayOfWeek][index] && (
                <div className="invalid-feedback-react-select">
                  {errors.schedule[dayOfWeek][index].location}
                </div>
              )}
            </Form.Group>
          </div>
          <div className="d-flex align-items-start gap-3 align-slef-stretch">
            <div className="d-flex flex-column justify-content-start align-items-start gap-3">
              <p className="mb-0 p1">
                <Trans>primary_shift</Trans>
              </p>
              <div className="d-flex align-items-center gap-1 align-self-stretch">
                <DatePicker
                  name={`primaryShiftStart-${dayOfWeek}-${index}`}
                  selected={data.primaryShiftStart}
                  onChange={(date) =>
                    handleShiftStartChange(
                      dayOfWeek,
                      true,
                      index,
                      date,
                      data.location
                    )
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={step}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  className={`form-control ${
                    errors.primaryShiftStart ? "datepicker-error" : ""
                  }`}
                  disabled={data.dayOff}
                />
                <p className="mb-0 p1">-</p>
                <DatePicker
                  name={`primaryShiftEnd-${dayOfWeek}-${index}`}
                  selected={data.primaryShiftEnd}
                  onChange={(date) =>
                    handleShiftEndChange(
                      dayOfWeek,
                      true,
                      index,
                      date,
                      data.location
                    )
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={step}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  className={`form-control ${
                    errors.primaryShiftEnd ? "datepicker-error" : ""
                  }`}
                  disabled={data.dayOff}
                />
              </div>
              {errors.schedule[dayOfWeek][index] && (
                <Form.Text className="text-danger">
                  {errors.schedule[dayOfWeek][index].primaryShift}
                </Form.Text>
              )}
            </div>
            <div className="d-flex flex-column justify-content-start align-items-start gap-3 align-self-stretch">
              <Form.Check
                label={t("secondary_shift_shortened")}
                type="checkbox"
                id={`optionalSecondaryShift-checkbox-${dayOfWeek}-${index}`}
                name={`optionalSecondaryShift-checkbox-${dayOfWeek}-${index}`}
                checked={optionalSecondaryShifts[index] || false}
                onChange={(e) =>
                  handleOptionalSecondaryShiftChange(e.target.checked, index)
                }
                className="secondary-shift-checkbox"
              />
              <div className="d-flex align-items-center gap-1 align-self-stretch">
                <DatePicker
                  name={`secondaryShiftStart-${dayOfWeek}-${index}`}
                  selected={data.secondaryShiftStart}
                  onChange={(date) =>
                    handleShiftStartChange(
                      dayOfWeek,
                      false,
                      index,
                      date,
                      data.location
                    )
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={step}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  className={`form-control ${
                    errors.secondaryShiftStart ? "datepicker-error" : ""
                  }`}
                  disabled={data.dayOff || !optionalSecondaryShifts[index]}
                />
                <p className="mb-0 p1">-</p>
                <DatePicker
                  name={`secondaryShiftEnd-${dayOfWeek}-${index}`}
                  selected={data.secondaryShiftEnd}
                  onChange={(date) =>
                    handleShiftEndChange(
                      dayOfWeek,
                      false,
                      index,
                      date,
                      data.location
                    )
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={step}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  className={`form-control ${
                    errors.secondaryShiftEnd ? "datepicker-error" : ""
                  }`}
                  disabled={data.dayOff || !optionalSecondaryShifts[index]}
                />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between align-self-stretch">
            <Form.Check
              label={t("day_off")}
              type="checkbox"
              id={`dayOff-checkbox-${dayOfWeek}-${index}`}
              name={`dayOff-checkbox-${dayOfWeek}-${index}`}
              checked={data.dayOff}
              onChange={(e) => handleDayOffChange(e.target.checked, index)}
            />
            <Button
              id={`removeSelect-${dayOfWeek}-${index}`}
              name={`removeSelect-${dayOfWeek}-${index}`}
              variant="orange-light"
              size="sm"
              type="button"
              onClick={() => handleRemoveLocationSelect(dayOfWeek, index)}
            >
              <ZymranIcon name="delete" />
              <Trans>delete</Trans>
            </Button>
          </div>
        </div>
      ))}
      <Button
        id={`addSelect-${dayOfWeek}`}
        name={`addSelect-${dayOfWeek}`}
        variant="blue-light"
        size="sm"
        type="button"
        onClick={() => handleAddLocationSelect(dayOfWeek)}
        disabled={dayOfWeekData.length >= selectedLocationOptions.length}
      >
        <ZymranIcon name="plus" />
        <Trans>add_another_location</Trans>
      </Button>
    </div>
  );
};

DayOfWeekSchedule.propTypes = {
  dayOfWeek: PropTypes.string.isRequired,
  selectedLocationOptions: PropTypes.array.isRequired,
  dayOfWeekData: PropTypes.array.isRequired,
  getDataByDayOfWeek: PropTypes.func.isRequired,
  setDataByDayOfWeek: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default DayOfWeekSchedule;
