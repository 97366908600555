import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getIncomes = () => {
  return http.get(`finance/income`, {
    headers: authHeader(),
  });
};

const getIncomeServiceByCode = (code) => {
  return http.get(`finance/income/${code}`, { headers: authHeader() });
};

const createIncomeRecord = (payload) => {
  return http.post(`finance/income/manual`, payload, { headers: authHeader() });
};

export { getIncomes, getIncomeServiceByCode, createIncomeRecord };
