import { GoogleLogin } from "@react-oauth/google";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import PropTypes from "prop-types";
import React from "react";
import { Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import { REGISTRATION_STEPS } from "util/constants";

const Step1 = ({
  currentStep,
  handleGoogleOAuthResponse,
  userInformation,
  errors,
  setField,
  registerPartnerStep1,
}) => {
  const { t } = useTranslation();

  if (currentStep !== REGISTRATION_STEPS.STEP1) {
    return null;
  }

  // TODO: Do not remove
  // function handlePhoneMask(event) {
  //   let { value } = event.target;

  //   const isMobilePhone = /^\+?[0-9\s]+$/.test(value); // Check if it's a numeric phone number

  //   if (isMobilePhone) {

  //     const leadingPlus = value.startsWith('+') ? '' : '+';
  //     value = leadingPlus + value;
  //     // Format the numeric phone number with spaces
  //     // value = value.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4');
  //     // value = leadingPlus + value.replace(/^\+?(\d{1})(\d{3})(\d{3})(\d+)$/, '$1 $2 $3 $4');
  //     // value = leadingPlus + value.replace(/^\+?(\d{1})(?:\s?(\d{3}))?(?:\s?(\d{3}))?(?:\s?(\d+))?$/, '$1 $2 $3 $4');
  //     value = value.replace(/(\+\d{1,2})(\d{3})(\d{7})/, '$1 ($2) $3');

  //     const originalValue = value.replace(/[^+0-9]/g, '');
  //     setField("userInformation", "phoneNumber", originalValue)
  //     setField("userInformation", "login", originalValue)
  //   } else {
  //     setField("userInformation", "login", value)
  //   }
  //   event.currentTarget.value = value;
  // }

  return (
    <>
      <div className="mt-3">
        {/* <InputMask
                mask={isMaskEnabled ? "+9 999 9999999" : ""}
                maskChar=""
                value={userInformation.login}
                onChange={e => handlePhoneAndEmailChange("userInformation", "login", e.target.value)}
                className="form-control"
              /> */}
        <EnhancedFormControl
          id="emailOrPhone"
          name="emailOrPhone"
          type="text"
          value={userInformation.login}
          onChange={(e) => setField("userInformation", "login", e.target.value)}
          // onChange={handlePhoneMask}
          isInvalid={!!errors.login}
          placeholder={t("email_or_phone_number")}
          label={t("email_or_phone_number")}
          errors={errors.login}
          groupClassName="mb-3"
        />
        <EnhancedFormControl
          id="password"
          name="password"
          type="password"
          value={userInformation.password}
          onChange={(e) =>
            setField("userInformation", "password", e.target.value)
          }
          isInvalid={!!errors.password}
          placeholder={t("password")}
          label={t("password")}
          errors={errors.password}
          groupClassName="mb-3"
        />
        <EnhancedFormControl
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          value={userInformation.confirmPassword}
          onChange={(e) =>
            setField("userInformation", "confirmPassword", e.target.value)
          }
          isInvalid={!!errors.confirmPassword}
          placeholder={t("confirm_password")}
          label={t("confirm_password")}
          errors={errors.confirmPassword}
          groupClassName="mb-3"
        />
      </div>
      <Button
        name="continueButton"
        variant="primary"
        className="w-100 mb-3"
        onClick={registerPartnerStep1}
      >
        <Trans>continue</Trans>
      </Button>
      <div className="w-100 position-relative text-center">
        <hr className="my-0" />
      </div>

      <div className="d-flex my-4 justify-content-center w-100">
        <GoogleLogin
          width="400px"
          logo_alignment="center"
          text={t("continue_with_google")}
          onSuccess={handleGoogleOAuthResponse}
          onFailure={handleGoogleOAuthResponse}
        />
      </div>
    </>
  );
};

Step1.propTypes = {
  currentStep: PropTypes.number.isRequired,
  userInformation: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setField: PropTypes.func.isRequired,
};

export default Step1;
