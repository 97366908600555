import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Trans } from "react-i18next";
import {
  getUserProfile,
} from "../../service/profile/UserProfileService";
import { Card, Col, Modal, ProgressBar, Row } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import ZymranIcon from "component/common/ZymranIcon";
import ProfileStatsLegend from "./ProfileStatsLegend";
import mockDailyCustomers from "./mocks/mockDailyCustomers.json";
import EditProfile from "./EditProfile";
import ZymranAvatar from "component/common/ZymranAvatar";
import { fromZonedTime } from "date-fns-tz";
import {
  getDashboardIncome,
  getDashboardServices,
  getDashboardLocationServicesSum,
  getDashboardLocationRegistrySum,
  getDashboardEmployeeServicesStats,
} from "service/dashboard/DashboardService";
import ProfileStatsProgressBar from "./ProfileStatsProgressBar";
import { ReactComponent as DefaultAvatar } from "assets/img/zymran-default-avatar.svg";
import Cookies from "js-cookie";

const UserProfile = () => {
  const currentUser = Cookies.get("zymran_user") ? JSON.parse(Cookies.get("zymran_user")) : null;
  const [userProfileData, setUserProfileData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const [dailyIncome, setDailyIncome] = useState([]);
  const [dailyServices, setDailyServices] = useState([]);
  const [dailyStaffAndCashRegisters, setDailyStaffAndCashRegisters] = useState(
    []
  );
  const [dailyServicesStats, setDailyServicesStats] = useState([]);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);

  const colors = {
    expected_remainder: "profile-stat-expected",
    expected: "profile-stat-expected",
    received: "profile-stat-received",
    canceled: "profile-stat-canceled",
    extra: "profile-stat-extra",
  };

  useEffect(() => {
    if (currentUser) {
      getProfile();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    const startDateUtc = fromZonedTime(
      startOfToday,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const nowUtc = fromZonedTime(
      new Date(),
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const startDate = startDateUtc.toISOString();
    const endDate = nowUtc.toISOString();
    loadDashboardIncome(startDate, endDate);
    loadDashboardServices(startDate, endDate);
    loadDashboardLocationServicesSum(startDate, endDate);
    loadDashboardLocationRegistrySum(startDate, endDate);
    loadDashboardEmployeeServicesStats(startDate, endDate);
  }, []);

  const loadDashboardIncome = async (startDate, endDate) => {
    try {
      const response = await getDashboardIncome(startDate, endDate);
      setDailyIncome(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const loadDashboardServices = async (startDate, endDate) => {
    try {
      const response = await getDashboardServices(startDate, endDate);
      setDailyServices(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const loadDashboardLocationServicesSum = async (startDate, endDate) => {
    try {
      const response = await getDashboardLocationServicesSum(
        startDate,
        endDate
      );
      setDailyServicesStats(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const loadDashboardLocationRegistrySum = async (startDate, endDate) => {
    try {
      const response = await getDashboardLocationRegistrySum(
        startDate,
        endDate
      );
      setDailyStaffAndCashRegisters(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const loadDashboardEmployeeServicesStats = async (startDate, endDate) => {
    try {
      await getDashboardEmployeeServicesStats(
        startDate,
        endDate
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditProfileModalClose = () => {
    getProfile();
    setShowEditProfileModal(false);
  };

  const getProfile = async () => {
    try {
      const response = await getUserProfile(currentUser.login);

      const data = {
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        companyName: response.data.companyName,
        email: response.data.email,
        phoneNumber: response.data.phoneNumber,
        avatarUrl: response.data.avatarUrl,
      };

      if (!data.phoneNumber) {
        data.phoneNumber = "";
      }

      if (response.data.avatarUrl) {
        data.avatarUrl = response.data.avatarUrl;
      }
      setUserProfileData(data);
    } catch (error) {
      console.error(error);
    }
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <div className="profile-page-header">
        <div className="left-side">
          {userProfileData.companyName ? (
            <h1 className="mb-0">{userProfileData.companyName}</h1>
          ) : (
            <h1 className="mb-0">
              <Trans>add_company_name</Trans>
            </h1>
          )}
          <button
            className="icon-button profile-page-header-button"
            onClick={() => setShowEditProfileModal(true)}
          >
            <ZymranIcon name="pen" size={24} />
          </button>
        </div>
      </div>
      <div className="profile-info-card-block mb-4">
        <Card className="profile-info-card">
          <Card.Body>
            <h2 className="mb-0">
              {userProfileData.firstName} {userProfileData.lastName}
            </h2>
            <div>
              <div className="profile-info-card__contacts">
                <div className="contact-info">
                  <span className="label">
                    <Trans>phone_number</Trans>:
                  </span>
                  <span>{userProfileData.phoneNumber}</span>
                </div>
              </div>
              <div className="profile-info-card__contacts">
                <div className="contact-info">
                  <span className="label">
                    <Trans>email</Trans> :
                  </span>
                  <span>{userProfileData.email}</span>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="profile-socials-card">
          <Card.Body>
            <div className="profile-socials-note p2 secondary-text-color">
              <Trans>user_profile_adding_socials_instruction</Trans>
            </div>
            <button
              className="icon-button profile-socials-plus-btn"
              onClick={() => setShowEditProfileModal(true)}
            >
              <ZymranIcon name="plus" size={24} />
            </button>
          </Card.Body>
        </Card>
        <ZymranAvatar
          avatarUrl={userProfileData.avatarUrl}
          fullName={`${userProfileData.firstName} ${userProfileData.lastName}`}
          size="xl"
          shape="circle"
          className={`profile-avatar-main-page`}
          defaultAvatarIcon={DefaultAvatar}
        />
      </div>
      <div className="profile-cards-row mb-4">
        <Card className="profile-stats-card three-per-row">
          <Card.Body>
            <div className="p2-bold">
              <Trans>revenue_for_today</Trans>
            </div>
            {dailyIncome.map((locationStats, index) => (
              <ProfileStatsProgressBar
                key={index}
                locationStats={locationStats}
                dataKey="payment"
                colors={colors}
              />
            ))}
            <ProfileStatsLegend />
          </Card.Body>
        </Card>
        <Card className="profile-stats-card three-per-row">
          <Card.Body>
            <div className="p2-bold">
              <Trans>number_of_services_for_today</Trans>
            </div>
            {dailyServices.map((locationStats, index) => (
              <ProfileStatsProgressBar
                key={index}
                locationStats={locationStats}
                dataKey="service"
                colors={colors}
              />
            ))}
            <ProfileStatsLegend />
          </Card.Body>
        </Card>
        <Card className="profile-stats-card three-per-row">
          <Card.Body>
            <div className="p2-bold">
              <Trans>number_of_customers_for_today</Trans>
            </div>
            {mockDailyCustomers.map((locationStats, index) => {
              const totalSum =
                locationStats.customers.expected_remainder +
                locationStats.customers.received +
                locationStats.customers.canceled +
                locationStats.customers.extra;
              const totalRevenue =
                locationStats.customers.received +
                locationStats.customers.extra;
              const totalExpected =
                locationStats.customers.expected_remainder +
                locationStats.customers.received;
              return (
                <div className="profile-stats-card__stat-block" key={index}>
                  <div>
                    <span className="p3">{locationStats.locationName} </span>
                    <span className="p3-bold">{totalRevenue}</span>
                    <span className="p3">
                      {" "}
                      <Trans>out_of</Trans> {totalExpected}
                    </span>
                  </div>
                  <div className="w-100">
                    <ProgressBar
                      style={{ height: 10 }}
                      className="shadow-none rounded-3"
                    >
                      {Object.entries(locationStats.customers).map(
                        ([category, value]) => {
                          if (value === 0) return null;

                          return (
                            <ProgressBar
                              variant={colors[category]}
                              now={(value * 100) / totalSum}
                              key={category}
                              // label={`${category}: $${value}`}
                              className="border-end border-2 border-white"
                            />
                          );
                        }
                      )}
                    </ProgressBar>
                  </div>
                </div>
              );
            })}
            <ProfileStatsLegend />
          </Card.Body>
        </Card>
      </div>
      <div className="profile-cards-row">
        <Card className="profile-stats-card two-per-row">
          <Card.Body>
            <div className="w-100">
              <Row className="gx-3 w-100 p2-bold">
                <Col sm={4}>
                  <Trans>working_locations</Trans>
                </Col>
                <Col sm={4}>
                  <Trans>employees_today</Trans>
                </Col>
                <Col sm={4}>
                  <Trans>sum_by_cash_registers</Trans>
                </Col>
              </Row>
            </div>
            <div className="w-100">
              {dailyStaffAndCashRegisters.length === 0 ? (
                <p className="text-center"><Trans>no_data_available</Trans></p>
              ) : (
                dailyStaffAndCashRegisters.map((locationStats, index) => (
                  <Row className="p2 profile-stats-table-row" key={index}>
                    <Col sm={4}>{locationStats.locationName}</Col>
                    <Col sm={4}>{locationStats.activeEmployees}</Col>
                    <Col sm={4}>{locationStats.totalReceivedSum}</Col>
                  </Row>
                ))
              )}
            </div>
          </Card.Body>
        </Card>
        <Card className="profile-stats-card two-per-row">
          <Card.Body>
            <div className="w-100">
              <Row className="gx-3 w-100 p2-bold">
                <Col sm={4}>
                  <Trans>top_services_for_today</Trans>
                </Col>
                <Col sm={4}>
                  <Trans>quantity</Trans>
                </Col>
                <Col sm={4}>
                  <Trans>sum</Trans>
                </Col>
              </Row>
            </div>
            <div className="w-100">
              {dailyServicesStats.length === 0 ? (
                <p className="text-center"><Trans>no_data_available</Trans></p>
              ) : (
                dailyServicesStats.map((serviceStats, index) => (
                  <Row className="p2 profile-stats-table-row" key={index}>
                    <Col sm={4}>{serviceStats.serviceName}</Col>
                    <Col sm={4}>{serviceStats.quantity}</Col>
                    <Col sm={4}>{serviceStats.sumPerServicePerLocation}</Col>
                  </Row>
                ))
              )}
            </div>
          </Card.Body>
        </Card>
      </div>

      <Modal
        show={showEditProfileModal}
        onHide={handleEditProfileModalClose}
        fullscreen={true}
      >
        <Modal.Header closeButton closeVariant="white" className="bg-primary">
          <Modal.Title className="text-white light">
            <Trans>edit</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditProfile handleClose={handleEditProfileModalClose} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserProfile;
