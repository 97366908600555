import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getSystemServices = () => {
  return http.get(`system-service/`, {
    headers: authHeader(),
  });
};

export { getSystemServices };
