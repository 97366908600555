import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateStaff from "./CreateStaff";
import EditStaff from "./EditStaff";
import ListStaff from "./ListStaff";

const Staff = () => {
  return (
    <>
      <Routes>
        <Route path="create" element={<CreateStaff />} />
        <Route path="edit/:staffId" element={<EditStaff />} />
        <Route path="/" element={<ListStaff />} />
      </Routes>
    </>
  );
};

export default Staff;
