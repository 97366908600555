import { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Modal,
  ToggleButton,
} from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import IncomeDetail from "./income-detail/IncomeDetail";
import Select, { components } from "react-select";
import { getIncomes } from "service/income/IncomeService";
import { getLocations } from "service/location/LocationService";
import AdvanceTableWrapper from "component/common/advance-table/AdvanceTableWrapper";
import AdvanceTablePagination from "component/common/advance-table/AdvanceTablePagination";
import AdvanceTable from "component/common/advance-table/AdvanceTable";
import { toZonedTime } from "date-fns-tz";
import {
  format,
  endOfMonth,
  endOfWeek,
  isToday as isTodayDateFns,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import CreateIncomeRecord from "./CreateIncomeRecord";
import Lottie from "lottie-react";
import loadingAnimation from "assets/img/animated-icons/loading.json";
import ZymranIcon from "component/common/ZymranIcon";
import useLocale from "hooks/useLocale";
import { INCOME_STATUS } from "util/constants";
import { DateTime } from "luxon";

const IncomeView = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [showIncomeDetailModal, setShowIncomeDetailModal] = useState(false);
  const [showCreateIncomeRecordModal, setShowCreateIncomeRecordModal] =
    useState(false);
  const [orderTypes, setOrderTypes] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [filteredIncomes, setFilteredIncomes] = useState([]);
  const [selectedIncome, setSelectedIncome] = useState({});
  const timezone = DateTime.local().zoneName;
  const [selectedOrderType, setSelectedOrderType] = useState({
    value: "",
    label: "",
  });
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({
    value: "",
    label: "",
  });
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const columns = [
    {
      accessor: "code",
      Header: t("income_code_label"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { code } = rowData.row.original;
        return (
          <>
            <Link
              to="#"
              onClick={() => handleIncomeSelect(rowData.row.original)}
            >
              {code}
            </Link>
          </>
        );
      },
    },
    {
      accessor: "date",
      Header: t("date"),
      Cell: (rowData) => {
        const { date } = rowData.row.original;
        const zonedTime = toZonedTime(date, timezone);
        return <>{format(zonedTime, "dd MMM yyyy hh:mm", { locale })}</>;
      },
    },
    {
      accessor: "type",
      Header: t("type"),
      Cell: (rowData) => {
        const { type } = rowData.row.original;
        return (
          <>
            <Trans>{`income_type_${type}`}</Trans>
          </>
        );
      },
    },
    {
      accessor: "status",
      Header: t("income_status"),
      Cell: (rowData) => {
        const { incomeStatus } = rowData.row.original;
        return (
          <>
            <span
              className={
                incomeStatus === INCOME_STATUS.DONE
                  ? "text-success"
                  : incomeStatus === INCOME_STATUS.DELETED
                  ? "text-danger"
                  : ""
              }
            >
              <Trans>{`income_status_${incomeStatus}`}</Trans>
            </span>
          </>
        );
      },
    },
    {
      accessor: "location",
      Header: t("location"),
      Cell: (rowData) => {
        const { location } = rowData.row.original;
        return <>{location.name}</>;
      },
    },
    {
      accessor: "acceptedBy",
      Header: t("income_order_processed_by"),
      Cell: (rowData) => {
        const { acceptedFromUser } = rowData.row.original;
        return (
          <>
            {acceptedFromUser
              ? `${acceptedFromUser.firstName} ${acceptedFromUser.lastName}`
              : null}
          </>
        );
      },
    },
    {
      accessor: "sum",
      Header: t("sum"),
      Cell: (rowData) => {
        const { sum } = rowData.row.original;
        return <>{sum}</>;
      },
    },
  ];

  const handleIncomeDetailModalClose = () => {
    setShowIncomeDetailModal(false);
  };

  const handleCreateIncomeRecordModalClose = () => {
    setShowCreateIncomeRecordModal(false);
  };

  useEffect(() => {
    initOrderTypes();
    loadIncomes();
    loadLocations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let filteredIncomesByType = [];
    let filteredIncomesByLocation = [];

    if (selectedOrderType.value !== "selectAll") {
      filteredIncomesByType = incomes.filter(
        (income) => income.type === selectedOrderType.value
      );
    } else {
      filteredIncomesByType = incomes;
    }

    if (selectedLocation.value !== "" && selectedLocation.value !== 0) {
      filteredIncomesByLocation = filteredIncomesByType.filter(
        (income) => income.location.id === selectedLocation.value
      );
    } else {
      filteredIncomesByLocation = filteredIncomesByType;
    }

    let filteredIncomesByDate = [];

    switch (selectedDateFilter) {
      case 1: // today
        filteredIncomesByDate = filteredIncomesByLocation.filter((income) =>
          isToday(income.date)
        );
        break;
      case 2: // week
        filteredIncomesByDate = filteredIncomesByLocation.filter((income) =>
          isWithinThisWeek(income.date)
        );
        break;
      case 3: // current_month
        filteredIncomesByDate = filteredIncomesByLocation.filter((income) =>
          isInCurrentMonth(income.date)
        );
        break;
      default:
        filteredIncomesByDate = filteredIncomesByLocation;
        break;
    }
    setFilteredIncomes(filteredIncomesByDate);
    // eslint-disable-next-line
  }, [selectedOrderType, selectedLocation, incomes, selectedDateFilter]);

  const initOrderTypes = () => {
    const orderTypes = [
      { label: t("select_all"), value: "selectAll" },
      { label: t("service-appointment"), value: "service-appointment" },
      { label: t("manual-cash"), value: "manual-cash" },
    ];
    setOrderTypes(orderTypes);
    setSelectedOrderType(orderTypes[0]);
  };

  function isToday(date) {
    const today = startOfDay(new Date());
    const comparedDate = startOfDay(toZonedTime(date, timezone));
    return isTodayDateFns(comparedDate, today);
  }

  function isWithinThisWeek(date) {
    const now = new Date();
    const startOfCurrentWeek = startOfWeek(now);
    const endOfCurrentWeek = endOfWeek(now);
    const comparedDate = startOfDay(toZonedTime(date, timezone));
    return isWithinInterval(comparedDate, {
      start: startOfCurrentWeek,
      end: endOfCurrentWeek,
    });
  }

  function isInCurrentMonth(date) {
    const now = new Date();
    const startOfCurrentMonth = startOfMonth(now);
    const endOfCurrentMonth = endOfMonth(now);
    const comparedDate = startOfDay(toZonedTime(date, timezone));
    return isWithinInterval(comparedDate, {
      start: startOfCurrentMonth,
      end: endOfCurrentMonth,
    });
  }

  const loadIncomes = async () => {
    try {
      setLoadingCounter(loadingCounter + 1);
      const response = await getIncomes();
      console.log("income response: ", response.data);
      setIncomes(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCounter(loadingCounter - 1);
    }
  };

  const loadLocations = async () => {
    try {
      setLoadingCounter(loadingCounter + 1);
      const response = await getLocations();
      console.log("LOCATIONS: ", response.data);
      let locs = [];
      locs.push({ value: 0, label: t("select_all") });
      response.data.forEach((loc) => {
        const location = {
          value: loc.id,
          label:
            loc.name + " " + loc.address.street + " " + loc.address.houseNumber,
        };
        locs.push(location);
      });
      setSelectedLocation(locs[0]);
      setLocationOptions(locs);
    } catch (error) {
    } finally {
      setLoadingCounter(loadingCounter - 1);
    }
  };

  const handleIncomeSelect = (selectedIncome) => {
    setSelectedIncome(selectedIncome);
    setShowIncomeDetailModal(true);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ZymranIcon name="chevron-down" className="secondary-text-color" />
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="mb-0">
          <Trans>incoming_order</Trans>
        </h1>
        <Button
          variant="blue"
          name="addIncomeButton"
          size="sm"
          onClick={() => setShowCreateIncomeRecordModal(true)}
        >
          <ZymranIcon name="plus" />
          <Trans>add</Trans>
        </Button>
      </div>

      {loadingCounter > 0 ? (
        <Card className="mb-3 d-flex justify-content-center align-items-center h-100 p-5">
          <Card.Body>
            <Lottie
              animationData={loadingAnimation}
              loop={true}
              style={{ height: "220px", width: "220px" }}
            />
          </Card.Body>
        </Card>
      ) : (
        <AdvanceTableWrapper
          columns={columns}
          data={filteredIncomes}
          sortable
          pagination
          perPage={10}
        >
          <Card className="mb-3">
            <Card.Header>
              <div className="finance-filter-area">
                <div className="d-flex align-items-center gap-3">
                  <div className="d-flex align-items-center gap-2">
                    <span className="p2">
                      <Trans>location</Trans>:
                    </span>
                    <Select
                      id="location"
                      name="location"
                      classNamePrefix="react-select"
                      className="react-select--sm finance-select"
                      placeholder="test"
                      value={selectedLocation}
                      options={locationOptions}
                      required
                      onChange={(option) => setSelectedLocation(option)}
                      components={{ DropdownIndicator }}
                    />
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <span className="p2">
                      <Trans>income_type</Trans>:
                    </span>
                    <Select
                      id="order-type"
                      name="order-type"
                      classNamePrefix="react-select"
                      className="react-select--sm finance-select"
                      placeholder="test"
                      value={selectedOrderType}
                      options={orderTypes}
                      required
                      onChange={(option) => setSelectedOrderType(option)}
                      components={{ DropdownIndicator }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-start gap-2">
                  <ButtonGroup className="finance-filter-pills" size="sm">
                    <ToggleButton
                      id="radio-1"
                      type="radio"
                      variant="grey"
                      name="radio-today"
                      value={1}
                      checked={selectedDateFilter === 1}
                      onChange={() => setSelectedDateFilter(1)}
                    >
                      <Trans>today</Trans>
                    </ToggleButton>
                    <ToggleButton
                      id="radio-2"
                      type="radio"
                      variant="grey"
                      name="radio-week"
                      value={2}
                      checked={selectedDateFilter === 2}
                      onChange={() => setSelectedDateFilter(2)}
                    >
                      <Trans>week</Trans>
                    </ToggleButton>
                    <ToggleButton
                      id="radio-3"
                      type="radio"
                      variant="grey"
                      name="radio-month"
                      value={3}
                      checked={selectedDateFilter === 3}
                      onChange={() => setSelectedDateFilter(3)}
                    >
                      <Trans>current_month</Trans>
                    </ToggleButton>
                  </ButtonGroup>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="pt-2">
              <div className="table-rounded-wrapper">
                <AdvanceTable
                  table
                  headerClassName="p2-bold secondary-text-color text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: "sm",
                    className: "p2 mb-0 overflow-hidden jazu-list-table",
                  }}
                />
              </div>
            </Card.Body>
            <Card.Footer className="pt-0">
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      )}

      <Modal
        show={showIncomeDetailModal}
        onHide={handleIncomeDetailModalClose}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title>
            <Trans>income_detail</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IncomeDetail
            handleClose={handleIncomeDetailModalClose}
            selectedIncome={selectedIncome}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showCreateIncomeRecordModal}
        onHide={handleCreateIncomeRecordModalClose}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title>
            <Trans>add_income_record_into_register</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateIncomeRecord
            handleClose={handleCreateIncomeRecordModalClose}
            setIncomes={setIncomes}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IncomeView;
