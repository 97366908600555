import { ReactComponent as UserAvatarIcon } from "assets/img/icons/user-avatar.svg";
import CustomDateInput from "component/common/CustomDateInput";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import EnhancedSelect from "component/common/EnhancedSelect";
import FalconDropzone from "component/common/FalconDropzone";
import Flex from "component/common/Flex";
import ZymranIcon from "component/common/ZymranIcon";
import { toZonedTime } from "date-fns-tz";
import { parsePhoneNumber } from "libphonenumber-js/core";
import metadata from "libphonenumber-js/metadata.min.json";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Datepicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { getCustomer, updateCustomer } from "service/customer/CustomerService";
import { uploadAvatar } from "service/profile/UserProfileService";
import { notifySuccess } from "util/notify";
import useLocale from "hooks/useLocale";
import avatarFallback from "assets/img/avatar-fallback.png";

const EditCustomer = ({ customerId, onUpdateCustomer, handleClose }) => {
  const [customerData, setCustomerData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    gender: "",
    phoneNumberPrimary: "",
    phoneNumberSecondary: "",
    dob: "",
    notes: "",
  });

  const [selectedGender, setSelectedGender] = useState(null);

  const [avatar, setAvatar] = useState([]);
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [blocking, setBlocking] = useState(false);
  const { register } = useForm();
  const timezone = DateTime.local().zoneName;
  const [genders, setGenders] = useState([]);
  const [loading, setLoading] = useState(true);
  const locale = useLocale();

  useEffect(() => {
    initGenders();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadCustomerData();
    // eslint-disable-next-line
  }, [customerId]);

  useEffect(() => {
    const customerGender = genders.find(
      (gender) => gender.value === customerData.genderId
    );
    setSelectedGender(customerGender);
    // eslint-disable-next-line
  }, [customerData.id]);

  const initGenders = () => {
    const genders = [
      { label: t("male"), value: 1 },
      { label: t("female"), value: 2 },
    ];
    setGenders(genders);
    setSelectedGender(genders.find(({ value }) => value === 3));
  };

  const loadCustomerData = async () => {
    try {
      const response = await getCustomer(customerId);
      const responseCustomer = {
        id: response.data.id,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        middleName: response.data.middleName,
        phoneNumberPrimary: response.data.phoneNumberPrimary
          ? response.data.phoneNumberPrimary
          : "",
        phoneNumberSecondary: response.data.phoneNumberSecondary
          ? response.data.phoneNumberSecondary
          : "",
        email: response.data.email,
        genderId: response.data.genderId,
        dob: response.data.dob
          ? toZonedTime(response.data.dob, timezone)
          : response.data.dob,
        notes: response.data.notes ? response.data.notes : "",
        avatarFilename: response.data.avatarFilename
          ? response.data.avatarFilename
          : "",
        avatarUrl: response.data.avatarUrl ? response.data.avatarUrl : "",
      };

      setSelectedGender(
        genders.find(({ value }) => value === responseCustomer.genderId)
      );
      setCustomerData(responseCustomer);

      if (responseCustomer.avatarUrl) {
        setAvatar([{ url: responseCustomer.avatarUrl }]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const setField = (field, value) => {
    setCustomerData({
      ...customerData,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const handleGenderSelectionChange = (selectedOption) => {
    setSelectedGender(selectedOption);
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors["selectedGender"])
      setErrors({
        ...errors,
        selectedGender: null,
      });
  };

  const handleFileChange = (uploadedFiles) => {
    setAvatar(uploadedFiles);
  };

  const sendFileToBackend = async (base64EncodedImage, mimeType) => {
    try {
      const response = await uploadAvatar(base64EncodedImage, mimeType);
      console.log("RESPONSE:", response);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    console.log("handleSubmit called");
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      setBlocking(true);

      const payload = { ...customerData };
      payload.genderId = selectedGender.value;

      if (avatar.length > 0) {
        const fileData = avatar[0];
        try {
          const avatarResponse = await sendFileToBackend(
            fileData.base64,
            fileData.type
          );
          console.log("AVATAR UPLOAD RESPONSE:", avatarResponse);
          payload.avatarFilename = avatarResponse.data.filename;
          payload.avatarUrl = avatarResponse.data.url;
        } catch (error) {}
      }

      try {
        const response = await updateCustomer(payload);
        notifySuccess(t("customer_updated_successfully"));
        onUpdateCustomer(response.data);
        // setServiceCategoryUpdated(true);
        handleClose();
      } catch (error) {
        console.error(error);
      } finally {
        setBlocking(false);
      }
    }
  };

  const findFormErrors = () => {
    const { firstName, lastName, phoneNumberPrimary, phoneNumberSecondary } =
      customerData;

    const newErrors = {};

    if (!firstName || firstName === "")
      newErrors.firstName = t("field_required");

    if (!lastName || lastName === "") newErrors.lastName = t("field_required");

    if (!selectedGender || !selectedGender.value)
      newErrors.selectedGender = t("field_required");

    if (!phoneNumberPrimary || phoneNumberPrimary === "")
      newErrors.phoneNumberPrimary = t("field_required");
    else if (phoneNumberPrimary.length > 12)
      newErrors.phoneNumberPrimary = t("too_long");
    else {
      try {
        const phoneNumberObject = parsePhoneNumber(
          phoneNumberPrimary,
          metadata
        );
        if (!phoneNumberObject.isValid())
          newErrors.phoneNumberPrimary = t("invalid_phone_number");
      } catch (error) {
        newErrors.phoneNumberPrimary = t("invalid_phone_number");
      }
    }

    if (phoneNumberSecondary && phoneNumberSecondary !== "") {
      if (phoneNumberSecondary.length > 12)
        newErrors.phoneNumberSecondary = t("too_long");
      else {
        try {
          const phoneNumberSecondaryObject = parsePhoneNumber(
            phoneNumberSecondary,
            metadata
          );
          if (!phoneNumberSecondaryObject.isValid())
            newErrors.phoneNumberSecondary = t("invalid_phone_number");
        } catch (error) {
          newErrors.phoneNumberSecondary = t("invalid_phone_number");
        }
      }
    }
    return newErrors;
  };

  if (loading)
    return (
      <div className="container central-content-container">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <div className="container central-content-container">
      <Card className="mb-3">
        <Card.Header>
          <h3 className="mb-0">
            <Trans>basic_information</Trans>
          </h3>
        </Card.Header>
        <Card.Body className="border-top">
          <div className="d-flex align-items-start gap-4 align-self-stretch">
            <FalconDropzone
              files={avatar}
              className="staff-avatar-dropzone"
              onChange={handleFileChange}
              multiple={false}
              accept="image/*"
              preview={true}
              isCircular={true}
              fallbackImage={avatarFallback}
              placeholder={
                <>
                  <Flex justifyContent="center" className="position-relative">
                    {/* <img src={cloudUpload} alt="" width={25} className="me-2" /> */}
                    <UserAvatarIcon className="profile-icon" />
                    <div className="staff-avatar-plus-button">
                      <ZymranIcon name="plus" />
                    </div>
                  </Flex>
                </>
              }
            />
            <div className="d-flex flex-column align-items-stretch gap-3 flex-grow-1">
              <Row className="gx-3">
                <Col xs={12} sm={4}>
                  <EnhancedFormControl
                    id="firstName"
                    name="firstName"
                    type="text"
                    label={t("firstname")}
                    value={customerData.firstName}
                    onChange={(e) => setField("firstName", e.target.value)}
                    isInvalid={!!errors.firstName}
                    placeholder={t("firstname")}
                    errors={errors.firstName}
                    groupClassName="w-100"
                    required
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <EnhancedFormControl
                    id="lastName"
                    name="lastName"
                    type="text"
                    label={t("lastname")}
                    value={customerData.lastName}
                    onChange={(e) => setField("lastName", e.target.value)}
                    isInvalid={!!errors.lastName}
                    placeholder={t("lastname")}
                    errors={errors.lastName}
                    groupClassName="w-100"
                    required
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <EnhancedFormControl
                    id="middleName"
                    name="middleName"
                    type="text"
                    label={t("middlename")}
                    value={customerData.middleName}
                    onChange={(e) => setField("middleName", e.target.value)}
                    isInvalid={!!errors.middleName}
                    placeholder={t("middlename")}
                    errors={errors.middleName}
                    groupClassName="w-100"
                  />
                </Col>
              </Row>
              <Row className="gx-3">
                <Col xs={12} sm={4}>
                  <Form.Group className="w-100">
                    <Form.Label>
                      <Trans>birth_day</Trans>
                    </Form.Label>
                    <Datepicker
                      className="w-100"
                      selected={customerData.dob}
                      defaultValue={customerData.dob}
                      onChange={(date) => setField("dob", date)}
                      dateFormat="dd-MM-yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      scrollableYearDropdown={true}
                      maxDate={new Date()}
                      yearDropdownItemNumber={100}
                      locale={locale}
                      wrapperClassName={
                        !!errors.dob
                          ? "is-invalid customer-datepicker"
                          : "customer-datepicker"
                      }
                      customInput={
                        <CustomDateInput
                          formControlProps={{
                            placeholder: "d/m/y",
                            name: "dob",
                            ...register("dob", {
                              required: true,
                            }),
                          }}
                        />
                      }
                    />
                    {errors.dob && (
                      <p className="text-red-600 text-sm cursor-default">
                        Birthdate is required
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} sm={4}>
                  <EnhancedSelect
                    id="gender"
                    name="gender"
                    placeholder={t("gender")}
                    label={t("gender")}
                    selectedValue={selectedGender}
                    options={genders}
                    onChange={(option) => handleGenderSelectionChange(option)}
                    errors={errors.selectedGender}
                    required
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header>
          <h3 className="mb-0">
            <Trans>contact_details</Trans>
          </h3>
        </Card.Header>
        <Card.Body className="border-top">
          <div className="d-flex flex-column align-items-stretch gap-3 flex-grow-1">
            <Row className="gx-3">
              <Col xs={12} sm={6}>
                <Form.Group className="w-100" controlId="phone-number">
                  <Form.Label
                    className={
                      !!errors.phoneNumberPrimary
                        ? "is-invalid phone-input-label"
                        : "phone-input-label"
                    }
                  >
                    <Trans>phone_number</Trans>:
                  </Form.Label>
                  <PhoneInput
                    id="phoneNumberPrimary"
                    name="phoneNumberPrimary"
                    defaultCountry="KZ"
                    placeholder="+7 777 777 7777*"
                    value={customerData.phoneNumberPrimary}
                    onChange={(value) => setField("phoneNumberPrimary", value)}
                    limitMaxLength={true}
                    maxLength={15}
                    className={!!errors.phoneNumberPrimary ? "is-invalid" : ""}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group className="w-100" controlId="phone-number">
                  <Form.Label
                    className={
                      !!errors.phoneNumberPrimary
                        ? "is-invalid phone-input-label"
                        : "phone-input-label"
                    }
                  >
                    <Trans>additional_phone</Trans>:
                  </Form.Label>
                  <PhoneInput
                    id="phoneNumberSecondary"
                    name="phoneNumberSecondary"
                    defaultCountry="KZ"
                    placeholder="+7 777 777 7777"
                    value={customerData.phoneNumberSecondary}
                    onChange={(value) =>
                      setField("phoneNumberSecondary", value)
                    }
                    limitMaxLength={true}
                    maxLength={15}
                    className={
                      !!errors.phoneNumberSecondary ? "is-invalid" : ""
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="gx-3">
              <Col xs={12} sm={6}>
                <EnhancedFormControl
                  id="email"
                  name="email"
                  type="email"
                  label={t("email")}
                  value={customerData.email}
                  onChange={(e) => setField("email", e.target.value)}
                  isInvalid={!!errors.email}
                  placeholder={t("email")}
                  errors={errors.email}
                  groupClassName="w-100"
                />
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header>
          <h3 className="mb-0">
            <Trans>important_information</Trans>
          </h3>
        </Card.Header>
        <Card.Body className="border-top">
          <Row className="gx-3">
            <Col sm={6}>
              <EnhancedFormControl
                id="notes"
                name="notes"
                type="text"
                label={t("type_information_about_customer")}
                value={customerData.notes}
                onChange={(e) => setField("notes", e.target.value)}
                isInvalid={!!errors.notes}
                placeholder={t("type_information_about_customer")}
                errors={errors.notes}
                groupClassName="w-100"
                as="textarea"
                rows={3}
              />
            </Col>
            <Col sm={6} className="d-flex align-items-center">
              <div className="p2">
                <Trans>customer_important_info_description</Trans>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-end align-items-start gap-3">
        <Button name="cancelButton" variant="grey" onClick={handleClose}>
          <Trans>cancel</Trans>
        </Button>
        <Button
          name="saveCustomerButton"
          type="submit"
          variant="blue"
          onClick={handleSubmit}
        >
          <Trans>save_customer</Trans>
        </Button>
      </div>
    </div>
  );
};

export default EditCustomer;
