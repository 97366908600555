import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import { ReactComponent as OfficeIcon } from "assets/img/icons/office.svg";
import FalconDropzone from "component/common/FalconDropzone";
import ZymranIcon from "component/common/ZymranIcon";
import Flex from "component/common/Flex";

const LocationBasicInformationForm = ({
  basicInformation,
  setField,
  errors,
  avatar,
  handleFileChange,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-start gap-4 align-self-stretch">
      <FalconDropzone
        files={avatar}
        className="staff-avatar-dropzone"
        onChange={handleFileChange}
        multiple={false}
        accept="image/*"
        preview={true}
        placeholder={
          <>
            <Flex justifyContent="center" className="position-relative">
              {/* <img src={cloudUpload} alt="" width={25} className="me-2" /> */}
              <OfficeIcon />
              <div className="staff-avatar-plus-button">
                <ZymranIcon name="plus" />
              </div>
            </Flex>
          </>
        }
      />
      <div className="d-flex flex-column align-items-stretch gap-3 align-self-stretch flex-grow-1">
        <EnhancedFormControl
          id="name"
          name="name"
          type="text"
          value={basicInformation.name}
          onChange={(e) => setField("basicInformation", "name", e.target.value)}
          isInvalid={!!errors.name}
          placeholder={t("name")}
          label={t("name")}
          errors={errors.name}
        />
        <div className="d-flex align-items-start gap-3 align-self-stretch">
          <EnhancedFormControl
            id="email"
            name="email"
            type="email"
            value={basicInformation.email}
            onChange={(e) =>
              setField("basicInformation", "email", e.target.value)
            }
            isInvalid={!!errors.email}
            placeholder={t("email")}
            label={t("email")}
            errors={errors.email}
            groupClassName="flex-grow-1"
          />
          <Form.Group className="flex-grow-1">
            <Form.Label className="phone-input-label">
              <Trans>phone_number</Trans>
            </Form.Label>
            <PhoneInput
              defaultCountry="KZ"
              placeholder="+7 777 777 7777"
              value={basicInformation.phoneNumber}
              onChange={(value) =>
                setField("basicInformation", "phoneNumber", value)
              }
              id="phoneNumber"
              name="phoneNumber"
              className={!!errors.phoneNumber ? "is-invalid" : ""}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phoneNumber}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

LocationBasicInformationForm.propTypes = {
  basicInformation: PropTypes.object.isRequired,
  setField: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default LocationBasicInformationForm;
