import { GoogleOAuthProvider } from "@react-oauth/google";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { unstable_HistoryRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Layout from "./layouts/Layout";
import "./styles/css/main.css";
import { history } from "./util/history";
import "./datePickerLocale";

const App = () => {
  return (
    <Router history={history}>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
      >
        <Layout />
      </GoogleOAuthProvider>
    </Router>
  );
};

export default App;
