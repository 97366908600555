import ZymranIcon from "component/common/ZymranIcon";
import ZymranAvatar from "component/common/ZymranAvatar";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import useLocale from "hooks/useLocale";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import Select from "react-select";
import { getCashRegistersByLocationid } from "service/cash-register/CashRegisterService";
import {
  finalizeSalary,
  getSalaryProcessData,
} from "service/salary/SalaryService";
import { notifySuccess } from "util/notify";

const SalaryStaffProcessing = ({
  handleClose,
  selectedStaff,
  selectedLocation,
  setSalaryPaid,
}) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [salaryProcessData, setSalaryProcessData] = useState({});
  // const [cashRegisters, setCashRegisters] = useState([]);
  const [selectedCashRegisterTypes, setSelectedCashRegisterTypes] = useState(
    []
  );
  const [cashRegisterOptions, setCashRegisterOptions] = useState([]);
  const [registerPairs, setRegisterPairs] = useState([
    { selectedCashRegisterType: "", sumToPay: "" },
  ]);
  const { t } = useTranslation();
  const locale = useLocale();
  const currentDate = new Date();
  const [errors, setErrors] = useState({
    selectedCashRegisterType: [],
    sumToPay: [],
  });

  useEffect(() => {
    loadCashRegisters();
  }, []);

  useEffect(() => {
    if (selectedStaff && selectedStaff.id) {
      loadSalaryDetails();
    }
  }, [selectedStaff]);

  const toggleRowExpansion = (id) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const loadCashRegisters = async () => {
    try {
      const response = await getCashRegistersByLocationid(
        selectedLocation.value
      );
      // setCashRegisters(response.data);
      const cashRegisterOptions = response.data.map((item) => ({
        value: item.id,
        label: t("cash_register_type_" + item.cashRegisterType.name),
      }));
      setCashRegisterOptions(cashRegisterOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const loadSalaryDetails = async () => {
    const payload = {
      staffId: selectedStaff.id,
      locationId: selectedLocation.value,
    };
    try {
      const response = await getSalaryProcessData(payload);
      setSalaryProcessData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRegisterTypeSelectionChange = (selectedOption, index) => {
    const newPairs = [...registerPairs];
    const oldSelection = newPairs[index].selectedCashRegisterType.value;

    newPairs[index].selectedCashRegisterType = selectedOption;
    setRegisterPairs(newPairs);

    const updatedSelectedTypes = selectedCashRegisterTypes.filter(
      (type) => type !== oldSelection
    );
    if (selectedOption.value) {
      updatedSelectedTypes.push(selectedOption.value);
    }
    setSelectedCashRegisterTypes(updatedSelectedTypes);

    const newErrors = { ...errors };
    if (newErrors.selectedCashRegisterType[index]) {
      newErrors.selectedCashRegisterType[index] = "";
      setErrors(newErrors);
    }
  };

  const handleSumChange = (e, index) => {
    const newValue = e.target.value;
    const numericValue = newValue === "" ? "" : Number(newValue);

    const newPairs = [...registerPairs];
    newPairs[index].sumToPay = numericValue;
    setRegisterPairs(newPairs);

    const newErrors = { ...errors };
    if (newErrors.sumToPay && newErrors.sumToPay[index]) {
      newErrors.sumToPay[index] = "";
      setErrors(newErrors);
    }
  };

  const addNewPair = () => {
    if (registerPairs.length < cashRegisterOptions.length) {
      setRegisterPairs([
        ...registerPairs,
        { selectedCashRegisterType: "", sumToPay: "" },
      ]);
    }
  };

  const findFormErrors = () => {
    const newErrors = {
      selectedCashRegisterType: [],
      sumToPay: [],
    };
    if (registerPairs.length > 0) {
      registerPairs.forEach((pair, index) => {
        if (!pair.selectedCashRegisterType) {
          newErrors.selectedCashRegisterType[index] = t("field_required");
        }
        if (!pair.sumToPay) {
          newErrors.sumToPay[index] = t("field_required");
        }
      });
    }
    return newErrors;
  };

  const handleSubmit = async () => {
    try {
      const newErrors = findFormErrors();

      let hasErrors = Object.values(newErrors).some((errorList) => {
        return errorList.some((error) => error !== "");
      });

      if (hasErrors) {
        setErrors(newErrors);
        return;
      }

      if (registerPairs.length > 0) {
        registerPairs.forEach((pair, index) => {
          if (!pair.selectedCashRegisterType) {
            hasErrors = true;
            newErrors[`selectedCashRegisterType-${index}`] = t("required");
          }
          if (!pair.sumToPay) {
            hasErrors = true;
            newErrors[`sumToPay-${index}`] = t("required");
          }
        });
      }

      if (hasErrors) {
        setErrors(newErrors);
        return;
      }

      const payload = {
        staffId: selectedStaff.id,
        locationId: selectedLocation.value,
        expenseId: salaryProcessData.expenseId,
        salaryData: {
          sumToPay: salaryProcessData.salaryData.totalSumToPay,
          // sumToPay: registerPairs.reduce(
          //   (total, pair) => total + pair.sumToPay,
          //   0
          // ),
          sumsPaid: registerPairs.map((pair) => ({
            cashRegisterId: pair.selectedCashRegisterType.value,
            sum: pair.sumToPay,
          })),
        },
      };

      await finalizeSalary(payload);
      setSalaryPaid(true);
      notifySuccess(t("salary_issued_successfully"));
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container central-content-container">
      <Card className="mb-3">
        <Card.Header className="plain-card-header">
          <div className="d-flex justify-content-center">
            <h3 className="mb-0">
              <Trans>salary_issue</Trans>
            </h3>
          </div>
        </Card.Header>
        <Card.Body>
          <Table borderless size="sm" className="p1 primary-text-color w-auto">
            <tbody>
              <tr>
                <th className="p1-bold secondary-text-color">
                  <Trans>location</Trans>
                </th>
                <td>{selectedLocation.label}</td>
              </tr>
              <tr>
                <th className="p1-bold secondary-text-color">
                  <Trans>staff_member</Trans>
                </th>
                <td className="d-flex flex-row align-items-center">
                  <div className="d-flex">
                    <ZymranAvatar
                      avatarUrl={selectedStaff.avatarUrl}
                      size="md"
                      shape="circle"
                      className={"me-2"}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-start text-start">
                    <p className="p2-bold mb-0 text-blue mb-0 ">
                      {selectedStaff.firstName} {selectedStaff.lastName}
                    </p>
                    <p className="p3 mb-0 secondary-text-color">
                      {selectedStaff.position}
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
          {salaryProcessData && salaryProcessData.salaryData && (
            <div className="table-rounded-wrapper mb-3">
              <Table
                borderless
                size="sm"
                className="jazu-list-table p2 primary-text-color w-100 mb-0"
              >
                <thead className="p2-bold secondary-text-color text-nowrap align-middle">
                  <tr>
                    <th>
                      <Trans>payment_period</Trans>
                    </th>
                    <th>
                      <Trans>payment_date</Trans>
                    </th>
                    <th>
                      <Trans>wage</Trans>
                    </th>
                    <th>
                      <Trans>payment_for_services</Trans>
                    </th>
                    <th>
                      <Trans>payment_for_sales</Trans>
                    </th>
                    <th>
                      <Trans>debt</Trans>
                    </th>
                    <th>
                      <Trans>sum_to_pay_out_short</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    <tr
                      className="align-middle white-space-nowrap"
                      onClick={() =>
                        toggleRowExpansion(
                          salaryProcessData.salaryData.staff.id
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <td className="p2-bold">
                        <ZymranIcon name="chevron-down" className="me-2" />
                        {format(
                          toZonedTime(
                            salaryProcessData.calculationPeriod.startDate
                          ),
                          "dd MMM yyyy",
                          { locale }
                        )}{" "}
                        -{" "}
                        {format(
                          toZonedTime(
                            salaryProcessData.calculationPeriod.endDate
                          ),
                          "dd MMM yyyy",
                          { locale }
                        )}
                      </td>
                      <td>{format(currentDate, "dd MMM yyyy", { locale })}</td>
                      <td>{salaryProcessData.salaryData.salary}</td>
                      <td>{salaryProcessData.salaryData.totalSumToPay}</td>
                      <td></td>
                      <td>{salaryProcessData.salaryData.debt}</td>
                      <td>{salaryProcessData.salaryData.totalSumToPay}</td>
                    </tr>
                    {expandedRows.includes(
                      salaryProcessData.salaryData.staff.id
                    ) &&
                      salaryProcessData.salaryData.salaryDetails.map(
                        (detail, detailIndex) => (
                          <tr key={detailIndex} className="detail-row">
                            <td>{detail.calculationDate}</td>
                            <td>{detail.paymentDate}</td>
                            <td></td>
                            <td>{detail.servicesPayment}</td>
                            <td>{detail.salesPayment}</td>
                            <td>{detail.debt}</td>
                            <td>{detail.sumToPay}</td>
                          </tr>
                        )
                      )}
                  </React.Fragment>
                </tbody>
              </Table>
            </div>
          )}
          <div className="row">
            <div className="col-sm-6">
              <p className="p1-bold primary-text-color mb-2">
                <Trans>issue_from_cash_register</Trans>
              </p>
            </div>
            <div className="col-sm-6">
              <p className="p1-bold primary-text-color mb-2">
                <Trans>amount_to_be_issued</Trans>
              </p>
            </div>
          </div>
          {registerPairs.map((pair, index) => (
            <div className="row mb-3" key={index}>
              <div className="col-sm-6">
                <Select
                  id={`cash-register-type-${index}`}
                  name={`cash-register-type-${index}`}
                  classNamePrefix="react-select"
                  placeholder={t("select_payment_method_register")}
                  value={pair.selectedCashRegisterType}
                  options={cashRegisterOptions.filter(
                    (option) =>
                      !selectedCashRegisterTypes.includes(option.value)
                  )}
                  required
                  onChange={(option) =>
                    handleRegisterTypeSelectionChange(option, index)
                  }
                  className={
                    !!errors.selectedCashRegisterType[index]
                      ? "is-invalid-react-select"
                      : ""
                  }
                />
                {!!errors.selectedCashRegisterType && (
                  <div className="invalid-feedback-react-select">
                    {errors.selectedCashRegisterType[index]}
                  </div>
                )}
              </div>
              <div className="col-sm-6">
                <Form.Control
                  id={`sum-${index}`}
                  name={`sum-${index}`}
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  className="form-control"
                  value={pair.sumToPay || ""}
                  onChange={(e) => handleSumChange(e, index)}
                  isInvalid={!!errors.sumToPay[index]}
                  placeholder={t("sum")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.sumToPay[index]}
                </Form.Control.Feedback>
              </div>
            </div>
          ))}
          <Button
            onClick={addNewPair}
            variant="blue-light"
            size="sm"
            className="btn btn-primary mt-3"
            disabled={registerPairs.length >= cashRegisterOptions.length}
          >
            <ZymranIcon name="plus" />
            <Trans>add_payment_method</Trans>
          </Button>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-end gap-3">
        <Button variant="grey" className="btn" onClick={handleClose}>
          <Trans>cancel</Trans>
        </Button>
        <Button variant="blue" className="btn" onClick={handleSubmit}>
          <Trans>submit</Trans>
        </Button>
      </div>
    </div>
  );
};

export default SalaryStaffProcessing;
