import AuthCardLayout from "layouts/AuthCardLayout";
import PasswordRestore from "./password/PasswordRestore";

const PasswordRestoreWrapper = () => {
  return (
    <AuthCardLayout isLoginPage={true}>
      <PasswordRestore />
    </AuthCardLayout>
  );
};

export default PasswordRestoreWrapper;
