import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getLocations = () => {
  return http.get(`location/`, {
    headers: authHeader(),
  });
};

const getLocationsCashRegisterBalances = (id) => {
  return http.get(`finance/cash-register/location/${id}`, {
    headers: authHeader(),
  });
};

const getLocation = (id) => {
  return http.get(`location/${id}`, { headers: authHeader() });
};

const createLocation = (payload) => {
  return http.post(`location/`, payload, { headers: authHeader() });
};

const updateLocation = (payload) => {
  return http.put(`location/`, payload, { headers: authHeader() });
};

const deleteLocation = (id) => {
  return http.delete(`location/${id}`, { headers: authHeader() });
};

const uploadLocationAvatar = (fileData, mimeType) => {
  const binaryStr = atob(fileData.split(",")[1]);
  const len = binaryStr.length;
  const arrayBuffer = new ArrayBuffer(len);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < len; i++) {
    uint8Array[i] = binaryStr.charCodeAt(i);
  }

  const formData = new FormData();
  formData.append("file_upload", new Blob([uint8Array], { type: mimeType }));

  return http.post("/location/avatar", formData, {
    headers: {
      ...authHeader(),
    },
  });
};

export {
  getLocations,
  getLocation,
  createLocation,
  updateLocation,
  deleteLocation,
  uploadLocationAvatar,
  getLocationsCashRegisterBalances,
};
