import { authHeader } from "../auth/auth-header";
import http from "../../http-common";

const getAppointments = (payload) => {
  return http.post("appointment/all", payload, { headers: authHeader() });
};

const getChainedAppointments = (chainId) => {
  return http.get(`appointment/chain/${chainId}`, {
    headers: authHeader(),
  });
};

const createAppointments = (payload) => {
  return http.post(`appointment/`, payload, { headers: authHeader() });
};

const updateAppointments = (payload) => {
  return http.put(`appointment/`, payload, { headers: authHeader() });
};

const deleteAppointment = (id) => {
  return http.delete(`appointment/${id}`, { headers: authHeader() });
};

const deleteAppointmentСhain = (id) => {
  return http.delete(`appointment/chain/${id}`, { headers: authHeader() });
};

const checkoutAppointment = (payload) => {
  return http.post("appointment/checkout", payload, { headers: authHeader() });
};

export {
  getAppointments,
  getChainedAppointments,
  createAppointments,
  updateAppointments,
  deleteAppointment,
  deleteAppointmentСhain,
  checkoutAppointment,
};
