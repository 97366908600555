import React from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { useEffect, useState } from "react";
import { getBusinessTypes } from "service/business-type/BusinessTypeService";
import BusinessTypeButton from "./BusinessTypeButton";
import { REGISTRATION_STEPS } from "util/constants";

const Step3 = ({ currentStep, setField }) => {
  const [selectedBusinessTypes, setSelectedBusinessTypes] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [selected, setSelected] = useState({});
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    loadBusinessTypes();
  }, []);

  const loadBusinessTypes = async () => {
    try {
      const response = await getBusinessTypes();
      setBusinessTypes(response.data);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  if (currentStep !== REGISTRATION_STEPS.STEP3) {
    return null;
  }

  const handleCheck = (businessType, id) => {
    if (selectedBusinessTypes.some((o) => o.id === businessType.id)) {
      const filteredBusinessTypes = selectedBusinessTypes.filter(
        (o) => o.id !== businessType.id
      );
      setSelectedBusinessTypes(filteredBusinessTypes);
      setField(
        "locationWithBusinessTypes",
        "businessTypes",
        filteredBusinessTypes.map(({ id }) => id)
      );
    } else {
      if (Object.keys(selected).length < 3) {
        const filteredBusinessTypes = [...selectedBusinessTypes, businessType];
        setSelectedBusinessTypes(filteredBusinessTypes);
        setField(
          "locationWithBusinessTypes",
          "businessTypes",
          filteredBusinessTypes.map(({ id }) => id)
        );
      }
    }

    if (!selected[id]) {
      if (Object.keys(selected).length < 3) {
        setSelected((prevState) => ({
          ...prevState,
          [id]: counter,
        }));
        setCounter(counter + 1);
      }
    } else {
      const deselectedCounter = selected[id];

      setSelected((prevState) => {
        const newState = { ...prevState };
        delete newState[id];

        for (let key in newState) {
          if (newState[key] > deselectedCounter) {
            newState[key] -= 1;
          }
        }

        return newState;
      });

      setCounter((prevCounter) => prevCounter - 1);
    }
  };

  return (
    <>
      <h5 className="mb-4 text-center">
        <Trans>select_business_types</Trans>
      </h5>
      <div className="business-type-scrollable-area mb-4">
        <div className="business-type-grid">
          {businessTypes.map(({ id, alias }) => {
            const businessType = { id, alias };
            const checked = selectedBusinessTypes.some((o) => o.id === id);
            return (
              <BusinessTypeButton
                key={id}
                alias={alias}
                checked={checked}
                attribute={selected[id]}
                onChange={() => handleCheck(businessType, id)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

Step3.propTypes = {
  currentStep: PropTypes.number.isRequired,
  locationWithBusinessTypes: PropTypes.object.isRequired,
  setField: PropTypes.func.isRequired,
};

export default Step3;
