import React, { useContext } from "react";
import { Nav, Tooltip, OverlayTrigger } from "react-bootstrap";
import ProfileDropdown from "component/navbar/top/ProfileDropdown";
import ZymranIcon from "component/common/ZymranIcon";
import AppContext from "context/Context";
import LanguageSwitcher from "component/common/LanguageSwitcher";

const TopNavRightSideNavItem = () => {
  const {
    config: { isDark, isRTL },
    setConfig,
  } = useContext(AppContext);

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <Nav.Item as={"li"}>
        <Nav.Link
          className="theme-control-toggle zymran-theme-control-toggle"
          onClick={() => setConfig("isDark", !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? "bottom" : "left"}
            overlay={
              <Tooltip id="ThemeColor">
                {isDark ? "Switch to light theme" : "Switch to dark theme"}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <ZymranIcon name={isDark ? "sun" : "moon"} size={16} />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as={"li"}><LanguageSwitcher /></Nav.Item>
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
