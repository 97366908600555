import ZymranAvatar from "component/common/ZymranAvatar";
import { Table } from "react-bootstrap";
import { Trans } from "react-i18next";
import { format, toZonedTime } from "date-fns-tz";
import useLocale from "hooks/useLocale";
import { DateTime } from "luxon";

const ExpenseDetailCredit = ({ expenseDetail }) => {
  const timezone = DateTime.local().zoneName;
  const locale = useLocale();

  return (
    <div className="d-flex justify-content-between align-items-start align-self-stretch">
      <Table borderless size="sm" className="p1 primary-text-color w-auto">
        <tbody>
          <tr>
            <th className="p1-bold secondary-text-color">
              <Trans>document_number</Trans>:
            </th>
            <td>{expenseDetail.code}</td>
          </tr>
          <tr>
            <th className="p1-bold secondary-text-color">
              <Trans>date</Trans>:
            </th>
            <td>
              {format(
                toZonedTime(expenseDetail.date, timezone),
                "dd MMM yyyy HH:mm",
                { locale }
              )}
            </td>
          </tr>
          <tr>
            <th className="p1-bold secondary-text-color">
              <Trans>location</Trans>:
            </th>
            <td>{expenseDetail.location && expenseDetail.location.name}</td>
          </tr>
          <tr>
            <th className="p1-bold secondary-text-color">
              <Trans>expense_reason</Trans>:
            </th>
            <td>
              <span className="me-2">
                <Trans>{expenseDetail.reason}</Trans>
              </span>
            </td>
          </tr>
          <tr className="text-center fw-bold">
            <td colSpan={2}>
              <Trans>taken_from_register</Trans>
            </td>
          </tr>
          {expenseDetail.cashRegisterSums &&
            expenseDetail.cashRegisterSums.map((cashRegisterSum, index) => (
              <tr key={index}>
                <td className="p1-bold secondary-text-color">
                  <Trans>{`cash_register_type_${cashRegisterSum.cashRegisterName}`}</Trans>
                </td>
                <td>{cashRegisterSum.sum}</td>
              </tr>
            ))}
          <tr>
            <td className="p1-bold secondary-text-color text-uppercase">
              <Trans>sum</Trans>
            </td>
            <td className="p1-bold">{expenseDetail.totalSum}</td>
          </tr>
        </tbody>
      </Table>
      <div className="finances-issued-by-block">
        <p className="p1-bold mb-0">
          <Trans>issued_by</Trans>
        </p>
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex">
            <ZymranAvatar
              avatarUrl={expenseDetail.providedByUser.avatarUrl}
              size="lg"
              shape="circle"
              className={"me-2"}
            />
          </div>
          {expenseDetail.providedByUser && (
            <div className="d-flex flex-column align-items-start text-start">
              <p className="p2-bold mb-0 text-blue mb-0 ">
                {expenseDetail.providedByUser.firstName}{" "}
                {expenseDetail.providedByUser.lastName}
              </p>
              <p className="p3 mb-0 secondary-text-color">
                <Trans>{expenseDetail.providedByUser.type}</Trans>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExpenseDetailCredit;
