import React, { useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import Markers from "./Markers";

let DefaultIcon = L.icon({
  iconSize: [32, 37],
  iconAnchor: [16, 37],
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const Map = (props) => {
  // Default position
  const initialPosition = [51.1282837, 71.4306345];

  return (
    <MapContainer
      className={props.cn}
      center={initialPosition}
      zoom={14}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Markers
        setLocationAddress={props.setLocationAddress}
        locationAddress={props.locationAddress}
        requestUserLocation={props.requestUserLocation !== false}
      />
    </MapContainer>
  );
};

export default Map;
