import React from "react";
import { Card } from "react-bootstrap";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const Forbidden = () => {
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">403</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">
          <Trans>forbidden</Trans>
        </p>
        <hr />
        <p>
          <Trans>access_denied</Trans>
        </p>
        <Link reloadDocument className="btn btn-primary btn-sm mt-3" to="/">
          <FontAwesomeIcon icon={faHome} className="me-2" />
          <Trans>take_me_home</Trans>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default Forbidden;
