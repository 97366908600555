import React, { useEffect, useState, useRef } from 'react';
import Image from 'react-bootstrap/Image';
import { ReactComponent as UserAvatarIcon } from "assets/img/icons/user-avatar.svg";
import loading from "assets/img/animated-icons/loading.json";
import Lottie from "lottie-react";

const ZymranAvatar = ({
  avatarUrl,
  size,
  className,
  fullName,
  shape,
  defaultAvatarUrl,
  defaultAvatarIcon: DefaultAvatarIcon
}) => {
  const [imageError, setImageError] = useState(false);
  const [loadAttempts, setLoadAttempts] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const maxAttempts = 1;
  const attemptInterval = 2000;
  const isMounted = useRef(true);

  const shapeClass = `avatar-shape-${shape}`;
  const classNames = ['zymran-avatar', `avatar-${size}`, shapeClass, className].join(' ');

  const sizeMapping = {
    sm: { height: "1.5rem", width: "1.5rem" },
    md: { height: "2rem", width: "2rem" },
    lg: { height: "3rem", width: "3rem" },
    xl: { height: "6rem", width: "6rem" },
    xxl: { height: "12.5rem", width: "12.5rem" },
    "xl-location": { height: "7.5rem", width: "7.5rem" },
  };

  const lottieStyle = sizeMapping[size] || sizeMapping.medium;

  useEffect(() => {
    isMounted.current = true; // Set to true when mounted

    // Cleanup function to update the ref when unmounted
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setImageError(false);
    setShowSpinner(false);
    setLoadAttempts(0);
  }, [avatarUrl]);

  const handleError = () => {
    if (loadAttempts < maxAttempts) {
      setShowSpinner(true);
      setImageError(true);
      setTimeout(() => {
        if (isMounted.current) {
          setLoadAttempts(attempts => attempts + 1);
          setImageError(false);
          setShowSpinner(false);
        }
      }, attemptInterval);
    } else {
      if (isMounted.current) {
        setImageError(true);
        setShowSpinner(false);
      }
    }
  };

  return (
    !imageError && avatarUrl ? (
      <Image
        src={avatarUrl}
        className={classNames}
        alt={`${fullName}'s Avatar`}
        onError={handleError}
        key={loadAttempts}
        onLoad={() => setShowSpinner(false)}
      />
    ) : showSpinner ? (
      <div className={classNames}>
        <Lottie
          animationData={loading}
          loop={true}
          style={lottieStyle}
        />
      </div>
    ) : (
      <div role="img" aria-label={`Default Avatar`}>
        {defaultAvatarUrl ? (
          <img src={defaultAvatarUrl} alt="Default Avatar" className={classNames} />
        ) : (
          <DefaultAvatarIcon className={`${classNames} ${DefaultAvatarIcon === UserAvatarIcon ? 'gray-avatar' : ''}`} />
        )}
      </div>
    )
  );
}

ZymranAvatar.defaultProps = {
  size: 'md',
  shape: 'circle',
  defaultAvatarIcon: UserAvatarIcon,
};

export default ZymranAvatar;
