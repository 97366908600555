import React, { useState, useEffect } from "react";
import { Marker, useMapEvents, useMap } from "react-leaflet";
import i18n from "i18next";
import { getAddressByCoordinates } from "../../service/geolocation/GeolocationService";
import { notifyError } from "../../util/notify";
import { useTranslation } from "react-i18next";

const Markers = (props) => {
  const [blocking, setBlocking] = useState(false);
  const { t } = useTranslation();

  const [selectedPosition, setSelectedPosition] = useState([0, 0]);
  useEffect(() => {
    if (
      props.locationAddress !== undefined &&
      props.locationAddress !== null &&
      props.locationAddress.coordinates
    ) {
      const coordinates = parseCoordinates(props.locationAddress.coordinates);
      setSelectedPosition(coordinates);
    }
    // eslint-disable-next-line
  }, []);

  const parseCoordinates = (coordinates) => {
    const result = coordinates.split(", ");
    return result;
  };

  let map = useMap();

  useEffect(() => {
    if (props.requestUserLocation && (!props.locationAddress || !props.locationAddress.coordinates)) {
      map.locate().on("locationfound", function(e) {
        setSelectedPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.locationAddress && props.locationAddress.coordinates) {
      const coordinates = parseCoordinates(props.locationAddress.coordinates);
      setSelectedPosition(coordinates);
      map.flyTo(coordinates, map.getZoom());
    }
    // eslint-disable-next-line
  }, [props.locationAddress]);

  map = useMapEvents({
    click(e) {
      setBlocking(true);
      getAddressByCoordinates(e.latlng.lat, e.latlng.lng, i18n.language)
        .then((address) => {
          props.setLocationAddress(address.data);
          setBlocking(false);
        })
        .catch((error) => {
          console.error(error);
          setBlocking(false);
          const response = error.response;
          if (response) {
            if (error.response.status === 429) {
              notifyError(t("too_many_requests"));
            }
          } else {
            notifyError(t("something_went_wrong"));
          }
        });

      setSelectedPosition([e.latlng.lat, e.latlng.lng]);
    },
  });

  return selectedPosition ? (
    <Marker
      key={selectedPosition[0]}
      position={selectedPosition}
      interactive={false}
      animate={true}
      draggable={true}
    />
  ) : null;
};

export default Markers;
