import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getAddressByCoordinates = (lat, lng, language) => {
  return http.get(
    `geolocation/`,
    {
      params: {
        latitude: lat,
        longitude: lng,
        language: language,
      },
    },
    { headers: authHeader() }
  );
};

export { getAddressByCoordinates };
