import React, { useContext, useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  getLocations,
  deleteLocation,
  getLocationsCashRegisterBalances,
} from "../../service/location/LocationService";
import { notifySuccess } from "../../util/notify";
import {
  Accordion,
  AccordionContext,
  Button,
  useAccordionButton,
} from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Card } from "react-bootstrap";
import CreateLocation from "./create/CreateLocation";
import EditLocation from "./edit/EditLocation";
import { getHours, getMinutes } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import ZymranIcon from "component/common/ZymranIcon";
import { ReactComponent as EmptyListIcon } from "assets/img/icons/empty-list.svg";
import DefaultLocationIcon from "assets/img/location-avatar.png";
import ZymranAvatar from "component/common/ZymranAvatar";
import i18n from "i18next";

const ListLocations = () => {
  const [locations, setLocations] = useState([]);
  const [cashBalances, setCashBalances] = useState({});
  const [locationToDelete, setLocationToDelete] = useState("");
  const [locationsDataList, setLocationsDataList] = useState([]);
  const [blocking, setBlocking] = useState(false);
  const [show, setShow] = useState(false);
  const [showCreateLocationModal, setShowCreateLocationModal] = useState(false);
  const [showEditLocationModal, setShowEditLocationModal] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState("");

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadLocations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const loadCashBalances = async () => {
      // Create an array of promises to fetch cash register balances for each location
      const balancesPromises = locations.map((location) =>
        loadLocationsCashRegisterBalances(location.id)
      );

      // Wait for all balance fetching promises to resolve
      const balances = await Promise.all(balancesPromises);

      // Map the balances back to an object where the key is the locationId and the value is the balance
      const balancesMap = balances.reduce((acc, balanceInfo) => {
        acc[balanceInfo.locationId] = balanceInfo.balance;
        return acc;
      }, {});
      setCashBalances(balancesMap);
    };
    if (locations.length > 0) {
      loadCashBalances();
    }
    // eslint-disable-next-line
  }, [locations.length]);

  useEffect(() => {
    console.log("LANGUAGE CHANGED: ", i18n.language);
    constructLocationsTable();
    // eslint-disable-next-line
  }, [locations, locations.length, cashBalances, i18n.language]);

  const CustomToggle = ({ locationId, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, () => { });
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <Button
        name={`collapseButton-${locationId}`}
        type="button"
        className="btn-blue-light location-accordion-uncollapse-btn"
        size="sm"
        onClick={decoratedOnClick}
      >
        {isCurrentEventKey ? (
          <>
            <ZymranIcon name="chevron-up" />
            <Trans>collapse</Trans>
          </>
        ) : (
          <>
            <ZymranIcon name="chevron-down" />
            <Trans>uncollapse</Trans>
          </>
        )}
      </Button>
    );
  };

  const getHoursFromDate = (date, timezone) => {
    const convertedDate = toZonedTime(date, timezone);
    const hours = getHours(convertedDate);
    if (hours > 9) {
      return hours.toString();
    } else {
      return "0".concat(hours);
    }
  };

  const getMinutesFromDate = (date, timezone) => {
    const convertedDate = toZonedTime(date, timezone);
    const minutes = getMinutes(convertedDate);
    if (minutes > 9) {
      return minutes.toString();
    } else {
      return "0".concat(minutes);
    }
  };

  const constructLocationsTable = () => {
    if (locations.length === 0) {
      setLocationsDataList(
        <Card>
          <Card.Body>
            <div className="d-flex flex-column justify-content-center align-items-center gap-4 py-3 empty-services">
              <EmptyListIcon />
              <p className="p2 mb-0">
                <Trans>locations_have_not_been_added_yet</Trans>
              </p>
            </div>
          </Card.Body>
        </Card>
      );
    } else {
      const dataList = locations.map((location, index) => {
        return (
          <Card key={index} className="mb-3 location-card">
            <Card.Header>
              <div className="location-card-header-left">
                <div className="image-wrapper">
                  <ZymranAvatar
                    avatarUrl={location.avatarUrl}
                    fullName={location.name}
                    size="xl-location"
                    defaultAvatarUrl={DefaultLocationIcon}
                  />
                </div>
                <div className="d-flex flex-column align-items-start primary-text-color">
                  <h2 className="mb-3">{location.name}</h2>
                  <p className="p1 mb-0">{location.phoneNumber}</p>
                  <p className="p1 mb-0">{location.email}</p>
                </div>
              </div>
              <div className="location-card-header-right">
                <div className="d-flex flex-column align-items-start gap-2 primary-text-color">
                  <p className="p1 mb-0">
                    <Trans>address</Trans>: {location.address.region},{" "}
                    {location.address.city}, {location.address.street}{" "}
                    {location.address.houseNumber}
                  </p>
                  <p className="p1 mb-0">
                    <Trans>employee_count</Trans>: {location.staffList.length}
                  </p>
                  <p className="p1 mb-0">
                    <Trans>sum_by_cash_registers</Trans>:{" "}
                    {cashBalances[location.id]}
                  </p>
                </div>
                {/* <CustomToggle locationId={location.id} eventKey={`location-${index}`}>{location.name}</CustomToggle> */}
                <div className="d-flex align-items-start gap-2 align-self-stretch ms-auto">
                  <button
                    name={`deleteButton-${location.id}`}
                    onClick={() => onClickDeleteLocation(location)}
                    className="icon-button icon-button--sm icon-button--grey-light"
                  >
                    <ZymranIcon name="delete" />
                  </button>
                  <button
                    name={`editButton-${location.id}`}
                    onClick={() => onClickEditLocation(location.id)}
                    className="icon-button icon-button--sm icon-button--grey-light"
                  >
                    <ZymranIcon name="pen" />
                  </button>
                </div>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey={`location-${index}`}>
              <Card.Body className="border-bottom-0">
                <div className="working-hours-section">
                  <h3 className="mb-0">
                    <Trans>working_hours</Trans>
                  </h3>
                  <div className="working-hours-grid">
                    {location.openHours.map((openHour, index) => {
                      return (
                        <div key={index} className="working-hours-card">
                          <p className="p2-bold mb-0">
                            <Trans>{openHour.dayOfWeek}</Trans>
                          </p>
                          {openHour.dayOff ? (
                            <p className="p1">
                              <Trans>day_off</Trans>
                            </p>
                          ) : (
                            <p className="p1">
                              {getHoursFromDate(
                                openHour.startTime,
                                location.address.timezone
                              )}
                              :
                              {getMinutesFromDate(
                                openHour.startTime,
                                location.address.timezone
                              )}
                              {`-`}
                              {getHoursFromDate(
                                openHour.endTime,
                                location.address.timezone
                              )}
                              :
                              {getMinutesFromDate(
                                openHour.endTime,
                                location.address.timezone
                              )}
                            </p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="employees-section mb-4">
                  <h3 className="mb-0">
                    <Trans>staff</Trans>
                  </h3>
                  <div className="location-employee-cards">
                    {location.staffList.length === 0 && (
                      <span className="p2 secondary-text-color">
                        <Trans>add_staff_in_location_edit_mode</Trans>
                      </span>
                    )}
                    {location.staffList.map((staff, slIndex) => {
                      return (
                        <div className="employee-cell" key={slIndex}>
                          <ZymranAvatar
                            avatarUrl={staff.avatarUrl}
                            fullName={`${staff.firstName} ${staff.lastName}`}
                            size="lg"
                            shape="circle"
                          />
                          <div className="d-flex flex-column align-items-center text-center">
                            <p className="p2 mb-0 primary-text-color">
                              {staff.firstName} {staff.lastName}
                            </p>
                            <p className="p3 mb-0 secondary-text-color">
                              {staff.position}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
            <CustomToggle
              locationId={location.id}
              eventKey={`location-${index}`}
            >
              {location.name}
            </CustomToggle>
          </Card>
        );
      });
      setLocationsDataList(dataList);
    }
  };

  const loadLocationsCashRegisterBalances = async (locationId) => {
    try {
      const response = await getLocationsCashRegisterBalances(locationId);
      // Return balance and id so we can map it back to the location later
      return {
        locationId: locationId,
        balance: response.data.reduce((sum, item) => sum + item.balance, 0),
      };
    } catch (error) {
      console.error(
        "Failed to fetch cash register balances for location:",
        locationId,
        error
      );
      return { locationId: locationId, balance: 0 };
    }
  };

  const loadLocations = async () => {
    try {
      setIsLoading(true);
      const response = await getLocations();
      setLocations(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickDeleteLocation = (location) => {
    setLocationToDelete(location);
    setShow(true);
  };

  const onClickEditLocation = (locationId) => {
    setSelectedLocationId(locationId);
    setShowEditLocationModal(true);
  };

  const handleDelete = async () => {
    try {
      setBlocking(true);
      await deleteLocation(locationToDelete.id);
      deleteLocalLocation(locationToDelete.id);
      notifySuccess(t("location_deleted"));
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
      setShow(false);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleCreateLocationModalClose = () => {
    setShowCreateLocationModal(false);
  };

  const handleEditLocationModalClose = () => {
    setShowEditLocationModal(false);
  };

  const onCreateLocationClick = () => {
    setShowCreateLocationModal(true);
  };

  const deleteLocalLocation = (locationId) => {
    let locs = locations.filter((loc, index) => {
      return loc.id !== locationId;
    });
    setLocations(locs);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>
          <Trans>locations</Trans>
        </h1>
        <Button
          name="addLocationButton"
          className="btn btn-blue"
          size="sm"
          onClick={onCreateLocationClick}
        >
          <ZymranIcon name="plus" />
          <Trans>add_location</Trans>
        </Button>
      </div>
      <Accordion defaultActiveKey="0">
        {isLoading ? (
          <div>
            <Trans>loading</Trans>
          </div>
        ) : (
          locationsDataList
        )}
      </Accordion>
      {/* Remove location modal */}
      <Modal
        show={show}
        onHide={handleClose}
        className="confirmation-modal"
        centered
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center align-self-stretch text-center">
            <h2>
              <Trans>delete_location_question</Trans> {locationToDelete.name}?
            </h2>
            <p className="mb-0 p1 secondary-text-color">
              <Trans>all_location_data_will_be_deleted</Trans>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            name="confirmDeleteButton"
            variant="orange"
            size="sm"
            id="delete-location-confirm"
            className="flex-grow-1"
            onClick={handleDelete}
          >
            <Trans>delete_location</Trans>
          </Button>
          <Button
            name="closeDeleteWindowButton"
            variant="blue"
            size="sm"
            id="delete-location-close"
            className="flex-grow-1"
            onClick={handleClose}
          >
            <Trans>close</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Create location modal */}
      <Modal
        show={showCreateLocationModal}
        onHide={handleCreateLocationModalClose}
        fullscreen={true}
      >
        <Modal.Header closeButton closeVariant="white" className="bg-primary">
          <Modal.Title className="text-white light">
            <Trans>create_location</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <CreateLocation
            setLocations={setLocations}
            setShow={setShowCreateLocationModal}
            handleClose={handleCreateLocationModalClose}
          />
        </Modal.Body>
      </Modal>
      {/* Edit location modal */}
      <Modal
        show={showEditLocationModal}
        onHide={handleEditLocationModalClose}
        fullscreen={true}
      >
        <Modal.Header closeButton closeVariant="white" className="bg-primary">
          <Modal.Title className="text-white light">
            <Trans>edit_location</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditLocation
            locationId={selectedLocationId}
            handleClose={handleEditLocationModalClose}
            setLocations={setLocations}
          ></EditLocation>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ListLocations;
