import React from "react";
import PropTypes from "prop-types";
import LocationAddressForm from "../../location/forms/LocationAddressForm";
import { Trans } from "react-i18next";
import { Form } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import { REGISTRATION_STEPS } from "util/constants";

const Step4 = ({
  currentStep,
  errors,
  laErrors,
  locationAddress,
  setLocationAddressField,
  setLocationAddress,
  location,
  setField,
  t,
}) => {
  if (currentStep !== REGISTRATION_STEPS.STEP4) {
    return null;
  }

  return (
    <div className="registration-scrollable-area">
      <h5 className="mb-4 text-center">
        <Trans>creation_of_location</Trans>
      </h5>
      <div className="mb-3">
        <EnhancedFormControl
          id="name"
          name="name"
          type="text"
          value={location.name}
          onChange={(e) =>
            setField("locationWithBusinessTypes", "name", e.target.value)
          }
          isInvalid={!!errors.email}
          placeholder={t("name")}
          label={t("name")}
          errors={errors.name}
          groupClassName="mb-3"
          required={true}
        />
        <EnhancedFormControl
          id="email"
          name="email"
          type="text"
          value={location.locationEmail}
          onChange={(e) =>
            setField(
              "locationWithBusinessTypes",
              "locationEmail",
              e.target.value
            )
          }
          isInvalid={!!errors.email}
          placeholder={t("email")}
          label={t("email")}
          errors={errors.locationEmail}
          groupClassName="mb-3"
          required={true}
        />
        <Form.Group className="position-relative">
          <Form.Label className="phone-input-label">
            <Trans>phone_number</Trans>
          </Form.Label>
          <PhoneInput
            defaultCountry="KZ"
            placeholder="+7 777 777 7777*"
            value={location.phoneNumber}
            onChange={(value) =>
              setField("locationWithBusinessTypes", "phoneNumber", value)
            }
            className={!!errors.phoneNumber ? "is-invalid" : ""}
          />
          {!!errors.phoneNumber && (
            <div className="invalid-feedback">{errors.phoneNumber}</div>
          )}
        </Form.Group>
      </div>
      <LocationAddressForm
        locationAddress={locationAddress}
        setLocationAddress={setLocationAddress}
        setField={setLocationAddressField}
        errors={laErrors}
      />
      <Form.Check
        type="checkbox"
        id="skipCreateLocation"
        label={t("skip_create_location")}
        checked={location.skip}
        onChange={(e) =>
          setField(
            "locationWithBusinessTypes",
            "skipCreateLocation",
            e.target.checked
          )
        }
        className="mb-3"
      />
    </div>
  );
};

Step4.propTypes = {
  currentStep: PropTypes.number.isRequired,
  locationAddress: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  laErrors: PropTypes.object.isRequired,
  setLocationAddressField: PropTypes.func.isRequired,
  setLocationAddress: PropTypes.func.isRequired,
};

export default Step4;
