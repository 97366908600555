import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getCustomers = () => {
  return http.get(`customer`, {
    headers: authHeader(),
  });
};

const getCustomersWithPagination = (pageNumber, pageSize) => {
  return http.get(`customer`, {
    headers: authHeader(),
    params: {
      page_number: pageNumber,
      page_size: pageSize,
    },
  });
};

const updateCustomer = (payload) => {
  return http.put(`customer`, payload, { headers: authHeader() });
};

const getCustomer = (id) => {
  return http.get(`customer/${id}`, { headers: authHeader() });
};

const createCustomer = (payload) => {
  return http.post(`customer`, payload, { headers: authHeader() });
};

const createCustomerQuick = (payload) => {
  return http.post(`customer/quick`, payload, { headers: authHeader() });
};

const deleteCustomer = (id) => {
  return http.delete(`customer/${id}`, { headers: authHeader() });
};

const searchCustomer = (payload) => {
  return http.post(`customer/search`, payload, { headers: authHeader() });
};

const getCountOfFinishedAppointments = (customerId) => {
  return http.get(`customer/finished-appointments-count/${customerId}`, {
    headers: authHeader(),
  });
};

const getSumOfFinishedAppointments = (customerId) => {
  return http.get(`customer/finished-appointments-sum/${customerId}`, {
    headers: authHeader(),
  });
};

const getCountOfCanceledAppointments = (customerId) => {
  return http.get(`customer/canceled-appointments-count/${customerId}`, {
    headers: authHeader(),
  });
};

export {
  getCustomers,
  updateCustomer,
  getCustomer,
  createCustomer,
  createCustomerQuick,
  deleteCustomer,
  getCountOfFinishedAppointments,
  getSumOfFinishedAppointments,
  getCountOfCanceledAppointments,
  getCustomersWithPagination,
  searchCustomer,
};
