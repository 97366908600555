import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getEncashmentList = () => {
  return http.get(`finance/encashment/last`, { headers: authHeader() });
};

const encashLocationStep1 = (payload) => {
  return http.post(`finance/encashment/location/step1`, payload, {
    headers: authHeader(),
  });
};

const encashLocationStep2 = (payload) => {
  return http.post(`finance/encashment/location/step2`, payload, {
    headers: authHeader(),
  });
};

const encashCashRegisterStep1 = (payload) => {
  return http.post(`finance/encashment/cash-register/step1`, payload, {
    headers: authHeader(),
  });
};

const encashCashRegisterStep2 = (payload) => {
  return http.post(`finance/encashment/cash-register/step2`, payload, {
    headers: authHeader(),
  });
};

export {
  getEncashmentList,
  encashLocationStep1,
  encashLocationStep2,
  encashCashRegisterStep1,
  encashCashRegisterStep2,
};
