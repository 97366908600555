import React from "react";
import "./block-ui.css";
import { Trans } from "react-i18next";

const BlockUI = (props) => {
  if (!props.blocking) {
    return "";
  } else {
    return (
      <div className="block-ui-container">
        <div className="block-ui-overlay" />
        <div className="block-ui-message-container">
          <div className="block-ui-message">
            <h4>{props.title}</h4>
            <div className="spinner-border text-jazu-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

BlockUI.defaultProps = {
  blocking: false,
  title: <Trans>loading</Trans>,
};

export default BlockUI;
