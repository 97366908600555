import { Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import { RESTORE_PASSWORD_STEPS } from "util/constants";

const Step1 = ({
  currentStep,
  userInformation,
  errors,
  setField,
  restorePasswordStep1,
}) => {
  const { t } = useTranslation();

  if (currentStep !== RESTORE_PASSWORD_STEPS.STEP1) {
    return null;
  }

  return (
    <>
      <EnhancedFormControl
        id="emailOrPhone"
        name="emailOrPhone"
        type="text"
        value={userInformation.login}
        onChange={(e) => setField("userInformation", "login", e.target.value)}
        isInvalid={!!errors.login}
        placeholder={t("email_or_phone_number")}
        label={t("email_or_phone_number")}
        errors={errors.login}
        groupClassName="mb-3"
      />
      <Button
        name="continueButton"
        color="primary float-right"
        className="w-100 mt-3"
        onClick={restorePasswordStep1}
      >
        <Trans>continue</Trans>
      </Button>
    </>
  );
};

export default Step1;
