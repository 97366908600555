import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { roleRoutesMap } from "./role-routes";
import Cookies from "js-cookie";
import { ROLES } from "util/constants";

const ProtectedRoute = () => {

  const checkAccess = () => {
    const currentUser = Cookies.get("zymran_user") ? JSON.parse(Cookies.get("zymran_user")) : null;
    if (!currentUser) {
      return false;
    }

    const role = currentUser.roles[0];

    const routes = roleRoutesMap[role.name];
    if ((routes === null || routes === undefined) || routes.length === 0) {
      console.log("ProtectedRoute. no role found");
      return false;
    }
    return true;
  };

  return checkAccess() ? <Outlet /> : <Navigate to={{ pathname: "/403" }} />;
};

export default ProtectedRoute;
