import React from "react";
import PropTypes from "prop-types";
import createMarkup from "helpers/createMarkup";
import Section from "component/common/Section";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { version } from "config";
import classNames from 'classnames';
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

const FooterTitle = ({ children }) => (
  <h5 className="text-uppercase text-white opacity-85 mb-3">{children}</h5>
);

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const FooterList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map(({ title, to }, index) => (
      <li className="mb-1" key={index}>
        <Link className="text-600" to={to}>
          {title}
        </Link>
      </li>
    ))}
  </ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };

const FooterBlogList = ({ list }) => (
  <ul className="list-unstyled">
    <li>
      <h5 className="fs-0 mb-3">
        <Link className="text-600" to="#!">
          Политика конфиденциальности
        </Link>
      </h5>
      {/* <p className="text-600 opacity-50">
        test &bull; test
      </p> */}
    </li>
    <li>
      <h5 className="fs-0 mb-3">
        <Link className="text-600" to="#!">
          Пользовательское соглашение
        </Link>
      </h5>
    </li>
    <li>
      <h5 className="fs-0 mb-0">
        <Link className="text-600" to="#!">
          Условия использования
        </Link>
      </h5>
    </li>
  </ul>
);

FooterBlogList.propTypes = { list: PropTypes.array.isRequired };

const FooterStandard = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Section bg="dark" className="pt-8 pb-4 light">
        <div
          className="position-absolute btn-back-to-top cursor-pointer bg-dark"
          onClick={scrollToTop}
        >
          <FontAwesomeIcon
            icon="chevron-up"
            transform="rotate-45"
            className="text-600"
          />
        </div>
        <Row>
          <Col lg={4}>
            <div className="d-flex flex-row gap-3 justify-content-center mb-3">
              <div className="d-flex flex-column text-end">
                <span className={classNames('font-sans-serif', 'text-white', 'lh-1', 'h2', 'fw-bold', 'mb-0')}>zymran</span>
                <span className="text-white fw-normal lh-1 fs--2">для бизнеса</span>
              </div>
              <h1 className="text-white mb-0">2024</h1>
            </div>
            <div className="d-flex flex-row justify-content-center gap-2 mb-3">
              <FontAwesomeIcon icon="at" className="me-2 text-white h1 mb-0" />
              <FontAwesomeIcon icon={faInstagram} className="me-2 text-white h1 mb-0" />
              <FontAwesomeIcon icon={faFacebook} className="me-2 text-white h1 mb-0" />
            </div>
            {/* <IconGroup className="mt-4" icons={bgWhiteIcons} /> */}
          </Col>
          <Col className="ps-lg-6 ps-xl-8">
            <Row className="mt-5 mt-lg-0">
              <Col xs={6} md={3}>
              </Col>
              <Col xs={6} md={3}>
              </Col>
              <Col className="mt-5 mt-md-0">
                <FooterTitle>Юридическая информация</FooterTitle>
                <FooterBlogList />
              </Col>
            </Row>
          </Col>
        </Row>
      </Section>

      <section className=" bg-dark py-0 text-center fs--1 light">
        <hr className="my-0 border-600 opacity-25" />
        <div className="container py-3">
          <Row className="justify-content-between">
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">
                &copy; Zymran{" "}
                <span className="d-none d-sm-inline-block">| </span>
                <br className="d-sm-none" /> {new Date().getFullYear()}
              </p>
            </Col>
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">v.{version}</p>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default FooterStandard;
