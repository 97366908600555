import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { createStaff } from "../../service/staff/StaffService";
import { uploadAvatar } from "service/profile/UserProfileService";
import { getLocations } from "../../service/location/LocationService";
import Form from "react-bootstrap/Form";
import { Button, Card, Collapse } from "react-bootstrap";
import BlockUI from "../../util/block-ui/block-ui";
import { notifySuccess } from "../../util/notify";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import FalconDropzone from "component/common/FalconDropzone";
import Flex from "component/common/Flex";
import { parsePhoneNumber } from "libphonenumber-js/core";
import metadata from "libphonenumber-js/metadata.min.json";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import ZymranIcon from "component/common/ZymranIcon";
import DayOfWeekSchedule from "component/staff/DayOfWeekSchedule";
import { fromZonedTime } from "date-fns-tz";
import moment from "moment";
import { ReactComponent as UserAvatarIcon } from "assets/img/icons/user-avatar.svg";
import classNames from "classnames";
import EnhancedFormControlWithToggle from "component/common/EnhancedFormControlWithToggle";
import SalaryForCategoriesSection from "./SalaryForCategoriesSection";
import SalaryForServicesSection from "./SalaryForServicesSection";
import Lottie from "lottie-react";
import loadingAnimation from "assets/img/animated-icons/loading.json";
import { WEEKDAYS } from "util/constants";
import { isNumericInput } from "util/validation";
import avatarFallback from "assets/img/avatar-fallback.png";

const CreateStaff = ({ handleClose, onCreate }) => {
  const [staffData, setStaffData] = useState({
    basicInformation: {
      id: "",
      firstName: "",
      lastName: "",
      middleName: "",
      commission: null,
      salary: null,
      position: "",
      phoneNumber: "",
      email: "",
      isAdmin: false,
      locations: [],
    },
    staffSchedule: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    salary: {
      salary: 0,
      isPercentage: false,
      period: "month",
      dueDate: 30,
    },
    salaryForCategories: [],
    salaryForServices: [],
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [avatar, setAvatar] = useState([]);

  const [errors, setErrors] = useState({
    basicInformation: {},
    schedule: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    salary: {},
    salaryForCategories: [],
    salaryForServices: [],
  });
  const [blocking, setBlocking] = useState(false);
  const [locations, setLocations] = useState([]);
  const [locationAddresses, setLocationAddresses] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState({});

  const isSelectedLocationsInitialized = useRef(false);
  const allLocationsCheckboxRef = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [openWorkingHours, setOpenWorkingHours] = useState(false);
  const [selectedLocationOptions, setSelectedLocationOptions] = useState([]);
  const [openSalary, setOpenSalary] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(true);
  const salaryToggleOptions = [
    { name: "tenge", value: "tenge" },
    { name: "percent", value: "percentage" },
  ];

  useEffect(() => {
    loadLocations();
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      initSelectedLocations();
      isSelectedLocationsInitialized.current = true;
    }
    // eslint-disable-next-line
  }, [locations]);

  useEffect(() => {
    if (locations.length > 0) {
      prepareLocationsDivs();
    }
    // eslint-disable-next-line
  }, [
    isSelectedLocationsInitialized,
    selectedLocations,
    errors.selectedLocations,
  ]);

  useEffect(() => {
    if (allLocationsCheckboxRef.current) {
      if (checkAllLocationsSelected()) {
        allLocationsCheckboxRef.current.indeterminate = false;
        allLocationsCheckboxRef.current.checked = true;
      } else if (checkAtLeastOneLocationSelected()) {
        allLocationsCheckboxRef.current.indeterminate = true;
        allLocationsCheckboxRef.current.checked = false;
      } else {
        allLocationsCheckboxRef.current.indeterminate = false;
        allLocationsCheckboxRef.current.checked = false;
      }
    }
    // eslint-disable-next-line
  }, [selectedLocations]);

  useEffect(() => {
    if (locations.length > 0) {
      const selectedLocationsId = Object.entries(selectedLocations)
        .filter(([locId, isChecked]) => isChecked)
        .map(([locationId]) => locationId);

      const options = locations
        .filter((loc) => selectedLocationsId.includes(loc.id))
        .map((loc) => ({
          value: loc.id,
          label: loc.name,
        }));

      setSelectedLocationOptions(options);
    }
  }, [selectedLocations, locations]);

  const checkAllLocationsSelected = () => {
    for (let i = 0; i < locations.length; i++) {
      if (!selectedLocations[locations[i].id]) {
        return false;
      }
    }
    return true;
  };

  const checkAtLeastOneLocationSelected = () => {
    for (let i = 0; i < locations.length; i++) {
      if (selectedLocations[locations[i].id]) {
        return true;
      }
    }
    return false;
  };

  const loadLocations = async () => {
    try {
      const response = await getLocations();
      const locs = [];
      response.data.forEach((loc) => locs.push(loc));
      setLocations(locs);
    } catch (error) {
    } finally {
      setLoadingLocations(false);
    }
  };

  const initSelectedLocations = () => {
    let selectedLocs = {};
    locations.forEach((item) => {
      selectedLocs[item.id] = false;
    });
    setSelectedLocations(selectedLocs);
  };

  const prepareLocationsDivs = () => {
    let locationAddresses = [];

    locationAddresses = locations.map((loc, index) => {
      let la = loc.address;

      return (
        <Form.Check
          key={loc.id}
          label={la.city + " " + la.street + " " + la.houseNumber}
          type="checkbox"
          id={`la-checkbox-${loc.id}`}
          name={`la-checkbox-${loc.id}`}
          value={loc.id}
          checked={selectedLocations[loc.id] || false}
          onChange={onChangeLocation}
          isInvalid={!!errors.selectedLocations}
        />
      );
    });
    setLocationAddresses(locationAddresses);
  };

  const onChangeAllLocations = (e) => {
    const checked = e.target.checked;
    const selectedLocs = locations.reduce((acc, item) => {
      acc[item.id] = checked;
      return acc;
    }, {});
    if (!!errors.selectedLocations) {
      setErrors({
        ...errors,
        selectedLocations: null,
      });
    }
    setSelectedLocations(selectedLocs);
  };

  const onChangeLocation = (e) => {
    const locId = e.target.value;
    setSelectedLocations((prevSelectedLocations) => ({
      ...prevSelectedLocations,
      [locId]: e.target.checked,
    }));

    if (!!errors.selectedLocations) {
      setErrors({
        ...errors,
        selectedLocations: null,
      });
    }
  };

  const setField = (parentField, field, value) => {
    setStaffData({
      ...staffData,
      [parentField]: {
        ...staffData[parentField],
        [field]: value,
      },
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[parentField][field])
      setErrors({
        ...errors,
        [parentField]: {
          ...errors[parentField],
          [field]: null,
        },
      });
  };

  const setSalaryValue = (value) => {
    if (isNumericInput(value) || value === "") {
      setStaffData({
        ...staffData,
        salary: {
          ...staffData.salary,
          salary: value,
        },
      });

      if (!!errors.salary.salary) {
        setErrors({
          ...errors,
          salary: {
            ...errors.salary,
            salary: null,
          },
        });
      }
    }
  };

  const handlePeriodChange = (selectedPeriod) => {
    let updatedSalary = {
      ...staffData.salary,
      period: selectedPeriod,
    };

    if (selectedPeriod === "day") {
      updatedSalary.dueDate = null;
    }

    setStaffData({
      ...staffData,
      salary: updatedSalary,
    });
  };

  const handleSalaryToggleChange = (selectedOption) => {
    setStaffData({
      ...staffData,
      salary: {
        ...staffData.salary,
        isPercentage: selectedOption === "percentage",
      },
    });
  };

  const handleFileChange = (uploadedFiles) => {
    setAvatar(uploadedFiles);
  };

  const sendFileToBackend = async (base64EncodedImage, mimeType) => {
    try {
      const response = await uploadAvatar(base64EncodedImage, mimeType);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const findFormErrors = () => {
    const { firstName, lastName, middleName, position, email } =
      staffData.basicInformation;
    const newErrors = {
      basicInformation: {},
      schedule: {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      },
      salary: {},
      salaryForCategories: [],
      salaryForServices: [],
    };
    const namePattern = /^[\p{L}]+$/u;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!firstName || firstName === "")
      newErrors.basicInformation.firstName = t("field_required");
    else if (firstName.length > 64)
      newErrors.basicInformation.firstname = t("too_long");
    else if (!namePattern.test(firstName))
      newErrors.basicInformation.firstName = t(
        "number_or_special_symbols_not_allowed"
      );

    if (!lastName || lastName === "")
      newErrors.basicInformation.lastName = t("field_required");
    else if (lastName.length > 64)
      newErrors.basicInformation.lastName = t("too_long");
    else if (!namePattern.test(lastName))
      newErrors.basicInformation.lastName = t(
        "number_or_special_symbols_not_allowed"
      );

    if (middleName.length > 64)
      newErrors.basicInformation.middleName = t("too_long");
    else if ((middleName || middleName !== "") && !namePattern.test(middleName))
      newErrors.basicInformation.middleName = t(
        "number_or_special_symbols_not_allowed"
      );

    if (!position || position === "")
      newErrors.basicInformation.position = t("field_required");
    else if (position.length > 32)
      newErrors.basicInformation.position = t("too_long");

    if (!phoneNumber || phoneNumber === "")
      newErrors.basicInformation.phoneNumber = t("field_required");
    else if (phoneNumber.length > 12)
      newErrors.basicInformation.phoneNumber = t("too_long");
    else {
      try {
        const phoneNumberObject = parsePhoneNumber(phoneNumber, metadata);
        if (!phoneNumberObject.isValid())
          newErrors.basicInformation.phoneNumber = t("invalid_phone_number");
      } catch (error) {
        newErrors.basicInformation.phoneNumber = t("invalid_phone_number");
      }
    }
    if (!email || email === "")
      newErrors.basicInformation.email = t("field_required");
    else if (email.length > 128)
      newErrors.basicInformation.email = t("too_long");
    else if (!emailPattern.test(email))
      newErrors.basicInformation.email = t("email_wrong_format");

    let atLeastOneLocationSelected = false;
    for (let loc in selectedLocations) {
      if (selectedLocations[loc]) {
        atLeastOneLocationSelected = true;
      }
    }

    if (!atLeastOneLocationSelected)
      newErrors.basicInformation.selectedLocations = t(
        "checkbox_selection_required"
      );

    for (const key of Object.keys(WEEKDAYS)) {
      const dayOfWeek = WEEKDAYS[key];
      const scheduleData = staffData.staffSchedule[dayOfWeek];

      if (scheduleData) {
        for (const [index, schedule] of scheduleData.entries()) {
          const scheduleErrors = {};

          if (!schedule.locationId) {
            scheduleErrors.location = t("field_required");
          } else if (!selectedLocations[schedule.locationId]) {
            scheduleErrors.location = t("location_not_selected");
          }
          if (
            !schedule.primaryShiftStart &&
            !schedule.primaryShiftEnd &&
            !schedule.dayOff
          ) {
            scheduleErrors.primaryShift = t("primary_shift_required");
          } else if (
            (!schedule.primaryShiftStart || !schedule.primaryShiftEnd) &&
            !schedule.dayOff
          ) {
            scheduleErrors.primaryShift = t("shift_both_values_required");
          } else if (
            moment(schedule.primaryShiftStart).isSameOrAfter(
              schedule.primaryShiftEnd
            )
          ) {
            scheduleErrors.primaryShift = t("primary_shift_end_after_start");
          }

          if (
            (schedule.secondaryShiftStart && !schedule.secondaryShiftEnd) ||
            (!schedule.secondaryShiftStart && schedule.secondaryShiftEnd)
          ) {
            scheduleErrors.secondaryShift = t("shift_both_values_required");
          }

          if (
            schedule.secondaryShiftStart &&
            moment(schedule.primaryShiftEnd).isSameOrAfter(
              schedule.secondaryShiftStart
            )
          ) {
            scheduleErrors.primaryShift = t(
              "primary_and_secondary_shifts_overlap"
            );
          }

          if (Object.keys(scheduleErrors).length > 0) {
            newErrors.schedule[dayOfWeek][index] = scheduleErrors;
          }
        }
      }
    }
    if (
      staffData.salary.salary === null ||
      staffData.salary.salary === undefined ||
      staffData.salary.salary === "" ||
      isNaN(staffData.salary.salary) ||
      staffData.salary.salary < 0
    ) {
      newErrors.salary.salary = t("invalid_salary_value");
    }

    staffData.salaryForCategories.forEach((category, index) => {
      const categoryErrors = {};

      if (
        category.serviceCategoryId === null ||
        category.serviceCategoryId === undefined
      ) {
        categoryErrors.serviceCategory = t("field_required");
      }

      if (isNaN(parseFloat(category.salary)) || category.salary < 0) {
        categoryErrors.salary = t("invalid_salary_value");
      }

      if (
        category.isPercentage === null ||
        category.isPercentage === undefined
      ) {
        categoryErrors.isPercentage = t("invalid_percentage_value");
      }

      newErrors.salaryForCategories[index] = categoryErrors;
    });

    staffData.salaryForServices.forEach((service, index) => {
      const serviceErrors = {};

      if (service.serviceId === null || service.serviceId === undefined) {
        serviceErrors.service = t("field_required");
      }

      if (
        service.salary === null ||
        service.salary === undefined ||
        service.salary < 0
      ) {
        serviceErrors.salary = t("invalid_salary_value");
      }

      if (service.isPercentage === null || service.isPercentage === undefined) {
        serviceErrors.isPercentage = t("invalid_percentage_value");
      }

      newErrors.salaryForServices[index] = serviceErrors;
    });

    return newErrors;
  };

  const convertDayOfWeekToUtc = (daySchedule) => {
    return daySchedule.map((scheduleEntry) => {
      const convertedEntry = { ...scheduleEntry };

      const location = locations.find(
        (location) => location.id === scheduleEntry.locationId
      );
      const timezone = location.address.timezone;

      if (convertedEntry.primaryShiftStart !== null) {
        convertedEntry.primaryShiftStart = fromZonedTime(
          convertedEntry.primaryShiftStart,
          timezone
        );
      }
      if (convertedEntry.primaryShiftEnd !== null) {
        convertedEntry.primaryShiftEnd = fromZonedTime(
          convertedEntry.primaryShiftEnd,
          timezone
        );
      }
      if (convertedEntry.secondaryShiftStart !== null) {
        convertedEntry.secondaryShiftStart = fromZonedTime(
          convertedEntry.secondaryShiftStart,
          timezone
        );
      }
      if (convertedEntry.secondaryShiftEnd !== null) {
        convertedEntry.secondaryShiftEnd = fromZonedTime(
          convertedEntry.secondaryShiftEnd,
          timezone
        );
      }
      return convertedEntry;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = staffData.basicInformation;

    if (avatar.length > 0) {
      const fileData = avatar[0];
      try {
        const avatarResponse = await sendFileToBackend(
          fileData.base64,
          fileData.type
        );
        payload.avatarFilename = avatarResponse.data.filename;
        payload.avatarUrl = avatarResponse.data.url;
      } catch (error) {}
    }

    const newErrors = findFormErrors();
    if (
      Object.keys(newErrors.basicInformation).length > 0 ||
      Object.values(newErrors.schedule).some(
        (dayErrors) => dayErrors.length > 0
      ) ||
      Object.keys(newErrors.salary).length > 0 ||
      Object.values(newErrors.salaryForCategories).some(
        (categoryErrors) => Object.keys(categoryErrors).length > 0
      ) ||
      Object.values(newErrors.salaryForServices).some(
        (serviceErrors) => Object.keys(serviceErrors).length > 0
      )
    ) {
      // We got errors!
      console.log("WE GOT ERRORS:", newErrors);
      setErrors(newErrors);
    } else {
      try {
        setBlocking(true);
        const locs = [];

        for (var key in selectedLocations) {
          if (selectedLocations[key]) {
            locs.push(key);
          }
        }
        payload.locations = locs;
        payload.phoneNumber = phoneNumber;

        const schedule = {};
        for (const dayOfWeek in staffData.staffSchedule) {
          schedule[dayOfWeek] = convertDayOfWeekToUtc(
            staffData.staffSchedule[dayOfWeek]
          );
        }

        payload.staffSchedule = schedule;
        payload.salary = staffData.salary;
        payload.salaryForCategories = staffData.salaryForCategories;
        payload.salaryForServices = staffData.salaryForServices;
        const response = await createStaff(payload);
        navigate("/settings/staff");
        notifySuccess(t("staff_created"));
        onCreate(response.data);
        handleClose();
      } catch (error) {
        console.error(error);
      } finally {
        setBlocking(false);
      }
    }
  };

  const getDataByDayOfWeek = (dayOfWeek) => {
    return staffData.staffSchedule[dayOfWeek] || [];
  };

  const setDataByDayOfWeek = (dayOfWeek, updatedData) => {
    setStaffData((prevStaffData) => ({
      ...prevStaffData,
      staffSchedule: {
        ...prevStaffData.staffSchedule,
        [dayOfWeek]: updatedData,
      },
    }));
  };

  return (
    <div className="container staff-edit-and-create-container">
      <Card className="staff-edit-and-create-card">
        <Card.Header>
          <h3 className="mb-0">
            <Trans>basic_information</Trans>
          </h3>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-items-start gap-4 align-self-stretch">
            <FalconDropzone
              files={avatar}
              className="staff-avatar-dropzone"
              onChange={handleFileChange}
              multiple={false}
              accept="image/*"
              preview={true}
              isCircular={true}
              fallbackImage={avatarFallback}
              placeholder={
                <>
                  <Flex justifyContent="center" className="position-relative">
                    {/* <img src={cloudUpload} alt="" width={25} className="me-2" /> */}
                    <UserAvatarIcon className="profile-icon" />
                    <div className="staff-avatar-plus-button">
                      <ZymranIcon name="plus" />
                    </div>
                  </Flex>
                </>
              }
            />
            <div className="d-flex flex-column align-items-start gap-3 flex-grow-1">
              <div className="d-flex align-items-start gap-3 align-self-stretch">
                <EnhancedFormControl
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={staffData.basicInformation.firstName}
                  onChange={(e) =>
                    setField("basicInformation", "firstName", e.target.value)
                  }
                  isInvalid={!!errors.basicInformation.firstName}
                  placeholder={t("firstname")}
                  label={t("firstname")}
                  errors={errors.basicInformation.firstName}
                  groupClassName="w-100"
                />
                <EnhancedFormControl
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={staffData.basicInformation.lastName}
                  onChange={(e) =>
                    setField("basicInformation", "lastName", e.target.value)
                  }
                  isInvalid={!!errors.basicInformation.lastName}
                  placeholder={t("lastname")}
                  label={t("lastname")}
                  errors={errors.basicInformation.lastName}
                  groupClassName="w-100"
                />
                <EnhancedFormControl
                  id="middleName"
                  name="middleName"
                  type="text"
                  value={staffData.basicInformation.middleName}
                  onChange={(e) =>
                    setField("basicInformation", "middleName", e.target.value)
                  }
                  isInvalid={!!errors.basicInformation.middleName}
                  placeholder={t("middlename")}
                  label={t("middlename")}
                  errors={errors.basicInformation.middleName}
                  groupClassName="w-100"
                />
              </div>
              <div className="d-flex align-items-start gap-3 align-self-stretch">
                <EnhancedFormControl
                  id="position"
                  name="position"
                  type="text"
                  value={staffData.basicInformation.position}
                  onChange={(e) =>
                    setField("basicInformation", "position", e.target.value)
                  }
                  isInvalid={!!errors.basicInformation.position}
                  placeholder={t("position")}
                  label={t("position")}
                  errors={errors.basicInformation.position}
                  groupClassName="w-100"
                />
                <Form.Group className="w-100" controlId="phone-number">
                  <Form.Label
                    className={
                      !!errors.basicInformation.phoneNumber
                        ? "is-invalid phone-input-label"
                        : "phone-input-label"
                    }
                  >
                    <Trans>phone_number</Trans>:
                  </Form.Label>
                  <PhoneInput
                    id="phoneNumber"
                    defaultCountry="KZ"
                    placeholder="+7 777 777 7777"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    className={
                      !!errors.basicInformation.phoneNumber ? "is-invalid" : ""
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.basicInformation.phoneNumber}
                  </Form.Control.Feedback>
                </Form.Group>
                <EnhancedFormControl
                  id="email"
                  name="email"
                  type="text"
                  value={staffData.basicInformation.email}
                  onChange={(e) =>
                    setField("basicInformation", "email", e.target.value)
                  }
                  isInvalid={!!errors.basicInformation.email}
                  placeholder={t("email")}
                  label={t("email")}
                  errors={errors.basicInformation.email}
                  groupClassName="w-100"
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="staff-edit-and-create-card">
        <Card.Header>
          <h3 className="mb-0">
            <Trans>available_locations</Trans>
          </h3>
        </Card.Header>
        <Card.Body>
          {loadingLocations ? (
            <div className="d-flex justify-content-center align-items-centerf">
              <Lottie
                animationData={loadingAnimation}
                loop={true}
                style={{ height: "220px", width: "220px" }}
              />
            </div>
          ) : (
            <>
              <div className="form-check mb-4">
                <input
                  ref={allLocationsCheckboxRef}
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="la-all-checkbox"
                  name="la-all-checkbox"
                  onChange={onChangeAllLocations}
                />
                <label className="form-check-label" htmlFor="la-all-checkbox">
                  <Trans>select_all</Trans>
                </label>
              </div>
              <div className="d-flex align-items-start gap-3">
                {locationAddresses}
              </div>
              <div className="text-danger invalid-feedback">
                {errors.basicInformation.selectedLocations}
              </div>
            </>
          )}
        </Card.Body>
      </Card>
      <Card className="staff-edit-and-create-card">
        <Card.Header>
          <h3 className="mb-0">
            <Trans>admin_settings</Trans>
          </h3>
        </Card.Header>
        <Card.Body>
          <Form.Check
            type="checkbox"
            id="isAdminCheckbox"
            label={t("user_with_admin_rights")}
            checked={staffData.basicInformation.isAdmin}
            onChange={(e) =>
              setStaffData({
                ...staffData,
                basicInformation: {
                  ...staffData.basicInformation,
                  isAdmin: e.target.checked,
                },
              })
            }
          />
        </Card.Body>
      </Card>
      <Card className="staff-edit-and-create-card">
        <Card.Header
          className={classNames(
            "d-flex justify-content-between align-items-center align-self-stretch",
            { "border-bottom-0": !openWorkingHours }
          )}
        >
          <h3 className="mb-0">
            <Trans>working_hours</Trans>
          </h3>
          <Button
            name="workingHoursCollapseButton"
            variant="grey"
            size="sm"
            onClick={() => setOpenWorkingHours(!openWorkingHours)}
            aria-controls="collapse-working-hours"
            aria-expanded={openWorkingHours}
          >
            {openWorkingHours ? (
              <>
                <ZymranIcon name="chevron-up" />
                <Trans>hide_standard_timetable</Trans>
              </>
            ) : (
              <>
                <ZymranIcon name="chevron-down" />
                <Trans>set_standard_timetable</Trans>
              </>
            )}
          </Button>
        </Card.Header>
        <Collapse in={openWorkingHours}>
          <Card.Body className="p-0">
            <div id="collapse-working-hours">
              <div className="staff-work-time-card-row">
                <DayOfWeekSchedule
                  dayOfWeek={"monday"}
                  dayOfWeekData={staffData.staffSchedule["monday"]}
                  selectedLocationOptions={selectedLocationOptions}
                  getDataByDayOfWeek={getDataByDayOfWeek}
                  setDataByDayOfWeek={setDataByDayOfWeek}
                  errors={errors}
                />
                <DayOfWeekSchedule
                  dayOfWeek={"tuesday"}
                  dayOfWeekData={staffData.staffSchedule["tuesday"]}
                  selectedLocationOptions={selectedLocationOptions}
                  getDataByDayOfWeek={getDataByDayOfWeek}
                  setDataByDayOfWeek={setDataByDayOfWeek}
                  errors={errors}
                />
              </div>
              <div className="staff-work-time-card-row">
                <DayOfWeekSchedule
                  dayOfWeek={"wednesday"}
                  dayOfWeekData={staffData.staffSchedule["wednesday"]}
                  selectedLocationOptions={selectedLocationOptions}
                  getDataByDayOfWeek={getDataByDayOfWeek}
                  setDataByDayOfWeek={setDataByDayOfWeek}
                  errors={errors}
                />
                <DayOfWeekSchedule
                  dayOfWeek={"thursday"}
                  dayOfWeekData={staffData.staffSchedule["thursday"]}
                  selectedLocationOptions={selectedLocationOptions}
                  getDataByDayOfWeek={getDataByDayOfWeek}
                  setDataByDayOfWeek={setDataByDayOfWeek}
                  errors={errors}
                />
              </div>
              <div className="staff-work-time-card-row">
                <DayOfWeekSchedule
                  dayOfWeek={"friday"}
                  dayOfWeekData={staffData.staffSchedule["friday"]}
                  selectedLocationOptions={selectedLocationOptions}
                  getDataByDayOfWeek={getDataByDayOfWeek}
                  setDataByDayOfWeek={setDataByDayOfWeek}
                  errors={errors}
                />
                <DayOfWeekSchedule
                  dayOfWeek={"saturday"}
                  dayOfWeekData={staffData.staffSchedule["saturday"]}
                  selectedLocationOptions={selectedLocationOptions}
                  getDataByDayOfWeek={getDataByDayOfWeek}
                  setDataByDayOfWeek={setDataByDayOfWeek}
                  errors={errors}
                />
              </div>
              <div className="staff-work-time-card-row">
                <DayOfWeekSchedule
                  dayOfWeek={"sunday"}
                  dayOfWeekData={staffData.staffSchedule["sunday"]}
                  selectedLocationOptions={selectedLocationOptions}
                  getDataByDayOfWeek={getDataByDayOfWeek}
                  setDataByDayOfWeek={setDataByDayOfWeek}
                  errors={errors}
                />
                <div className="staff-work-time-card"></div>
              </div>
            </div>
          </Card.Body>
        </Collapse>
      </Card>
      <Card className="staff-edit-and-create-card">
        <Card.Header
          className={classNames(
            "d-flex justify-content-between align-items-center align-self-stretch",
            { "border-bottom-0": !openSalary }
          )}
        >
          <h3 className="mb-0">
            <Trans>salary</Trans>
          </h3>
          <Button
            name="salaryCollapseButton"
            variant="grey"
            size="sm"
            onClick={() => setOpenSalary(!openSalary)}
            aria-controls="collapse-salary"
            aria-expanded={openSalary}
          >
            {openSalary ? (
              <>
                <ZymranIcon name="chevron-up" />
                <Trans>hide_salary</Trans>
              </>
            ) : (
              <>
                <ZymranIcon name="chevron-down" />
                <Trans>set_salary</Trans>
              </>
            )}
          </Button>
        </Card.Header>
        <Collapse in={openSalary}>
          <Card.Body className="p-0">
            <div id="collapse-salary" className="primary-text-color">
              <div className="salary-block">
                <div className="d-flex align-items-center gap-4">
                  <p className="p1-bold mb-0">
                    <Trans>wage</Trans>
                  </p>
                  <Form.Group className="d-flex align-items-center gap-3">
                    <Form.Check
                      type="radio"
                      label={t("for_month")}
                      name="radioOptionForMonth"
                      id="radioOptionForMonth"
                      checked={staffData.salary.period === "month"}
                      onChange={() => handlePeriodChange("month")}
                    />
                    <Form.Check
                      type="radio"
                      label={t("for_week")}
                      name="radioOptionForWeek"
                      id="radioOptionForWeek"
                      checked={staffData.salary.period === "week"}
                      onChange={() => handlePeriodChange("week")}
                    />
                    <Form.Check
                      type="radio"
                      label={t("for_day")}
                      name="radioOptionForDay"
                      id="radioOptionForDay"
                      checked={staffData.salary.period === "day"}
                      onChange={() => handlePeriodChange("day")}
                    />
                  </Form.Group>
                </div>
                <p className="mb-0 p3">
                  <Trans>staff_salary_decription</Trans>
                </p>
                <EnhancedFormControlWithToggle
                  id="salary"
                  name="salary"
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  toggleOptions={salaryToggleOptions}
                  value={staffData.salary.salary}
                  onChange={(e) =>
                    setSalaryValue(
                      e.target.value === "" ? "" : parseFloat(e.target.value)
                    )
                  }
                  isInvalid={!!errors.salary.salary}
                  placeholder={t("salary")}
                  label={t("salary")}
                  errors={errors.salary.salary}
                  groupClassName="salary-input"
                  onToggleChange={handleSalaryToggleChange}
                  initiallySelectedOption={
                    staffData.salary.isPercentage ? "percentage" : "tenge"
                  }
                />
              </div>
              <SalaryForCategoriesSection
                staffData={staffData}
                setStaffData={setStaffData}
                toggleOptions={salaryToggleOptions}
                errors={errors}
                setErrors={setErrors}
              />
              <SalaryForServicesSection
                staffData={staffData}
                setStaffData={setStaffData}
                toggleOptions={salaryToggleOptions}
                errors={errors}
                setErrors={setErrors}
              />
            </div>
          </Card.Body>
        </Collapse>
      </Card>
      <BlockUI tag="div" blocking={blocking} />
      <div className="d-flex flex-row justify-content-end gap-3 staff-edit-and-create-buttons">
        <Button name="cancelButton" variant="grey" onClick={handleClose}>
          <Trans>cancel</Trans>
        </Button>
        <Button
          name="saveEmployeeButton"
          type="submit"
          variant="blue"
          onClick={handleSubmit}
        >
          <Trans>save_employee</Trans>
        </Button>
      </div>
    </div>
  );
};

export default CreateStaff;
