import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";

const CodeInput = ({ numDigits, code, onChange, errors }) => {
  const [phoneCode, setPhoneCode] = useState(new Array(numDigits).fill(""));
  const inputRefs = useRef([]);

  const handleInputChange = (event, index) => {
    const value = event.target.value;
    const phoneCodeCopy = [...phoneCode];
    phoneCodeCopy[index] = value;
    setPhoneCode(phoneCodeCopy);

    // Move to the next input field if the current input field is filled
    if (value !== "" && index < numDigits - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleInputKeyDown = (event, index) => {
    if (event.key === "Backspace" && phoneCode[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  useEffect(() => {
    onChange(phoneCode.join(""));
    // eslint-disable-next-line
  }, [phoneCode]);

  function handlePaste(event) {
    const paste = (event.clipboardData || window.clipboardData).getData("text");
    if (paste.length !== numDigits || isNaN(paste)) {
      // Not a valid OTP code, do nothing
      return;
    }
    // Update input values with pasted code
    const newValues = paste.split("");
    setPhoneCode(newValues);

    // Move focus to next input field
    for (let i = 0; i < numDigits; i++) {
      if (inputRefs.current[i].current === document.activeElement) {
        if (i < numDigits - 1) {
          inputRefs.current[i + 1].current.focus();
        }
        break;
      }
    }
  }

  const inputFields = phoneCode.map((value, index) => (
    <Form.Control
      key={index}
      type="text"
      maxLength={1}
      value={value}
      className="form-control single-digit-input"
      onChange={(event) => handleInputChange(event, index)}
      onKeyDown={handleInputKeyDown}
      onPaste={handlePaste}
      ref={(ref) => (inputRefs.current[index] = ref)}
      isInvalid={!!errors.code}
    />
  ));

  return (
    <div
      className={
        "phone-code-input-group " + (!!errors.code ? "is-invalid" : "")
      }
    >
      {inputFields}
    </div>
  );
};

export default CodeInput;
