import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getExpenses = () => {
  return http.get(`finance/expense`, {
    headers: authHeader(),
  });
};

const getExpenseByCode = (code) => {
  return http.get(`finance/expense/${code}`, { headers: authHeader() });
};

const createExpenseRecord = (payload) => {
  return http.post(`finance/expense/manual`, payload, {
    headers: authHeader(),
  });
};

export { getExpenses, getExpenseByCode, createExpenseRecord };
