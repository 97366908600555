import http from "../../http-common";
import { authHeader } from "../auth/auth-header";

const getSalaryAll = (payload) => {
  return http.post(`finance/salary/all`, payload, { headers: authHeader() });
};

const getSalaryProcessData = (payload) => {
  return http.post(`finance/salary/process`, payload, {
    headers: authHeader(),
  });
};

const finalizeSalary = (payload) => {
  return http.post(`finance/salary/finalize`, payload, {
    headers: authHeader(),
  });
};

export { getSalaryAll, getSalaryProcessData, finalizeSalary };
