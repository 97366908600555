import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import loadingAnimation from "assets/img/animated-icons/loading.json";
import notFoundAnimation from "assets/img/animated-icons/not-found.json";
import classNames from "classnames";
import ZymranIcon from "component/common/ZymranIcon";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { Accordion, Button, Card, Modal, Table } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { getCashRegisters, updateCashRegisters } from "service/cash-register/CashRegisterService";
import { notifySuccess } from "util/notify";
import CreateCashRegister from "./CreateCashRegister";
import EditCashRegister from "./EditCashRegister";

const CashRegister = () => {
  const [cashRegistersGroupedByType, setCashRegistersGroupedByType] = useState(
    []
  );
  const [selectedCashRegisterGroup, setSelectedCashRegisterGroup] = useState(
    {}
  );
  const [selectedCashRegisterGroupId, setSelectedCashRegisterGroupId] =
    useState(0);
  const [cashRegistersDataList, setCashRegistersDataList] = useState([]);
  const [showCreateCashRegisterModal, setShowCreateCashRegisterModal] =
    useState(false);
  const [showEditCashRegisterModal, setShowEditCashRegisterModal] =
    useState(false);
  const [showDeleteCashRegisterModal, setShowDeleteCashRegisterModal] =
    useState(false);
  // const [totalZymranBalance, setTotalZymranBalance] = useState(0);
  const [blocking, setBlocking] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    loadCashRegistersGroupedByType();
  }, []);

  useEffect(() => {
    prepareCashRegistersTable();
    // eslint-disable-next-line
  }, [cashRegistersGroupedByType]);

  const handleCreateCashRegisterModalClose = () => {
    setShowCreateCashRegisterModal(false);
  };

  const handleEditCashRegisterModalClose = () => {
    setShowEditCashRegisterModal(false);
  };

  const handleShowEditCashRegisterModal = (
    cashRegisterGroup,
    cashRegisterGroupId
  ) => {
    setSelectedCashRegisterGroup(cashRegisterGroup);
    setSelectedCashRegisterGroupId(cashRegisterGroupId);
    setShowEditCashRegisterModal(true);
  };

  const handleShowDeleteCashRegisterModal = (
    cashRegisterGroup,
    cashRegisterGroupId
  ) => {
    setSelectedCashRegisterGroup(cashRegisterGroup);
    setSelectedCashRegisterGroupId(cashRegisterGroupId);
    setShowDeleteCashRegisterModal(true);
  };

  const handleUpdate = (cashRegisterGroupWithKey) => {
    setCashRegistersGroupedByType((prevState) => {
      return { ...prevState, ...cashRegisterGroupWithKey };
    });
  };

  const handleCreate = (newCashRegisterGroupWithKey) => {
    setCashRegistersGroupedByType((prevState) => {
      const newState = { ...prevState };
      for (const [cashRegisterId, newCashRegisterData] of Object.entries(
        newCashRegisterGroupWithKey
      )) {
        if (cashRegisterId in newState) {
          newState[cashRegisterId].locations = [
            ...newState[cashRegisterId].locations,
            ...newCashRegisterData.locations,
          ];
          newState[cashRegisterId].balances = {
            ...newState[cashRegisterId].balances,
            ...newCashRegisterData.balances,
          };
        } else {
          newState[cashRegisterId] = { ...newCashRegisterData };
        }
      }

      return newState;
    });
  };

  const onDelete = (cashRegisterGroupWithKey) => {
    if (Object.keys(cashRegisterGroupWithKey).length === 0) {
      return; // if cashRegisterGroupToRemove is empty, do nothing
    }
    setCashRegistersGroupedByType((prevState) => {
      const newState = { ...prevState };
      const keyToRemove = Object.keys(cashRegisterGroupWithKey)[0];
      delete newState[keyToRemove];
      return newState;
    });
  };

  const handleDelete = async () => {
    setBlocking(true);
    let payload = {};
    payload.cashRegisterTypeId = selectedCashRegisterGroupId;
    payload.locations = [];
    try {
      const response = await updateCashRegisters(payload);
      notifySuccess(t("cash_register_deleted"));
      onDelete(response.data);
      setShowDeleteCashRegisterModal(false);
    } catch (error) {
      console.log("ERROR: ", error);
    } finally {
      setBlocking(false);
    }
  };

  const loadCashRegistersGroupedByType = async () => {
    setLoading(true);
    try {
      const response = await getCashRegisters();
      setCashRegistersGroupedByType(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const prepareCashRegistersTable = () => {
    const cashRegistersDataList = Object.keys(cashRegistersGroupedByType).map(
      (key, index, { length }) => {
        const cashRegisterGroup = cashRegistersGroupedByType[key];
        const cashRegisterGroupId = key;
        const balances = cashRegisterGroup.balances;
        const balanceSum = Object.values(balances).reduce(
          (acc, cur) => acc + cur,
          0
        );
        const rows = cashRegisterGroup.locations.map((location) => ({
          name: cashRegisterGroup.name,
          locationName: location.name,
          locationId: location.id,
          balance: cashRegisterGroup.balances[location.id] || 0,
        }));
        return (
          <Card
            key={cashRegisterGroup.name}
            className={classNames("shadow-none")}
          >
            <Accordion.Item
              eventKey={cashRegisterGroup.name}
              className="border-0"
            >
              <Card.Header className="d-flex flex-row align-items-center">
                <Accordion.Button className="btn btn-link text-decoration-none d-flex flex-row w-100 py-2 px-3 border-0 rounded-0 text-start shadow-none">
                  <FontAwesomeIcon
                    icon="caret-right"
                    className="accordion-icon me-3 "
                    transform="shrink-2"
                  />
                  <div className="d-flex flex-column primary-text-color">
                    <h3 className="mb-0">
                      <Trans>{`cash_register_type_${cashRegisterGroup.name}`}</Trans>
                    </h3>
                    <span className="p1">
                      {cashRegisterGroup.locations
                        .map((location) => location.name)
                        .join(", ")}
                    </span>
                  </div>
                  <span className="p1-bold secondary-text-color ms-auto me-3">
                    <Trans>balance</Trans>: {balanceSum}
                  </span>
                </Accordion.Button>
                <button
                  type="button"
                  name={`deleteCashRegister-${index}`}
                  className="icon-button icon-button--grey-light icon-button--sm me-2"
                  onClick={() =>
                    handleShowDeleteCashRegisterModal(
                      cashRegisterGroup,
                      cashRegisterGroupId
                    )
                  }
                >
                  <ZymranIcon name="delete" />
                </button>
                <button
                  type="button"
                  name={`editCashRegister-${index}`}
                  className="icon-button icon-button--grey-light icon-button--sm"
                  onClick={() =>
                    handleShowEditCashRegisterModal(
                      cashRegisterGroup,
                      cashRegisterGroupId
                    )
                  }
                >
                  <ZymranIcon name="pen" />
                </button>
              </Card.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="pt-2">
                  <div className="table-responsive table-rounded-wrapper">
                    <Table bordered className="services-table mb-0">
                      <thead className="p2-bold secondary-text-color">
                        <tr>
                          <th>
                            <Trans>name</Trans>
                          </th>
                          <th>
                            <Trans>location</Trans>
                          </th>
                          <th>
                            <Trans>balance</Trans>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="p2 primary-text-color">
                        {rows.map((row, index) => (
                          <tr key={index}>
                            {index === 0 && (
                              <td
                                rowSpan={rows.length}
                                className="border-bottom-0"
                              >
                                <Trans>{`cash_register_type_${row.name}`}</Trans>
                              </td>
                            )}
                            <td>{row.locationName}</td>
                            <td>{row.balance}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>
          </Card>
        );
      }
    );

    setCashRegistersDataList(cashRegistersDataList);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="mb-0">
          <Trans>payment_method_cash_register</Trans>
        </h1>
        <Button
          variant="blue"
          name="addCashRegisterButton"
          size="sm"
          onClick={() => setShowCreateCashRegisterModal(true)}
        >
          <ZymranIcon name="plus" />
          <Trans>add_payment_method</Trans>
        </Button>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center align-items-centerf">
          <Lottie
            animationData={loadingAnimation}
            loop={true}
            style={{ height: "220px", width: "220px" }}
          />
        </div>
      ) : Object.keys(cashRegistersGroupedByType).length > 0 ? (
        <>
          <Accordion
            id="accordionFaq"
            className="rounded services-accordion mb-3"
            alwaysOpen
          >
            {cashRegistersDataList}
          </Accordion>
        </>
      ) : (
        <Card>
          <div className="d-flex flex-column justify-content-center align-items-center mb-3">
            <Lottie
              animationData={notFoundAnimation}
              loop={true}
              style={{ height: "150px", width: "150px" }}
            />
            <span className="text-info">
              <Trans>no_data</Trans>
            </span>
          </div>
        </Card>
      )}

      <Modal
        show={showCreateCashRegisterModal}
        onHide={handleCreateCashRegisterModalClose}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title>
            <Trans>create</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateCashRegister
            onCreate={handleCreate}
            handleClose={handleCreateCashRegisterModalClose}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditCashRegisterModal}
        onHide={handleEditCashRegisterModalClose}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title>
            <Trans>edit</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditCashRegister
            selectedCashRegisterGroup={selectedCashRegisterGroup}
            selectedCashRegisterGroupId={selectedCashRegisterGroupId}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            handleClose={handleEditCashRegisterModalClose}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteCashRegisterModal}
        onHide={() => setShowDeleteCashRegisterModal(false)}
        className="confirmation-modal"
        centered
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center align-self-stretch text-center">
            <h2>
              <Trans>do_you_want_to_delete_cash_register</Trans> "
              <Trans>{`cash_register_type_${selectedCashRegisterGroup.name}`}</Trans>
              "?
            </h2>
            <p className="mb-0 p1 text-warning">
              <Trans>cash_register_delete_warning</Trans>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="orange"
            name="confirmDeleteButton"
            size="sm"
            className="flex-grow-1"
            onClick={handleDelete}
          >
            <Trans>delete_payment_method</Trans>
          </Button>
          <Button
            variant="blue"
            name="cancelDeleteButton"
            size="sm"
            className="flex-grow-1"
            onClick={() => setShowDeleteCashRegisterModal(false)}
          >
            <Trans>cancel</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CashRegister;
